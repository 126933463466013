import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { authActions } from '../../../state/ducks/auth';
import { providersActions } from '../../../state/ducks/providers';
import { Loading, Toggle } from '../../../components';
import FormLogin from './components/FormLogin';
import FormRecover from './components/FormRecover';
import AmexLogo from '../../../resources/images/assets/amex.png';
import './styles.scss';
import Modal from '../../../components/Modal';
import { TOAST_CONFIG } from '../../../config/constants';
import Fingerprint2 from 'fingerprintjs2';
import logoDos from '../../../resources/images/assets/logo-c.png';
import BusinessLink from '../../../resources/images/assets/components/BusinessLink';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidMount = () => {
    const { auth: { language } } = this.props;
    this.setState({ language });
  
    var promise = new Promise( (resolve) => {

      var options = {
        excludes: {
          touchSupport: true,
          fontsFlash: true,
          screenResolution: true,
          pixelRatio: true,
          colorDepth: true,
          availableScreenResolution: true,
        }
      };

      Fingerprint2.get(options, function (components) {
        var values = components.map(function (component) {
          return component.value;
        });
        var murmur = Fingerprint2.x64hash128(values.join(''), 31);
        resolve(murmur);
      });
    });
    
    promise.then( result => {
      this.setState({device_info: result});
    });
    //loadReCaptcha();
    // if(window.location.hostname === 'localhost'){
      this.setState({robot: false});
    // }
  };
  
  verifyHuman = () => {
    this.setState({robot: false});
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = (event) => {
    
    event.preventDefault();
    const { signIn, showMessage, t } = this.props;
    const { ...data } = this.state;
    const { robot } = this.state;

    if(!robot){
      signIn({ data });
    }else{
      showMessage({
        message: t('verifyHuman'),
        config: TOAST_CONFIG.INFO,
      });
    }
  };

  handleReset = (onToggle) => {
    const { t, passwordReset, history, showMessage } = this.props;
    const { username } = this.state;
    const email = username;
    passwordReset({
      email,
      callback: (response) => {
        showMessage({
          message: response.data.message,//t('successEmail'),
          config: TOAST_CONFIG.SUCCESS,
        });
        onToggle();
        this.resetState();
      },
    });
  };
  getInitialState = () => ({
    language: '',
    username: '',
    password: '',
    device_info: '',
    robot: true,
    icon: 'fa fa-eye',
    inputType: 'password'
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  handleVerificationSuccess = (token, ekey) => {
    this.setState({
      robot: false,
    });
  }

  handleClickIcon = () => {
    const { inputType } = this.state;
    const isText = (inputType === 'text');
    this.setState({
      inputType: isText ? 'password' : 'text',
      icon: isText ? 'fa fa-eye' : 'fa fa-eye-slash',
    });
  };

  render() {
    const {
      auth: { logged, loading, user },
      t,
    } = this.props;
    const { language, username, password, device_info, icon, inputType } = this.state;
    if (logged === true) {
      if(user.first_login === 1){
        if(user.b2b.type_user === 'seller'){
          return <Redirect to="/confirm-provider"/>;
        }else if(user.b2b.type_user === 'buyer'){
          return <Redirect to="/confirm-customer"/>;
        }
      }else if (user.b2b.password_changed === 0) {
        return <Redirect to="/recover-password"/>;
      } else if (user.b2b.password_changed === -1) {
        return <Redirect to="/validate-code"/>;
      } else {
        if ((user.b2b.registered_card === 0 || user.b2b.pending_payment_condition.length > 0) && ((user.b2b.type_user === 'buyer') && (user.b2b.roles[0].owner_id === null))) {
          if (user.b2b.registered_card === 0) {
            return <Redirect to="/register-card"/>;
          }
          if (user.b2b.pending_payment_condition.length > 0) {
            return <Redirect to="/accept-discount-rate"/>;
          }
        } else {
          return <Redirect to="/dashboard"/>;
        }
      }
    }

    return (
      <Toggle>
        {({ toggled, onToggle }) => (
          <Fragment>
            {
              toggled
              && (
                <Modal onToggle={onToggle}>
                  <FormRecover
                    handleReset={() => this.handleReset(onToggle)}
                    handleInputChange={this.handleInputChange}
                    data={{ username }}
                  />
                </Modal>
              )
            }
            <div className="SignIn">
              <ToastContainer/>
              {loading && <Loading/>}

              {/* HERO */}
              <section className="sign-in-hero"> 
                  <div className="hero-filter"></div>
                  <figure className="image is-128x128">
                    <img src={AmexLogo} alt="logo" />
                  </figure>
                  <h2>Don't do business without it.</h2>
              </section>
              
              {/* FORM MAIN CONTAINER */}
              <section className="sign-in-form">
                {/* LANGUAGES SELECT */}
                <div className="sign-in-language">
                  <div className="field">
                    <label htmlFor="language" className="label has-text-white">
                      {t('language')}
                      <div className="control has-icons-left">
                        <div className="select is-small">
                          <select 
                            name="language" 
                            value={language}
                            onChange={this.handleSelectChange}
                          >
                            <option value="en">{t('en')}</option>
                            <option value="es">{t('es')}</option>
                            <option value="fr">{t('fr')}</option>
                            <option value="pt">{t('pt')}</option>
                            <option value="zh">{t('中文')}</option>
                          </select>
                        </div>
                        <div className="icon is-small is-left">
                          <i className="fas fa-globe"/>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                {/* B2B LOGO */}
                <BusinessLink width="250" height="100" />
                {/* FORM */}
                <div className="login-form">
                  <FormLogin
                    handleSubmit={this.handleSubmit}
                    handleInputChange={this.handleInputChange}
                    data={{
                      username,
                      password,
                      device_info
                    }}
                    handleClickIcon={this.handleClickIcon}
                    options={{
                      icon,
                      inputType
                    }}
                  />
                  {/* <div className="recaptcha-box">
                    <div className="captcha-container">
                      <HCaptcha
                        sitekey="15d6a7e0-1ba4-4252-90be-f52d7260c679"
                        onVerify={(token,ekey) => this.handleVerificationSuccess(token, ekey)}
                      />
                    </div> 
                  </div> */}
                  <div className="center link-chile" style={{position: 'relative', zIndex: 999, marginTop: '1em'}}>
                    <a href={'#'}
                      onClick={() => {
                        onToggle({
                          isEdit: true,
                        });
                      }}
                    >
                      {t('forgotPassword')}
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </Fragment>
        )}
      </Toggle>
    );
  }
}

SignIn.propTypes = {
  signIn: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  passwordReset: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, providers }) => (
  {
    auth,
    providers,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...providersActions,
  }),
  withNamespaces(),
)(SignIn);
