import React from 'react';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';

const SearchPersonForm = ({
                              t,
                              searchPerson,
                            handleInputChange,
                            document_number,
                            name,
                            email,
                            min_amount,
                            max_amount,
                            type_user,
                            user,
                            rol,
                            rut,
                            type_rol,
                          }) => (
    <Panel>
        <Form
        submitText={t('search')}
        onSubmit={(event) => {
            event.preventDefault();
            searchPerson();
        }}
        buttonClasses="mr-8px"
        >
            <div className="columns field-body" style={{ display: type_user === '' ? '' : 'none' }}>
                <div className="column">
                    <TextInput
                      name="name"
                      label={t('businessName')}
                      value={name}
                      onChange={handleInputChange}
                      placeholder={t('businessName')}
                      type="text"
                      controlClasses="is-expanded has-icons-left"
                      iconPosition="is-left"
                    icon="fas fa-search"
                    />
                </div>
                <div className="column">
                    <TextInput
                    name="document_number"
                    label={t('taxId')} // t('documentNumber')
                    value={document_number}
                    onChange={handleInputChange}
                    placeholder="11111111-1"
                    type="text"
                    controlClasses="is-expanded has-icons-left"
                    inputClasses="has-text-right"
                    iconPosition="is-left"
                    icon="fas fa-search"
                    />
                </div>
                <div className="column">
                    <TextInput
                      name="email"
                      label={t('email')}
                      value={email}
                      onChange={handleInputChange}
                      placeholder={t('email')}
                      type="text"
                      controlClasses="is-expanded"
                    />
                    <TextInput
                      name="max_amount"
                      value="9999999999"
                      type="hidden"
                    />
                </div>
            </div>
            <div className="columns field-body" style={{ display: type_user !== '' ? '' : 'none' }}>
                <div className="column">
                  <TextInput
                    name="user"
                    label={t('user')}
                    value={user}
                    onChange={handleInputChange}
                    placeholder={t('user')}
                    type="text"
                    controlClasses="is-expanded"
                  />
                </div>
              <div className="column"
                   style={{ display: type_rol === 'general_admin' ? 'none' : '' }}>
                <TextInput
                  name="rol"
                  label={t('rol')}
                  value={rol}
                  onChange={handleInputChange}
                  placeholder={t('rol')}
                  type="text"
                  controlClasses="is-expanded"
                />
              </div>
                <div className="column">
                    <TextInput
                      name="email"
                      label={t('email')}
                      value={email}
                      onChange={handleInputChange}
                      placeholder={t('email')}
                      type="text"
                      controlClasses="is-expanded"
                    />
                </div>
                <div className="column">
                    <TextInput
                      name="rut"
                      label={t('taxId')} // t('documentNumber')
                      value={rut}
                      onChange={handleInputChange}
                      placeholder={t('taxId')} // t('documentNumber')
                      type="text"
                      controlClasses="is-expanded"
                      inputClasses="has-text-right"
                    />
                </div>
            </div>
        </Form>
    </Panel>
);
SearchPersonForm.defaultProps = {
  type_user: '',
  type_rol: '',
};
export default SearchPersonForm;