import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';
import { formatAmount } from '../../util';

const InformativeBox = ({
                          title, bigValue, bigValue1, icon, size, typeCurrency, typeCurrency1,
                        }) => (
  <div className={`column ${size} is-flex`}>
    <div className="information-box ">
      {/* <div className="icon"><i className={icon} /></div> */}
      <div className="content-title">
        <div className="title">{title}</div>
      </div>
      <div className="big-value">
        <div className="content">
          {typeCurrency === '' && (
            <div>
              {bigValue}
            </div>
          )}
          {typeCurrency !== '' && (
            <div>
              <div className="columns field-body">
                <div className="column" ><span
                  style={{ fontSize: '20px' }}>{typeCurrency}&nbsp;</span></div>
                <div className="column"><span style={{ float: 'right' }}>{bigValue}</span></div>
              </div>
              <div className="columns field-body">
                <div className="column" ><span
                  style={{ fontSize: '20px' }}>{typeCurrency1}&nbsp;</span></div>
                <div className="column"><span style={{ float: 'right' }}>{bigValue1}</span></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

InformativeBox.propTypes = {
  title: PropTypes.string.isRequired,
  bigValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  bigValue1: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  icon: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};
InformativeBox.defaultProps = {
  typeCurrency: '',
  typeCurrency1: '',
};

export default withNamespaces()(InformativeBox);
