const SAVE_CREDIT = 'credits/SAVE_CREDIT';
const UPDATE_AMOUNTS = 'credits/UPDATE_AMOUNTS';
const SET_USED_FOR = 'credits/SET_USED_FOR';
const MESSAGE = 'credits/MESSAGE';
const API_CALL = 'webpay/API_CALL';
const START_FETCH = 'clients/START_FETCH';
const END_FETCH = 'clients/END_FETCH';
const END_ERROR = 'clients/END_ERROR';

export default {
  SAVE_CREDIT,
  UPDATE_AMOUNTS,
  SET_USED_FOR,
  MESSAGE,
  API_CALL,
  START_FETCH,
  END_FETCH,
  END_ERROR,
};
