import React, { useState } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { Panel } from '../../../../components';
import { formatCurrencyCl } from '../../../../util';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { transactionsActions } from '../../../../state/ducks/transactions';
import { clientsActions } from '../../../../state/ducks/clients';
import { providersActions } from '../../../../state/ducks/providers';
import { creditsActions } from '../../../../state/ducks/credits';
import { TOAST_CONFIG } from '../../../../config/constants';

const MixedPaymentsForm = ({ 
    onToggle, 
    transactions: { currentInvoice: { invoice_amount, invoice_number, id } }, 
    credits: { usedFor, availableAmounts: { accountCredit, amexCredit, preApprovedCredit } },  
    handleForm,
    amount: { amexAmount, creditAmount, arrangeAmount },
    setUsedFor,
    approveInvoice,
    updateAmounts,
    listTransactionsSender,
    listTransactionsReceiver,
    auth: { user: { user_id, profile, b2b: { type_user } } },
    showMessage,
    t,
    setStep,
}) => {

    // const [amount, setAmount] = useState({ amexAmount: 0, creditAmount: 0, arrangeAmount: 0 });
    const [needCredit, setNeedCredit] = useState({ amexCredits: false, creditCredit: false, arrangeCredit: false });
    // const { amexAmount, creditAmount, arrangeAmount } = amount;
    const { amexCredits, creditCredit, arrangeCredit } = needCredit;

    const handleChange = e => {
        const { valueAsNumber, name } = e.target;
        // handleForm({...amount, [name]: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber})
        handleForm({[name]: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber})
    }
    const handleClick = e => {
        const { name } = e.target;
        setNeedCredit({...needCredit, [name]: !needCredit[name]})
    }

    const approveInvoices = (transaction_id/*, onToggle*/) => {
        const data = {
          transaction_id,
          approved: true,
          user_id: profile.user_id,
          role_id: profile.role_id,
          provider: type_user === 'seller' ? true : false,
          file_sii: '',
          file_pass: '',
        //   file_sii: binaryFile,
        //   file_pass: password,
        };
    
        approveInvoice({
          data,
          callback: (resp) => {
            type_user === 'buyer' ? listTransactionsSender({ params: { user_id } })
              : listTransactionsReceiver({ params: { user_id } });
            if (resp.process) {
              showMessage({
                message: t('invoiceApprovedSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            } else {
              showMessage({
                message: t('actionNotAllowed'),
                config: TOAST_CONFIG.ERROR,
              });
            }
            //  onToggle();
          },
        });
        //onToggle();
    };
    
    return (
        <>
        <div className='MixedPayments__main'>
            <h2>{t('invoiceNumber')} {invoice_number}</h2>
            <div className='Panel__extra-title'>
                <p>{t('balanceToPay')}</p>
                <p>${formatCurrencyCl(Number(invoice_amount))}</p>
            </div>
            <div className='Panel__extra-title'>
                <p>{t('mixedBalance')}</p>
                <p>${formatCurrencyCl(amexAmount + creditAmount + arrangeAmount)}</p>
            </div>
            <Panel>
                <form>
                    <div className='form-field'>
                        <input 
                            type='radio'
                            name='amexCredits'
                            checked={amexCredits}
                            onClick={e => handleClick(e)}
                            />
                        <i className="fab fa-cc-amex"></i>
                        <div className='special-field'>
                            <label>{t('available')}</label>
                            <input 
                                className='custom-input' 
                                style={{ marginRight: '30px', minWidth: '350px' }} 
                                value={`${t('creditCard')} $${formatCurrencyCl(amexCredit)}`}
                                disabled/>
                        </div>
                        <div className='special-field'>
                            <label>{t('amountToRequest')}</label>
                            <input 
                                className='custom-input' 
                                placeholder='$0,00'
                                type='number'
                                name='amexAmount'
                                disabled={!amexCredits}
                                onChange={e => handleChange(e)}/>
                        </div>
                    </div>
                    <div className='form-field'>
                        {/* <p>Cuenta corriente</p>
                        <div> */}
                            <input 
                                type='radio'
                                name='creditCredit'
                                checked={creditCredit}
                                onClick={e => handleClick(e)}/>
                            <i class="fas fa-credit-card"></i>
                            <input 
                                className='custom-input' 
                                style={{ marginRight: '30px', minWidth: '350px' }} 
                                value={`${t('accountBalance')} $${formatCurrencyCl(accountCredit)}`}
                                disabled/>
                            <input 
                                className='custom-input' 
                                placeholder='$0,00'
                                type='number'
                                name='creditAmount'
                                disabled={!creditCredit}
                                onChange={e => handleChange(e)}/>
                        {/* </div> */}
                    </div>
                    <div className='form-field'>
                        <input 
                            type='radio'
                            name='arrangeCredit'
                            checked={arrangeCredit}
                            onClick={e => handleClick(e)}/>
                        <i class="fas fa-handshake"></i>
                        <input 
                            className='custom-input' 
                            style={{ marginRight: '30px', minWidth: '350px' }} 
                            value={`${t('creditAvailableBalance')} $${formatCurrencyCl(preApprovedCredit)}`}
                            disabled/>
                        <input 
                            className='custom-input' 
                            placeholder='$0,00'
                            type='number'
                            name='arrangeAmount'
                            disabled={!arrangeCredit}
                            onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-BTNS'>
                        <Link to='/review-invoices' className='button custom-btn is-danger'>{t('return')}</Link>

                        <button 
                            className='button custom-btn is-primary'
                            disabled={invoice_amount != (amexAmount + creditAmount + arrangeAmount)}
                            onClick={e => {
                                e.preventDefault();
                                if(arrangeCredit && arrangeAmount !== 0) {
                                    setStep('creditRequest')

                                } else {
                                    approveInvoices(id)
                                    onToggle()
                                    updateAmounts({
                                        accountCredit: accountCredit-creditAmount,
                                        amexCredit: amexCredit-amexAmount, 
                                        preApprovedCredit: preApprovedCredit-arrangeAmount,
                                    })
                                }
                            }}>
                                {t('continue')}
                        </button>
                    </div>
                </form>
            </Panel>
        </div>
        </>
    );
};

const mapStateToProps = ({ transactions, auth, credits }) => (
    {
      transactions,
      auth,
      credits,
    }
);

export default compose(
    connect(mapStateToProps, {
      ...transactionsActions, ...clientsActions, ...providersActions, ...creditsActions,
    }),
    withNamespaces(),
)(MixedPaymentsForm);