const getMenuOptions = (hasAccessTo, userHasRoles) => ([
  {
    url: '/dashboard',
    icon: 'fas fa-chart-line',
    name: 'dashboard',
    isHidden: !hasAccessTo('/dashboard'),
  },
  {
    url: '/search-users',
    icon: 'fas fa-chart-line',
    name: 
      userHasRoles('issuing_bank') ? 'searchBuyers' :
      userHasRoles('acquiring_bank') ? 'searchSuppliers' : 
      'searchUsers',
    isHidden: !hasAccessTo('/search-users'),
  },
  {
    url: '#create-provider',
    icon: 'fas fa-user-plus',
    name: 'createProvider',
    isHidden: !hasAccessTo('/create-provider'),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-provider',
            name: 'provider',
            isHidden: !hasAccessTo(['/create-provider']),
          },
          {
            id: 'sm4-1',
            url: '/provider-group-load',
            name: 'providerGroup',
            isHidden: !hasAccessTo(['/provider-group-load']),
          },
        ]
    }
  },
  {
    url: '#create-customer',
    icon: 'fas fa-user-plus',
    name: 'createBuyer',
    isHidden: !hasAccessTo('/create-customer'),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-customer',
            name: 'buyerManualUp',
            isHidden: !hasAccessTo(['/create-customer']),
          },
          {
            id: 'sm4-1',
            url: '/customer-group-load',
            name: 'buyersBatchUp',
            isHidden: !hasAccessTo(['/customer-group-load']),
          },
        ]
    }
  },
  {
    url: '#bills',
    icon: 'far fa-list-alt',
    name: (userHasRoles('seller') || userHasRoles('seller_assist_card')) ? 'sentInvoices' : 'receivedInvoices',
    isHidden: !hasAccessTo(['/review-invoices', '/approved-invoices', '/paid-invoices', '/reject-invoices']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/review-invoices',
            name: 'underReview',
            isHidden: !hasAccessTo(['/review-invoices']),
          },
          {
            id: 'sm4-2',
            url: '/approved-invoices',
            name: 'approved',
            isHidden: !hasAccessTo(['/approved-invoices']),
          },
          {
            id: 'sm4-3',
            url: '/paid-invoices',
            name: 'paid',
            isHidden: !hasAccessTo(['/paid-invoices']),
          },
          {
            id: 'sm4-4',
            url: '/reject-invoices',
            name: 'rejectedInvoices',
            isHidden: !hasAccessTo(['/reject-invoices']),
          },
          {
            id: 'sm4-5',
            url: '/pending-invoices',
            name: 'invoicePending',
            isHidden: !hasAccessTo(['/pending-invoices']),
          },
        ],
    },
  },
  // {
  //   url: '/load-invoice',
  //   icon: 'fas fa-file-invoice-dollar',
  //   name: 'loadInvoice',
  //   isHidden: !hasAccessTo('/load-invoice'),
  // },
  {
    url: '#load-invoice',
    icon: 'fas fa-file-invoice-dollar',
    name: 'loadInvoice',
    isHidden: !hasAccessTo('/load-invoice'),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/load-invoice',
            name: 'invoice',
            isHidden: !hasAccessTo(['/review-invoices']),
          },
          {
            id: 'sm4-1',
            url: '/load-invoice-group',
            name: 'invoiceGroup',
            isHidden: !hasAccessTo(['/load-invoice-group']),
          },
        ]
    }
  },
  {
    url: '/related-users',
    icon: 'fas fa-users',
    name: userHasRoles('seller') || userHasRoles('seller_assist_card') ? 'customers' : 'providers',
    isHidden: !hasAccessTo('/related-users'),
  },
  {
    url: '#administrators',
    icon: 'fas fa-university',
    name: 'administrators',
    isHidden: !hasAccessTo(['/register-bank', '/bank-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-bank',
            name: 'registerAdministrator',
            isHidden: !hasAccessTo(['/register-bank']),
          },
          {
            id: 'sm4-2',
            url: '/bank-list',
            name: 'administratorsList',
            isHidden: !hasAccessTo(['/bank-list']),
          },
        ],
    },
  },
  {
    url: '#roles',
    icon: 'fas fa-user-tag',
    name: 'admin',
    isHidden: !hasAccessTo(['/register-role', '/role-list', '/admin-routes', '/create-profile', '/profile-list', '/create-bank-user', '/bank-user-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-role',
            name: 'registerRole',
            isHidden: !hasAccessTo(['/register-role']),
          },
          {
            id: 'sm4-2',
            url: '/role-list',
            name: 'roleList',
            isHidden: !hasAccessTo(['/role-list']),
          },
          {
            id: 'sm4-3',
            url: '/admin-routes',
            name: 'assignPermissions',
            isHidden: !hasAccessTo(['/admin-routes']),
          },
          {
            id: 'sm4-4',
            url: '/create-profile',
            name: 'createProfile',
            isHidden: !hasAccessTo(['/create-profile']),
          },
          {
            id: 'sm4-5',
            url: '/profile-list',
            name: 'profileList',
            isHidden: !hasAccessTo(['/profile-list']),
          },
          {
            id: 'sm4-6',
            url: '/approval-flow-list',
            name: 'flowsApproval',
            isHidden: !hasAccessTo(['/approval-flow-list']),
          },
          {
            id: 'sm4-7',
            url: '/create-bank-user',
            name: 'createUser',
            isHidden: !hasAccessTo(['/create-bank-user']),
          },
          {
            id: 'sm4-5',
            url: '/bank-user-list',
            name: 'profileList',
            isHidden: !hasAccessTo(['/bank-user-list']),
          },
        ],
    },
  },
  {
    url: '#logs',
    icon: 'fas fa-file-alt',
    name: 'logs',
    isHidden: !hasAccessTo(['/log-view', '/operation-log']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/log-view',
            name: 'logView',
            isHidden: !hasAccessTo('/log-view'),
          },
          {
            id: 'sm4-2',
            url: '/operation-log',
            icon: 'fas fa-file-alt',
            name: 'operationLog',
            isHidden: !hasAccessTo('/operation-log'),
          },
        ],
    },
  },
  {
    url: '/manual-resolution',
    icon: 'fas fa-user-check',
    name: 'manualResolution',
    isHidden: !hasAccessTo('/manual-resolution'),
  },
  {
    url: '#request',
    icon: 'fas fa-clipboard-list',
    name: 'request',
    isHidden: !hasAccessTo(['/payment-request-sent', '/payment-request-received', '/payment-conditions-log']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/payment-request-sent',
            name: 'requestSent',
            isHidden: !hasAccessTo(['/payment-request-sent']),
          },
          {
            id: 'sm4-2',
            url: '/payment-request-received',
            name: 'requestReceived',
            isHidden: !hasAccessTo(['/payment-request-received']),
          },
          {
            id: 'sm4-3',
            url: '/payment-conditions-log',
            icon: 'fas fa-file-alt',
            name: 'changeLog',
            isHidden: !hasAccessTo('/payment-conditions-log'),
          },
        ],
    },
  },
  /*  {
      url: '#routes',
      icon: 'fas fa-shield-alt',
      name: 'routes',
      isHidden: !hasAccessTo(['/register-route', '/route-list']),
      submenu: {
        items:
          [
            {
              id: 'sm4-1',
              url: '/register-route',
              name: 'registerRoute',
              isHidden: !hasAccessTo(['/register-route']),
            },
            {
              id: 'sm4-2',
              url: '/route-list',
              name: 'routeList',
              isHidden: !hasAccessTo(['/route-list']),
            },
          ],
      },
    },
    {
    url: '#financingProducts',
    icon: 'fas fa-money-check-alt',
    name: 'financingProducts',
    isHidden: !hasAccessTo(['/register-financing-products', '/financing-products']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-financing-products',
            name: 'addFinancingProducts',
            isHidden: !hasAccessTo(['/register-financing-products']),
          },
          {
            id: 'sm4-2',
            url: '/financing-products',
            name: 'financingProducts',
            isHidden: !hasAccessTo(['/financing-products']),
          },
        ],
    },
  }, */
  /* {
    url: '#alta',
    icon: 'fas fa-user-clock',
    name: 'lead',
    isHidden: !hasAccessTo(['/create-lead', '/lead-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-lead',
            name: 'createLead',
            isHidden: !hasAccessTo(['/create-lead']),
          },
          {
            id: 'sm4-2',
            url: '/lead-list',
            name: 'leadList',
            isHidden: !hasAccessTo(['/lead-list']),
          },
        ],
    },
  }, */
  {
    url: '/clients',
    icon: 'far fa-id-badge',
    name: 'clients',
    isHidden: !hasAccessTo('/clients'),
  },
  {
    url: '/providers',
    icon: 'far fa-id-badge',
    name: 'providers',
    isHidden: !hasAccessTo('/providers'),
  },
  
  {
    url: '#cards',
    icon: 'far fa-credit-card',
    name: 'cards',
    isHidden: !hasAccessTo('/register-card'),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/register-card',
            name: 'registerCard',
            isHidden: !hasAccessTo('/register-card'),
          },
          {
            id: 'sm4-1',
            url: '/list-cards',
            name: 'listCards',
            isHidden: !hasAccessTo('/list-cards'),
          },
          {
            id: 'sm4-1',
            url: '/credits-list',
            name: 'creditsList',
            isHidden: !hasAccessTo('/credits-list'),
          },
          {
            id: 'sm4-1',
            url: '/credit-request',
            name: 'creditRequest',
            isHidden: !hasAccessTo('/credit-request'),
          },
        ]
    }
  },
  {
    url: '#checks',
    icon: 'far fa-credit-card',
    name: 'checks',
    isHidden: !hasAccessTo(['/check-deposit', '/rejected-checks', '/approved-checks', 'not-approved-checks']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/check-deposit',
            name: 'checkDeposit',
            isHidden: !hasAccessTo('/check-deposit'),
          },
          {
            id: 'sm4-1',
            url: '/not-approved-checks',
            name: 'checksToBeApproved',
            isHidden: !hasAccessTo('/not-approved-checks'),
          },
          {
            id: 'sm4-1',
            url: '/approved-checks',
            name: 'approvedChecks',
            isHidden: !hasAccessTo('/approved-checks'),
          },
          {
            id: 'sm4-1',
            url: '/rejected-checks',
            name: 'rejectedChecks',
            isHidden: !hasAccessTo('/rejected-checks'),
          },
        ]
    }
  },
  /*{
    url: '#insurances',
    icon: 'fas fa-file-contract',
    name: 'insurances',
    isHidden: !hasAccessTo(['/products']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/products',
            name: 'QuoteAndRequest',
            isHidden: !hasAccessTo(['/products']),
          },
          {
            id: 'sm4-1',
            url: '/contracted-products',
            name: 'productsContracted',
            isHidden: !hasAccessTo('/contracted-products'),
          },
        ],
    },
  },*/
  {
    url: '#BillingCycle',
    icon: 'fas fa-file-invoice',
    name: 'billingCycles',
    isHidden: !hasAccessTo(['/load-billing-cycle', '/edit-billing-cycle', '/billing-cycles']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/load-billing-cycle',
            name: 'loadBillingCycle',
            isHidden: !hasAccessTo(['/load-billing-cycle']),
          },
          {
            id: 'sm4-2',
            url: '/edit-billing-cycle',
            name: 'editCycle',
            isHidden: !hasAccessTo('/edit-billing-cycle'),
          },
          {
            id: 'sm4-3',
            url: '/billing-cycles',
            name: 'cyclesList',
            isHidden: !hasAccessTo(['/billing-cycles']),
          },
        ],
    },
  },
  {
    url: '#Reports',
    icon: 'fas fa-th-list',
    name: 'reports',
    isHidden: !hasAccessTo(['/reports', '/monthly-report']),
    submenu: {
      items:
        [
          {
            url: '/reports',
            icon: 'fas fa-th-list',
            name: 'transactions',
            isHidden: !hasAccessTo('/reports'),
          },
          {
            url: '/monthly-report',
            icon: 'fas fa-th-list',
            name: 'monthlyReport',
            isHidden: !hasAccessTo('/monthly-report'),
          },
          {
            url: '/send-information',
            icon: 'fas fa-th-list',
            name: 'sendErp',
            isHidden: !hasAccessTo('/send-information'),
          },
        ],
    },
  },
  {
    url: '/transactions',
    icon: 'fas fa-th-list',
    name: 'transactions',
    isHidden: !hasAccessTo('/transactions'),
  },
  {
    url: '#BusinessExecutives',
    icon: 'fas fa-user-friends',
    name: 'businessExecutives',
    isHidden: !hasAccessTo(['/create-banking-executive']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-banking-executive',
            name: 'createExecutive',
            isHidden: !hasAccessTo(['/create-banking-executive']),
          },
          {
            id: 'sm4-1',
            url: '/banking-executives',
            name: 'bankingExecutives',
            isHidden: !hasAccessTo('/banking-executives'),
          },
        ],
    },
  },
  {
    url: '#ManualsAndFaq',
    icon: 'fas fa-book',
    name: 'manualsAndFaq',
    isHidden: !hasAccessTo(['/save-manual', '/list-manual']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/save-manual',
            name: 'uploadManuals',
            isHidden: !hasAccessTo(['/save-manual']),
          },
          {
            id: 'sm4-2',
            url: '/list-manual',
            name: 'listManuals',
            isHidden: !hasAccessTo(['/list-manual']),
          },
          {
            id: 'sm4-3',
            url: '/save-faq',
            name: 'saveQuestion',
            isHidden: !hasAccessTo('/save-faq'),
          },
          {
            id: 'sm4-4',
            url: '/list-faqs',
            name: 'listFaqs',
            isHidden: !hasAccessTo('/list-faqs'),
          }
        ],
    },
  },
  {
    url: '#banks',
    icon: 'fas fa-university',
    name: 'banks',
    isHidden: !hasAccessTo(['/create-admin-bank', '/admin-bank-list']),
    submenu: {
      items:
        [
          {
            id: 'sm4-1',
            url: '/create-admin-bank',
            name: 'registerBank',
            isHidden: !hasAccessTo(['/create-admin-bank']),
          },
          {
            id: 'sm4-2',
            url: '/admin-bank-list',
            name: 'bankList',
            isHidden: !hasAccessTo(['/admin-bank-list']),
          },
        ],
    },
  },
]);

export default getMenuOptions;
