import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Card, Loading, Panel, TableSimple, } from '../../components';
import { cardActions } from '../../state/ducks/card';
import { bankActions } from '../../state/ducks/bank';
import DateInput from '../../components/DateInput';
import SelectSingle from '../../components/SelectSingle';
import { formatDate } from '../../util';
import { Link } from 'react-router-dom';
import 'moment/locale/es';

class BillingCycleList extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    cycle_date: new Date(),
    bank: '',
    bank_id: '',
    bank_name: '',
    bank_document: '',
    bank_address: '',
    bank_email: '',
  });

  componentDidMount() {
    const {
      getBillingCycles,
      fetchBanks,
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    if(type_user === "admin"){
      fetchBanks({
        params: {
          admin: true,
        }
      });
    }

    getBillingCycles({
      params: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      }
    });
  };

  handleDateChange = (newDate) => {
    const {
      getBillingCycles
    } = this.props;

    const {
      bank_id
    } = this.state;

    getBillingCycles({
      params: {
        bank: bank_id,
        year: newDate.getFullYear(),
        month: newDate.getMonth() + 1,
      },
    });

    this.setState({
      cycle_date: newDate,
    });
  };

  getGridSettings = () => {
    const {
      t,
      card: { list },
    } = this.props;
    let dias = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'group', // Required because our accessor is not a string
        Header: t('group'),
        accessor: d => t('group') + d.group,
      },
      {
        id: 'dayFf', // Required because our accessor is not a string
        Header: t('day'),
        accessor: d => ((new Date(d.date_init * 1000).getDay() - 1) < 0 ? dias[6] : dias[new Date(d.date_init * 1000).getDay() - 1]),//formatDate(d.date_init),
      },
      {
        id: 'dateff', // Required because our accessor is not a string
        Header: t('dateFf'),
        accessor: d => formatDate(d.date_init),
      },
      {
        id: 'dayV', // Required because our accessor is not a string
        Header: t('day'),
        accessor: d => ((new Date(d.date_end * 1000).getDay() - 1) < 0 ? dias[6] : dias[new Date(d.date_end * 1000).getDay() - 1]),//formatDate(d.date_init),
      },
      {
        id: 'dateV', // Required because our accessor is not a string
        Header: t('dateV'),
        accessor: d => formatDate(d.date_end),
      },
    ];
    const data = list.length > 0 ? this.orderArray(list) : list;
    const title = t('profileList');
    return {
      columns,
      data,
      title,
    };
  };

  orderArray = (list) => {
    let variable = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    for (let j = 0; j < 5; j++) {
      list.forEach(function (path) {
        if (order[i] === Number(path.billingCycle.cycle)) {
          variable.push({
            id: i,
            group: path.billingCycle.cycle,
            billing_cycle_id: path.billing_cycle_id,
            dayI: '',
            date_init: path.date_init,
            dayF: '',
            date_end: path.date_end,
          });
          i = i + 1;
        }
      });
    }

    return variable;
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectBank = (newVal, label) => {
    const {
      bank: { list },
      //getCycleCard,
      getBillingCycles
    } = this.props;

    const { cycle_date } = this.state;

    /*getCycleCard({
      params: {
        bank: list[newVal.value].id,
      },
      callback: (response) => {
        this.arrayMonth(response);
      }
    }) */

    getBillingCycles({
      params: {
        bank: list[newVal.value].id,
        year: cycle_date.getFullYear(),
        month: cycle_date.getMonth() + 1,
      },
    });

    this.setState({
      bank_id: list[newVal.value].id,
      bank_name: list[newVal.value].admin_name,
      bank_document: list[newVal.value].idPerson.document_number,
      bank_address: list[newVal.value].address,
      bank_email: list[newVal.value].username,
    });
  };

  render() {
    const {
      cycle_date,
      bank,
      bank_name,
      bank_document,
      bank_address,
      bank_email,
    } = this.state;
    const {
      t,
      card: { loading },
      bank: { list },
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    const { title, columns, data } = this.getGridSettings();
    return (
      <div>
        {loading && <Loading/>}
        <Panel headingText={t('cyclesList')}>
          <div className="field is-horizontal">
            <div className="field-body columns">
              <div className="column is-one-third">
                <DateInput
                  name={'cycle_date'}
                  label={t('month')}
                  value={cycle_date}
                  onChange={this.handleDateChange}
                  fieldClasses="is-expanded"
                  controlClasses="has-icons-left"
                  icon="far fa-calendar-alt"
                  iconPosition="is-left"
                  dateFormat="MM/yyyy"
                  showMonthYearPicker={true}
                  showFullMonthYearPicker={true}
                  locale={'es'}
                />
                <br/>
              </div>
              {type_user === "admin" && 
              <Fragment>
                <div className="column">
                  <SelectSingle
                    name={'bank'}
                    label={t('adminBank')}
                    selectClasses="is-fullwidth"
                    placeholder={t('Select')}
                    onChange={this.handleSelectBank}
                    options={[
                      ...list.map((item, index) => ({
                        label: item.admin_name +" - "+item.username,
                        value: index,
                      })),
                    ]}
                    value={bank}
                  />
                </div>
                <div className="column">
                  {bank_email !== '' &&
                  <Card 
                    title={t('bankInformation')}
                    subtitle={bank_name}
                    items={[
                      {
                        name: 'documentNumber',
                        value: bank_document,
                      }, {
                        name: 'address',
                        value: bank_address,
                      }, {
                        name: 'email',
                        value: bank_email,
                      },
                    ]}
                  />}
                </div>
              </Fragment>}
            </div>
          </div>
          {data
          && (
            <div>
              <div className="panel-top-content">
                <Link
                  className="button is-primary"
                  to="/load-billing-cycle"
                >
                  <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                  {t('loadBillingCycle')}
                </Link>
              </div>
              <TableSimple data={data} columns={columns}/>
            </div>
          )
          }
        </Panel>
      </div>
    );
  }
}

BillingCycleList.propTypes = {
  t: PropTypes.func.isRequired,
  getCycleCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, bank, }) => (
  {
    card,
    bank,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
    ...bankActions,
  }),
  withNamespaces(),
)(BillingCycleList);