import { logActions, logTypes } from '../ducks/log';

const getLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.FETCH_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-log/get-log',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

const eventLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.EVENT_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'b2b-log/get-log-operations',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

const transactionLog = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== logTypes.TRANSACTION_LOG) {
    return;
  }

  const {
    callback = () => {
    }
  } = action.payload;

  dispatch({
    type: logTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'trans-bank/log-webpay-transaction',
      },
      authorization: true,
      onStart: logActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(logActions.getLog(data));
        }
      },
      onEnd: logActions.endFetch,
    },
  });
};

export default [getLog, eventLog, transactionLog];