import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Modal, Toggle } from '../../components';
import { MixedPaymentsForm } from './components';
import { creditsActions } from '../../state/ducks/credits';
import { transactionsActions } from '../../state/ducks/transactions';

const MixedPayments = ({
    transactions: { currentInvoice: { invoice_number, invoice_amount } },
    credits: { availableAmounts: { accountCredit, amexCredit, preApprovedCredit } },
    setUsedFor,
    updateAmounts,
    saveCurrentInvoice,
    t,
 }) => {

    
    const [amount, setAmount] = useState({ amexAmount: 0, creditAmount: 0, arrangeAmount: 0 });
    const [step, setStep] = useState('');
    const handleForm = value => setAmount({ ...amount, ...value })
  
    return (
        <Toggle>
            {({toggled, onToggle}) => {
                return (
                    <>
                    {
                        toggled && step !== '' ? (
                            <Modal 
                              onToggle={onToggle}
                            //   title={`El pago de su factura número ${invoice_number} ha sido programada`}>
                              title={`The payment for the invoice No. ${invoice_number} was scheduled`}>
                                <p style={{ marginTop:'20px', fontSize: '1.1em', textAlign: 'center'}}> 
                                    {t('referenceNumber')}: TPS2005466YIJKL
                                </p>
                                <div className='modal-action-btns'>
                                    <Link to='/review-invoices' className='button custom-btn is-danger'>
                                        {t('return')}
                                    </Link>
                                    <button className='button custom-btn is-primary'>{t('print')}</button>
                                </div>
                            </Modal>
                        ) : step === 'creditRequest' ? (
                            <Modal
                              onToggle={onToggle}
                              title={t('creditConditionsMsg')}>
                                <div className='modal-action-btns'>
                                    <button 
                                      className='button custom-btn is-danger'
                                      onClick={() => {
                                        //   onToggle()
                                          setStep('')
                                          setAmount({ amexAmount: 0, creditAmount: 0, arrangeAmount: 0 });

                                          }}>{t('cancel')}</button>
                                    <Link 
                                      to='/credit-request' 
                                      className='button custom-btn is-primary'
                                      onClick={() => {
                                        setUsedFor('approveWithMixedPayment')
                                        onToggle()
                                        updateAmounts({
                                            accountCredit: accountCredit-amount.creditAmount,
                                            amexCredit: amexCredit-amount.amexAmount, 
                                            preApprovedCredit: preApprovedCredit-amount.arrangeAmount,
                                        })
                                        saveCurrentInvoice({requiredAmount: amount.arrangeAmount })
                                      }}>
                                        {t('continue')}
                                    </Link>
                                </div>
                            </Modal>
                        ) : (
                            <MixedPaymentsForm onToggle={onToggle} setStep={setStep} amount={amount} handleForm={handleForm}/>
                        )
                    }
                    </>
                )
            }}
        </Toggle>
    );
};

const mapStateToProps = ({ transactions, credits }) => (
    {
      transactions,
      credits,
    }
);

export default compose(
    connect(mapStateToProps, {...creditsActions, ...transactionsActions}),
    withNamespaces(),
)(MixedPayments);