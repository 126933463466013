import React, { Component, Fragment } from 'react';
import './styles.scss';
import { TOAST_CONFIG } from '../../config/constants';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
// REDUX
import { compose } from 'redux';
import { connect } from 'react-redux';
// ENDPOINTS ACTIONS
import { statsActions } from '../../state/ducks/stats';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { providersActions } from '../../state/ducks/providers';
import { documentTypeActions } from '../../state/ducks/documentType';
import { bankActions } from '../../state/ducks/bank';
import { cardActions } from '../../state/ducks/card';
import { authActions } from '../../state/ducks/auth';
import { roleActions } from '../../state/ducks/role';
import { itemActions } from '../../state/ducks/item';
import { currencyActions } from '../../state/ducks/currency';
// REACT ROUTER DOM
import { Link } from 'react-router-dom';
import Redirect from 'react-router-dom/es/Redirect';
// APP COMPONENTS
import {
    InformativeBoxes,
    InvoiceDetailForm,
    Loading,
    Panel,
    PaymentConditionForm,
    TableSimple,
    Toggle,
    Modal,
    SearchPersonForm,
    DateInput,
    Form,
    TextInput,
} from '../../components';
// UTILS
import {
    formatCurrency,
    formatCurrencyCl,
    formatDate,
    formatFriendlyDate,
    formatFriendlyDateLong,
    formatIntCurrency,
    getPath,
} from '../../util';
// CHARTS
import InformativeBox from '../../components/InformativeBoxes/InformativeBox';
import DataBox from '../dashboard/components/DataBox/index'
import LineChartBox from '../dashboard/components/LineChartBox';
import BarChartBox from '../dashboard/components/BarChartBox';
import {
  Area,
  AreaChart,
  Brush,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

const ItemCard = ({ name, value }) => (
    <li>
    <b>
      {`${name}:`}
    </b>
    {value}
  </li>
);

ItemCard.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

class SearchUsers extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    client: null,
    provider: null,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    percentage: 0,
    days: 365,
    editable: false,
    startCountingFrom: 0,
    //limitAmount: '',
    inputValue: '',
    alert: false,
    document_number: '',
    name: '',
    email: '',
    min_amount: '',
    max_amount: '',
    search: true,
    stat: '',
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
    is_rate: false,
    user_type: '',
    user: '',
    rol: '',
    rut: '',
    arrayOrder: [],
    notification: '',
    codes: [],
    codeDescription: '',
    commerceCode: '',
    dashboardStarDate: new Date(new Date().setDate(new Date().getDate() - 90)),
    dashboardEndDate: new Date(new Date().setHours(23, 59, 59, 999)),
    invoicesToApproveMonthly: [],
    invoicesSentMontly: [],
    approvedAndDeferredInvoices: [],
    inactiveClientsMonthly: [],
    numberOfClientsMonthly: [],
    ammountOfApprovedInvoicesMonthly: [],
    ammountPaidInvoices: [],
    ammountApprovedInvoices: [],
    quatityOne: 0,
    quatityTwo: 0,
    quatityThree: 0,
  });

  componentDidMount() {
    const {
      fetchBanks,
      fetchStats,
      listTransactionsSender,
      listTransactionsReceiver,
      auth: {
        user: {
          token,
          user_id,
          b2b: {
            type_user,
          },
        },
      },
      fetchDocumentTypes,
      getClients,
      getProviders,
      statTransaction,
      getBillingCycles,
      t,
      fetchRoles,
    } = this.props;

    if (type_user !== 'general_admin') {
      fetchRoles({
        params: {
          name: 'seller',
        },
      });
    }

    if (type_user === 'general_admin' || type_user === 'general_admin_issuing' ||
      type_user === 'general_admin_acquiring' || type_user === 'general_admin_issuing_amex') {
      fetchBanks({
        params: {
          //id: user_id,
          general_admin: true,
        }
      });
    }

    window.sessionStorage.setItem('token', token);

    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth();

    if (type_user === 'acquiring_bank' || type_user === 'issuing_bank') {
      fetchStats({});
      this.setState({ search: true });
    }

    if (type_user === 'main_bank') {
      this.searchPerson();
    }

    if (type_user === 'main_bank_issuing' || type_user === 'main_bank_issuing_amex' ||
      type_user === 'main_bank_acquiring') {
      this.searchPerson();
      fetchDocumentTypes({
        params: { legal_type: 'J' },
      });
    } else {
      fetchDocumentTypes({});
    }
    if (type_user === 'issuing_bank' || type_user === 'acquiring_bank') {
      getClients({});
      getProviders({});
      fetchStats({
        callback: (resp) => {
          parseInt(resp) > 0 ? this.setState({
            alert: 'bank',
            notification: Number(resp) === 1 ? 'applicationOneNotification' : 'applicationNotification'
          }) : this.setState({ alert: '' });
        },
      });
    } else {
      fetchStats({});
    }
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        statTransaction({
          params: {
            emmit: true,
            approved: true,
            //min_date: 1589154906,
            //max_date: 1589654906,
          },
          callback: (response) => {
            this.setState({ stat: response });
          }
        });
        break;
      case 'buyer':
        listTransactionsSender({
          params: {
            user_id,
          },
          t,
          callback: () => this.setState({ alert: 'b' }),
        });
        break;
      case 'seller_assist_card':
      case 'seller':
        listTransactionsReceiver({
          params: {
            user_id,
          },
          t,
          callback: () => this.setState({ alert: 's' }),
        });
        break;
      case 'cycle_loader':
          //getCyclePeriods({});
          getBillingCycles({
            params: {
              month: currentMonth + 1,
              year: currentYear,
            },
            callback: (response) => {
              this.orderArray(response);
            }
          });
          break;
      /*case 'issuing_bank':
        fetchLeads({
          params: {
            role_id: 'buyer',
          },
        });
        break;
      case 'acquiring_bank':
        fetchLeads({
          params: {
            role_id: 'seller',
          },
        });
        break;*/
      default:
    }
    this.setState({ 
      invoicesToApproveMonthly: [
        { name: 'Jan', value: 53 },
        { name: 'Feb', value: 45 },
        { name: 'Mar', value: 12 },
        { name: 'Apr', value: 27 },
        { name: 'May', value: 32 },
        { name: 'Jun', value: 6 },
        { name: 'Jul', value: 25 },
        { name: 'Aug', value: 39 },
        { name: 'Sep', value: 65 },
        { name: 'Oct', value: 72 },
        { name: 'Nov', value: 68 },
        { name: 'Dec', value: 26 },
      ],
      inactiveClientsMonthly: [
        { name: 'Jan', value: 12 },
        { name: 'Feb', value: 7 },
        { name: 'Mar', value: 2 },
        { name: 'Apr', value: 4 },
        { name: 'May', value: 16 },
        { name: 'Jun', value: 13 },
        { name: 'Jul', value: 17 },
        { name: 'Aug', value: 6 },
        { name: 'Sep', value: 19 },
        { name: 'Oct', value: 12 },
        { name: 'Nov', value: 9 },
        { name: 'Dec', value: 5 },
      ],
      numberOfClientsMonthly: [
        { name: 'Jan', value: 25 },
        { name: 'Feb', value: 43 },
        { name: 'Mar', value: 24 },
        { name: 'Apr', value: 27 },
        { name: 'May', value: 32 },
        { name: 'Jun', value: 26 },
        { name: 'Jul', value: 25 },
        { name: 'Aug', value: 39 },
        { name: 'Sep', value: 42 },
        { name: 'Oct', value: 35 },
        { name: 'Nov', value: 51 },
        { name: 'Dec', value: 26 },
      ],
      ammountOfApprovedInvoicesMonthly: [
        { name: 'Jan', value: 17500 },
        { name: 'Feb', value: 23500 },
        { name: 'Mar', value: 12500 },
        { name: 'Apr', value: 27000 },
        { name: 'May', value: 32000 },
        { name: 'Jun', value: 6000 },
        { name: 'Jul', value: 25000 },
        { name: 'Aug', value: 39000 },
        { name: 'Sep', value: 65000 },
        { name: 'Oct', value: 72500 },
        { name: 'Nov', value: 68200 },
        { name: 'Dec', value: 26100 },
      ],
      ammountPaidInvoices: [
        { name: 'Jan', first: 15000, second: 17000 },
        { name: 'Feb', first: 25000, second: 29000 },
        { name: 'Mar', first: 17000, second: 18500 },
        { name: 'Apr', first: 21500, second: 23200 },
        { name: 'May', first: 12350, second: 17500 },
        { name: 'Jun', first: 6500, second: 7250 },
        { name: 'Jul', first: 15600, second: 18200 },
        { name: 'Aug', first: 16500, second: 12800 },
        { name: 'Sep', first: 6750, second: 9800 },
        { name: 'Oct', first: 9650, second: 6950 },
        { name: 'Nov', first: 12600, second: 16200 },
        { name: 'Dec', first: 8500, second: 5800 },
      ],
      ammountApprovedInvoices: [
        { name: 'Jan', first: 18000, second: 17000 },
        { name: 'Feb', first: 26000, second: 29000 },
        { name: 'Mar', first: 12000, second: 18500 },
        { name: 'Apr', first: 31500, second: 23200 },
        { name: 'May', first: 17350, second: 17500 },
        { name: 'Jun', first: 7500, second: 7250 },
        { name: 'Jul', first: 12600, second: 18200 },
        { name: 'Aug', first: 13500, second: 12800 },
        { name: 'Sep', first: 6750, second: 9800 },
        { name: 'Oct', first: 9750, second: 6950 },
        { name: 'Nov', first: 15600, second: 16200 },
        { name: 'Dec', first: 8750, second: 5800 },
      ],
      invoicesSentMontly: [
        { name: 'Jan', first: 15, second: 17 },
        { name: 'Feb', first: 25, second: 29 },
        { name: 'Mar', first: 17, second: 18 },
        { name: 'Apr', first: 21, second: 23 },
        { name: 'May', first: 12, second: 17 },
        { name: 'Jun', first: 65, second: 72 },
        { name: 'Jul', first: 15, second: 18 },
        { name: 'Aug', first: 16, second: 12 },
        { name: 'Sep', first: 65, second: 98 },
        { name: 'Oct', first: 92, second: 69 },
        { name: 'Nov', first: 16, second: 25 },
        { name: 'Dec', first: 85, second: 58 },
      ],
      approvedAndDeferredInvoices: this.getBarChartData(),
      quatityOne: 45,
      quatityTwo: 12,
      quatityThree: 25,
    });
  }

  getRandomValues = (randomValue) => Math.floor(Math.random() * randomValue)

  getLineChartData = (randomValue) => [
    { name: 'Jan', value: (randomValue) ? this.getRandomValues(randomValue): 53 },
    { name: 'Feb', value: (randomValue) ? this.getRandomValues(randomValue): 45 },
    { name: 'Mar', value: (randomValue) ? this.getRandomValues(randomValue): 12 },
    { name: 'Apr', value: (randomValue) ? this.getRandomValues(randomValue): 27 },
    { name: 'May', value: (randomValue) ? this.getRandomValues(randomValue): 32 },
    { name: 'Jun', value: (randomValue) ? this.getRandomValues(randomValue): 6 },
    { name: 'Jul', value: (randomValue) ? this.getRandomValues(randomValue): 25 },
    { name: 'Aug', value: (randomValue) ? this.getRandomValues(randomValue): 39 },
    { name: 'Sep', value: (randomValue) ? this.getRandomValues(randomValue): 65 },
    { name: 'Oct', value: (randomValue) ? this.getRandomValues(randomValue): 72 },
    { name: 'Nov', value: (randomValue) ? this.getRandomValues(randomValue): 68 },
    { name: 'Dec', value: (randomValue) ? this.getRandomValues(randomValue): 26 },
  ];

  getBarChartData = (randomValue) => [
    { name: 'Jan', first: (randomValue) ? this.getRandomValues(randomValue): 26, second: (randomValue) ? this.getRandomValues(randomValue): 32 },
    { name: 'Feb', first: (randomValue) ? this.getRandomValues(randomValue): 45, second: (randomValue) ? this.getRandomValues(randomValue): 38 },
    { name: 'Mar', first: (randomValue) ? this.getRandomValues(randomValue): 32, second: (randomValue) ? this.getRandomValues(randomValue): 26 },
    { name: 'Apr', first: (randomValue) ? this.getRandomValues(randomValue): 62, second: (randomValue) ? this.getRandomValues(randomValue): 51 },
    { name: 'May', first: (randomValue) ? this.getRandomValues(randomValue): 15, second: (randomValue) ? this.getRandomValues(randomValue): 23 },
    { name: 'Jun', first: (randomValue) ? this.getRandomValues(randomValue): 9, second: (randomValue) ? this.getRandomValues(randomValue): 12 },
    { name: 'Jul', first: (randomValue) ? this.getRandomValues(randomValue): 12, second: (randomValue) ? this.getRandomValues(randomValue): 19 },
    { name: 'Aug', first: (randomValue) ? this.getRandomValues(randomValue): 32, second: (randomValue) ? this.getRandomValues(randomValue): 38 },
    { name: 'Sep', first: (randomValue) ? this.getRandomValues(randomValue): 25, second: (randomValue) ? this.getRandomValues(randomValue): 34 },
    { name: 'Oct', first: (randomValue) ? this.getRandomValues(randomValue): 6, second: (randomValue) ? this.getRandomValues(randomValue): 17 },
    { name: 'Nov', first: (randomValue) ? this.getRandomValues(randomValue): 67, second: (randomValue) ? this.getRandomValues(randomValue): 73 },
    { name: 'Dec', first: (randomValue) ? this.getRandomValues(randomValue): 41, second: (randomValue) ? this.getRandomValues(randomValue): 27 },
  ];

  loadDashboardData = () => {
    this.setState({ 
      invoicesToApproveMonthly: this.getLineChartData(61),
      invoicesSentMontly: this.getBarChartData(81),
      approvedAndDeferredInvoices: this.getBarChartData(81),
      inactiveClientsMonthly: this.getLineChartData(61),
      numberOfClientsMonthly: this.getLineChartData(81),
      ammountOfApprovedInvoicesMonthly: this.getLineChartData(10000),
      ammountPaidInvoices: this.getBarChartData(10000),
      ammountApprovedInvoices: this.getBarChartData(10000),
    });
  }

  sort = (list) => {
    return list.sort(((a, b) => b.created_at - a.created_at));
  };

  orderArray = (list) => {
    let arrayOrder = [],
      i = 0,
      order = [5, 3, 8, 1, 2];

    for (let j = 0; j < 5; j++) {
      list.forEach(function (path) {
        if (order[i] === Number(path.billingCycle.cycle)) {
          arrayOrder.push({
            id: i,
            group: path.billingCycle.cycle,
            billing_cycle_id: path.billing_cycle_id,
            dayI: '',
            date_init: path.date_init,
            dayF: '',
            date_end: path.date_end,
          });
          i = i + 1;
        }
      });
    }

    this.setState({
      arrayOrder,
    });
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  renderRowSubComponent = ({ row }) => {
    const { _original: data } = row;
    const {
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    return (
      <div className="columns row-sub-component" style={{ padding: '1rem' }}>
        <div className="column">
          <h4>
            {`${t('invoicesApproved')}:`}
          </h4>
          <span>{`${data.approved_invoice_count}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('approvedMount')}:`}
          </h4>
          <span>{`${formatCurrency(data.approved_invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('nInvoices')}:`}
          </h4>
          <span>{`${data.invoice_count}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('totalAmount')}:`}
          </h4>
          <span>{`${formatCurrency(data.invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t(`${type_user === 'issuing_bank' ? 'defer' : 'advance'}Mount`)}:`}
          </h4>
          <span>{`${formatCurrency(data.advance_invoice_total_amount)}`}</span>
        </div>
        <div className="column">
          <h4>
            {`${t('totalCommissionAmount')}:`}
          </h4>
          <span>{`${formatCurrency(data.commission_advance_invoice_total_amount)}`}</span>
        </div>
      </div>
    );
  };

  getStatsData = (userType) => {
    let statsData;
    const {
      t,
      transactions: { list: graph },
      clients: { list: listClient },
      providers: { list: listProvider },
      stats: {
        total_paid,
        next_payment,
        total_debt_amount,
        total_owed_amount,
        account_balance,
        paid_total,
        pending_amount,
        invoice_total,
        total_operators,
        total_users,
        total_active_users,
        total_amount_approved_crc,
        total_amount_approved_usd,
        total_pending_invoices,
        total_amount_paid_crc,
        total_amount_paid_usd,
        total_debt_amount_crc,
        total_debt_amount_usd,
        inactive_providers,
        total_paid_clients_crc,
        total_paid_clients_usd,
        total_paid_clients_month_usd,
        total_paid_clients_month_crc,
        total_pending_invoice_usd,
        total_pending_invoice_crc,
        next_payment_crc,
        next_payment_usd,
        paid_total_crc,
        paid_total_usd,
        total_owed_amount_crc,
        total_owed_amount_usd,
        total_paid_crc,
        total_paid_usd,
      },
    } = this.props;
    const {
      stat,
    } = this.state;
    const date = new Date();
    const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const month = months[date.getMonth()];
    const mat = stat.invoices_number;

    switch (userType) {
      case 'admin_amex':
      case 'admin':
        statsData = [
          {
            icon: 'far fa-id-badge',
            title: 'clients',
            value: listClient.length,
          }, {
            icon: 'far fa-id-badge',
            title: 'providers',
            value: listProvider.length,
          }, {
            icon: 'fas fa-hand-holding-usd',
            title: 'totalToReceive',
            value: mat != undefined ? formatIntCurrency(mat[4].total_amount) : 0,//formatCurrency(graph.invoices_number[4].total_amount),
          },
          {
            icon: 'fas fa-hand-holding-usd',
            title: 'totalToApprove',
            value: mat != undefined ? formatIntCurrency(mat[3].total_amount) : 0, //formatCurrency(graph.invoices_number[3].total_amount),
          },
        ];
        break;
      // case 'acquiring_bank':
      case 'acquiring_query':
        statsData = [
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountOfTransactionsToProcess',
            value: total_amount_approved_usd,
            //bigValue1: total_amount_approved_crc,
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, {
            icon: 'fas fa-file-invoice',
            title: 'transactionsToProcess',
            value: total_pending_invoices,
          }, {
            icon: 'fas fa-hand-holding-usd',
            title: 'volumeProcessed',
            value: total_amount_paid_usd,
            //bigValue1: total_amount_paid_crc,
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          },
          {
            icon: 'fas fa-store-alt-slash',
            title: 'inactiveBusinesses',
            value: inactive_providers,
          },
        ];
        break;
      // case 'issuing_bank':
      case 'issuing_query':
        statsData = [
          {
            icon: 'fas fa-file-invoice',
            title: t('billingPerMonth', { month }),
            value: total_paid_clients_month_usd,
            //bigValue1: total_paid_clients_month_crc,
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'totalVolumePendingPayment',
            value: total_pending_invoice_usd,
            //bigValue1: total_pending_invoice_crc,
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, {
            icon: 'fas fa-hand-holding-usd',
            title: 'billingVolume',
            value: total_paid_clients_usd,
            //bigValue1: total_paid_clients_crc,
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          },
        ];
        break;
      case 'buyer':
        statsData = [
          {
            icon: 'far fa-id-badge',
            title: 'nextPayment',
            value: next_payment_usd !== false ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {(next_payment_usd.due_date_grouped)}
                </div>
                <div className="">{(next_payment_usd.grouped_amount)}</div>
              </div>
            ) : '0.00',
            /*bigValue1: next_payment_crc !== false ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {formatFriendlyDateLong(next_payment_crc.due_date_grouped)}
                </div>
                <div className="">{(next_payment_crc.grouped_amount)}</div>
              </div>
            ) : '0.00',*/
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'totalDebtAmount',
            value: total_debt_amount_usd ? (total_debt_amount_usd) : ('0.00'),
            //bigValue1: total_debt_amount_crc ? (total_debt_amount_crc) : ('0.00'),
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, /*{
            icon: 'fas fa-hand-holding-usd',
            title: 'accountBalance',
            value: account_balance ? formatCurrency(account_balance) : formatCurrency(0),
          },*/
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_total_usd ? formatIntCurrency(paid_total_usd) : ('0.00'),
            //bigValue1: id_total_crc ? formatIntCurrency(paid_total_crc) : ('0.00'),
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          },
        ];
        break;
      case 'seller_assist_card':
      case 'seller':
        statsData = [
          {
            icon: 'far fa-id-badge',
            title: 'nextPayment',
            value: next_payment_usd !== false ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {(next_payment_usd.due_date_grouped)}
                </div>
                <div className="">{(next_payment_usd.grouped_amount)}</div>
              </div>
            ) : '0.00',
            /*bigValue1:next_payment_crc !== false ? (
              <div>
                <div className="is-capitalized is-size-3">
                  {formatFriendlyDateLong(next_payment_crc.due_date_grouped)}
                </div>
                <div className="">{(next_payment_crc.grouped_amount)}</div>
              </div>
            ) : '0.00',*/
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'totalOwedAmount',
            value: total_owed_amount_usd ? (total_owed_amount_usd) : ('0.00'),
            //bigValue1: total_owed_amount_crc ? (total_owed_amount_crc) : ('0.00'),
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          }, /*{
            icon: 'fas fa-hand-holding-usd',
            title: 'accountBalance',
            value: account_balance ? formatCurrency(account_balance) : formatCurrency(0),
          },*/
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToGet',
            value: invoice_total ? invoice_total : 0,
          },
          {
            icon: 'fas fa-file-invoice-dollar',
            title: t('totalCharged', { month }),
            value: total_paid_usd ? (total_paid_usd) : ('0.00'),
            //bigValue1: total_paid_crc ? (total_paid_crc) : ('0.00'),
            typeCurrency: 'USD',
            //typeCurrency1: 'CRC',
          },
        ];
        break;
      case 'main_bank':
      case 'main_bank_acquiring':
      case 'main_bank_issuing':
        statsData = [
          {
            icon: 'fas fa-users',
            title: 'users',
            value: total_operators === undefined ? 0 : total_operators,
          }, {
            icon: 'fas fa-building',
            title: 'companies',
            value: total_users === undefined ? 0 : total_users,
          }, {
            icon: 'fas fa-chart-line',
            title: 'activeCompanies',
            value: total_active_users === undefined ? 0 : total_active_users,
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  getStatsData1 = (userType) => {
    const {
      stats: {
        buyer_total,
        inactive_customers,
      }
    } = this.props;
    let statsData1;
    /*
    switch (userType) {
      case 'issuing_bank':
        statsData1 = [
          {
            icon: 'fas fa-users',
            title: 'affiliateClients',
            value: buyer_total,
          }, {
            icon: 'fas fa-users-slash',
            title: 'inactiveClients',
            value: inactive_customers,
          },
        ];
        break;
    }
    */
    return { statsData1 };
  };

  getLateralStatsData = (userType) => {
    let statsData;
    const {
      t,
      stats: {
        paid_amount,
        pending_amount,
        financed_amount,
        total_paid,
      },
    } = this.props;
    const date = new Date();
    const months = [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august'), t('september'), t('october'), t('november'), t('december')];
    const month = months[date.getMonth()];
    switch (userType) {
      /* case 'buyer':
        statsData = [
          {
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToPay',
            value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
          }, /* {
            icon: 'fas fa-hand-holding-usd',
            title: 'deferredAmount',
            value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
          }, */
      /*  ];
        break;*/
      case 'seller':
        statsData = [
          /*{
            icon: 'fas fa-file-invoice-dollar',
            title: 'amountToGet',
            value: pending_amount ? formatCurrency(pending_amount) : formatCurrency(0),
          }, {
            icon: 'fas fa-file-invoice-dollar',
            title: 'paidAmount',
            value: paid_amount ? formatCurrency(paid_amount) : formatCurrency(0),
          },  {
            icon: 'fas fa-hand-holding-usd',
            title: 'anticipatedAmount',
            value: financed_amount ? formatCurrency(financed_amount) : formatCurrency(0),
          }, */
          {
            icon: 'fas fa-file-invoice-dollar',
            title: t('totalCharged', { month }),
            value: total_paid ? formatIntCurrency(total_paid) : formatCurrency(0),
          },
        ];
        break;
      default:
        statsData = [];
    }
    return { statsData };
  };

  labelType = (role) => {
    const {
      t,
    } = this.props;

    return role === 'issuing_bank' ? t('issuing_bank') :
      role === 'issuing_query' ? t('issuing_query') : role === 'cycle_loader' ? t('cycle_loader') :
        role === 'rate_operator' ? t('rate_operator') : t('rate_operator');
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const { search } = this.state;
    const {
      t,
      history,
      stats: { sellers, buyers },
      auth: { user: { b2b: { defer_condition, advance_condition }, profile: { type } } },
      getClients,
      getClient,
      getClientsConditions,
      getProviders,
      getProvidersConditions,
      getProvider,
      getCycleCard,
      getCurrency,
      getCard,
      fetchBanks,
      updateStatus,
      getProviderCommerceCode,
      transactions: { list },
      bank: { list: bankList },
      providerCommerceCode,
      role: { list: roleList },
      fetchCategories,
    } = this.props;

    let role_id = '';
    for (let i = 0; i < roleList.length; i++) {
      if (roleList[i].name === 'seller') {
        role_id = roleList[i].id;
      }
    }

    switch (userType) {
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('taxId'), //t('documentNumber')
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            headerClassName: 'wordwrap',
            accessor: d => d !== '' ? d.address_full : t('incompleteDate'),
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.username}`,
          },
          {
            id: 'fantasyName',
            Header: t('fantasyName'),
            show: false,
            headerClassName: 'wordwrap',
            accessor: d => d.business_name === null ? '' : `${d.business_name}`,
          },
          {
            id: 'operationVolume', // Required because our accessor is not a string
            Header: t('operationVolume'),
            headerClassName: 'wordwrap',
            accessor: d => formatCurrencyCl(d.invoice_total_amount),
          },
          {
            id: 'user_is_active', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_is_active === 1 ? t('active') : t('inactive'),
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return true;
              }
              if (filter.value === t('active')) {
                return row.user_is_active === t('active');
              }
              if (filter.value === t('inactive')) {
                return row.user_is_active === t('inactive');
              }
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value={t('active')}>{t('active')}</option>
                <option value={t('inactive')}>{t('inactive')}</option>
              </select>,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: 'T&C', //t('conditions')
            headerClassName: 'wordwrap',
            style: { whiteSpace: 'nowrap' },
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      style={{ display: (type === 'query' ? 'none' : '') }}
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getClients({
                          params: {
                            //id: cellInfo.original.id,
                          },
                          callback: () => {
                            providerCommerceCode({
                              params: {
                                user: cellInfo.original.id,
                                role: role_id,
                              },
                              callback: (response) => {
                                history.push({
                                  pathname: '/create-payment-condition',
                                  state: {
                                    userInfo: cellInfo.original,
                                    codesProvider: response,
                                  },
                                });
                                //this.setState({ codes: response.commerce_codes });
                              },
                            });
                          },
                          /*callback: () => this.setState({
                            id: cellInfo.original.id,
                            baseDays: 0,
                            percentage: 0,
                            discountRate: 0,
                            receiverDiscountShare: 0,
                            // editable: true,
                            hidden: true,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              paymentCondition: cellInfo.original,
                            });
                          }),*/
                        });
                      }}
                    >
                      <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                      {`${t('new')}`}
                    </button>
                    <Link
                      className="button is-primary is-inverted ml-8px"
                      to="/payment-conditions"
                      onClick={() => getClientsConditions({ params: { id: cellInfo.original.id } })}
                    >
                      <i className="far fa-eye mr-8px" aria-hidden="true"/>
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchCategories({});
                        getCurrency({});
                        getProvider({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push('/edit-provider');
                          }
                        });
                      }}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      title={t('affiliateNumber')}
                      onClick={() => {
                        getProviderCommerceCode({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push({
                              pathname: '/commerce-code',
                            });
                          }
                        });
                      }}
                    >
                      <i className="fas fa-store mr-8px" aria-hidden="true"/>
                      {/*`${t('code')}`*/}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        search ? data = sellers : data = [];
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('taxId'), //t('documentNumber')
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            headerClassName: 'wordwrap',
            accessor: d => d !== '' ? d.address_full : t('incompleteDate'),
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.username}`,
          },
          {
            id: 'fantasyName',
            Header: t('fantasyName'),
            show: false,
            headerClassName: 'wordwrap',
            accessor: d => d.business_name === null ? '' : `${d.business_name}`,
          },
          {
            id: 'operationVolume', // Required because our accessor is not a string
            Header: t('operationVolume'),
            headerClassName: 'wordwrap',
            accessor: d => formatCurrencyCl(d.invoice_total_amount),
          },
          {
            id: 'user_is_active', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => d.user_is_active === 1 ? t('active') : t('inactive'),
            filterMethod: (filter, row) => {
              if (filter.value === 'all') {
                return true;
              }
              if (filter.value === t('active')) {
                return row.user_is_active === t('active');
              }
              if (filter.value === t('inactive')) {
                return row.user_is_active === t('inactive');
              }
            },
            Filter: ({ filter, onChange }) =>
              <select
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value={t('active')}>{t('active')}</option>
                <option value={t('inactive')}>{t('inactive')}</option>
              </select>,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: 'T&C', //t('conditions')
            headerClassName: 'wordwrap',
            style: { whiteSpace: 'nowrap' }, 
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      style={{ display: (type === 'query' ? 'none' : '') }}
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getProviders({
                          params: {
                            //id: cellInfo.original.id,
                          },
                          callback: () =>
                            history.push({
                              pathname: '/create-payment-condition',
                              state: {
                                userInfo: cellInfo.original,
                                //codesProvider: response,
                              },
                            })

                          /*callback: () => this.setState({
                            id: cellInfo.original.id,
                            baseDays: 0,
                            percentage: 0,
                            discountRate: 0,
                            receiverDiscountShare: 0,
                            //editable: true,
                            hidden: true,
                          }, () => {
                            onToggle({
                              isEdit: true,
                              paymentCondition: cellInfo.original,
                            });
                          }),*/
                        });
                      }}
                    >
                      <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                      {`${t('new')}`}
                    </button>

                    <Link
                      className="button is-primary is-inverted ml-8px"
                      to="/payment-conditions"
                      onClick={() =>
                        getProvidersConditions({ params: { id: cellInfo.original.id } })}
                    >
                      <i className="far fa-eye mr-8px" aria-hidden="true"/>
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        fetchCategories({});
                        getCycleCard({});
                        getClient({
                          params: {
                            id: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push('/edit-customer');
                          }
                        });
                      }}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {`${t('edit')}`}
                    </button>
                  </td>
                  {/*<td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        getCard({
                          params: {
                            person: cellInfo.original.id,
                          },
                          callback: () => {
                            history.push({
                              pathname: '/payment-person',
                              state: {
                                clientName: cellInfo.original.idPerson.name,
                              }
                            });
                          }
                        });
                      }}
                    >
                      <span className="icon" title={t('viewTta')}>
                        <i className="far fa-credit-card" style={{ fontSize: '16px' }}/>
                      </span>
                    </button>
                  </td>*/}
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        search ? data = (buyers) : data = [];
        title = t('customers');
        break;
      case 'rate_operator':
        columns = [
          {
            id: 'mainInvoiceNumber', // Required because our accessor is not a string
            Header: t('mainInvoiceNumber'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoice_number === null ? '' : `${d.invoice_number}`,
          },
          {
            id: 'datePayment', // Required because our accessor is not a string
            Header: t('datePayment'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => formatCurrencyCl(d.adjusted_amount),
          },
          {
            id: 'currency', // Required because our accessor is not a string
            Header: t('currency'),
            headerClassName: 'wordwrap',
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('customer'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiverStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <td>
                <Link
                  className="button is-primary is-inverted"
                  to="/dashboard"
                  onClick={() => {
                    this.setState({
                      id: cellInfo.original.id,
                      discountRateChargesDate: formatDate(cellInfo.original.date),
                      discountRateChargesDueDate: formatDate(cellInfo.original.pay_provider_date),
                      discountRateChargesTotal: cellInfo.original.adjusted_amount,
                      discountRateChargesDescription: cellInfo.original.description,
                      discountRateChargesPercentage: cellInfo.original.paymentCondition.percentage,
                      discountRateChargesDiscountRate: cellInfo.original.paymentCondition.discount_rate,
                      discountRateChargesInvoiceNumber: cellInfo.original.invoice_number === null ? '' : cellInfo.original.invoice_number,
                      currency: cellInfo.original.pay_currency_id,
                      is_rate: true
                      //editable: true,
                    }, () => {
                      onToggle({
                        is_rate: true,
                      });
                    });
                  }}
                >
                  <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                  {t('info')}
                </Link>
              </td>
            ),
          }];
        title = t('transactions');
        data = list;
        break;
      case 'buyer':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => parseFloat(d.adjusted_amount),
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.senderStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <td>
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-primary is-inverted"
                    to="/dashboard"
                    onClick={() => this.approveInvoice(cellInfo.original.id)}
                  >
                    <i className="fas fa-check mr-8px" aria-hidden="true"/>
                    {t('accept')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PAP'
                && (
                  <Link
                    className="button is-danger is-inverted"
                    to="/dashboard"
                    onClick={() => this.rejectInvoice(cellInfo.original.id)}
                  >
                    <i className="fas fa-times mr-8px" aria-hidden="true"/>
                    {t('reject')}
                  </Link>
                )}
                {cellInfo.original.sender_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: defer_condition,
                      onSubmit: this.deferPayment,
                    })}
                  >
                    <i className="far fa-clock mr-8px" aria-hidden="true"/>
                    {t('differ')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('payedAmounts');
        break;
      case 'seller_assist_card':
      case 'seller':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'paymentDate', // Required because our accessor is not a string
            Header: t('paymentDate'),
            headerClassName: 'wordwrap',
            accessor: d => formatDate(d.due_date),
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            accessor: d => `$ ${d.adjusted_amount}`,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.description}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.receiverStatus.description}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <td>
                {cellInfo.original.receiver_status_id === 'PEJ'
                && (
                  <Link
                    className="button is-success is-inverted"
                    to="/dashboard"
                    onClick={() => onToggle({
                      transaction: cellInfo.original,
                      paymentCondition: advance_condition,
                      onSubmit: this.advancePayment,
                    })}
                  >
                    <i className="far fa-clock mr-8px" aria-hidden="true"/>
                    {t('advance')}
                  </Link>
                )}
              </td>
            ),
          }];
        title = t('amountToCollect');
        break;
      case 'general_admin':
      case 'general_admin_issuing':
      case 'general_admin_issuing_amex':
      case 'general_admin_acquiring':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'document_number', // Required because our accessor is not a string
            Header: t('taxId'), //t('documentNumber') 
            accessor: d => getPath(['idPerson', 'document_number'], d) !== null ?
              `${(getPath(['idPerson', 'document_number'], d))}` : '',
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => d.idPerson.name,
          },
          {
            id: 'bank_type', // Required because our accessor is not a string
            Header: t('type'),
            accessor: d => t(d.roles[0].name),
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => d.idPerson.idEmailActive.email,
          },
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            width: 100,
            Cell: cellInfo => (cellInfo.original.user_status === '1'
              ? <i className="far fa-check-circle mr-8px" aria-hidden="true"
                   style={{ color: 'green' }}/>
              : <i className="fas fa-ban mr-8px" aria-hidden="true" style={{ color: 'red' }}/>),
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            style: { whiteSpace: 'nowrap' },
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <Link
                      className={`button is-inverted is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                      to="/bank-list"
                      onClick={() => updateStatus({
                        data: {
                          id_user: cellInfo.original.id,
                          new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                        },
                        callback: () => fetchBanks({})
                      })}
                    >
                      {cellInfo.original.user_status === '1'
                        ? t('disable')
                        : t('enable')
                      }
                    </Link>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => history.push({
                        pathname: '/edit-admin-bank',
                        state: {
                          userInfo: cellInfo.original
                        },
                      })}
                    >
                      <i className="far fa-edit mr-8px" aria-hidden="true"/>
                      {t('edit')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          }];
        title = t('amountToCollect');
        data = bankList;
        break;
      case 'main_bank':
      case 'main_bank_acquiring':
      case 'main_bank_issuing':
      case 'main_bank_issuing_amex':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('taxId'), //t('documentNumber')
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'document_number'], d) === null ? '' : getPath(['idPerson', 'document_number'], d)} `,
            //accessor: d => `${getPath(['idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.idPerson.name}`,
          },
          {
            id: 'charge', // Required because our accessor is not a string
            Header: t('charge'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.admin_position}`,
          },
          {
            id: 'rol', // Required because our accessor is not a string
            Header: t('rol'),
            headerClassName: 'wordwrap',
            accessor: d => userType === 'main_bank_issuing' ? this.labelType(d.roles[0].name) : `${t(d.roles[0].name)}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.username}`,
          },
          /*{
            id: 'fantasyName',
            Header: t('fantasyName'),
            accessor: d => d.business_name === null ? '' : `${d.business_name}`,
          },*/
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                <div>
                    <Link
                      className={`button is-inverted is-${cellInfo.original.user_status === '1' ? 'danger' : 'success'}`}
                      to="/dashboard"
                      onClick={() =>
                        updateStatus({
                          data: {
                            id_user: cellInfo.original.id,
                            new_status: cellInfo.original.user_status === '1' ? '0' : '1'
                          },
                          callback: () => fetchBanks({})
                        })}
                    >
                      {cellInfo.original.user_status === '1'
                        ? t('disable')
                        : t('enable')
                      }
                    </Link>
                    <button
                      className="button is-primary is-inverted"
                      style={{marginLeft: '.5em'}}
                      onClick={() => history.push({
                        pathname: '/edit-bank-user',
                        state: {
                          userInfo: cellInfo.original
                        },
                      })}
                    >
                      <i className="far fa-edit" aria-hidden="true"/>
                      {/*t('edit')*/}
                    </button>
                  </div>
                </tr>
                </tbody>
              </table>
            ),
          }
        ];
        title = t('users');
        search ? data = bankList : data = [];
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  newPaymentCondition = (onToggle) => {
    const {
      auth: { user: { user_id, b2b: { type_user } } },
      clients: { list: clientList },
      providers: { list: providerList },
      createPaymentCondition,
      requestPaymentCondition,
      t,
      showMessage,
    } = this.props;
    const {
      id,
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      percentage,
      startCountingFrom,
      //limitAmount,
      clientPayment,
      commerceCode,
    } = this.state;
    const days = baseDays;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const conditionData = {
      bank_sender_id: true,
      sender_request_id: null,
      sender_id: type_user === 'acquiring_bank' ? persons[client].id : id,
      receiver_id: type_user === 'acquiring_bank' ? id : persons[provider].id,
      days,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      type: 'base',
      person_commerce_code: commerceCode,
    };
    if (days < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
      requestPaymentCondition({
        data: conditionData,
        callback: () => {
          onToggle();
          showMessage({
            message: t('createPaymentConditionSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    //}
  };

  approveInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: true,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
      },
    });
  };

  rejectInvoice = (transaction_id) => {
    const {
      approveInvoice,
      listTransactionsSender,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      approved: false,
    };
    approveInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
      },
    });
  };

  deferPayment = (transaction_id, condition_id, onToggle) => {
    const {
      deferInvoice,
      listTransactionsSender,
      fetchStats,
      auth: {
        user: { user_id },
      },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    deferInvoice({
      data,
      callback: () => {
        listTransactionsSender({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };

  advancePayment = (transaction_id, condition_id, onToggle) => {
    const {
      advanceInvoice,
      listTransactionsReceiver,
      fetchStats,
      auth: {
        user: { user_id },
      },
      card: { list },
    } = this.props;
    const data = {
      transaction_id,
      condition_id,
    };
    advanceInvoice({
      data,
      callback: () => {
        listTransactionsReceiver({ params: { user_id } });
        fetchStats();
        onToggle();
      },
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /* if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else 
    if(name === "document_number"){
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';
      if(num.length >= 9){
          prenum = num.slice(0,8);
          postnum = num.slice(8,9);
          num = prenum+"-"+postnum;
        }  
        
      this.setState({
        [name]: (num),
      })
    } else */

    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    }else{
      if(name === "document_number"){
        let num = this.formatNumber2(event.target.value, 10, "number");

        this.setState({
          [name]: (num),
        })
      } else {
      this.setState({
        [name]: (value),
      });
      }
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  formatNumber2 = (value, length, type) => {
      if(type === "number") {return value.replace(/\D/g, '').slice(0, length)}
      if(type === "text") {return value.replace(new RegExp("[0-9]"), '').slice(0, length)}
      if(type === "textNum") {return value.replace(new RegExp("^[a-zA-Z][0-9]"), '').slice(0, length)}
}

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const { clients, providers, providerCommerceCode, role: { list } } = this.props;
    const { editable, codes } = this.state;
    this.setState({
      [label.name]: newVal.value,
    });

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      providerCommerceCode({
        params: {
          user: providers.list[newVal.value].id,
          role: role_id,
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].description,
      });
    }

    if (editable) {
      const index = newVal.value;
      const conditions = label.name === 'client' ? clients.list[index].conditions : providers.list[index].conditions;

      const percentageValue = conditions.receiver_discount_share * 100;

      this.setState({
        baseDays: conditions.days,
        percentage: conditions.percentage,
        discountRate: conditions.discount_rate,
        receiverDiscountShare: percentageValue,
        clientPayment: 100 - percentageValue,
        //limitAmount: conditions.limit_amount,
      });
    }
  };

  searchPerson = () => {
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
      user,
      rol,
      rut,
    } = this.state;
    const { fetchStats, fetchBanks, auth: { user: { b2b: { type_user } } }, } = this.props;
    this.setState({ search: true });
    if (type_user === 'main_bank' || type_user === 'main_bank_acquiring'
      || type_user === 'main_bank_issuing' || type_user === 'main_bank_issuing_amex') {
      fetchBanks({
        params: {
          email,
          user,
          role: rol,
          document_number: rut,
          main_bank: true,
        }
      });
    } else if (type_user === 'general_admin' || type_user === 'general_admin_issuing' ||
      type_user === 'general_admin_acquiring' || type_user === 'general_admin_issuing_amex') {
      fetchBanks({
        params: {
          email,
          user,
          role: rol,
          document_number: rut,
          general_admin: true,
        }
      });
    } else {
      fetchStats({
        document_number,
        name,
        email,
        min_amount,
        max_amount,
      });
    }
  };

  getTitle = () => {
    const {
      t,
    } = this.props;
    return {
      title1: t('issuedInvoices'),
      title2: t('invoicesApproved'),
      title3: t('approvedMount'),
      title4: t('invoicesToBeApproved'),
      title5: t('dateRange'),
    };
  };

  handleDateChange = (dateName, newDate) => {
    if (dateName === 'invoiceDate' && newDate !== '') {
      newDate.setHours(0, 0, 0, 0);
    }
    if (dateName === 'invoiceDateUntil' && newDate !== '') {
      newDate.setHours(23, 59, 59, 999);
    }

    this.setState({
      [dateName]: newDate,
    });
  };

  rangeDate = () => {
    const {
      statTransaction,
      auth: { user: { b2b: { type_user } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      statTransaction({
        params: {
          emmit: true,
          approved: true,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        },
        callback: (response) => {
          this.setState({ stat: response });
        }
      });
    }
  };

  searchInvoice = () => {
    const {
      t, showMessage,
    } = this.props;
    const {
      mainInvoiceNumber,
      invoiceDate,
      invoiceDateUntil,
    } = this.state;
    const { rateTransaction } = this.props;

    if (invoiceDateUntil < invoiceDate) {
      showMessage({
        message: <strong
          style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      rateTransaction({
        params: {
          invoice_number: mainInvoiceNumber,
          date_init: invoiceDate,
          date_end: invoiceDateUntil / 1000,
          is_report: false,
        }
      });
    }
  };

  saveInvoice = (onToggle) => {
    const {
      showMessage,
      t,
      saveRate,
      rateTransaction,
    } = this.props;
    const {
      id,
      discountRateChargesInvoiceNumber,
      discountRateChargesTotal,
      mainInvoiceNumber,
      invoiceDate,
      invoiceDateUntil,
    } = this.state;

    if (discountRateChargesInvoiceNumber !== '' && discountRateChargesTotal !== '') {
      const data = {
        id,
        invoice_number: discountRateChargesInvoiceNumber,
        adjusted_amount: discountRateChargesTotal,
      };

      saveRate({
        data,
        callback: () => {
          rateTransaction({
            params: {
              invoice_number: mainInvoiceNumber,
              date_init: invoiceDate,
              date_end: invoiceDateUntil,
            }
          });
          onToggle();
          showMessage({
            message: t('discountRateInvoiceSavedSuccessfully'),
            config: TOAST_CONFIG.SUCCESS,
          });
        }
      });
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }

  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  render() {
    const {
      t, auth: { loading: authLoading, user: { b2b: { type_user, is_password_expire } } },
      card: { list, loading: cardLoading },
      stats: { graph_data, payment_request_pending, loading: statsLoading },
      clients: { list: clientList, loading: clientLoading },
      providers: { list: providerList, loading: providerLoading },
      transactions: {
        list: graph,
        pending_invoices,
        reject_invoices,
        emit_invoices,
        loading: transactionLoading,
      },
      bank: { loading: bankLoading },
      transactions,
      paymentConditions: { loading: paymentConditionLoading },
      showMessage,
    } = this.props;
    const {
      document_number,
      name,
      email,
      min_amount,
      max_amount,
    } = this.state;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const { statsData } = this.getStatsData(type_user);
    const { statsData1 } = this.getStatsData1(type_user);
    const { statsData: lateralStatsData } = this.getLateralStatsData(type_user);
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      //limitAmount,
      inputValue,
      alert,
      stat,
      invoiceDate,
      invoiceDateUntil,
      mainInvoiceNumber,
      handleDateChange,
      is_rate,
      discountRateChargesPercentage,
      discountRateChargesDescription,
      discountRateChargesDate,
      discountRateChargesDueDate,
      discountRateChargesTotal,
      discountRateChargesDiscountRate,
      discountRateChargesInvoiceNumber,
      currency,
      user,
      rol,
      rut,
      hidden,
      arrayOrder,
      notification,
      codes,
      commerceCode,
      codeDescription,
      dashboardStarDate,
      dashboardEndDate,
      invoicesToApproveMonthly,
      invoicesSentMontly,
      approvedAndDeferredInvoices,
      inactiveClientsMonthly,
      numberOfClientsMonthly,
      ammountOfApprovedInvoicesMonthly,
      ammountPaidInvoices,
      ammountApprovedInvoices,
      quatityOne,
      quatityTwo,
      quatityThree,
    } = this.state;
    
    let dias = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];
    // const loading = authLoading || bankLoading || clientLoading || providerLoading || cardLoading || transactionLoading || paymentConditionLoading || statsLoading;

    const loading = authLoading || bankLoading || clientLoading || providerLoading || transactionLoading || paymentConditionLoading || statsLoading;
    
    if (is_password_expire === 1) {
      return <Redirect to="/recover-password"/>;
    }
    /*if (type_user === 'main_bank' || type_user === 'main_bank_acquiring' || type_user === 'main_bank_issuing') {
      return <Redirect to="/bank-user-list"/>;
    }*/
    const { title1, title2, title3, title4, title5 } = this.getTitle();
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              {/* ALERTS */}
              {/* {alert && (
                <Modal 
                    onToggle={() => this.setState({ alert: false })}
                    title={alert === 'bank' ? t(notification, { payment_request_pending }) : alert === 's' ? t('emitInvoices', { emit_invoices }) : t('pendingInvoicesQTY', { pending_invoices })}>
                    {alert === 'bank' &&
                    <div style={{textAlign: 'center', marginTop: '1.5em'}}>
                      <Link
                        className="button custom-btn is-primary"
                        style={{ minWidth: '200px' }}
                        to="/payment-request-received"
                      >
                        {t('view')}
                      </Link>
                    </div>}
                    {alert === 's' &&
                    <div style={{textAlign: 'center', marginTop: '1.5em'}}>
                      <Link
                        className="button custom-btn is-primary"
                        style={{ minWidth: '200px' }}
                        to="/pending-invoices"
                      >
                        {t('view')}
                      </Link>
                    </div>}
                    {alert === 'b' &&
                    <div style={{textAlign: 'center', marginTop: '1.5em'}}>
                      <Link
                        className="button custom-btn is-primary"
                        style={{ minWidth: '200px' }}
                        to="/review-invoices"
                      >
                        {t('view')}
                      </Link>
                    </div>}
                </Modal>
              )} */}

              {/* INFORMATIVE BOXES */}
              <div className="columns is-multiline">
                {/* { ['issuing_bank', 'acquiring_bank'].includes(type_user) &&
                  <>
                    <div className='column is-full'>
                      <div className='columns'>
                        <DataBox icon="far fa-id-badge" bg="red" text={ type_user === 'issuing_bank' ? t('totalBuyersTh') : t('totalSellers') } value={quatityOne} size={'is-one-third'} />
                        <DataBox icon="fas fa-file-invoice-dollar" bg="yellow" text={ type_user === 'issuing_bank' ? t('totalBuyersInvoiced') : t('totalSellersInvoiced')} value={quatityTwo} size={'is-one-third'} />
                        <DataBox icon="fas fa-hand-holding-usd" bg="green" text={ type_user === 'issuing_bank' ? t('totalBuyersDeferred') : t('totalBuyersDeferred')} value={quatityThree} size={'is-one-third'} />
                      </div>
                    </div>
                    <div className="column is-full">
                      <div className="column is-full date-range-dashboard">
                        <h3 className='date-range-title'>{t('dateRange')}</h3>
                        <div className="column is-full">
                          <div className="columns">
                            <div className="column is-one-quarter"></div>
                            <div className="column is-one-quarter">
                              <DateInput
                                name="dashboardStarDate"
                                label={t('since')}
                                value={dashboardStarDate}
                                onChange={(value) => this.handleDateChange('dashboardStarDate', value)}
                                type="text"
                                fieldClasses="is-expanded"
                                controlClasses="has-icons-left"
                                icon="far fa-calendar-alt"
                                iconPosition="is-left"
                                position="absolute"
                              />
                            </div>
                            <div className="column is-one-quarter">
                              <DateInput
                                name="dashboardEndDate"
                                label={t('until')}
                                value={dashboardEndDate}
                                onChange={(value) => this.handleDateChange('dashboardEndDate', value)}
                                type="text"
                                fieldClasses="is-expanded"
                                controlClasses="has-icons-left"
                                icon="far fa-calendar-alt"
                                iconPosition="is-left"
                              />
                            </div>
                            <div className="column">
                              <button
                                type="button"
                                className="button is-primary btn-send"
                                onClick={() => {
                                  dashboardStarDate === '' && dashboardEndDate === '' ?
                                    showMessage({
                                      message: <strong
                                        style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                      config: TOAST_CONFIG.ERROR,
                                    })
                                    : dashboardEndDate < dashboardStarDate ?
                                    showMessage({
                                      message: <strong
                                        style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                      config: TOAST_CONFIG.ERROR,
                                    })
                                    :
                                    this.loadDashboardData();
                                }}
                              >
                                {t('send')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='column is-full'>
                      {type_user === 'issuing_bank' && (
                        <div>
                          <div className='columns'>
                            <LineChartBox title={`${t('invoicesToBeApproved')} ${t('monthly')}`} name={t('invoicesToBeApproved')} data={invoicesToApproveMonthly} />
                            <BarChartBox title={`${t('invoicesSent')} ${t('monthly')}`} names={[t('invoicesSent')]} data={invoicesSentMontly} keys={['first']} colors={['#e47d08']} />
                            <BarChartBox title={t('invoicesDeferredApproved')} names={[t('approved'), t('deferred')]} data={approvedAndDeferredInvoices} keys={['first', 'second']} colors={['#e47d08', '#0278f3']} legend={true} />
                          </div>
                          <div className='columns'>
                            <LineChartBox title={`${t('inactiveClients')} ${t('monthly')}`} name={t('inactiveClients')} data={inactiveClientsMonthly} />
                            <LineChartBox title={`${t('clientsQuantity')} ${t('monthly')}`} name={t('clientsQuantity')} data={numberOfClientsMonthly} />
                            <LineChartBox title={`${t('amountApprovedInvoices')} ${t('monthly')}`} name={t('amountApprovedInvoices')} data={ammountOfApprovedInvoicesMonthly} />
                          </div>
                        </div>
                      )}
                      {type_user === 'acquiring_bank' && (
                        <div>
                          <div className='columns'>
                            <BarChartBox title={t('amountPaidInvoices')} names={[t('amount')]} data={ammountPaidInvoices} keys={['first']} colors={['#e47d08']} />
                            <LineChartBox title={t('invoicesApproved')} name={t('approved')} data={invoicesToApproveMonthly} />
                            <BarChartBox title={t('invoicesPendingApproved')} names={[t('pending'), t('deferred')]} data={approvedAndDeferredInvoices} keys={['first', 'second']} colors={['#e47d08', '#0278f3']} legend={true} />
                          </div>
                          <div className='columns'>
                            <BarChartBox title={t('amountApprovedInvoices')} names={[t('amount')]} data={ammountApprovedInvoices} keys={['first']} colors={['#0278f3']} />
                            <LineChartBox title={`${t('sellersQuantity')} ${t('monthly')}`} name={t('providers')} data={numberOfClientsMonthly} />
                            <LineChartBox title={t('invoicesToBeApproved')} name={t('invoicesToBeApproved')} data={inactiveClientsMonthly} />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                } */}
                {/* <div className="column is-full"
                     style={{
                       display: type_user !== 'general_admin' && type_user !== 'general_admin_acquiring' &&
                       type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                       && type_user !== 'main_bank_issuing_amex' ? '' : 'none'
                     }}>
                  <InformativeBoxes items={statsData} size="is-one-four"/>
                </div>
                <div className="column is-full"
                     style={{
                       display: type_user !== 'general_admin' && type_user !== 'general_admin_acquiring' &&
                       type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                       && type_user !== 'main_bank_issuing_amex' ? '' : 'none'
                     }}>
                  <InformativeBoxes items={statsData1} size="is-one-four"/>
                </div> */}
                <Fragment>
                  {
                    toggled && is_rate === false
                    && (
                      <Modal onToggle={onToggle}> {loading && <Loading/>}
                        <PaymentConditionForm
                          t={t}
                          onSubmit={() => this.newPaymentCondition(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handlePercentageChange={this.handlePercentageChange}
                          isClient={type_user !== 'acquiring_bank'}
                          isConditionClient={type_user === 'buyer'}
                          isConditionProvider={type_user === 'seller'}
                          handleSelectChange={this.handleSelectChange}
                          persons={persons}
                          client={client}
                          provider={provider}
                          baseDays={baseDays}
                          discountRate={discountRate}
                          receiverDiscountShare={receiverDiscountShare}
                          clientPayment={clientPayment}
                          editable={editable}
                          //limitAmount={limitAmount}
                          //hidden={hidden}
                          hidden={false}
                          handleOnInputChange={this.handleOnInputChange}
                          inputValue={inputValue}
                          closeModal={() => this.closeModal(onToggle)}
                          codes={codes}
                          commerceCode={commerceCode}
                          codeDescription={codeDescription}
                          usedInModals
                        />
                      </Modal>
                    )
                  }
                  {/* {((type_user !== 'admin' && type_user !== 'admin_amex' && type_user !== 'cycle_loader'
                    && type_user !== 'rate_operator' && type_user !== 'general_admin' &&
                    type_user !== 'general_admin_issuing' && type_user !== 'general_admin_issuing_amex'
                    && type_user !== 'general_admin_acquiring' && type_user !== 'main_bank_issuing_amex') && graph_data)
                  && (
                    <div className="column">
                      <Panel headingText={title}>
                        <ResponsiveContainer width="100%" height={500}>
                          <AreaChart
                            data={graph_data}
                            margin={{
                              top: 0,
                              right: 100,
                              bottom: 10,
                              left: 50,
                            }}
                          >
                            <defs>
                              <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                              </linearGradient>
                            </defs>
                            <Area
                              type="monotone"
                              legendType="line"
                              dataKey="y"
                              stroke="#0278f3"
                              strokeWidth={3}
                              fillOpacity={1}
                              fill="url(#greenGradient)"
                            />
                            <XAxis
                              dataKey="label"
                              interval="preserveStartEnd"
                              name={t('date')}
                              tick={{
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                              tickSize={10}
                              minTickGap={50}
                              tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                              // hide={true}
                              />
                            <YAxis
                              interval="preserveStartEnd"
                              name={t('amount')}
                              tick={{
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                              tickFormatter={tick => formatCurrency(tick)}
                              // hide={true}
                              />
                            <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                            <Tooltip
                              formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                              labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                month: 'long',
                                day: '2-digit',
                                weekday: 'long',
                                year: 'numeric',
                              }).format(new Date(label.replace(/-/g, '\/')))}
                            />
                            {graph_data.length > 0 && (
                              <Brush
                                dataKey="label"
                                height={50}
                                stroke="#000000"
                                travellerWidth={10}
                                y={450}
                                startIndex={0}
                                endIndex={60}
                              >
                                <AreaChart>
                                  <Area
                                    type="monotone"
                                    dataKey="y"
                                    fillOpacity={1}
                                    fill="url(#greenGradient)"
                                  />
                                </AreaChart>
                              </Brush>
                            )
                            }
                          </AreaChart>
                        </ResponsiveContainer>
                      </Panel>
                    </div>
                  )} */}
                  {/* {((type_user === 'admin' || type_user === 'admin_amex') && graph_data)
                  && (
                    <div className="column is-full">
                      <div style={{ zIndex: 99999 }}>
                        {
                          (type_user === 'admin' || type_user === 'admin_amex')
                          && (
                            <div className="block">
                              <div className="column is-full">
                                <div className="columns field-body">
                                  <div className="column">
                                    <p style={{
                                      textAlign: 'center',
                                      fontSize: '1.25rem'
                                    }}>
                                      {t('dateRange')}
                                    </p>
                                  </div>
                                  <div className="column" style={{ zIndex: 2 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                      position="absolute"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 2 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div style={{ marginTop: '35px' }}>
                                    <button
                                      type="button"
                                      className="button is-primary"
                                      onClick={() => {
                                        invoiceDate === '' && invoiceDateUntil === '' ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          : invoiceDateUntil < invoiceDate ?
                                          showMessage({
                                            message: <strong
                                              style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                            config: TOAST_CONFIG.ERROR,
                                          })
                                          :
                                          this.rangeDate();
                                      }}
                                    >
                                      {t('send')}
                                    </button>
                                  </div>
                                </div>
                                <div className="columns field-body">
                                  <div className="column"></div>
                                </div>
                              </div>
                            </div>
                          )}
                      </div>
                      <br/>
                      <div className="columns field-body" style={{ zIndex: 1 }}>
                        <div className="column" style={{ zIndex: 1, }}>
                          <Panel headingText={title1} style={{ zIndex: 1 }}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[0].graph_emmit : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                                width={300}
                                height={100}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#0278f3"
                                  strokeWidth={3}    
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                  // hide={true}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                  // hide={true}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[0].graph_emmit.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title2}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[1].graph_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#0278f3"
                                  strokeWidth={3} 
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[1].graph_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                      <div className="columns field-body">
                        <div className="column">
                          <Panel headingText={title3}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[2].graph_amount_approved : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#0278f3"
                                  strokeWidth={3} 
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('amount')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => formatCurrency(tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [formatCurrency(value), t('accumulatedAmount')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[2].graph_amount_approved.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                        <div className="column">
                          <Panel headingText={title4}>
                            <ResponsiveContainer width="100%" height={400}>
                              <AreaChart
                                data={stat !== '' ? stat.graph_data[3].graph_pending_approve : 0}
                                margin={{
                                  top: 0,
                                  right: 50,
                                  bottom: 10,
                                  left: 10,
                                }}
                              >
                                <defs>
                                  <linearGradient id="greenGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.95}/>
                                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.1}/>
                                  </linearGradient>
                                </defs>
                                <Area
                                  type="monotone"
                                  legendType="line"
                                  dataKey="y"
                                  stroke="#0278f3"
                                  strokeWidth={3} 
                                  fillOpacity={1}
                                  fill="url(#greenGradient)"
                                />
                                <XAxis
                                  dataKey="label"
                                  interval="preserveStartEnd"
                                  name={t('date')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickSize={5}
                                  minTickGap={25}
                                  tickFormatter={tick => formatFriendlyDate(new Date(tick.replace(/-/g, '\/')))}
                                />
                                <YAxis
                                  interval="preserveStartEnd"
                                  name={t('quantity')}
                                  tick={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                  }}
                                  tickFormatter={tick => (tick)}
                                />
                                <CartesianGrid strokeDasharray="5 5" vertical={false}/>
                                <Tooltip
                                  formatter={value => [(value), t('totalInvoices')]}
                                  labelFormatter={label => new Intl.DateTimeFormat('es-AR', {
                                    month: 'long',
                                    day: '2-digit',
                                    weekday: 'long',
                                    year: 'numeric',
                                  }).format(new Date(label.replace(/-/g, '\/')))}
                                />
                                {stat !== '' ? stat.graph_data[3].graph_pending_approve.length > 0 : 0 && (
                                  <Brush
                                    dataKey="label"
                                    height={50}
                                    stroke="#000000"
                                    travellerWidth={10}
                                    y={450}
                                    startIndex={0}
                                    endIndex={60}
                                  >
                                    <AreaChart>
                                      <Area
                                        type="monotone"
                                        dataKey="y"
                                        fillOpacity={1}
                                        fill="url(#greenGradient)"
                                      />
                                    </AreaChart>
                                  </Brush>
                                )
                                }
                              </AreaChart>
                            </ResponsiveContainer>
                          </Panel>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/*((type_user === 'seller' || type_user === 'seller_assist_card') && lateralStatsData) && (
                    <div className="column is-one-third">
                      <InformativeBoxes
                        items={lateralStatsData}
                        size="is-full"
                        className="inverted"
                      />
                    </div>
                  )*/}
                  {data
                  && (
                    <div className="column is-full"
                         style={{ display: type_user === 'admin' ? 'none' : '' }}>
                      <Panel
                        headingText={
                          (type_user === 'issuing_bank' || type_user === 'issuing_query') ? t('search') + ' ' + t('clients') :
                            (type_user === 'acquiring_bank' || type_user === 'acquiring_query') ? t('search') + ' ' + t('providers') :
                              (type_user === 'rate_operator' || type_user === 'cycle_loader') ? t('search') + ' ' + t('invoices') : t('search') + ' ' + t('users')}>
                        {
                          (type_user === 'acquiring_bank' || type_user === 'acquiring_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                            />
                          )}
                        {
                          (type_user === 'issuing_bank' || type_user === 'issuing_query')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              document_number={document_number}
                              name={name}
                              email={email}
                              min_amount={min_amount}
                              max_amount={max_amount}
                            />
                          )}
                        {
                          (type_user === 'general_admin' || type_user === 'main_bank' || type_user === 'general_admin_acquiring'
                            || type_user === 'main_bank_acquiring' || type_user === 'general_admin_issuing'
                            || type_user === 'general_admin_issuing_amex' || type_user === 'main_bank_issuing'
                            || type_user === 'main_bank_issuing_amex')
                          && (
                            <SearchPersonForm
                              t={t}
                              searchPerson={this.searchPerson}
                              handleInputChange={this.handleInputChange}
                              user={user}
                              email={email}
                              rol={rol}
                              rut={rut}
                              type_user={'banks'}
                              type_rol={type_user}
                            />
                          )}
                        {(type_user === 'rate_operator')
                        && (
                          <div style={{ zIndex: 99999 }}>
                            <Form
                              submitText={t('search')}
                              onSubmit={(event) => {
                                event.preventDefault();
                                this.searchInvoice();
                              }}
                              buttonClasses="mr-8px"
                            >
                              <div className="field is-horizontal">
                                <div className="columns field-body">
                                  <div className="column">
                                    <TextInput
                                      name="mainInvoiceNumber"
                                      label={t('mainInvoiceNumber')}
                                      value={mainInvoiceNumber}
                                      onChange={this.handleInputChange}
                                      placeholder={t('mainInvoiceNumber')}
                                      type="text"
                                      inputClasses="has-text-right"
                                      controlClasses="is-expanded has-icons-left"
                                      iconPosition="is-left"
                                      icon="fas fa-search"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('since')}
                                      value={invoiceDate}
                                      onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                  <div className="column" style={{ zIndex: 99999 }}>
                                    <DateInput
                                      name="invoice_date"
                                      label={t('until')}
                                      value={invoiceDateUntil}
                                      onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                      type="text"
                                      fieldClasses="is-expanded"
                                      controlClasses="has-icons-left"
                                      icon="far fa-calendar-alt"
                                      iconPosition="is-left"
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form>
                            {/*</Panel>*/}
                            <Fragment>
                              {
                                toggled && is_rate
                                && (
                                  <Modal onToggle={onToggle}> {loading && <Loading/>}
                                    <InvoiceDetailForm
                                      t={t}
                                      onSubmit={() => this.saveInvoice(onToggle)}
                                      handleInputChange={this.handleInputChange}
                                      sender={true}
                                      discountRateChargesDescription={discountRateChargesDescription}
                                      discountRateChargesDate={discountRateChargesDate}
                                      discountRateChargesDueDate={discountRateChargesDueDate}
                                      discountRateChargesInvoiceNumber={discountRateChargesInvoiceNumber}
                                      discountRateChargesDiscountRate={discountRateChargesDiscountRate}
                                      discountRateChargesPercentage={discountRateChargesPercentage}
                                      discountRateChargesTotal={discountRateChargesTotal}
                                      currency={currency}
                                      rate={true}
                                      isEdit={true}
                                      isRate={is_rate}
                                    />
                                  </Modal>
                                )
                              }
                            </Fragment>
                          </div>
                        )}
                        {(type_user !== 'admin' && type_user !== 'admin_amex' && type_user !== 'cycle_loader') && (
                          <div style={{ zIndex: 1, }}>
                            <TableSimple
                              data={data}
                              columns={columns}
                              subComponent={this.renderRowSubComponent}
                            />
                          </div>
                        )}
                        {(type_user === 'cycle_loader') && (
                          <Panel headingText={t('currentCycle')}>
                            <div className="field is-horizontal">
                            <div className="field-body columns">
                              <div className="column is-one-third">
                              </div>
                              <div className="column is-one-third">
                                <strong>
                                  {t('dateFf')}
                                </strong>
                              </div>
                              <div className="column is-one-third">
                                <strong>
                                  {t('dateV')}
                                </strong>
                              </div>
                            </div>
                          </div>
                          <div>
                          <ul>
                            {arrayOrder.map((val, index) => (
                              <li>
                                <div className="field is-horizontal">
                                  <div className="field-body columns">
                                    <div className="column is-one-fifth">
                                      <label style={{
                                        marginTop: '30px',
                                        display: 'block',
                                      }}>
                                        {t('group') + val.group}
                                      </label>
                                    </div>
                                    <div style={{
                                      width: '70px',
                                      marginTop: '40px'
                                    }}>
                                      <label>
                                        {dias[(new Date(val.date_init*1000).getDay())-1]}
                                      </label>
                                    </div>
                                    <div className="column is-one-third">
                                      <DateInput
                                        name={'cycle_date'}
                                        label={t('month')}
                                        value={new Date(val.date_init*1000)}
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthYearPicker={true}
                                        showFullMonthYearPicker={true}
                                        locale={'es'}
                                        disabled
                                        />
                                      <br/>
                                    </div>
                                    <div style={{
                                      width: '70px',
                                      marginTop: '40px'
                                    }}>
                                      <label>
                                        {dias[(new Date(val.date_end*1000).getDay())-1]}
                                      </label>
                                    </div>
                                    <div className="column is-one-third">
                                      <DateInput
                                        name={'cycle_date'}
                                        label={t('month')}
                                        value={new Date(val.date_end*1000)}
                                        fieldClasses="is-expanded"
                                        controlClasses="has-icons-left"
                                        icon="far fa-calendar-alt"
                                        iconPosition="is-left"
                                        dateFormat="dd/MM/yyyy"
                                        showMonthYearPicker={true}
                                        showFullMonthYearPicker={true}
                                        locale={'es'}
                                        disabled
                                        />
                                      <br/>
                                    </div>
                                  </div>
                                </div> 
                            </li>
                            ))}
                          </ul>
                          </div>
                          </Panel>
                        )}
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

SearchUsers.propTypes = {
  auth: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  stats: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getClientsConditions: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  getProvidersConditions: PropTypes.func.isRequired,
  fetchStats: PropTypes.func.isRequired,
  fetchLeads: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  approveInvoice: PropTypes.func.isRequired,
  deferInvoice: PropTypes.func.isRequired,
  advanceInvoice: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  getProvider: PropTypes.func.isRequired,
  getClient: PropTypes.func.isRequired,
  getCyclePeriods: PropTypes.func.isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  statTransaction: PropTypes.func.isRequired,
  requestPaymentCondition: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                           stats,
                           auth,
                           clients,
                           providers,
                           documentType,
                           transactions,
                           card,
                           currency,
                           bank,
                           paymentConditions,
                           role, item,
                         }) => ({
  stats,
  auth,
  clients,
  providers,
  documentType,
  transactions,
  card,
  currency,
  bank,
  paymentConditions,
  role,
  item,
});
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...bankActions,
    ...documentTypeActions,
    ...statsActions,
    ...transactionsActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...leadActions,
    ...cardActions,
    ...currencyActions,
    ...roleActions,
    ...itemActions,
  }),
  withNamespaces(),
)(SearchUsers);
