import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, PaymentConditionForm, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { getPath } from '../../util';
import { statsActions } from '../../state/ducks/stats';
import { TOAST_CONFIG } from '../../config/constants';

class PaymentConditions extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    client: null,
    provider: null,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    percentage: 0,
    days: 365,
    editable: true,
    startCountingFrom: 0,
    //limitAmount: 0,
    post: 0,
    clientEdit: '',
    codes: [],
    codeDescription: '',
    commerceCode: '',
    idCondition: '',
  });

  componentDidMount() {
    const { fetchRoles } = this.props;
    fetchRoles({
      params: {
        name: 'seller',
      },
    });
  }

  getTitle = () => {
    const {
      clients: { sender },
      stats: { sellers, buyers },
      providers: { receiver },
      auth: { user: { b2b: { type_user } } },
    } = this.props;

    let name = '';

    switch (type_user) {
      case 'acquiring_bank':
        if (sender.length > 0) {
          const post = sellers.map(
            (listClientMap, index) => ({
              ...listClientMap,
              index,
            }),
          )
            .filter(listClientFil => listClientFil.id
              === sender[0].condition.receiver_id)[0].index;
          name = getPath(['idPerson', 'name'], sellers[post]);
        }
        break;
      case 'issuing_bank':
        if (receiver.length > 0) {
          const post = buyers.map(
            (listClientMap, index) => ({
              ...listClientMap,
              index,
            }),
          )
            .filter(listClientFil => listClientFil.id
              === receiver[0].condition.sender_id)[0].index;
          name = getPath(['idPerson', 'name'], buyers[post]);
        }
        break;
      default:
        name = '';
    }
    return name;
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (userType, onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      showMessage,
      history,
      clients: { sender },
      providers: { receiver },
      t,
      deletePaymentCondition,
      getClientsConditions,
      getProvidersConditions,
      auth: { user: { profile: { type } } },
      providerCommerceCode,
      role: { list },
    } = this.props;

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }

    switch (userType) {
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('customers'),
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            width: 120,
            accessor: d => `${d.condition.days}`,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            accessor: d => ((d.condition.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'commerce_code', // Required because our accessor is not a string
            Header: t('merchantAffiliateCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.condition.personCode !== null ? `${d.condition.personCode.commerce_code}` : '',
          },
          /*{
            id: 'discountRate', // Required because our accessor is not a string
            Header: t('chargeForService'),
            headerClassName: 'wordwrap',
            width: 160,
            accessor: d => `${d.condition.discount_rate} %`,
          },
          {
            id: 'receiverDiscountShare', // Required because our accessor is not a string
            Header: t('receiverDiscountShare'),
            headerClassName: 'wordwrap',
            accessor: d => `${100 - d.condition.percentage} %`,
          },
          {
            id: 'limitAmount', // Required because our accessor is not a string
            Header: t('limit'),
            width: 190,
            accessor: d => `${formatCurrency(d.conditions.limit_amount)}`,
          },*/
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            show: type === 'query' ? false : true,
            width: 190,
            //accessor: d => `${d.id} `,
            Cell: cellInfo => (/*{
              original: {
                id,
                idPerson: {
                  name
                },
                conditions: {
                  id: conditionsId,
                  days,
                  due_from_approval,
                  discount_rate,
                  receiver_discount_share,
                  limit_amount,
                  person_commerce_code,
                },
              },
              original,
            }) => (*/
              <table>
                <tbody>
                  <tr>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.condition.receiver_id,
                              role: role_id,
                            },
                            callback: (response) => {
                              //this.setState({
                              history.push({
                                pathname: '/edit-payment-condition',
                                state: {
                                  codes: response.commerce_codes,
                                  commerceCode: response.commerce_codes.findIndex(function (code) {
                                    return code.id === cellInfo.original.condition.person_commerce_code;
                                  }),
                                  codeDescription: response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                    return code.id === cellInfo.original.condition.person_commerce_code;
                                  })].description,
                                  clientEdit: cellInfo.original.user.idPerson.name,
                                  idCondition: cellInfo.original.condition.id,
                                  baseDays: Number(cellInfo.original.condition.days),
                                  startCountingFrom: Number(cellInfo.original.condition.due_from_approval),
                                  sender_id: cellInfo.original.condition.sender_id,
                                  receiver_id: cellInfo.original.condition.receiver_id,

                                  discountRate: Number(cellInfo.original.condition.discount_rate),
                                  receiverDiscountShare: (cellInfo.original.condition.receiver_discount_share * 100).toFixed(2),
                                  clientPayment: 100 - (cellInfo.original.condition.receiver_discount_share * 100).toFixed(2),
                                  //limitAmount: limit_amount !== null ? this.formatNumber(limit_amount) : '',
                                  period: this.getDays(cellInfo.original.advance_condition.days),
                                  percentage: parseInt(cellInfo.original.advance_condition.percentage),
                                  idA: cellInfo.original.condition.id,
                                  idC: cellInfo.original.advance_condition.id,
                                  editable: true,
                                  hidden: false,
                                },
                              });
                            }
                          });
                        }}
                        /*codes: response.commerce_codes,
                        commerceCode: response.commerce_codes.findIndex(function (code) {
                          return code.id === cellInfo.original.condition.person_commerce_code;
                        }),
                        codeDescription: response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                          return code.id === cellInfo.original.condition.person_commerce_code;
                        })].description,
                      });*/
                        // },

                        //this.setState({
                        //clientEdit: cellInfo.original.user.idPerson.name,
                        //clientEdit: name,
                        /*sender.map(
                          (listClientMap, index) => ({
                            ...listClientMap,
                            index,
                          }),
                        )
                          .filter(listClientFil => listClientFil.id
                            === id)[0].index,*/
                        /*idCondition: cellInfo.original.condition.id,
                        baseDays: Number(cellInfo.original.condition.days),
                        startCountingFrom: Number(cellInfo.original.condition.due_from_approval),
                        sender_id: cellInfo.original.condition.sender_id,
                        receiver_id: cellInfo.original.condition.receiver_id,

                        discountRate: Number(cellInfo.original.condition.discount_rate),
                        receiverDiscountShare: (cellInfo.original.condition.receiver_discount_share * 100).toFixed(2),
                        clientPayment: 100 - (cellInfo.original.condition.receiver_discount_share * 100).toFixed(2),
                        //limitAmount: limit_amount !== null ? this.formatNumber(limit_amount) : '',
                        editable: true,
                        hidden: false,
                      }, () => {
                        onToggle({
                          isEdit: true,
                          role: cellInfo.original,
                        });*/
                        //});
                      >
                        <i className="far fa-edit mr-8px" aria-hidden="true"/>
                        {t('edit')}
                      </button>
                      <button
                        className="button is-danger is-inverted"
                        to="/payment-conditions"
                        onClick={() => {
                          onToggle({
                            deletePaymentCondition: true, 
                            id: cellInfo.original.condition.id,
                            userId: cellInfo.original.condition.receiver_id,
                          })
                          // deletePaymentCondition({
                          //   data: {
                          //     id: cellInfo.original.condition.id,
                          //   },
                          //   t,
                          //   callback: (resp) => {
                          //     getClientsConditions({
                          //       params: {
                          //         id: cellInfo.original.condition.receiver_id,
                          //       },
                          //     });
                          //     if (resp === 'Deleted') {
                          //       showMessage({
                          //         message: t('deletePaymentCondition'),
                          //         config: TOAST_CONFIG.SUCCESS,
                          //       });
                          //     } else {
                          //       showMessage({
                          //         message: t('actionNotAllowed'),
                          //         config: TOAST_CONFIG.ERROR,
                          //       });
                          //     }
                          //   },
                          // });
                        }}
                      >
                        <i className="fas fa-times mr-8px" aria-hidden="true" />
                        {t('delete')}
                      </button>
                    </div>
                  </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = sender;
        title = `${t('commercialConditions')} : ${this.getTitle()}`;
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('providers'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            width: 120,
            accessor: d => `${d.condition.days}`,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            headerClassName: 'wordwrap',
            accessor: d => ((d.condition.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          {
            id: 'commerce_code', // Required because our accessor is not a string
            Header: t('affiliateN'),
            headerClassName: 'wordwrap',
            accessor: d => d.condition.personCode !== null ? `${d.condition.personCode.commerce_code}` : '',
          },
          /*{
            id: 'discountRate', // Required because our accessor is not a string
            Header: t('chargeForService'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.condition.discount_rate} %`,
          },
          {
            id: 'senderDiscountShare', // Required because our accessor is not a string
            Header: t('clientPercentageCharge'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.condition.percentage} %`,
          },
          {
            id: 'limitAmount', // Required because our accessor is not a string
            Header: t('limit'),
            accessor: d => `${formatCurrency(d.conditions.limit_amount)}`,
          },*/
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            style: { whiteSpace: 'nowrap' },
            width: 190,
            show: type === 'query' ? false : true,
            accessor: d => `${d.id} `,
            Cell: ({
                     original: {
                       user: {
                         id,
                         idPerson: {
                           name
                         },
                       },
                condition: {
                  id: conditionsId,
                  days,
                  due_from_approval,
                  discount_rate,
                  receiver_discount_share,
                  limit_amount,
                  person_commerce_code,
                  sender_id, receiver_id,
                },
              },
              original,
            }) => (
              <table>
                <tbody>
                  <tr>
                    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                      <button
                        className="button is-primary is-inverted"
                        onClick={() => {
                          providerCommerceCode({
                            params: {
                              user: id,
                              role: role_id,
                            },
                            callback: (response) => {
                              history.push({
                                pathname: '/edit-payment-condition',
                                state: {
                                  codes: response.commerce_codes.length === 0 ? [] : response.commerce_codes,
                                  commerceCode: response.commerce_codes.length === 0 || person_commerce_code === null ? [] :
                                    response.commerce_codes.findIndex(function (code) {
                                      return code.id === person_commerce_code;
                                    }),
                                  codeDescription: response.commerce_codes.length === 0 || person_commerce_code === null ? '' :
                                    response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                      return code.id === person_commerce_code;
                                    })].description,
                                  clientEdit: name,
                                  sender_id,
                                  receiver_id,
                                  idCondition: original.condition.id,
                                  baseDays: Number(days),
                                  startCountingFrom: Number(due_from_approval),
                                  discountRate: Number(discount_rate),
                                  receiverDiscountShare: (receiver_discount_share * 100).toFixed(2),
                                  clientPayment: 100 - (receiver_discount_share * 100).toFixed(2),
                                  editable: true,
                                  hidden: false,
                                },
                              });
                            }
                          });
                        }}
                      >
                        <i className="far fa-edit mr-8px" aria-hidden="true"/>
                        {t('edit')}
                      </button>
                      <button
                        className="button is-danger is-inverted"
                        to="/payment-conditions"
                        onClick={() => {
                          onToggle({
                            deletePaymentCondition: true, 
                            id: original.condition.id,
                            userId: sender_id,
                          })
                          // deletePaymentCondition({
                          //   data: {
                          //     id: original.condition.id,
                          //   },
                          //   t,
                          //   callback: (resp) => {
                          //     getProvidersConditions({
                          //       params: {
                          //         id: sender_id,
                          //       },
                          //     });
                          //     if (resp === 'Deleted') {
                          //       showMessage({
                          //         message: t('deletePaymentCondition'),
                          //         config: TOAST_CONFIG.SUCCESS,
                          //       });
                          //     } else {
                          //       showMessage({
                          //         message: t('actionNotAllowed'),
                          //         config: TOAST_CONFIG.ERROR,
                          //       });
                          //     }
                          //   },
                          // });
                        }}
                      >
                        <i className="fas fa-times mr-8px" aria-hidden="true" />
                        {t('delete')}
                      </button>
                    </div>
                  </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = receiver;
        title = `${t('paymentConditionProvider')}: ${this.getTitle()}`;
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  deletePaymentCondition = (id, userId) => {

    const {
      getProvidersConditions,
      getClientsConditions,
      deletePaymentCondition,
      showMessage,
      t,
      auth: { user: { b2b: { type_user } } },
    } = this.props;


    deletePaymentCondition({
      data: {
        id, //original.condition.id,
      },
      t,
      callback: (resp) => {
        type_user === 'issuing_bank'
          ? getProvidersConditions({
              params: {
                id: userId // sender_id
              },
            })
          : getClientsConditions({
              params: {
                id: userId // receiver_id,
              },
            });
        if (resp === 'Deleted') {
          showMessage({
            message: t('deletePaymentCondition'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          showMessage({
            message: t('actionNotAllowed'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleSelectChange = (newVal, label) => {
    const { providers, providerCommerceCode, role: { list } } = this.props;
    const { codes } = this.state;

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      providerCommerceCode({
        params: {
          user: providers.list[newVal.value].id,
          role: role_id,
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }
    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: codes[newVal.value].id,
        codeDescription: codes[newVal.value].description,
      });
    }

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    }else*/
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  handleOnInputChange = (newValue, actionMeta) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  updatePaymentCondition = (onToggle) => {
    const {
      auth: { user: { user_id, b2b: { type_user } } },
      clients: { sender: clientList },
      providers: { receiver: providerList },
      editPaymentCondition,
      t,
      showMessage,
      getClientsConditions,
      getProvidersConditions,
      requestPaymentCondition,
    } = this.props;
    const {
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      percentage,
      startCountingFrom,
      //limitAmount,
      clientEdit,
      clientPayment,
      commerceCode,
      codes,
      idCondition,
      sender_id,
      receiver_id,
    } = this.state;

    let idList;
    if (type_user === 'acquiring_bank') {
      idList = clientList.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.user.idPerson.name
          === clientEdit)[0].index;
    } else {
      idList = providerList.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.user.idPerson.name
          === clientEdit)[0].index;
    }

    const days = baseDays;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const ids = type_user === 'acquiring_bank' ? clientList[idList].condition.id : providerList[idList].condition.id;
    const update = type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : providerList[0].condition.sender_id;
    const conditionData = {
      bank_sender_id: true,
      sender_request_id: null,
      sender_id: sender_id,//type_user === 'acquiring_bank' ? persons[idList].user.id : providerList[0].condition.sender_id,
      receiver_id: receiver_id, //type_user === 'acquiring_bank' ? clientList[0].condition.receiver_id : persons[idList].user.id,
      days,
      due_from_approval: startCountingFrom,
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      type: 'base',
      //limit_amount: limitAmount.toString().replaceAll(',', ''),
      person_commerce_code: codes[commerceCode].id,
    };

    if (days < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
    //editPaymentCondition({
      requestPaymentCondition({
        //id: ids,
        data: conditionData,
        callback: () => {
          if (type_user === 'acquiring_bank') {
            getClientsConditions({
              params: {
                id: update,
              },
            });
          } else {
            getProvidersConditions({
              params: {
                id: update,
              },
            });
          }
          onToggle();
          showMessage({
            message: t('editionRequestSent'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    //}
  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  render() {
    const {
      t,
      history,
      auth: { user: { b2b: { type_user } } },
      clients: { sender: clientList, loading: clientLoading },
      providers: { receiver: providerList, loading: providerLoading },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editable,
      startCountingFrom,
      //limitAmount,
      clientEdit,
      hidden,
      codes,
      commerceCode,
      codeDescription,
    } = this.state;
    const loading = clientLoading || providerLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(type_user, onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled && !toggleProps.deletePaymentCondition
                    && (
                      <Modal onToggle={onToggle}> {loading && <Loading/>}
                        <PaymentConditionForm
                          t={t}
                          onSubmit={() => this.updatePaymentCondition(onToggle)}
                          handleInputChange={this.handleInputChange}
                          handlePercentageChange={this.handlePercentageChange}
                          isClient={type_user !== 'acquiring_bank'}
                          handleSelectChange={this.handleSelectChange}
                          persons={[]}
                          client={client}
                          provider={provider}
                          baseDays={baseDays}
                          discountRate={discountRate}
                          receiverDiscountShare={receiverDiscountShare}
                          clientPayment={clientPayment}
                          startCountingFrom={startCountingFrom}
                          //limitAmount={limitAmount}
                          clientEdit={clientEdit}
                          editable={editable}
                          hidden={hidden}
                          closeModal={() => this.closeModal(onToggle)}
                          codes={codes}
                          commerceCode={commerceCode}
                          codeDescription={codeDescription}
                          usedInModals
                        />
                      </Modal>
                    )
                  }
                  {
                    toggled && toggleProps.deletePaymentCondition
                    && (
                      <Modal onToggle={onToggle} title={t('deletePaymentCondConf')}> 
                        {loading && <Loading/>}
                        <div className='modal-action-btns'>
                          <button 
                            className='button custom-btn is-danger'
                            onClick={onToggle}>
                            {t('cancel')}
                          </button>
                          <button 
                            className='button custom-btn is-primary'
                            onClick={() => {
                              this.deletePaymentCondition(toggleProps.id, toggleProps.userId)
                              onToggle()
                              }}>
                            {t('delete')}
                          </button>
                        </div>
                      </Modal>
                    )
                  }
                  {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns}/>
                          <br/>
                          <div className="return-box">
                            <div className="field is-grouped is-grouped-right">
                              <button
                                type="button"
                                className="button is-primary is-danger"
                                style={{
                                  position: 'absolute',
                                  bottom: '3.2em',
                                  right: '2em',
                                }}
                                onClick={() => {
                                  history.push('/dashboard');
                                }}
                              >
                                {t('goBack')}
                              </button>
                            </div>
                          </div>
                        </Panel>
                      </div>
                    )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

PaymentConditions.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  stats: PropTypes.shape(Object).isRequired,
  getClientsConditions: PropTypes.func.isRequired,
  getProvidersConditions: PropTypes.func.isRequired,
  editPaymentCondition: PropTypes.func.isRequired,
  deletePaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
                          role,
                          auth,
                          clients,
                          providers,
                          stats,
                          paymentConditions,
                        }) => (
  {
    role,
    auth,
    clients,
    providers,
    stats,
    paymentConditions,
  }
  );
export default compose(
  connect(mapStateToProps, {
    ...roleActions,
    ...authActions,
    ...clientsActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...statsActions,
  }),
  withNamespaces(),
)(PaymentConditions);
