import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { Panel, TableSimple } from '../../components';
import DateInput from '../../components/DateInput';
import { authActions } from '../../state/ducks/auth';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatCurrencyCl, formatDate } from '../../util';
import { TOAST_CONFIG } from '../../config/constants';

class MonthlyReport extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState();
        this.selectTable = React.createRef();
        this.csvRef = React.createRef();
    }


    getInitialState = () => ({
        date: '',
        downloadCsv: [],
        search: true,
    });

    componentDidMount() {}

    handleDateChange = (dateName, newDate) => {    
        this.setState({
            [dateName]: newDate,
        });
    };

    resetState = () => {
        this.setState(this.getInitialState());
    };    
    
    getGridSettings = () => {
        const {
            t,
            auth: {
                user: { b2b: { type_user } },
            },
            transactions: { sender, receiver, list },
        } = this.props;
        const { date } = this.state;
        let columns = null;
        let data = null;
        let title = null;
        switch (type_user) {
            case 'issuing_query':
            case 'issuing_bank':
                columns = [
                    {
                        id: 'period', // Required because our accessor is not a string
                        Header: t('period'),
                        accessor: 'date',
                        Cell: cellInfo => (
                            <div>
                                <span>
                                    {formatDate(cellInfo.original.date).substr(3,7)}
                                </span>
                            </div>
                        )
                    },
                    {
                        id: 'clientRUT', // Required because our accessor is not a string
                        Header: t('clientRUT'),
                        accessor: d => `${d.sender.idPerson.document_number}`
                    },
                    {
                        id: 'buyerName', // Required because our accessor is not a string
                        Header: t('buyerName'),
                        accessor: d => `${d.sender.idPerson.name}`,
                    },
                    {
                        id: 'providerRUT', // Required because our accessor is not a string
                        Header: t('providerRUT'),
                        accessor: d => `${d.receiver.idPerson.document_number}`
                    },
                    {
                        id: 'providerName', // Required because our accessor is not a string
                        Header: t('providerName'),
                        accessor: d => `${d.receiver.idPerson.name}`,
                    },
                    {
                        id: 'arrangedDays', // Required because our accessor is not a string
                        Header: t('arrangedDays'),
                        accessor: d => `${d.paymentCondition.days}`,
                    },
                    {
                        id: 'startCountingFrom', // Required because our accessor is not a string
                        Header: t('startCountingFrom'),
                        accessor: d => d.paymentCondition.due_from_approval == 1 ? t('approvalDate') : t('receptionDate'),
                    },
                    {
                        id: 'financingRate', // Required because our accessor is not a string
                        Header: t('financingRate'),
                        accessor: d => `${d.paymentCondition.discount_rate}`,
                    },
                    {
                        id: 'clientPercentageCharge', // Required because our accessor is not a string
                        Header: t('clientPercentageCharge'),
                        accessor: d => `${d.paymentCondition.percentage-0}%`,
                    },
                    {
                        id: 'providerPercentageCharge', // Required because our accessor is not a string
                        Header: t('providerPercentageCharge'),
                        accessor: d => `${100-d.paymentCondition.percentage}%`,
                    },
                    {
                        id: 'invoicesNumber', // Required because our accessor is not a string
                        Header: t('invoiceNumber'),
                        accessor: d => `${d.invoice_number}`,
                    },
                    {
                        id: 'date', // Required because our accessor is not a string
                        Header: t('sendDate'),
                        //accessor: d => formatDate(d.date),
                        accessor: 'date',
                        Cell: cellInfo => (
                        <div>
                            <span>{formatDate(cellInfo.original.date)}</span>
                        </div>
                        ),
                        filterMethod: (filter, row) => {
                        if (!filter.value.match(/[\d/]+$/gm)) {
                            return true;
                        }
                        if (formatDate(row.date)
                            .includes(filter.value)) {
                            return formatDate(row.date);
                        }
                        //return formatDate(row.date).indexOf(filter.value) >=0;
                        },
                        Filter: ({ filter, onChange }) =>
                        <input
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
                        />
                    },
                    {
                        id: 'due_date', // Required because our accessor is not a string
                        Header: t('collectionDate'),
                        //accessor: d => formatDate(d.due_date),
                        accessor: 'due_date',
                        Cell: cellInfo => (
                        <div>
                            <span>{formatDate(cellInfo.original.due_date)}</span>
                        </div>
                        ),
                        filterMethod: (filter, row) => {
                        if (!filter.value.match(/[\d/]+$/gm)) {
                            return true;
                        }
                        if (formatDate(row.due_date)
                            .includes(filter.value)) {
                            return formatDate(row.due_date);
                        }
                        //return formatDate(row.date).indexOf(filter.value) >=0;
                        },
                        Filter: ({ filter, onChange }) =>
                        <input
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
                        />
                    },
                    {
                        id: 'totalPay', // Required because our accessor is not a string
                        Header: t('totalPay'),
                        Cell: cellInfo => (
                        <div
                            style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
                        )
                    },
                    {
                        id: 'currency',
                        Header: t('currency'),
                        accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
                    },
                    {
                        id: 'description', // Required because our accessor is not a string
                        Header: t('description'),
                        Cell: cellInfo => (
                        <div>
                            <span data-type="info"
                                data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                            <ReactTooltip/>
                        </div>
                        ),
                    },
                    {
                        id: 'transferState', // Required because our accessor is not a string
                        Header: t('transferState'),
                        accessor: d => `${d.senderStatus.description}`,
                    },
                ];
                data = receiver;
                title = t('customers');
                break;
            case 'acquiring_query':
            case 'acquiring_bank':
                columns = [
                    {
                        id: 'period', // Required because our accessor is not a string
                        Header: t('period'),
                        accessor: 'date',
                        Cell: cellInfo => (
                            <div>
                                <span>
                                    {formatDate(cellInfo.original.date).substr(3,7)}
                                </span>
                            </div>
                        )
                    },
                    {
                        id: 'clientRUT', // Required because our accessor is not a string
                        Header: t('clientRUT'),
                        accessor: d => `${d.sender.idPerson.document_number}`
                    },
                    {
                        id: 'buyerName', // Required because our accessor is not a string
                        Header: t('buyerName'),
                        accessor: d => `${d.sender.idPerson.name}`,
                    },
                    {
                        id: 'providerRUT', // Required because our accessor is not a string
                        Header: t('providerRUT'),
                        accessor: d => `${d.receiver.idPerson.document_number}`
                    },
                    {
                        id: 'providerName', // Required because our accessor is not a string
                        Header: t('providerName'),
                        accessor: d => `${d.receiver.idPerson.name}`,
                    },
                    {
                        id: 'arrangedDays', // Required because our accessor is not a string
                        Header: t('arrangedDays'),
                        accessor: d => `${d.paymentCondition.days}`,
                    },
                    {
                        id: 'startCountingFrom', // Required because our accessor is not a string
                        Header: t('startCountingFrom'),
                        accessor: d => d.paymentCondition.due_from_approval == 1 ? t('approvalDate') : t('receptionDate'),
                    },
                    {
                        id: 'financingRate', // Required because our accessor is not a string
                        Header: t('financingRate'),
                        accessor: d => `${d.paymentCondition.discount_rate}`,
                    },
                    {
                        id: 'clientPercentageCharge', // Required because our accessor is not a string
                        Header: t('clientPercentageCharge'),
                        accessor: d => `${d.paymentCondition.percentage-0}%`,
                    },
                    {
                        id: 'providerPercentageCharge', // Required because our accessor is not a string
                        Header: t('providerPercentageCharge'),
                        accessor: d => `${100-d.paymentCondition.percentage}%`,
                    },
                    {
                        id: 'invoicesNumber', // Required because our accessor is not a string
                        Header: t('invoiceNumber'),
                        accessor: d => `${d.invoice_number}`,
                    },
                    {
                        id: 'date', // Required because our accessor is not a string
                        Header: t('sendDate'),
                        //accessor: d => formatDate(d.date),
                        accessor: 'date',
                        Cell: cellInfo => (
                        <div>
                            <span>{formatDate(cellInfo.original.date)}</span>
                        </div>
                        ),
                        filterMethod: (filter, row) => {
                        if (!filter.value.match(/[\d/]+$/gm)) {
                            return true;
                        }
                        if (formatDate(row.date)
                            .includes(filter.value)) {
                            return formatDate(row.date);
                        }
                        //return formatDate(row.date).indexOf(filter.value) >=0;
                        },
                        Filter: ({ filter, onChange }) =>
                        <input
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
                        />
                    },
                    {
                        id: 'due_date', // Required because our accessor is not a string
                        Header: t('collectionDate'),
                        //accessor: d => formatDate(d.due_date),
                        accessor: 'due_date',
                        Cell: cellInfo => (
                        <div>
                            <span>{formatDate(cellInfo.original.due_date)}</span>
                        </div>
                        ),
                        filterMethod: (filter, row) => {
                        if (!filter.value.match(/[\d/]+$/gm)) {
                            return true;
                        }
                        if (formatDate(row.due_date)
                            .includes(filter.value)) {
                            return formatDate(row.due_date);
                        }
                        //return formatDate(row.date).indexOf(filter.value) >=0;
                        },
                        Filter: ({ filter, onChange }) =>
                        <input
                            onChange={event => onChange(event.target.value)}
                            style={{ width: '100%' }}
                            value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
                        />
                    },
                    {
                        id: 'totalPay', // Required because our accessor is not a string
                        Header: t('totalPay'),
                        Cell: cellInfo => (
                        <div
                            style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
                        )
                    },
                    {
                        id: 'currency',
                        Header: t('currency'),
                        accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
                    },
                    {
                        id: 'description', // Required because our accessor is not a string
                        Header: t('description'),
                        Cell: cellInfo => (
                        <div>
                            <span data-type="info"
                                data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                            <ReactTooltip/>
                        </div>
                        ),
                    },
                    {
                        id: 'transferState', // Required because our accessor is not a string
                        Header: t('transferState'),
                        accessor: d => `${d.senderStatus.description}`,
                    },
                ];
                data = sender;
                title = t('providers');
                break;
            default:
            columns = [];
            data = [];
            title = '';
        }
        return {
            columns,
            data,
            title,
        };
    };

    searchReport = () => {

        const {
            listTransactionsSender,
            listTransactionsReceiver,
            auth: { user: { b2b: { id, type_user } } },
        } = this.props;
        const { date } = this.state;
        
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        
        if (type_user === 'issuing_bank' || type_user === 'issuing_query') {
            listTransactionsReceiver({
                params: {
                bank_id: id,
                min_date: date/1000,
                max_date: lastDay/1000,
                is_report: true,
                },
            });
        }
        
        if (type_user === 'acquiring_bank' || type_user === 'acquiring_query') {
            listTransactionsSender({
                params: {
                    bank_id: id,
                    min_date: date/1000,
                    max_date: lastDay/1000,
                    is_report: true,
                },
            });
        }
        this.setState({search: false});
    };

    download = () => {
        const { sortedData, columns } = this.selectTable.current.getResolvedState();
        console.log(sortedData);
        console.log(columns);
    
        for (let i = 0; i < sortedData.length; i++) {
            sortedData[i].clientRUT = sortedData[i].clientRUT+"\t";
            sortedData[i].startCountingFrom = sortedData[i].startCountingFrom.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            sortedData[i].totalPay = sortedData[i]._original.adjusted_amount;
            sortedData[i].description = sortedData[i]._original.description;
            sortedData[i].due_date = formatDate(sortedData[i].due_date);
            sortedData[i].date = formatDate(sortedData[i].date);
            sortedData[i].currency = sortedData[i].currency === null ? '' :  sortedData[i].currency;
        }

        for(let i = 0; i < columns.length; i++){
            columns[i].Header = columns[i].Header.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if(columns[i].id == "invoicesNumber"){
                columns[i].Header = "N. Factura";
            }
        }

        const downloadCsv = [['sep=,']];
        downloadCsv.push(columns.map(column => column.Header));
        downloadCsv.push(...sortedData.map((sorted) => {
            const downloadCsvObject = {};
            columns.forEach((column) => { downloadCsvObject[column.id] = sorted[column.id]; });
            return Object.values(downloadCsvObject);
        }));
        this.setState({ downloadCsv }, () => {
            this.csvRef.current.link.click();
        });
    }

    render() {

        const { t, showMessage } = this.props;
        const { date, downloadCsv, search } = this.state;
        const { columns, data } = this.getGridSettings();
        return(
            
            <div className="column is-full">
                <Panel headingText={t('monthlyReport')}>
                    <div className="field is-horizontal">
                        <div className="field-body columns" style={{display: 'flex', alignItems: 'center'}}>
                            <div className="column" style={{display: 'flex', alignItems: 'center'}}>
                                <DateInput
                                    name="invoice_date"
                                    label={t('month')}
                                    value={date}
                                    onChange={(value) => this.handleDateChange('date', value)}
                                    type="text"
                                    fieldClasses="is-expanded"
                                    controlClasses="has-icons-left"
                                    icon="far fa-calendar-alt"
                                    iconPosition="is-left"
                                    showMonthYearPicker={true}
                                    showFullMonthYearPicker={true}
                                    dateFormat="MM/yyyy"
                                />
                                <button
                                    className="button is-primary"
                                    style={{marginTop: '17px', marginLeft: '1em'}}
                                    onClick={() => {
                                        date === '' ?
                                        showMessage({
                                            message: 
                                                <strong style={{ color: '#ffffff' }}>
                                                    {t('emptyFields')}
                                                </strong>,
                                            config: TOAST_CONFIG.ERROR,
                                        }):
                                        this.searchReport()
                                    }}
                                >
                                    <i className="fas fa-search mr-8px" aria-hidden="true"/>
                                    {`${t('search')}`}
                                </button>
                            </div>
                            <div className="column">
                                &nbsp;&nbsp;
                                <button
                                    className="button is-primary"
                                    style={{marginTop: '17px'}}
                                    onClick={this.download}
                                    disabled={search}
                                >
                                    <i className="fas fa-file-csv mr-8px" aria-hidden="true"/>
                                    {`${t('download')} CSV`}
                                </button>
                                <CSVLink
                                    data={downloadCsv}
                                    filename="monthly_report_b2b.csv"
                                    className="hidden"
                                    ref={this.csvRef}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'none'}}>
                        <TableSimple
                            data={data}
                            columns={columns}
                            reff={this.selectTable}
                        />
                    </div>
                </Panel>
            </div>
        );
    }
}

MonthlyReport.propTypes = {
    t: PropTypes.func.isRequired,
    listTransactionsSender: PropTypes.func.isRequired,
    listTransactionsReceiver: PropTypes.func.isRequired,
    transactions: PropTypes.shape(Object).isRequired,
    auth: PropTypes.shape(Object).isRequired,
    showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    auth, transactions,
}) => ({
    auth,
    transactions,
});
export default compose(
connect(mapStateToProps, {
...authActions,
...transactionsActions,
}),
withNamespaces(),
)(MonthlyReport);