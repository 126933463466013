import React, {useState, useEffect} from 'react';
import './styles.scss';
import { Panel } from '../../../../../components';
import { formatCurrencyCl } from '../../../../../util';
import { creditsActions } from '../../../../../state/ducks/credits';
import { checksActions } from '../../../../../state/ducks/checks';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

const CheckForm = ({
    handleForm,
    onToggle,
    check,
    check: {
        accountNumber,
        currency,
        amount,
        reason,
        checkNumber,
        checkDate,
        mail
    },
    credits: { availableAmounts: { accountCredit, amexCredit, preApprovedCredit } },  
    saveCheck,
    checks: { notApprovedChecks },
    updateAmounts,
    t,
}) => {

    const [blockedAmount, setBlockedAmount] = useState(0);

    // useEffect(() => {
    //     setBlockedAmount(notApprovedChecks.reduce((acc, curr) => acc + curr.amount, 0))
    // }, [])

    const handleChange = e => {
        const { name, valueAsNumber, value } = e.target;

        name === 'amount' ?
        handleForm({[name]: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber}) :
        handleForm({[name]: value})
    }
    return (
        <>
          <div className='CheckForm__main'>
            <div className='Panel__extra-title'>
                <p>{t('accountBalance')}</p>
                <p>${formatCurrencyCl(accountCredit)}</p>
            </div>
            {/* <div className='Panel__extra-title'>
                <p>Saldo bloqueado</p>
                <p>${formatCurrencyCl(blockedAmount)}</p>
            </div> */}
            <Panel>
                <form>
                    <div className='form-field'>
                        <label>{t('accountNumber')}</label>
                        <input 
                          className='custom-input'
                          type='text'
                          disbled
                          value={accountNumber}/>
                    </div>
                    <div className='form-field'>
                        <label>{t('amount')}</label>
                        <div className='amount-field'>
                            <input 
                              type='radio' 
                              name='currency'
                              value='euro'
                              checked={currency === 'euro'}
                              onClick={e => handleChange(e)}/>
                            <label>€</label>
                            <input 
                              type='radio'
                              name='currency'
                              value='dolar'
                              checked={currency === 'dolar'}
                              onClick={e => handleChange(e)}/>
                            <label>$</label>
                            <input 
                              className='custom-input'
                              type='number'
                              name='amount'
                              value={amount}
                              onChange={e => handleChange(e)}/>
                        </div>
                    </div>
                    <div className='form-field'>
                        <label>{t('frontImage')}</label>
                        <input 
                            className='custom-input'
                            style={{ fontSize: '1em', width: 'fit-content' }}
                            type='file' />
                    </div>
                    <div className='form-field'>
                        <label>{t('backImage')}</label>
                        <input 
                            className='custom-input'
                            style={{ fontSize: '1em', width: 'fit-content' }}
                            type='file' />
                    </div>
                    <div className='form-field'>
                        <label>{t('depositReason')}</label>
                        <input 
                          className='custom-input'
                          type='text'
                          name='reason'
                          onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-field' style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div className='form-field'>
                            <label>{t('checkNumber')}</label>
                            <input 
                              className='custom-input'
                              type='text'
                              name='checkNumber'
                              onChange={e => handleChange(e)}/>
                        </div>
                        <div className='form-field'>
                            <label>{t('checkDate')}</label>
                            <input 
                              className='custom-input'
                              type='text'
                              name='checkDate'
                              onChange={e => handleChange(e)}/>
                        </div>
                    </div>
                    <div className='form-field'>
                        <label>{t('email')}</label>
                        <input 
                          className='custom-input'
                          type='mail'
                          name='mail'
                          onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-BTNS'>
                        <button className='button custom-btn is-danger'>
                            {t('return')}
                        </button>
                        <button 
                          className='button custom-btn is-primary'
                          onClick={() => {
                              saveCheck({
                                ...check, state: 'approvedChecks',
                              })
                              updateAmounts({accountCredit: accountCredit + amount})
                              onToggle()
                          }}>
                            {t('continue')}
                        </button>
                    </div>

                </form>
            </Panel>
          </div>
        </>
    );
};

const mapStateToProps = ({ credits, checks }) => ({ credits, checks })

// export default CheckForm;
export default compose(
    connect(mapStateToProps, {
      ...creditsActions,
      ...checksActions,
    }),
    withNamespaces(),
)(CheckForm);