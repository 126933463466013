import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { CSVLink } from 'react-csv';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { formatCurrencyCl, formatDate, getPath } from '../../util';
import './styles.scss';
import DateInput from '../../components/DateInput';
import { TOAST_CONFIG } from '../../config/constants';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.selectTable = React.createRef();
    this.csvRef = React.createRef();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    downloadCsv: [],
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
  });

  componentDidMount() {
    this.rangeDate();
    /*const {
      listTransactionsSender,
      listTransactionsReceiver,
      auth: { user: { b2b: { id, type_user } } },
    } = this.props;
    if (type_user === 'issuing_bank') {
      listTransactionsReceiver({});
    }
    if (type_user === 'buyer') {
      listTransactionsSender({ params: { user_id: id } });
    }
    if (type_user === 'acquiring_bank') {
      listTransactionsSender({});
    }
    if (type_user === 'seller') {
      listTransactionsReceiver({ params: { user_id: id } });
    }*/
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = () => {
    const {
      t,
      auth: {
        user: {
          b2b: {
            type_user,
          },
        },
      },
      transactions: { sender, receiver, list },
    } = this.props;
    let columns = null;
    let data = null;
    let title = null;
    switch (type_user) {
      case 'admin_amex':
      case 'admin':
        columns = [
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendInvoice'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('chargeDate'),
            //accessor: d => d.receiver_status_id === 'PAP' ? '' : formatDate(d.due_date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.receiver_status_id === 'PAP' ? '' : formatDate(cellInfo.original.due_date)
                }</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            //accessor: d => d.approved_date === null ? '' : formatDate(d.approved_date),
            accessor: 'approved_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.approved_date === null ? '' : formatDate(cellInfo.original.approved_date)
                }</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.approved_date)
                .includes(filter.value)) {
                return formatDate(row.approved_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => d.pay_currency_id !== null ? `${d.pay_currency_id}` : '',
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'document_number', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState1'),
            Cell: cellInfo => (
              <div>
                {t(cellInfo.original.receiverStatus.id)}
              </div>
            ),
          },
        ];
        data = sender;
        title = t('administer');
        break;
      case 'buyer':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            // accessor: d => d.parent_id == null ? t('BAC Credomatic') : `${d.receiver.idPerson.name}`,
            accessor: d => d.sender_id == null ? t('BAC Credomatic') : `${d.receiver.idPerson.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            // accessor: d => d.parent_id == null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
            accessor: d => d.sender_id == null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('shippingDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            //accessor: d => formatDate(d.due_date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = sender;
        // data = receiver;
        title = t('providers');
        break;
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('sendDate'),
            headerClassName: 'wordwrap',
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'approved_date', // Required because our accessor is not a string
            Header: t('approvalDate'),
            headerClassName: 'wordwrap',
            accessor: 'approved_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.approved_date === null ? '' : formatDate(cellInfo.original.approved_date)
                }</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.approved_date)
                .includes(filter.value)) {
                return formatDate(row.approved_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('datePayment'),
            headerClassName: 'wordwrap',
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.sender_status_id !== 'PAG' ?
                  formatDate(cellInfo.original.due_date) : cellInfo.original.sender_status_id === 'PAG' ?
                    formatDate(cellInfo.original.paid_date) : ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (row._original.sender_status_id !== 'PAG') {
                if (formatDate(row.due_date)
                  .includes(filter.value)) {
                  return formatDate(row.due_date);
                }
              } else {
                if (formatDate(row._original.paid_date)
                  .includes(filter.value)) {
                  return formatDate(row._original.paid_date);
                }
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'card_collection_date', // Required because our accessor is not a string
            Header: t('onlyCardPaymentDate'),
            headerClassName: 'wordwrap',
            accessor: 'card_collection_date',
            Cell: cellInfo => (
              <div>
                <span> {
                  cellInfo.original.card_collection_date === null ? '' : formatDate(cellInfo.original.card_collection_date)
                }</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.card_collection_date)
                .includes(filter.value)) {
                return formatDate(row.card_collection_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            headerClassName: 'wordwrap',
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            // accessor: d => d.parent_id === null ? t('bankProvider') : `${d.receiver.idPerson.name}`,
            accessor: d => d.receiver_id === null ? '' : `${d.receiver.idPerson.name}`,
          },
          {
            id: 'cif', // Required because our accessor is not a string
            Header: t('cif'),
            accessor: d => `${d.receiver.cif}`,
          },
          {
            id: 'typeAndDocumentNumber', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            // accessor: d => d.parent_id === null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
            accessor: d => d.receiver_id === null ? '' : `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = receiver;
        title = t('customers');
        break;
      case 'seller':
      case 'seller_assist_card':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: type_user === 'seller' ? t('invoiceNumber') : t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: type_user === 'seller' ? t('sendDate') : t('shippingDate'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date)
                  .indexOf(filter.value) >= 0;
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'pay_provider_date', // Required because our accessor is not a string
            Header: type_user === 'seller' ? t('collectionDate') : t('paymentDate'),
            //accessor: d => d.pay_provider_date !== null ? formatDate(d.pay_provider_date) : '',
            accessor: 'pay_provider_date',
            Cell: cellInfo => (
              <div>
                <span>{cellInfo.original.pay_provider_date !== null ? formatDate(cellInfo.original.pay_provider_date) : ''}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.pay_provider_date)
                .includes(filter.value)) {
                return formatDate(row.pay_provider_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            //accessor: d => `${d.senderStatus.description}`,
            Cell: cellInfo => (
              <div>
                {t(cellInfo.original.senderStatus.id)}
              </div>
            ),
          },
        ];
        data = receiver;
        title = t('customers');
        break;
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('provider'),
            accessor: d => `${d.receiver.idPerson.name}`,
          },
          {
            id: 'document_type', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['receiver', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['receiver', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'date', // Required because our accessor is not a string
            Header: t('dateInvoice'),
            //accessor: d => formatDate(d.date),
            accessor: 'date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.date)
                .includes(filter.value)) {
                return formatDate(row.date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('collectionDate'),
            //accessor: d => formatDate(d.due_date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'typeAndDocumentNumber', // Required because our accessor is not a string
            Header: t('typeAndDocumentNumber'),
            headerClassName: 'wordwrap',
            accessor: d => `${getPath(['sender', 'idPerson', 'idDocumentType', 'document_type'], d)} ${getPath(['sender', 'idPerson', 'document_number'], d)}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = sender;
        title = t('providers');
        break;
      case 'rate_operator':
        columns = [
          {
            id: 'invoice_number', // Required because our accessor is not a string
            Header: t('mainInvoiceNumber'),
            accessor: d => d.invoice_number_main === null ? '' : `${d.invoice_number_main}`,
          },
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('datePayment'),
            //accessor: d => formatDate(d.date),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            Cell: cellInfo => (
              <div
                style={{ textAlign: 'right' }}>{formatCurrencyCl(cellInfo.original.adjusted_amount)}</div>
            )
          },
          {
            id: 'currency', // Required because our accessor is not a string
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'name',
            Header: t('customer'),
            accessor: d => `${d.sender.idPerson.name}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            Cell: cellInfo => (
              <div>
                <span data-type="info"
                      data-tip={cellInfo.original.description}>{cellInfo.original.description}</span>
                <ReactTooltip/>
              </div>
            ),
          },
          {
            id: 'transferState', // Required because our accessor is not a string
            Header: t('transferState'),
            accessor: d => `${d.senderStatus.description}`,
          },
        ];
        data = list;
        title = t('transactions');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  download = () => {
    const {
      auth: {
        user: {
          b2b: {
            type_user
          },
        },
      },
    } = this.props;
    const { sortedData, columns } = this.selectTable.current.getResolvedState();

    for (let i = 0; i < sortedData.length; i++) {
      sortedData[i].description = sortedData[i]._original.description;
      sortedData[i].totalPay = sortedData[i]._original.adjusted_amount;
      if (type_user === 'rate_operator') {
        sortedData[i].due_date = formatDate(sortedData[i].due_date);
        sortedData[i].totalPay = sortedData[i]._original.base_amount;
      }
      if (type_user === 'buyer' || type_user === 'issuing_query' || type_user === 'acquiring_query'
        || type_user === 'acquiring_bank') {
        sortedData[i].due_date = formatDate(sortedData[i].due_date);
        sortedData[i].date = formatDate(sortedData[i].date);
      }
      if (type_user === 'issuing_query' || type_user === 'issuing_bank') {
        sortedData[i].due_date = sortedData[i]._original.receiver_status_id !== 'PAG' ?
          formatDate(sortedData[i].due_date) : formatDate(sortedData[i]._original.paid_date);
        sortedData[i].date = formatDate(sortedData[i].date);
      }
      if (type_user === 'seller') {
        sortedData[i].date = formatDate(sortedData[i].date);
        sortedData[i].pay_provider_date = sortedData[i]._original.pay_provider_date !== null ? formatDate(sortedData[i].pay_provider_date) : '';
      }
      if (type_user === 'admin' || type_user === 'admin_amex') {
        sortedData[i].date = formatDate(sortedData[i].date);
        sortedData[i].approved_date = sortedData[i]._original.approved_date === null ? '' : formatDate(sortedData[i].approved_date);
        sortedData[i].due_date = sortedData[i]._original.receiver_status_id === 'PAP' ? '' : formatDate(sortedData[i].due_date);
      }
    }

    const BOM = "\uFEFF";
    const downloadCsv = [[BOM]];
    downloadCsv.push(columns.map(column => column.Header));
    downloadCsv.push(...sortedData.map((sorted) => {
      const downloadCsvObject = {};
      columns.forEach((column) => { downloadCsvObject[column.id] = sorted[column.id]; });
      return Object.values(downloadCsvObject);
    }));

    this.setState({ downloadCsv }, () => {
      this.csvRef.current.link.click();
    });
  };

  handleDateChange = (dateName, newDate) => {
    if (dateName === 'invoiceDate' && newDate !== '') {
      newDate.setHours(0, 0, 0, 0);
    }
    if (dateName === 'invoiceDateUntil' && newDate !== '') {
      newDate.setHours(23, 59, 59, 999);
    }

    this.setState({
      [dateName]: newDate,
    });
  };

  rangeDate = () => {
    const {
      listTransactionsSender,
      listTransactionsReceiver,
      listTransactionAdmin,
      rateTransaction,
      auth: { user: { b2b: { id, type_user } } },
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    if (type_user === 'admin' || type_user === 'admin_amex') {
      listTransactionAdmin({
        params: {
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
          is_admin: true,
        }
      });
    }
    if (type_user === 'issuing_bank' || type_user === 'issuing_query') {
      listTransactionsReceiver({
        params: {
          bank_id: id,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
          is_report: true,
        },
      });
    }
    if (type_user === 'buyer') {
      listTransactionsSender({
        params: {
          user_id: id,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        }
      });
    }
    if (type_user === 'acquiring_bank' || type_user === 'acquiring_query') {
      listTransactionsSender({
        params: {
          bank_id: id,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
          is_report: true,
        },
      });
    }
    if (type_user === 'seller' || type_user === 'seller_assist_card' ) {
      listTransactionsReceiver({
        params: {
          user_id: id,
          min_date: invoiceDate / 1000,
          max_date: invoiceDateUntil / 1000,
        }
      });
    }
    if (type_user === 'rate_operator') {
      rateTransaction({
        params: {
          date_init: invoiceDate,
          date_end: invoiceDateUntil / 1000,
          is_report: true,
        }
      });
    }
  };

  render() {
    const {
      transactions: { loading },
      t,
      showMessage,
      auth: { user: { b2b: { buyer, seller, admin, admin_amex } } }
    } = this.props;
    const { downloadCsv, invoiceDate, invoiceDateUntil } = this.state;
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading />}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle} />
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={t('transactionReport')}>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="columns is-centered" style={{ width: '100%' }}>
                              <div className="column">
                                <p /*className="is-size-2"*/ style={{ fontSize: '1.25rem' }}>
                                  {buyer === 1 && t('paymentDateRangeClient')}
                                  {seller === 1 && t('paymentDateRange')}
                                  {admin === 1 && t('sendInvoice')}
                                  {admin_amex === 1 && t('sendInvoice')}
                                </p>
                              </div>
                              <div className="column" style={{ zIndex: 99999 }}>
                                <DateInput
                                  name="invoice_date"
                                  label={t('since')}
                                  value={invoiceDate}
                                  onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="far fa-calendar-alt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div className="column" style={{ zIndex: 99999 }}>
                                <DateInput
                                  name="invoice_date"
                                  label={t('until')}
                                  value={invoiceDateUntil}
                                  onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="far fa-calendar-alt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div style={{ marginTop: '36px' }}>
                                <button
                                  type="button"
                                  className="button is-primary"
                                  onClick={() => {
                                    invoiceDate === '' && invoiceDateUntil === '' ?
                                      showMessage({
                                        message: <strong
                                          style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                        config: TOAST_CONFIG.ERROR,
                                      })
                                      : invoiceDateUntil < invoiceDate ?
                                      showMessage({
                                        message: <strong
                                          style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                        config: TOAST_CONFIG.ERROR,
                                      })
                                      :
                                      this.rangeDate();
                                  }}
                                >
                                  {t('search')}
                                </button>
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        <div>
                          <button
                            className="button is-block is-primary"
                            style={{ margin: "10px 0" }}
                            onClick={this.download}
                          >
                            {`${t('download')} CSV`}
                          </button>
                          <CSVLink
                            data={downloadCsv}
                            filename="summary_b2b.csv"
                            className="hidden"
                            ref={this.csvRef}
                            separator=";"
                          />
                        </div>
                        <TableSimple
                          data={data}
                          columns={columns}
                          reff={this.selectTable}
                        />
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Reports.propTypes = {
  t: PropTypes.func.isRequired,
  listTransactionAdmin: PropTypes.func.isRequired,
  listTransactionsSender: PropTypes.func.isRequired,
  listTransactionsReceiver: PropTypes.func.isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions }) => (
  {
    auth,
    transactions,
  }
);
export default compose(
  connect(mapStateToProps, { ...transactionsActions }),
  withNamespaces(),
)(Reports);