// Helper function for insert a node after other
export const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

export const getPath = (p, o) => (p.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : null), o));

export const formatDate = date => new Intl.DateTimeFormat('es', {
  timeZone: 'America/Santiago',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}).format(date * 1000);

export const formatDateHour = date => new Intl.DateTimeFormat('es', {
  timeZone: 'America/Santiago',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
}).format(date * 1000);

export const formatFriendlyDate = date => new Intl.DateTimeFormat('es', {
  month: 'short',
  day: '2-digit',
  weekday: 'short',
}).format(date);

export const formatFriendlylongDate = (date, lng) => new Intl.DateTimeFormat(lng, {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date);

export const formatFriendlyDateLong = date => new Intl.DateTimeFormat('es', {
  month: 'long',
  day: '2-digit',
  weekday: 'long',
}).format(date * 1000);

export const formatCurrency = amount => Number(amount)
  .toLocaleString('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

export const formatCurrencyCl = amount => Number(amount)
  .toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatIntCurrency = amount => Number(amount)
  .toLocaleString('en-CA', {
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

export const csvToArray = (str, delimiter = ",") => {

    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    // Map the rows
    // split values from each row into an array
    // use headers.reduce to create an object
    // object properties derived from headers:values
    // the object passed as an element of the array
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  }

  export const formatAmount = (amount, currency = false) => {
    if (currency) {
      return amount > 0 ? `${currency} ${Intl.NumberFormat('en-US').format(amount)}` : '$0.00'
    } else {
      return amount > 0 ? Intl.NumberFormat('en-US').format(amount) : '0.00'
    }
  }
  