import axios from 'axios';
import { authActions, authTypes } from '../ducks/auth';
import { transactionsTypes } from '../ducks/transactions';
import { cardTypes } from '../ducks/card';
import { clientsTypes } from '../ducks/clients';
import { providersTypes } from '../ducks/providers';
import { documentTypeTypes } from '../ducks/documentType';
import { paymentConditionsTypes } from '../ducks/paymentConditions';
import { itemTypes } from '../ducks/item';
import { statsTypes } from '../ducks/stats';
import { roleTypes } from '../ducks/role';
import { financingProductsTypes } from '../ducks/financingProducts';
import { bankTypes } from '../ducks/bank';
import { routeTypes } from '../ducks/route';
import { leadTypes } from '../ducks/lead';
import { userProfileTypes } from '../ducks/userProfile';
import { flowApprovalTypes } from '../ducks/flowApproval';
import { logTypes } from '../ducks/log';
import { webpayTypes } from '../ducks/webpay';
import { gatewayTypes } from '../ducks/gateway';
import { credentialsTypes } from '../ducks/credentials';
import { currencyTypes } from '../ducks/currency';
import { bankingExecutivesTypes } from '../ducks/bankingExecutives';
import { productTypes } from '../ducks/product';
import { supportTypes } from '../ducks/support';
import { creditsTypes } from '../ducks/credits';

const api = ({ dispatch, getState }) => next => (action) => {
  const types = [authTypes.API_CALL, transactionsTypes.API_CALL,
    cardTypes.API_CALL, clientsTypes.API_CALL, providersTypes.API_CALL, documentTypeTypes.API_CALL,
    paymentConditionsTypes.API_CALL, itemTypes.API_CALL, statsTypes.API_CALL, roleTypes.API_CALL,
    bankTypes.API_CALL, routeTypes.API_CALL, financingProductsTypes.API_CALL, leadTypes.API_CALL,
    userProfileTypes.API_CALL, flowApprovalTypes.API_CALL, logTypes.API_CALL, webpayTypes.API_CALL,
    gatewayTypes.API_CALL, credentialsTypes.API_CALL, currencyTypes.API_CALL, productTypes.API_CALL,
    bankingExecutivesTypes.API_CALL, supportTypes.API_CALL, creditsTypes.API_CALL,
  ];

  if (!types.includes(action.type)) {
    return next(action);
  }

  const {
    config: preConfig,
    authorization,
    onStart = () => {
      console.error('onStart not defined');
    },
    onEnd = () => {
      console.error('onEnd not defined');
    },
    onComplete = (response) => {
      console.log('onComplete', response);
    },
    onError = (error) => {
      console.log('onError', error);
    },
  } = action.payload;
  const { auth: { user } } = getState();
  const config = authorization ? {
    ...preConfig,
    headers: {
      ...preConfig.headers,
      Authorization: `Bearer ${user.token}`,
    },
  } : preConfig;
  dispatch(onStart());
  axios(config)
    .then((response) => {
      // console.log(response);
      const { status } = response;
      if (status === 401) {
        dispatch(authActions.logout());
      }
      onComplete(response);
      dispatch(onEnd(response));
    })
    .catch((error) => {
      console.error(error);
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 401) {
          dispatch(authActions.logout());
        }
      }
      onError(error);
      dispatch(onEnd(error));
    });
};

export default [api];
