import React, { Component } from 'react';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { TOAST_CONFIG } from '../../config/constants';
// REDUX
import { compose } from 'redux';
import { connect } from 'react-redux';
// ENDPOINTS ACTIONS
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { itemActions } from '../../state/ducks/item';
import { clientsActions } from '../../state/ducks/clients';
import { leadActions } from '../../state/ducks/lead';
import { roleActions } from '../../state/ducks/role';
import { flowApprovalActions } from '../../state/ducks/flowApproval';
import { currencyActions } from '../../state/ducks/currency';
import { cardActions } from '../../state/ducks/card';
// APP COMPONENTS
import { 
  PersonForm,
  Loading, 
  SelectSingle, 
  TextInput,
} from '../../components';
// UTIL
import { getPath } from '../../util';

class CreateProvider extends Component {
  constructor(props) {
    super(props);
    const { 
      location: { state } 
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    name: '',
    documentType: -1,
    documentNumber: '',
    address: '',
    email: '',
    password: '',
    confirmPassword: '',
    percentage: 0,
    period: -1,
    installments: 1,
    items: [],
    client: '',
    startCountingFrom: 1,
    baseProvider: 0,
    baseDays: 0,
    discountRate: 0,
    adminName: '',
    adminLastname: '',
    adminDocumentNumber: '',
    adminDocumentType: [],
    adminPosition: '',
    receiverDiscountShare: 100,
    clientPayment: 0,
    noRetain: false,
    edit: false,
    hidden: false,
    typeFlow: -1,
    code: '',
    regions: [],
    region: -1,
    communes: [],
    commune: -1,
    inputValue: '',
    city: '',
    paymentCurrency: -1,
    currency: -1,
    inputValueExecutive: '',
    executives: [],
    assistCard: '',
    business_name: '',
    unformattedRut: '',
    unformattedAdminRut: '',
    newBankingExec: [],
    countBankingExec: 0,
    typePerson: '',
    typeDocument: [],
    documentTypeUser: '',
    type_legal_person: '',
    provinces: [],
    province: -1,
    cantons: [],
    canton: -1,
    districts: [],
    district: -1,
    street: '',
    number: '',
    otherSign: '',
    countTradeInformation: 0,
    commerce_code: [{
      id: 0,
      commerce_code: '',
      terminal: '',
      currency: -1,
      currency_id: '',
      description: '',
    }],
    cif: '',
    categories: [],
    category: '',
    username_csp: '',
    password_csp: '',
    send_notification: false,
    codes: [],
    baseDays: 0,
    client: null,
    provider: null,
    startCountingFrom: null,
    percentage: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    commerceCode: '',
    roleName: '',
  });

  componentDidMount() {
    const {
      t,
      fetchDocumentTypes,
      fetchItems,
      fetchRegions,
      fetchRoles,
      fetchCategories,
      getWorkFlowTypes,
      getCurrency,
      getExecutive,
      assistCard,
      router: { location: { pathname } },
      documentType: { list: documentTypeList },
      auth: { user: { b2b: { roles } } },
      fetchCities,
    } = this.props;

    fetchCities({
      canton_id: 2,
      callback: (response) => {
        this.setState({
          districts: response,
        //  district: response.findIndex(x => x.id == providerList.seller[0].district.id)
        });
      }
    });

    assistCard({});
    fetchItems({
      category_id: '',
    });
    fetchDocumentTypes({
      params: { legal_type: 'F' },
      callback: (data) => {
        this.setState({ adminDocumentType: data });
      }
    });
    getExecutive({
      params: { owner: roles[0].owner_id },
      callback: (response) => {
        const exc = [];
        let j = 0;
        for (let i = 0; i < response.length + 1; i++) {
          if (i === 0) {
            exc.push({
              bank_id: response.length === 0 ? roles[0].owner_id : response[i].bank_id,
              lastname: '',
              id: -1,
              email: t('businessExecutive'),
              name: t('addNew'),
            });
          } else {
            exc.push({
              bank_id: response[j].bank_id,
              email: response[j].email,
              id: response[j].id,
              lastname: response[j].lastname,
              name: response[j].name,
            });
            j += 1;
          }
        }
        this.setState({ exc: exc });
      }
    });
    getCurrency({});
    //fetchItems();
    /*fetchCategories({
      callback: (response) => {
        this.setState({ categories: response });
      }
    });*/
    fetchRegions({
      callback: (response) => {
        this.setState({ provinces: response });
      }
    });
    //getClients({});
    getWorkFlowTypes({});
    fetchRoles({
      params: {
        name: 'seller',
        type: 'external',
      },
      callback: (response) => {
        this.setState({ roleName: response[0].id })
      }
    });

    if (pathname === '/create-provider') {
      let document = 0;
      /*if (documentTypeList.length > 0) {
        document = (documentTypeList.map(
          (documentTypeMap, index) => ({
            ...documentTypeMap,
            index
          })
        )
          .filter(documentTypeFil => documentTypeFil.document_type
            === 'RUC')[0].index);
        this.setState({
          documentType: document,
          hidden: true
        });
      }*/
    }
    if (pathname === '/edit-provider') this.setState(this.arrayProvider);
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getTitle = () => {
    const {
      t,
      router: { location: { pathname } },
    } = this.props;

    switch (pathname) {
      case '/create-provider':
        return t('createProvider');
      case '/edit-provider':
        return t('edit');
      default:
        return t('');
    }
  };

  arrayProvider = () => {
    const {
      providers: { list: providerList },
      documentType: { list: documentTypeList },
      fetchCommunes, fetchRegions, fetchCities,
      currency: { list: listCurrency },
      fetchDocumentTypes,
      item: { list: listCategories },
    } = this.props;
    const {
      categories,
    } = this.state;
    let documentType = -1;

    if (providerList.seller[0].idPerson.idDocumentType !== null) {
      fetchDocumentTypes({
        params: {
          legal_type: providerList.seller[0].type_legal_person === null ?
            providerList.seller[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'J' : 'F'
            :
            providerList.seller[0].type_legal_person === 'JUR' ? 'J' : 'F'
        },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === providerList.seller[0].idPerson.idDocumentType.document_type)[0].index);
          this.setState({
            documentTypeUser: documentType,
            typeDocument: data,
            typePerson: providerList.seller[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'legal' : 'person',
            type_legal_person: providerList.seller[0].idPerson.idDocumentType.document_type === 'Cédula jurídica' ? 'JUR' : 'FIS',
          });
        }
      });
    }

    if (providerList.seller[0].admin_document_type !== '' && providerList.seller[0].admin_document_type !== null) {
      fetchDocumentTypes({
        params: { legal_type: 'F' },
        callback: (data) => {
          documentType = (data.map(
            (documentTypeMap, index) => ({
              ...documentTypeMap,
              index
            })
          )
            .filter(documentTypeFil => documentTypeFil.document_type
              === providerList.seller[0].admin_document_type)[0].index);
          this.setState({
            documentType: documentType,
          });
        }
      });
    }
    const item = [];
    for (let i = 0; i < providerList.items.length; i++) {
      item.push({
        id: providerList.items[i].id,
        classification: providerList.items[i].classification,
        code: providerList.items[i].code,
        description: providerList.items[i].description
      });
    }
    const executives = [];
    for (let i = 0; i < providerList.banking_executive.length; i++) {
      executives.push({
        id: providerList.banking_executive[i].executive.id,
        name: providerList.banking_executive[i].executive.name,
        lastname: providerList.banking_executive[i].executive.lastname,
        email: providerList.banking_executive[i].executive.email
      });
    }

    const commerce_code = [];
    if (providerList.commerce_code.length === 0) {
      commerce_code.push({
        id: 0,
        commerce_code: '',
        terminal: '',
        currency: -1,
        currency_id: '',
        description: '',
      });
    } else {
      for (let i = 0; i < providerList.commerce_code.length; i++) {
        commerce_code.push({
          id: providerList.commerce_code[i].id,
          commerce_code: providerList.commerce_code[i].commerce_code,
          terminal: providerList.commerce_code[i].terminal,
          currency: providerList.commerce_code[i].currency_id !== null ? (listCurrency.map(
            (listCurrencyTypeMap, index) => ({
              ...listCurrencyTypeMap,
              index
            })
          )
            .filter(listCurrencyTypeFil => listCurrencyTypeFil.id
              === providerList.commerce_code[i].currency_id)[0].index) : '',
          currency_id: providerList.commerce_code[i].currency_id,
          description: providerList.commerce_code[i].description
        });
      }
    }
    const period = providerList.seller[0].paymentConditionAsBuyer[0] !== null ?
      this.getDays(providerList.seller[0].paymentConditionAsBuyer[0].days) : -1;

    let idComuna = 0,
      idRegion = 0;

    if (providerList.seller[0].province !== null) {
      fetchRegions({
        callback: (response) => {
          this.setState({ province: response.findIndex(x => x.id == providerList.seller[0].province.id) });
        }
      });
      fetchCommunes({
        province_id: '',//providerList.seller[0].province.id,
        callback: (response) => {
          this.setState({
            cantons: response,
            canton: response.findIndex(x => x.id == providerList.seller[0].canton.id)
          });
        }
      });
      fetchCities({
        canton_id: providerList.seller[0].canton.id,
        callback: (response) => {
          this.setState({
            districts: response,
            // district: response.findIndex(x => x.id == providerList.seller[0].district.id)
            district: 2
          });
        }
      });
    }
    /*fetchCommunes({
      region_id: providerList.seller[0].region !== null ? providerList.seller[0].region.id : '0',
      callback: (response) => {
        idComuna = response.map(
          (communeMap, index) => ({
            ...communeMap,
            index
          })
        )
          .filter(communeFil => communeFil.name
            === providerList.seller[0].commune.name)[0].index;
        fetchRegions({
          callback: (response) => {
            idRegion = response.map(
              (communeMap, index) => ({
                ...communeMap,
                index
              })
            )
              .filter(communeFil => communeFil.name
                === providerList.seller[0].region.name)[0].index;
          }
        });
        this.setState({
          communes: response,
          commune: idComuna,
          city: providerList.seller[0].city !== null ? providerList.seller[0].city.name : '',
          region: idRegion,
        });
      }
    });*/

    /*let num = providerList.seller[0].idPerson.document_number;

    var	MM = num.substr(0,2); //Primeros 2 digitos
    var CC = num.substr(2,3); //Siguientes 3 digitos
    var DD = num.substr(5,3); //Ultimos 3 digitos antes del guión
    var last = num.substr(8,1); // Digito o K después del guión
    var RUT = MM+CC+DD+"-"+last;

    let next_num = providerList.seller[0].admin_document_number;

    var	aMM = next_num.substr(0,2); //Primeros 2 digitos
    var aCC = next_num.substr(2,3); //Siguientes 3 digitos
    var aDD = next_num.substr(5,3); //Ultimos 3 digitos antes del guión
    var aLast = next_num.substr(8,1); // Digito o K después del guión
    var adminRUT = aMM+aCC+aDD+"-"+aLast;

    this.setState({
      unformattedRut: num,
      unformattedAdminRut: next_num,
    })*/

    return {
      //category: providerList.items[0].category_id !== null ? listCategories.findIndex(x => x.id === parseInt(providerList.items[0].category_id)) : -1,
      code: providerList.seller[0].cod,
      name: providerList.seller[0].idPerson.name,
      documentTypeUser: documentType,
      documentNumber: providerList.seller[0].idPerson.document_number,
      cif: providerList.seller[0].cif !== null ? providerList.seller[0].cif : '',
      address: providerList.seller[0].address_full,
      street: providerList.seller[0].address !== null ? providerList.seller[0].address.street : '',
      number: providerList.seller[0].address !== null ? providerList.seller[0].address.number : '',
      otherSign: providerList.seller[0].address !== null ? providerList.seller[0].address.other_sign : '',
      adminName: providerList.seller[0].admin_name,
      adminLastname: providerList.seller[0].admin_lastname,
      adminDocumentNumber: providerList.seller[0].admin_document_number === null ? '' : providerList.seller[0].admin_document_number,
      //adminDocumentType: providerList.seller[0].admin_document_type === 'CI' ||
      //providerList.seller[0].admin_document_type === 'Cédula' ? 0 : 1,
      adminPosition: providerList.seller[0].admin_position,
      email: providerList.seller[0].idPerson.idEmailActive.email,
      percentage: providerList.seller[0].paymentConditionAsBuyer[0] !== null ?
        providerList.seller[0].paymentConditionAsBuyer[0].percentage : 0,
      typeFlow: providerList.process_type === null ? 0 : providerList.process_type,
      period,
      edit: true,
      items: item,
      hidden: true,
      business_name: providerList.seller[0].business_name === null ? '' : providerList.seller[0].business_name,
      /*currency: (listCurrency.map(
        (listCurrencyTypeMap, index) => ({
          ...listCurrencyTypeMap,
          index
        })
      )
        .filter(listCurrencyTypeFil => listCurrencyTypeFil.id
          === providerList.seller[0].currency[0].currency_id)[0].index),*/
      executives,
      commerce_code,
      username_csp: providerList.seller[0].username_csp === null ? '' : providerList.seller[0].username_csp,
      password_csp: providerList.seller[0].password_csp === null ? '' : providerList.seller[0].password_csp,
      send_notification: providerList.seller[0].send_notification === '1',
    };
  };

  getDays = (period) => {
    switch (period) {
      case 365:
        return 0;
      case 30:
        return 1;
      case 1:
        return 2;
      default:
        return '';
    }
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  formatNumber = (n) => {
    if (n.length < 14) {
      return n.replace(/\D/g, '')
        .replace(/([0-9]{14})$/);
    } else {
      return;
    }
  };

  formatNumber2 = (value, length, type) => {
    if (value.length <= length) {
      if(type === "number") {return value.replace(/\D/g, '')}
      if(type === "text") {return value.replace(new RegExp("[0-9]"), '')}
      if(type === "textNum") {return value.replace(new RegExp("^[a-zA-Z][0-9]"), '')}
    } else {
      return value.slice(0, length)
    }
  }

  handleInputChangeNewExc = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const executive = name.split('-');

    let newBankingExec = [...this.state.newBankingExec];

    switch (executive[0]) {
      case 'name':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: value,
          lastname: newBankingExec[executive[1]].lastname,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'lastname':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: value,
          email: newBankingExec[executive[1]].email,
          bank_id: id,
        };
        break;
      case 'email':
        newBankingExec[executive[1]] = {
          id: Number(executive[1]),
          name: newBankingExec[executive[1]].name,
          lastname: newBankingExec[executive[1]].lastname,
          email: value,
          bank_id: id,
        };
        break;
    }

    this.setState({ newBankingExec });
  };

  handleOnInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handleOnInputChangeExecutive = (newValue) => {
    this.setState({ inputValueExecutive: newValue });
    return newValue;
  };

  handleInputChangeTypeDocument = (event) => {
    const {
      fetchDocumentTypes,
    } = this.props;

    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    fetchDocumentTypes({
      params: { legal_type : value === 'legal' ? 'J' : 'F' },
      callback: (data) => {
        this.setState({
          type_legal_person: value === 'legal' ? 'JUR' : 'FIS',
          typeDocument: data,
          [name]: value,
        });
      }
    });

  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    const { typePerson, documentTypeUser, documentType } = this.state;

    if (name === 'documentNumber') {
      let num;
      if(typePerson === "legal") {
        num = this.formatNumber2(value, 10, "number").replace(new RegExp("^0+(?!$)"), "")
      } else {
        switch (documentTypeUser) {
          case 0:
          case 2:
            num = this.formatNumber2(value, 9, "number").replace(new RegExp("^0+(?!$)"), "")
            break;
          case 1:
            num = this.formatNumber2(value, 8, "textNum")
            break;
          case 3:
            num = num = this.formatNumber2(value, 12, "number").replace(new RegExp("^0+(?!$)"), "")
            break;
          default:
            num = value
            break;
        }
      }

      this.setState({
        [name]: (num),
      });
    }

    if (name === 'adminDocumentNumber') {
      let num;

      switch (documentType) {
        case 0:
        case 2:
          num = this.formatNumber2(value, 9, "number").replace(new RegExp("^0+(?!$)"), "")
          break;
        case 1:
          num = this.formatNumber2(value, 8, "textNum")
          break;
        case 3:
          num = num = this.formatNumber2(value, 12, "number").replace(new RegExp("^0+(?!$)"), "")
          break;
        default:
          num = value
          break;
      }

      this.setState({
        [name]: (num),
      });
    }
    if ((name !== 'documentNumber') && (name !== 'adminDocumentNumber')) {
      this.setState({
        [name]: value,
      });
    }
    /*switch (name) {
      case 'adminDocumentNumber':
        num = this.formatRUT(event.target.value);
        this.setState({unformattedAdminRut: num});
        prenum = '';
        postnum = '';

        if (num.length === 9) {
          num = num.replaceAll('-', '');
          prenum = num.slice(0, 8);
          postnum = num.slice(8, 9);
          num = prenum + '-' + postnum;
        }
        if (num.length === 8) {
          prenum = num.slice(0, 7);
          postnum = num.slice(7, 8);
          num = prenum + '-' + postnum;
        }

        this.setState({
          [name]: (num),
        });
        break;
      case 'documentNumber':
        let num = this.formatRUT(event.target.value);
        this.setState({unformattedRut: num})
        let prenum = '';
        let postnum = '';

        if (num.length >= 9) {
          prenum = num.slice(0, 8);
          postnum = num.slice(8, 9);
          num = prenum + '-' + postnum;
        }

        this.setState({
          [name]: (num),
        });
        break;
      default:
        this.setState({
          [name]: value,
        });
    }*/

    /*if (name === 'adminDocumentNumber'){
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if(num.length === 9) {
        num = num.replaceAll('-', '');
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }
      if(num.length === 8) {
        prenum = num.slice(0, 7);
        postnum = num.slice(7, 8);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    }
    if ((name === 'documentNumber')) {
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if(num.length >= 9){
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    } else if ((name !== 'documentNumber') || (name !== 'adminDocumentNumber')) {
      this.setState({
        [name]: value,
      });
    }*/
    // this.setState({
    //   [name]: value,
    // });
  };

  handleInputChangeAdminDocument = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      adminDocumentType,
    } = this.state;
    const {
      t,
      showMessage,
    } = this.props;

    const value = type === 'checkbox' ? checked : targetValue;

    /*if (adminDocumentType === -1) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('SelectDocumentType')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
      if ((name === 'adminDocumentNumber' && adminDocumentType === 0)) {
        let num = this.formatNumber(event.target.value);
        this.setState({
          [name]: (num),
        });
      } else {
        this.setState({
          [name]: value,
        });
      }
    //}
  };

  handleRegionChange = (newVal, label) => {
    const { fetchCommunes } = this.props;
    const { provinces } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCommunes({
      province_id: provinces[newVal.value].id,
      callback: (response) => {
        this.setState({
          cantons: response,
        });
      }
    });
  }

  handleCantonChange = (newVal, label) => {

    const { fetchCities } = this.props;
    const { cantons } = this.state;

    this.setState({
      [label.name]: newVal.value,
    });

    fetchCities({
      canton_id: cantons[newVal.value].id,
      callback: (response) => {
        this.setState({
          districts: response,
        });
      }
    });
  }

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const {
      fetchCities,
      t,
      showMessage,
      router: { location: { pathname } },
      fetchItems,
    } = this.props;
    const {
      communes,
      categories,
    } = this.state;

    /*if (label.name === 'commune') {
      let commune_id = communes[newVal.value].id;
      fetchCities({
        commune_id,
        callback: (response) => {
          if (response.length > 0) {
            this.setState({ city: response[0].name });
          } else {
            this.setState({ city: '' });
          }
        }
      });
    }*/
    if (label.name === 'currency' && pathname === '/edit-provider') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('changeCode')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }

    /*if(label.name === 'category'){
      fetchItems({
        category_id: categories[newVal.value].id,
      });
    }*/

    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleItemChange = (newValue) => {
    this.setState({ items: newValue });
  };

  handleExecutivesChange = (newValue, event) => {
    const {
      auth,
    } = this.props;
    const {
      newBankingExec,
    } = this.state;

    if (event.action === 'clear') {
      this.setState({
        executives: newValue,
        newBankingExec: [],
      });
    } else {
      if (event.action === 'remove-value' && event.removedValue.id === -1) {
        this.setState({
          executives: newValue,
          newBankingExec: [],
        });
      } else {
        if (newValue[newValue.length - 1].id === -1) {
          newBankingExec[0] = {
            id: 0,
            name: '',
            lastname: '',
            email: '',
            bank_id: auth.user.b2b.id,
          };
          this.setState({
            executives: newValue,
            newBankingExec: newBankingExec
          });
        } else {
          this.setState({
            executives: newValue
          });
        }
      }
    }
  };

  addBankingExec = () => {
    const {
      auth,
      showMessage,
      t,
    } = this.props;
    const {
      newBankingExec,
      countBankingExec,
    } = this.state;
    let count = 0,
      i = 1;
    count = countBankingExec + i;

    if (newBankingExec[newBankingExec.length - 1].email === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      let variable = {
        id: count,
        name: '',
        lastname: '',
        email: '',
        bank_id: auth.user.b2b.id,
      };
      this.state.newBankingExec.push(variable);
    }
    this.setState({
      countBankingExec: count
    });
  };

  deleteBankingExec = (index) => {
    const {
      newBankingExec,
    } = this.state;
    this.setState(newBankingExec.splice(index, 1));
  };

  validatePaymentCondition = () => {
    const {
      discountRate, client,
    } = this.state;
    const { t, showMessage } = this.props;
    if (client !== '' && discountRate > 0) {
      if (discountRate > 100) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('discountRateOutOfLimits')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
        return;
      }
      return true;
    }
    if (client === '' && discountRate === 0) {
      return false;
    }
    showMessage({
      message: <strong
        style={{ color: '#ffffff' }}>{t('completeComercialConditionOrEmptyAll')}</strong>,
      config: TOAST_CONFIG.ERROR,
    });
  };

  array_executives = (executives) => {
    let variable = [];

    for (let i = 0; i < executives.length; i++) {
      if (executives[i].id !== -1) {
        variable.push(Number(executives[i].id));
      }
    }

    return variable;
  };

  handleInputChangeTradeInformation = (event) => {
    const {
      currency: { list }
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const {
      auth: { user: { b2b: { id } } },
    } = this.props;
    const value = type === 'checkbox' ? checked : targetValue;

    const information = name.split('-');

    let commerce_code = [...this.state.commerce_code];

    switch (information[0]) {
      case 'commerce_code':
        commerce_code[information[1]] = {
          id: Number(information[1]),
          commerce_code: this.formatNumber2(value, 15, "number"),
          terminal: commerce_code[information[1]].terminal,
          currency: commerce_code[information[1]].currency,
          currency_id: commerce_code[information[1]].currency_id,
          description: commerce_code[information[1]].description,
        };
        break;
      case 'terminal':
        commerce_code[information[1]] = {
          id: Number(information[1]),
          commerce_code: commerce_code[information[1]].commerce_code,
          terminal: value,
          currency: commerce_code[information[1]].currency,
          currency_id: commerce_code[information[1]].currency_id,
          description: commerce_code[information[1]].description,
        };
        break;
      case 'currency':
        commerce_code[information[1]] = {
          id: Number(information[1]),
          commerce_code: commerce_code[information[1]].commerce_code,
          terminal: commerce_code[information[1]].terminal,
          currency: value,
          currency_id: list[value].id,
          description: commerce_code[information[1]].description,
        };
        break;
      case 'description':
        commerce_code[information[1]] = {
          id: Number(information[1]),
          commerce_code: commerce_code[information[1]].commerce_code,
          terminal: commerce_code[information[1]].terminal,
          currency: commerce_code[information[1]].currency,
          currency_id: commerce_code[information[1]].currency_id,
          description: value,
        };
        break;
    }

    this.setState({ commerce_code });
  };

  handleSelectCurrency = (newVal, label) => {
    const {
      currency: { list },
    } = this.props;

    const cycles1 = label.name.split('-');
    //const inputName = cycles1[0];
    const index = cycles1[1];

    let commerce_code = [...this.state.commerce_code];

    commerce_code[index] = {
      id: index,
      commerce_code: commerce_code[index].commerce_code,
      terminal: commerce_code[index].terminal,
      currency: newVal.value,
      currency_id: list[newVal.value].id,
      description: commerce_code[index].description,
    };

    this.setState({ commerce_code });
  };

  createProvider = () => {
    const {
      createProvider,
      auth,
      auth: {
        user: {
          b2b: {
            roles,
          },
        },
      },
      t,
      showMessage,
      createPaymentCondition,
      documentType: { list: documentTypeList },
      deleteLead,
      clients,
      role: { list: roleList },
      router: { location: { pathname } },
      providers: { list: providerList },
      editUser,
      history,
      flowApproval: { list: typeFlows },
      currency: { list: listCurrency },
    } = this.props;
    const {
      name,
      documentType,
      documentNumber,
      email,
      password,
      address,
      percentage,
      period,
      baseDays,
      items,
      discountRate,
      lead,
      lead_id,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      client,
      receiverDiscountShare,
      noRetain,
      typeFlow,
      code,
      region,
      regions,
      province,
      commune,
      communes,
      provinces,
      cantons,
      canton,
      districts,
      district,
      currency,
      executives,
      assistCard,
      business_name,
      unformattedRut,
      unformattedAdminRut,
      newBankingExec,
      documentTypeUser,
      typeDocument,
      type_legal_person,
      street,
      number,
      otherSign,
      commerce_code,
      cif,
      username_csp,
      password_csp,
      send_notification,
      roleName,
    } = this.state;

    /*if (documentType === -1) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('SelectDocumentType')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }*/
    const array = this.labelType(typeFlows);
    if (pathname === '/create-provider') {
      if (this.validateEmptyFields()) {
        const banking_executive = this.array_executives(executives);
        /*if (code.length < 8) {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('minimumCharacter')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        } else {*/
        const commerceC = [];
        commerce_code.forEach(function (code) {
          commerceC.push({
            id: code.id,
            commerce_code: code.commerce_code,
            terminal: '000023512',
            currency: code.currency,
            currency_id: code.currency_id,
            description: code.description,
          });
        });
        const data = {
          name,
          document_type: typeDocument[documentTypeUser].document_type,//'RUT',//documentTypesList[documentType].document_type,
          document_number: documentNumber,
          email,
          password,
          user_type: 'flecha',
          agency_user_type: 'business_admin',
          role_name: roleName, //roleList[0].id,
          items,
          process_type: null, //typeFlow === 0 ? null : array[typeFlow].id,
          commerce_code: commerceC,
          //pay_currency: listCurrency[currency].id,
          //banking_executive,
          //new_banking_executive: newBankingExec,
          type_provider: assistCard === true ? 'assist_card' : 'seller',
          username_csp,
          password_csp,
          b2b: {
            type_legal_person,
            bank_id: roles[0].owner_id,
            admin: 0,
            issuing_bank: 0,
            acquiring_bank: 0,
            seller: 1,
            buyer: 0,
            retain: noRetain ? 0 : 1,
            //address,
            admin_name: adminName,
            admin_lastname: adminLastname,
            admin_document_number: adminDocumentNumber,
            admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',//'RUT',
            admin_position: adminPosition,
            //cod: code,
            /*region_address_id: regions[region].id,
            commune_address_id: communes[commune].id,*/
            business_name,
            cif,
            send_notification: send_notification === true ? 1 : 0,
          },
          b2b_address: {
            street,
            number,
            other_sign: otherSign,
            district_id: districts[district].id,
          },
        };

        /*switch (data.b2b.admin_document_type) {
          case 0:
            data.b2b.admin_document_type = 'CI';
            break;
          case 1:
            data.b2b.admin_document_type = 'DNI';
            break;
          case 2:
            data.b2b.admin_document_type = 'Pasaporte';
            break;
        }*/
        createProvider({
          data,
          callback: (response) => {
            const { b2b: { id }, commerce_code } = response;
            /*const conditionData = {
              sender_id: auth.user.b2b.id,
              receiver_id: id,
              percentage,
              days: this.getDaysInv(period),
              type: 'advance',
            };*/
            if (lead) {
              deleteLead({
                params: {
                  id: lead_id,
                },
              });
            }
            showMessage({
              message: t('createProviderSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            // this.resetState();
            this.newPaymentCondition(id, commerce_code[0].id)
            history.push('/dashboard');

            /* createPaymentCondition({
                data: conditionData,
                callback: () => {
                  showMessage({
                    message: t('createProviderSuccess', { name }),
                    config: TOAST_CONFIG.SUCCESS,
                  });
                  this.resetState();
                  history.push('/dashboard');
                },
              });*/
          },
        });
        //}
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('mandatoryFieldsRequired')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }

    if (pathname === '/edit-provider') {
      if (this.validateEmptyFields()) {
        /*var adminDoctype = '';
        switch (adminDocumentType) {
          case 0:
            adminDoctype = 'CI';
            break;
          case 1:
            adminDoctype = 'Pasaporte';
            break;
        }
        let codeP = providerList.seller[0].cod;
        let currencyP = (listCurrency.map(
          (listCurrencyTypeMap, index) => ({
            ...listCurrencyTypeMap,
            index
          })
        )
          .filter(listCurrencyTypeFil => listCurrencyTypeFil.id
            === providerList.seller[0].currency[0].currency_id)[0].index);*/

        //if (this.validateEmptyFields()) {
        /*if (code.length < 8 || (currencyP !== currency && codeP === code)) {
          if (code.length < 8) {
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('minimumCharacter')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          } else {
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('changeCode')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {*/
        const data = {
          id_user: providerList.seller[0].id,
          name,
          type_legal_person,
          documentType: typeDocument[documentTypeUser].id,//documentType: '',//documentTypeList[documentType].id,
          documentNumber: documentNumber,
          email,
          items,
          address,
          admin_name: adminName,
          admin_lastname: adminLastname,
          admin_document_number: adminDocumentNumber,
          admin_document_type: documentType !== -1 ? adminDocumentType[documentType].document_type : '',// 'RUT',
          admin_position: adminPosition,
          percentage,
          days: this.getDaysInv(period),
          type: 'B',//Seller
          process_type: null,//typeFlow === 0 ? null : array[typeFlow].id,
          //region_address_id: regions[region].id,
          //commune_address_id: communes[commune].id,
          //pay_currency: listCurrency[currency].id,
          //cod: code,
          banking_executive: this.array_executives(executives),
          new_banking_executive: newBankingExec,
          business_name,
          username_csp,
          password_csp,
          b2b_address: {
            street,
            number,
            other_sign: otherSign,
            district_id: districts[district].id,
          },
          commerce_code,
          cif,
          send_notification: send_notification === true ? '1' : '0',
        };

        /*switch (data.admin_document_type) {
          case 0:
            data.admin_document_type = 'CI';
            break;
          case 1:
            data.admin_document_type = 'DNI';
            break;
          case 2:
            data.admin_document_type = 'Pasaporte';
            break;
        }*/

        /*if (
          adminName === '' ||
          adminLastname === '' ||
          adminPosition === '' ||
          email === '' ||
          adminDocumentNumber === '' ||
          adminDocumentType === -1) {

        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {*/
        // if (this.isEmail(email)) {
        editUser({
          data,
          callback: () => {
            history.push('/dashboard');
            this.resetState();
            showMessage({
              message: t('editProviderSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
          },
        });
        //}

        /*  } else {
            showMessage({
              message: t('incorrectEmail', { email }),
              config: TOAST_CONFIG.WARNING,
            });
            return;
          }*/
        //}
        /*} else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }*/
      } else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyAllRequest')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  isEmail = n => /[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*@[a-zA-Z0-9_]+([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{1,5}/.test(n);

  validateEmptyFields = () => {
    const {
      //code,
      name,
      //address,
      adminName,
      adminLastname,
      adminDocumentNumber,
      //adminDocumentType,
      documentNumber,
      //documentType,
      email,
      adminPosition,
      items,
      commerce_code,
      //region,
      //commune,
      //currency,
      //executives,
    } = this.state;

    return !(
      name === '' || //code === '' ||
      //address === '' ||
      adminName === '' ||
      adminLastname === '' ||
      //adminDocumentType === -1 ||
      //adminDocumentNumber === '' ||
      documentNumber === '' ||
      //documentType === -1 ||
      email === '' ||
      adminPosition === '' ||
      //region === -1 ||
      //commune === -1 ||
      commerce_code.currency_id === -1 ||
      items.length <= 0 /*|| executives.length === 0*/);
  };

  labelType = (typeList) => {
    const {
      t,
    } = this.props;
    const types = [];
    types.push({
      name: t('dontPose')
    });
    for (let i = 0; i < typeList.length; i++) {
      types.push({
        id: typeList[i].id,
        name: t(typeList[i].name)
      });
    }
    return types;
  };

  addTradeInformation = () => {
    const {
      countTradeInformation,
    } = this.state;
    let count = 0,
      i = 1;
    count = countTradeInformation + i;

    let variable = {
      id: count,
      commerce_code: '',
      terminal: '',
      currency_id: -1,
      description: '',
    };
    this.state.commerce_code.push(variable);

    this.setState({
      countTradeInformation: count
    });
  };

  deleteTradeInformation = (index) => {
    const {
      commerce_code,
    } = this.state;
    this.setState(commerce_code.splice(index, 1));
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  }

  // PAYMENT CONDITION FORM HANDLERS
  handlePCSelectChange = (newVal, label) => {
    const {
      auth: { user: { b2b: { type_user } } },
      clients,
      providers, providerCommerceCode, role: { list },
      fetchRoles,
    } = this.props;
    const { editable, codes, codesProvider } = this.state;
    this.setState({
      [label.name]: newVal.value,
    });
    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      // fetchRoles({
      //   params: {
      //     //type: 'external', 
      //     name: 'seller',
      //   },
      //   callback: (response) => {
      //     let role_id = '';
      //     for (let i = 0; i < response.length; i++) {
      //       if (response[i].name === 'seller') {
      //         role_id = response[i].id;
      //       }
      //     }
          providerCommerceCode({
            params: {
              user: providers.list[newVal.value].id,
              role: role_id,
            },
            callback: (response) => {
              this.setState({ codes: response.commerce_codes });
            },
          });
      //   }
      // });
    }

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].id
          : codes[newVal.value].id,
        codeDescription: type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].description
          : codes[newVal.value].description,
      });
    }

    if (editable) {
      const index = newVal.value;
      const conditions = label.name === 'client' ? clients.list[index].conditions : providers.list[index].conditions;

      const percentageValue = conditions.receiver_discount_share * 100;

      this.setState({
        baseDays: conditions.days,
        percentage: conditions.percentage,
        discountRate: conditions.discount_rate,
        receiverDiscountShare: percentageValue,
        clientPayment: 100 - percentageValue,
        //limitAmount: conditions.limit_amount,
      });
    }
  };

  handlePCOnInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  handlePCInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else
    if(name === "document_number"){

      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';
      if(num.length >= 9){
        prenum = num.slice(0,8);
        postnum = num.slice(8,9);
        num = prenum+"-"+postnum;
      }

      this.setState({
        [name]: (num),
      })
    } else*/
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: (value),
      });
    }
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  newPaymentCondition = (receiverId, commerceCode) => {
    const {
      auth: { user: { b2b: { type_user, id } } },
      clients: { list: clientList },
      providers: { list: providerList },
      requestPaymentCondition,
      t,
      showMessage,
      history,
      createPaymentCondition,
    } = this.props;
    const {
      userInfo,
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      startCountingFrom,
      //limitAmount,
      clientPayment,
      // commerceCode,
      period,
      percentage,
    } = this.state;
    const days = baseDays;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    let response = '',
      response1 = '',
      response2 = '';

    if (period !== -1 && percentage !== 0) {
      // requestPaymentCondition({
      //   data: {
      //     bank_sender_id: true, // TEST FOR PAYMENT REQUEST
      //     sender_request_id: null, // TEST FOR PAYMENT REQUEST
      //     receiver_id: receiverId,
      //     sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
      //     percentage,
      //     days: baseDays, //this.getDaysInv(period),
      //     is_request: 0,
      //     person_commerce_code: commerceCode,
      //     type: 'advance',
      //   },
      //   callback: (response) => {
          requestPaymentCondition({
            data: {
              bank_sender_id: true, // TEST FOR PAYMENT REQUEST
              sender_request_id: null, // TEST FOR PAYMENT REQUEST
              receiver_id: receiverId,
              sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
              discount_rate: discountRate,
              due_from_approval: startCountingFrom,
              days: baseDays,
              receiver_discount_share: receiverDiscountShare / 100,
              person_commerce_code: commerceCode,
              // is_request: 0,
              type: 'base',
            },
            callback: (response) => {
              response2 = response;
              showMessage({
                message: t('createPaymentConditionSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            },
          });
      //   },
      // });
    }
    // if (period !== -1 && percentage !== 0) {
    //   createPaymentCondition({
    //     data: {
    //       receiver_id: receiverId,
    //       sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
    //       percentage,
    //       days: baseDays, //this.getDaysInv(period),
    //       is_request: 0,
    //       person_commerce_code: commerceCode,
    //       type: 'advance',
    //     },
    //     callback: (response) => {
    //       createPaymentCondition({
    //         data: {
    //           receiver_id: receiverId,
    //           sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
    //           discount_rate: discountRate,
    //           due_from_approval: startCountingFrom,
    //           days: baseDays,
    //           receiver_discount_share: receiverDiscountShare / 100,
    //           person_commerce_code: commerceCode,
    //           is_request: 0,
    //           type: 'base',
    //         },
    //         callback: (response) => {
    //           response2 = response;
    //           showMessage({
    //             message: t('createPaymentConditionSuccess'),
    //             config: TOAST_CONFIG.SUCCESS,
    //           });
    //         },
    //       });
    //     },
    //   });
    // }
  };

  render() {
    const {
      t,
      clients: { list: clientList, loading: clientLoading, /*list: clientList*/ },
      providers: { list: providerList, loading: providerLoading },
      documentType: { list: documentTypesList },
      item: { list: itemList },
      flowApproval: { list: typeFlows },
      auth: { user: { profile: { role }, b2b: { type_user } } },
      currency: { list },
      card: { assist, list: executivesList },
      router: { location: { pathname } },
    } = this.props;
    const {
      name,
      documentNumber,
      documentType,
      email,
      password,
      confirmPassword,
      address,
      percentage,
      baseDays,
      items,
      discountRate,
      adminName,
      adminLastname,
      adminDocumentNumber,
      adminDocumentType,
      adminPosition,
      receiverDiscountShare,
      clientPayment,
      noRetain,
      period,
      edit,
      hidden,
      typeFlow,
      code,
      regions,
      region,
      communes,
      commune,
      inputValue,
      inputValueExecutive,
      city,
      currency,
      executives,
      assistCard,
      business_name,
      exc,
      newBankingExec,
      typePerson,
      typeDocument,
      documentTypeUser,
      provinces,
      province,
      cantons,
      canton,
      districts,
      district,
      street,
      number,
      otherSign,
      commerce_code,
      cif,
      categories,
      category,
      username_csp,
      password_csp,
      send_notification,
      client,
      provider,
      codes,
      commerceCode,
      codeDescription,
      codesProvider,
      startCountingFrom,
    } = this.state;
    return (
      <div>
        {(clientLoading || providerLoading) && <Loading />}
        <div className="columns">
          <div className="column is-full">
            <PersonForm
              t={t}
              title={this.getTitle()}
              onSubmit={this.createProvider}
              handleInputChange={this.handleInputChange}
              handleInputChangeAdminDocument={this.handleInputChangeAdminDocument}
              name={name}
              handleSelectChange={this.handleSelectChange}
              handleItemChange={this.handleItemChange}
              handleExecutivesChange={this.handleExecutivesChange}
              //documentTypesList={documentTypesList}
              //={this.state.documentsEcuador}
              documentNumber={documentNumber}
              address={address}
              documentType={documentType}
              items={items}
              itemList={itemList}
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              //persons={clientList}
              baseDays={baseDays}
              percentage={percentage}
              discountRate={discountRate}
              person="provider"
              adminName={adminName}
              adminLastname={adminLastname}
              adminDocumentNumber={ adminDocumentNumber }
              adminDocumentType={ adminDocumentType }
              adminPosition={adminPosition}
              handlePercentageChange={this.handlePercentageChange}
              receiverDiscountShare={receiverDiscountShare}
              clientPayment={clientPayment}
              typeFlows={this.labelType(typeFlows)}
              typeFlow={typeFlow}
              //noRetain={noRetain}
              period={period}
              edit={edit}
              hidden={hidden}
              code={code}
              role={role}
              regions={regions}
              region={region}
              handleRegionChange={this.handleRegionChange}
              handleCantonChange={this.handleCantonChange}
              cantons={cantons}
              canton={canton}
              districts={districts}
              district={district}
              communes={communes}
              commune={commune}
              handleOnInputChange={this.handleOnInputChange}
              handleOnInputChangeExecutive={this.handleOnInputChangeExecutive}
              inputValue={inputValue}
              inputValueExecutive={inputValueExecutive}
              city={city}
              paymentCurrency={list}
              //currency={currency}
              executivesList={exc}
              executives={executives}
              assistCard={assistCard}
              exist={assist !== null ? assist.exist : ''}
              business_name={business_name}
              newBankingExec={newBankingExec}
              addBankingExec={this.addBankingExec}
              deleteBankingExec={this.deleteBankingExec}
              handleInputChangeNewExc={this.handleInputChangeNewExc}
              handleInputChangeTypeDocument={this.handleInputChangeTypeDocument}
              documentTypeUser={documentTypeUser}
              documentTypesList={typeDocument}
              noRetain={typePerson}
              provinces={provinces}
              province={province}
              street={street}
              number={number}
              otherSign={otherSign}
              tradeInformation={commerce_code}
              addTradeInformation={this.addTradeInformation}
              deleteTradeInformation={this.deleteTradeInformation}
              handleInputChangeTradeInformation={this.handleInputChangeTradeInformation}
              handleSelectCurrency={this.handleSelectCurrency}
              cif={cif}
              cancelSave={this.cancelSave}
              categories={categories}
              category={category}
              username_csp={username_csp}
              password_csp={password_csp}
              send_notification={send_notification}
              pathName={pathname}
            >
              {
                pathname === '/create-provider' ?
                <>
                  <div className="field is-horizontal subtitle is-5 text-black">
                    {t('termsAndConditions')}
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <SelectSingle
                            name={type_user !== 'acquiring_bank' ? 'provider' : 'client'}
                            label={t(type_user !== 'acquiring_bank' ? 'provider' : 'customer')}
                            placeholder={t('SelectOption')}
                            onChange={this.handlePCSelectChange}
                            options={this.handlePCOnInputChange === null || inputValue.length > 1 ? [
                              ...clientList.map((person, index) => ({
                                label: `${person.idPerson.name} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], person)} ${person.idPerson.document_number}`,
                                value: index,
                              })),
                            ] : [{}]}
                            value={ type_user !== 'acquiring_bank' ? provider + 1 : client + 1 }
                            selectClasses="is-fullwidth"
                            controlClasses="has-icons-left"
                            icon="far fa-user"
                            iconPosition="is-left"
                            // isDisabled={editable}
                            isSearchable
                            onInputChange={this.handlePCOnInputChange}
                            inputValue={inputValue}
                          />
                        </div>
                      <div className="column">
                        <TextInput
                          name="baseDays"
                          label={t('arrangedDays')}
                          value={baseDays}
                          onChange={this.handlePCInputChange}
                          placeholder="0"
                          type="number"
                          controlClasses="is-expanded has-icons-right"
                          inputClasses="has-text-right"
                          //min="0"
                          step="1"
                          // disabled={band}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <SelectSingle
                          name="startCountingFrom"
                          label={t('startCountingFrom')}
                          placeholder={t('SelectOption')}
                          onChange={this.handlePCSelectChange}
                          options={
                            [{
                              label: t('receptionDate'),
                              value: 0,
                            }, {
                              label: t('approvalDate'),
                              value: 1,
                            }]}
                          selectClasses="is-fullwidth"
                          controlClasses="has-icons-left"
                          icon="far fa-user"
                          iconPosition="is-left"
                          value={startCountingFrom}
                          isClearable={false}
                          // isDisabled={band}
                        />
                      </div>
                      <div className="column">
                        <TextInput
                          name="discountRate"
                          label={t('financingRate')}
                          value={discountRate}
                          onChange={this.handlePCInputChange}
                          placeholder="0"
                          type="number"
                          controlClasses="is-expanded has-icons-right"
                          inputClasses="has-text-right"
                          min="0"
                          step="0.01"
                          max="100"
                          iconPosition="is-right"
                          icon="fas fa-percent"
                          // disabled={band || isConditionClient || isProvider}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <TextInput
                          name="receiverDiscountShare"
                          label={t('receiverDiscountShare')}
                          value={receiverDiscountShare}
                          onChange={this.handlePercentageChange}
                          placeholder={t('receiverDiscountShare')}
                          type="number"
                          controlClasses="is-expanded has-icons-right"
                          inputClasses="has-text-right"
                          min="0"
                          step="0.01"
                          max="100"
                          iconPosition="is-right"
                          icon="fas fa-percent"
                        />
                      </div>
                      <div className="column">
                        <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                          {`${t('paymentClient')}:`}
                        </div>
                        {`${Number(clientPayment)
                          .toFixed(2)} %`}
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal">
                    <div className="field-body columns">
                      <div className="column">
                        <SelectSingle
                          name={'commerceCode'}
                          label={t('commerceCode')}
                          placeholder={t('SelectOption')}
                          onChange={this.handlePCSelectChange}
                          options={[
                            // ...codesProvider.commerce_codes.map((code, index) => ({
                            ...codes.map((code, index) => ({
                              label: code.commerce_code,
                              value: index,
                            })),
                          ]}
                          value={commerceCode}
                          selectClasses="is-fullwidth"
                          controlClasses="has-icons-left"
                          icon="far fa-user"
                          iconPosition="is-left"
                          isDisabled={true}
                        />
                      </div>
                      <div className="column">
                        <div style={{ display: 'flex' }}>
                          <p style={{ marginTop: '1.5em' }}>
                            {codeDescription}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field is-horizontal subtitle is-4 text-black">
                    {t('flexibleConditions')}
                  </div>
                  <div className="field is-horizontal">
                    <div className="columns field-body">
                      <div className="column">
                        <TextInput
                          name="percentage"
                          label={t('interestRate')}
                          value={percentage}
                          onChange={this.handlePCInputChange}
                          placeholder="0"
                          type="number"
                          controlClasses="is-expanded has-icons-right"
                          inputClasses="has-text-right"
                          min="0"
                          step="0.01"
                          iconPosition="is-right"
                          icon="fas fa-percent"
                        />
                      </div>
                      <div className="column">
                        <SelectSingle
                          name="period"
                          label={t('period')}
                          //placeholder={t('SelectOption')}
                          onChange={this.handlePCSelectChange}
                          options={
                            [{
                              label: t('yearly'),
                              value: 0,
                            }, {
                              label: t('monthly'),
                              value: 1,
                            }, {
                              label: t('daily'),
                              value: 2,
                            },]}
                          value={period}
                          selectClasses="is-fullwidth"
                          controlClasses="has-icons-left"
                          icon="far fa-user"
                          iconPosition="is-left"
                          isClearable={false}
                        />
                      </div>
                    </div>
                  </div>
                </>
              : null
              }
            </PersonForm>
          </div>
        </div>
      </div>
    );
  }
}

CreateProvider.propTypes = {
  clients: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  item: PropTypes.shape(Object).isRequired,
  location: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  deleteLead: PropTypes.func.isRequired,
  createPaymentCondition: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  router: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  editUser: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({
                          auth, 
                          card,
                          clients, 
                          currency, 
                          documentType, 
                          flowApproval, 
                          item, 
                          paymentConditions,
                          providers, 
                          role, 
                          router, 
                        }) => ({
  auth,
  card,
  clients,
  currency,
  documentType,
  flowApproval,
  item,
  paymentConditions,
  providers,
  role,
  router,
});
export default compose(
  connect(mapStateToProps, {
    ...cardActions,
    ...clientsActions,
    ...currencyActions,
    ...documentTypeActions,
    ...flowApprovalActions,
    ...itemActions,
    ...leadActions,
    ...paymentConditionsActions,
    ...providersActions,
    ...roleActions,
  }),
  withNamespaces(),
)(CreateProvider);