import { createAction } from 'redux-actions';
import types from './types';

const saveCheck = createAction(types.SAVE_CHECK);
const updateChecks = createAction(types.UPDATE_CHECKS);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  saveCheck,
  updateChecks,
  showMessage,
};
