import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { bankingExecutivesActions } from '../../state/ducks/bankingExecutives/';
import { Link } from 'react-router-dom';
import { Form, Loading, Modal, Panel, TableSimple, Toggle } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import SelectSingle from '../../components/SelectSingle';
import TextInput from '../../components/TextInput';

class BankingExecutives extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    list: [],
    personExecutive: [],
    id_executive: '',
    executive: '',
    id: '',
    name: '',
    lastname: '',
    email: '',
  });

    componentDidMount() {
      const {
        auth: { user: { b2b: { id } } },
        getBankingExecutives,
      } = this.props;
      getBankingExecutives({
        params: { owner: id },
        callback: (response) => {
          this.setState({ list: response });
        }
      });
    }

    resetState = () => {
        this.setState(this.getInitialState());
    };

    openModal = (onToggle, data) => {
      this.setState({
        id: data.id,
        name: data.name,
        lastname: data.lastname,
        email: data.email,
      });
      onToggle({
        edit: true,
      })
    }

    getGridSettings = (onToggle) => {
      const {
        t,
        showMessage,
        auth: { user: { b2b: { id } } },
        getPersonExecutive,
        getBankingExecutives,
        deleteExecutive,
      } = this.props;

      const { 
        list,
        personExecutive,
      } = this.state;

      const columns = [
        {
          expander: true,
          show: false,
        },
        {
          id: 'name', // Required because our accessor is not a string
          Header: t('name'),
          accessor: d => d.name,
        },
        { 
          id: 'lastname',
          Header: t('adminLastname'),
          accessor: d => d.lastname,
        },
        {
          id: 'email', // Required because our accessor is not a string
          Header: t('email'),
          accessor: d => `${d.email}`,
        },
        {
        id: 'status', // Required because our accessor is not a string
        Header: t('status'),
        accessor: d => `${d.id} `,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <label
                  className={`button is-inverted is-${cellInfo.original.status === '1' ? 'success' : 'danger'}`}
                  style={{display: 'block'}}
                >
                  {cellInfo.original.status === '1'
                    ? t('enabled')
                    : t('disabled')
                  }
                </label>
              </td>
            </tr>
            </tbody>
          </table>
          ),
        },
        {
          id: 'assignedUsers', // Required because our accessor is not a string
          Header: t('assignedUsers'),
          accessor: d => `${d.id} `,
          Cell: cellInfo => (
            <table>
              <tbody>
              <tr>
                <td>
                  <button
                    className="button is-primary is-inverted"
                    onClick={() =>  
                      getPersonExecutive({
                        params: {
                          id: cellInfo.original.id,
                        },
                        callback: (response, success) => {
                          if (success) {
                            this.setState({ personExecutive: response });
                            onToggle();
                          }
                        }
                      })
                    }
                  >
                    <i className="far fa-eye mr-8px" aria-hidden="true"/>
                    {t('seeUsers')}
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          ),
        },
        {
          id: 'action', // Required because our accessor is not a string
          Header: t('action'),
          accessor: d => `${d.id} `,
          Cell: cellInfo => (
            <table>
              <tbody>
              <tr>
                <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
                  <button
                    className="button is-primary is-inverted"
                    onClick={() => this.openModal(onToggle, cellInfo.original)}
                  >
                    <i className="far fa-edit mr-8px" aria-hidden="true"/>
                    {t('edit')}
                  </button>
                  <button
                    className="button is-danger is-inverted"
                    onClick={() => {
                      getPersonExecutive({
                        params: {
                          id: cellInfo.original.id,
                        },
                        callback: (response, success) => {
                          if (response.length > 0 && success === true) {
                            getBankingExecutives({
                              params: { owner: id },
                              callback: () => {
                                onToggle({
                                  isBuyer: true,
                                });
                                this.setState({ id_executive: cellInfo.original.id });
                              }
                            });
                          } else {
                            if (!success) {
                              deleteExecutive({
                                data: {
                                  executive: cellInfo.original.id,
                                },
                                callback: () => {
                                  getBankingExecutives({
                                    params: { owner: id },
                                    callback: (response) => {
                                      this.setState({ list: response });
                                    }
                                  });
                                  showMessage({
                                    message: t('deleteExecutive'),
                                    config: TOAST_CONFIG.SUCCESS,
                                  });
                                }
                              });
                            }
                          }
                        },
                      });
                    }}
                  >
                    <i className="fas fa-times mr-8px" aria-hidden="true"/>
                    {t('delete')}
                  </button>
                </div>
              </tr>
              </tbody>
            </table>
          )
        }
      ];

      const data = list;
      const title = t('bankingExecutives');
      return {
        columns,
        data,
        title,
      };

    };

  closeModal = (onToggle) => {
    onToggle();
  };

  handleSelectChange = (newVal, label) => {
    const {
      t,
      showMessage,
      bankingExecutives: { executives },
    } = this.props;
    const {
      id_executive,
    } = this.state;

    if (executives[newVal.value].id !== id_executive) {
      this.setState({
        [label.name]: newVal.value,
      });
    } else {
      this.setState({
        [label.name]: newVal.value,
      });
      showMessage({
        message: t('shouldNotSelect'),
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  newExecutive = (onToggle) => {
    const {
      t,
      auth: { user: { b2b: { id } } },
      showMessage,
      bankingExecutives: { executives },
      getBankingExecutives,
      migrateExecutive,
    } = this.props;
    const {
      id_executive,
      executive,
    } = this.state;

    if (executive !== '') {
      if (parseInt(executives[executive].id) !== parseInt(id_executive)) {
        const data = {
          old_executive: parseInt(id_executive),
          new_executive: parseInt(executives[executive].id),
        };
        migrateExecutive({
          data,
          callback: () => {
            getBankingExecutives({
              params: { owner: id },
              callback: (response) => {
                this.setState({ list: response });
              }
            });
            showMessage({
              message: t('deleteExecutive'),
              config: TOAST_CONFIG.SUCCESS,
            });
            onToggle();
          }
        });
      } else {
        showMessage({
          message: t('shouldNotSelect'),
          config: TOAST_CONFIG.ERROR,
        });
      }
    } else {
      showMessage({
        message: t('selectANewCommercialExecutive'),
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  editExecutive = (onToggle) => {
    const {
      id,
      name,
      lastname,
      email,
    } = this.state;
    const { t, showMessage, editExecutive, getBankingExecutives, auth: { user: { b2b: { id: idOwner } } }, } = this.props;

    const data = {
      id,
      name,
      lastname,
      email,
    };

    editExecutive({
      data,
      callback: () => {
        showMessage({
          message: t('editUserSuccess', { name }),
          config: TOAST_CONFIG.SUCCESS,
        });
        onToggle();
        //window.location.reload(false);
        getBankingExecutives({
          params: { owner: idOwner },
          callback: (response) => {
            this.setState({ list: response });
          }
        });
      }
    });

  }

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      t,
      auth: { loading: authLoading },
      bankingExecutives: { loading: bankingExecutivesLoading, executives },
    } = this.props;
    const loading = authLoading || bankingExecutivesLoading;
    const {
      list,
      personExecutive,
      executive,
      id,
      name,
      lastname,
      email,
    } = this.state;

    return(
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div>
              {loading && <Loading/>}
              <div className="dashboard">
                <div className="columns is-multiline">
                  <Fragment>
                    {
                      toggled && (!toggleProps.isBuyer && !toggleProps.edit) &&(
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <Panel headingText={t('assignedUsers')}>
                            <table className="table is-striped">
                              <thead>
                              <th>{t('businessName')}</th>
                              <th>{t('RUT')}</th>
                              <th>{t('rol')}</th>
                              <th>{t('email')}</th>
                              </thead>
                              {personExecutive.map(person => (
                                <tr>
                                  <td>{person.users.name}</td>
                                  <td>{person.users.document_number}</td>
                                  <td>{person.users.roles[0].name}</td>
                                  <td>{person.users.username}</td>
                                </tr>
                              ))}
                            </table>
                            <div className="has-text-right">
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={this.closeModal.bind(this, onToggle)}
                              >
                                {t('accept')}
                              </button>
                            </div>
                          </Panel>
                        </Modal>
                      )
                    }
                    {
                      toggled && toggleProps.isBuyer && (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <Panel headingText={t('businessExecutives')}>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column">
                                  <SelectSingle
                                    name="executive"
                                    label={t('selectANewCommercialExecutive')}
                                    selectClasses="is-fullwidth"
                                    placeholder={t('Select')}
                                    onChange={this.handleSelectChange}
                                    options={[
                                      ...executives.map((executive, index) => ({
                                        label: executive.name + ' ' + executive.lastname,
                                        value: index,
                                      })),
                                    ]}
                                    value={executive}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="field is-grouped is-grouped-right">
                              <button
                                type="button"
                                className="button is-primary is-danger"
                                onClick={this.closeModal.bind(this, onToggle)}
                              >
                                {t('cancel')}
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={this.newExecutive.bind(this, onToggle)}
                              >
                                {t('accept')}
                              </button>
                            </div>
                          </Panel>
                        </Modal>
                      )
                    }
                    {
                      toggled && (toggleProps.edit && !toggleProps.isBuyer) && (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <Panel headingText={t('businessExecutives')}>
                            <Form
                              submitText={t('save')}
                              onSubmit={(event) => {
                                event.preventDefault();
                                this.editExecutive(onToggle);
                              }}
                              buttonClasses="mr-8px"
                            >

                              <div className="field is-horizontal">
                                <div className="field-body columns">
                                  <div className="column">
                                    <TextInput
                                      id={'name'}
                                      name={'name'}
                                      label={t('name')}
                                      value={name}
                                      onChange={this.handleInputChange}
                                      placeholder={t('name')}
                                      type="text"
                                      controlClasses="is-expanded"
                                      isRequired={true}
                                    />
                                  </div>
                                  <div className="column">
                                    <TextInput
                                      id={'lastname'}
                                      name={'lastname'}
                                      label={t('adminLastname')}
                                      value={lastname}
                                      onChange={this.handleInputChange}
                                      placeholder={t('adminLastname')}
                                      type="text"
                                      controlClasses="is-expanded"
                                      isRequired={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="field is-horizontal">
                                <div className="field-body columns">
                                  <div className="column">
                                    <TextInput
                                      id={'email'}
                                      name={'email'}
                                      label={t('email')}
                                      value={email}
                                      onChange={this.handleInputChange}
                                      placeholder={t('email')}
                                      type="text"
                                      controlClasses="is-expanded"
                                      isRequired={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form>
                          </Panel>
                        </Modal>
                      )
                    }
                    {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <div className="panel-top-content">
                            <Link
                              className="button is-primary"
                              to="/create-banking-executive"
                            >
                              <i className="fas fa-plus mr-8px" aria-hidden="true"/>
                              {t('newBankingExecutive')}
                            </Link>
                          </div>
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    )
                    }
                  </Fragment>
                </div>
              </div>
            </div>
          );    
        }}
      </Toggle>
    );
  }
}

BankingExecutives.propTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, bankingExecutives }) => (
  {
    auth,
    bankingExecutives,
  }
);

export default compose(
    connect(mapStateToProps, {
      ...bankingExecutivesActions,
    }),
    withNamespaces(),
  )(BankingExecutives);