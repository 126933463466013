import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { roleActions } from '../../state/ducks/role';
import DateInput from '../../components/DateInput';
import { formatCurrency, formatDate, getPath } from '../../util';
import { TOAST_CONFIG } from '../../config/constants';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    invoiceDate: new Date().setDate(new Date().getDate() - 1),
    invoiceDateUntil: new Date().setHours(23, 59, 59, 999),
  });

  componentDidMount() {
    const { getTransaction, getClients, getProviders } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;

    getTransaction({
      params: {
        min_due_date: invoiceDate,
        max_due_date: invoiceDateUntil,
      },
    });
    /* getClients({
       onComplete: getProviders({
         onComplete: getTransaction({
           params: {
             min_due_date: invoiceDate,
             max_due_date: invoiceDateUntil,
           },
         }),
       }),
     });*/
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  updateRol = (onToggle) => {
    const {
      editRole,
      role: { type: { list: typeList } },
      fetchRoles,
    } = this.props;
    const { id, name, roleType } = this.state;
    const data = {
      id,
      name,
      type: typeList[roleType],
    };
    editRole({
      data,
      callback: () => {
        fetchRoles({});
        onToggle();
        this.resetState();
      },
    });
  };

  getGridSettings = () => {
    const {
      auth: {
        user: {
          b2b: { type_user },
        },
      },
      t,
      transactions: { list: transactionList },
      clients: { list: clientList },
      providers: { list: providerList },
    } = this.props;
    let columns = null;
    let data = null;
    let title = null;
    switch (type_user) {
      case 'issuing_query':
      case 'issuing_bank':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoiceNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalPay', // Required because our accessor is not a string
            Header: t('totalPay'),
            accessor: d => formatCurrency(parseFloat(d.adjusted_amount)),
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], clientList.filter(client => client.id === d.sender_id)[0])}`,
          },
          {
            id: 'documentNumber', // Required because our accessor is not a string
            Header: t('RUT'),
            accessor: d => `${getPath(['idPerson', 'document_number'], clientList.filter(client => client.id === d.sender_id)[0])}`,
          },
        ];
        data = transactionList;
        title = t('clientPay');
        break;
      case 'acquiring_query':
      case 'acquiring_bank':
        columns = [
          {
            id: 'invoicesNumber', // Required because our accessor is not a string
            Header: t('invoicesNumber'),
            accessor: d => `${d.invoice_number}`,
          },
          {
            id: 'due_date', // Required because our accessor is not a string
            Header: t('paymentDate'),
            accessor: 'due_date',
            Cell: cellInfo => (
              <div>
                <span>{formatDate(cellInfo.original.due_date)}</span>
              </div>
            ),
            filterMethod: (filter, row) => {
              if (!filter.value.match(/[\d/]+$/gm)) {
                return true;
              }
              if (formatDate(row.due_date)
                .includes(filter.value)) {
                return formatDate(row.due_date);
              }
              //return formatDate(row.date).indexOf(filter.value) >=0;
            },
            Filter: ({ filter, onChange }) =>
              <input
                onChange={event => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter && filter.value.match(/[\d/]+$/gm) ? filter.value : ''}
              />
          },
          {
            id: 'totalReceiver', // Required because our accessor is not a string
            Header: t('totalReceiver'),
            accessor: d => formatCurrency(parseFloat(d.adjusted_amount)),
          },
          {
            id: 'currency',
            Header: t('currency'),
            accessor: d => "CAD", // accessor: d => `${d.pay_currency_id}`,
          },
          {
            id: 'description', // Required because our accessor is not a string
            Header: t('description'),
            accessor: d => `${d.description}`,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('name'),
            accessor: d => `${getPath(['idPerson', 'name'], providerList.filter(client => client.id === d.receiver_id)[0])}`,
          },
          {
            id: 'documentNumber', // Required because our accessor is not a string
            Header: t('documentNumber'),
            accessor: d => `${getPath(['idPerson', 'document_number'], providerList.filter(client => client.id === d.receiver_id)[0])}`,
          },
        ];
        data = transactionList;
        title = t('providersReceiver');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = (dateName, newDate) => {
    const { getTransaction } = this.props;

    if (dateName === 'invoiceDate' && newDate !== '') {
      newDate.setHours(0, 0, 0, 0);
    }
    if (dateName === 'invoiceDateUntil' && newDate !== '') {
      newDate.setHours(23, 59, 59, 999);
    }

    this.setState({
      [dateName]: newDate,
    });
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  render() {
    const {
      t,
      transactions: { loading: transactionLoading },
      auth: { language },
      showMessage,
      getTransaction,
    } = this.props;
    const { invoiceDate, invoiceDateUntil } = this.state;
    const loading = transactionLoading;
    console.log('Language', language);
    return (
      <Toggle>
        {({ toggled, onToggle }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {
                    toggled
                    && (
                      <Modal onToggle={onToggle}/>
                    )
                  }
                  {data
                  && (
                    <div className="column is-full">
                      <Panel /*headingText={title}*/>
                        <div className="field is-horizontal">
                          <div className="field-body columns">
                            <div className="columns is-centered" style={{ width: '100%' }}>
                              <div className="column">
                                <p className="is-size-4">
                                  {t('paymentDateInvoice')}
                                </p>
                              </div>
                              <div className="column">
                                <DateInput
                                  name="invoice_date"
                                  label={t('since')}
                                  value={invoiceDate}
                                  onChange={(value) => this.handleDateChange('invoiceDate', value)}
                                  type="date"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="far fa-calendar-alt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div className="column">
                                <DateInput
                                  name="invoice_date"
                                  label={t('until')}
                                  value={invoiceDateUntil}
                                  onChange={(value) => this.handleDateChange('invoiceDateUntil', value)}
                                  type="text"
                                  fieldClasses="is-expanded"
                                  controlClasses="has-icons-left"
                                  icon="far fa-calendar-alt"
                                  iconPosition="is-left"
                                />
                              </div>
                              <div style={{ marginTop: '35px' }}>
                                <button
                                  type="button"
                                  className="button is-primary"
                                  onClick={() => {
                                    invoiceDate === '' && invoiceDateUntil === '' ?
                                      showMessage({
                                        message: <strong
                                          style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
                                        config: TOAST_CONFIG.ERROR,
                                      })
                                      : invoiceDateUntil < invoiceDate ?
                                      showMessage({
                                        message: <strong
                                          style={{ color: '#ffffff' }}>{t('errorDate')}</strong>,
                                        config: TOAST_CONFIG.ERROR,
                                      })
                                      :
                                      getTransaction({
                                        params: {
                                          min_due_date: invoiceDate,
                                          max_due_date: invoiceDateUntil,
                                        },
                                      });
                                  }}
                                >
                                  {t('send')}
                                </button>
                              </div>
                            </div>
                          </div>
                          <br/>
                        </div>
                        <TableSimple data={data} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Transactions.propTypes = {
  t: PropTypes.func.isRequired,
  getTransaction: PropTypes.func.isRequired,
  getClients: PropTypes.func.isRequired,
  getProviders: PropTypes.func.isRequired,
  transactions: PropTypes.shape(Object).isRequired,
  clients: PropTypes.shape(Object).isRequired,
  providers: PropTypes.shape(Object).isRequired,
  role: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  fetchRoles: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  auth,
  transactions,
  clients,
  providers,
  role,
}) => (
  {
    auth,
    transactions,
    clients,
    providers,
    role,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...clientsActions,
    ...providersActions,
    ...transactionsActions,
    ...roleActions,
  }),
  withNamespaces(),
)(Transactions);
