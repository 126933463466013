import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { authActions } from '../../../state/ducks/auth';
import { TOAST_CONFIG } from '../../../config/constants';
import { Loading } from '../../../components';
import FormRecover from './components/FormRecover';
import './styles.scss';
import AmexLogo from '../../../resources/images/assets/amex.png';
import logoDos from '../../../resources/images/assets/logo-c.png';
import BusinessLink from '../../../resources/images/assets/components/BusinessLink';
class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: '',
      password: '',
      confirmPassword: '',
    };
  }

  componentDidMount = () => {
    const { auth: { language, } } = this.props;
    this.setState({ language });

    //window.location.hash="no-back-button";
  };

  handleInputChange = (event) => {
    const {
      target: {
        type, checked, value: targetValue, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSelectChange = (newVal) => {
    const { i18n } = this.props;
    this.setState({
      language: newVal.target.value,
    });
    i18n.changeLanguage(newVal.target.value);
    // eslint-disable-next-line no-undef
    window.location.reload();
  };

  handleSubmit = () => {
    const {
      t,
      passwordResetToken,
      passwordResetLogin,
      showMessage,
      history,
      auth: { user },
      updateB2B,
    } = this.props;
    const {
      password,
      confirmPassword
    } = this.state;
    const url = window.location.href;

    // eslint-disable-next-line prefer-const
    let regex = /[?&]([^=#]+)=([^&#]*)/g,
      params = {},
      match;
    // eslint-disable-next-line no-cond-assign
    while (match = regex.exec(url)) {
      params[match[1]] = match[2];
    }

    if(user.b2b.password_changed === 0){
      passwordResetLogin({
        email: user.email,
        password,
        callback: () => {
          updateB2B({
            params: {
              user_id: /*user.b2b.roles.owner_id === null ? user.user_id :*/ user.profile.user_id,
            },
            callback: () => {
              /*if (user.profile.role === 'buyer' && user.b2b.saved_credentials === 0) {
                history.push('/save-credentials');
              } else {*/
                window.top.location.replace('/dashboard');
              //}
              showMessage({
                message: t('changePasswordSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            }
          });
        }
      });
    }else{
      if (password !== '' && confirmPassword !== '') {
        if (password === confirmPassword) {
          params.password = password;
          passwordResetToken({
            params,
            callback: () => {
              history.push('/dashboard');
              showMessage({
                message: t('changePasswordSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            }
          });
        } else {
          showMessage({
            message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
            config: TOAST_CONFIG.ERROR,
          });
        }
      } else if (password !== '' || confirmPassword !== '') {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }
    }
  };

  render() {
    const {
      auth: { loading, user: { b2b: { is_password_expire } } },
      t,
    } = this.props;
    const { language, password, confirmPassword } = this.state;
    /*if (logged === true) {
      return <Redirect to="/dashboard" />;
    }*/
    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}

        {/* HERO */}
        <section className="sign-in-hero"> 
            <div className="hero-filter"></div>
            <figure className="image is-128x128">
              <img src={AmexLogo} alt="logo" />
            </figure>
            <h2>Don't do business without it.</h2>
        </section>

        {/* FORM MAIN CONTAINER */}
        <section className="sign-in-form">
          {/* LANGUAGES SELECT */}
          <div className='sign-in-language'>
            <div className="field">
              <label htmlFor="language" className="label has-text-white">
                  {t('language')}
                  <div className="control has-icons-left">
                    <div className="select is-small">
                      <select 
                        name="language" 
                        value={language}
                        onChange={this.handleSelectChange}
                      >
                        <option value="en">{t('en')}</option>
                        <option value="es">{t('es')}</option>
                        <option value="fr">{t('fr')}</option>
                        <option value="pt">{t('pt')}</option>
                        <option value="zh">{t('中文')}</option>
                      </select>
                    </div>
                    <div className="icon is-small is-left">
                      <i className="fas fa-globe"/>
                    </div>
                  </div>
                </label>
            </div>
          </div>
          {/* B2B LOGO */}
          <BusinessLink width="250" height="100" />
          {/* FORM */}
          <div className='login-form'>
            <div className='recover-password-form'>
              <FormRecover
                handleSubmit={this.handleSubmit}
                handleInputChange={this.handleInputChange}
                data={{
                  password,
                  confirmPassword
                }}
                isPasswordExpire={is_password_expire}
                />
            </div>
          </div>
        </section>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  passwordResetToken: PropTypes.func.isRequired,
  auth: PropTypes.shape(Object).isRequired,
  i18n: PropTypes.shape(Object).isRequired,
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(RecoverPassword);
