/**
 * Created by Jonas on 09/09/19.
 */

import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';
import { getPath } from '../../util';
import './styles.scss';

const PaymentConditionForm = ({
  t,
  onSubmit,
  handleInputChange,
  handleOnInputChange,
  handlePercentageChange,
                                handleSelectChange,
                                isClient,
                                client,
                                provider,
                                persons,
                                baseDays,
                                discountRate,
                                receiverDiscountShare,
                                clientPayment,
                                editable,
                                startCountingFrom,
                                //limitAmount,
                                hidden,
                                band,
                                inputValue,
                                clientEdit,
                                title,
                                editPercentage,
                                isProvider,
                                isConditionClient,
                                isConditionProvider,
                                closeModal,
                                codes,
                                commerceCode,
                                codeDescription,
                                reject,
                                rejectReason,
                                providerEdit,
                                isBank,
                                related,
                                percentage,
                                period,
                                usedInModals,
                              }) => (
  <div className={ usedInModals ? "main-for-modals" : ""} >
  <Panel
    headingText={title ? title : band ? t('commercialConditions') : editable ? t('edit') : t('commercialConditions')}>
    <Form
      submitText={band ? t('accept') : t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses={`mr-8px ${hidden === true ? 'is-hidden' : 'is-hidden'}`}
    >
      <div className="field is-horizontal" style={{ display: reject ? 'none' : '' }}>
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="rejectReason"
              label={t('rejectReason')}
              placeholder={t('rejectReason')}
              type="text"
              onChange={handleInputChange}
              value={rejectReason}
              isRequired={true}
            />
          </div>
        </div>
      </div>
      {/* <div className="field is-horizontal subtitle is-5 text-black">
        {t('billPayment')}
      </div>
      <div className="field is-horizontal subtitle is-4 text-black">
        {isClient ? t('providerPaymentCondition') : t('clientPaymentCondition')}
      </div> */}
      <div className="column" style={{ marginBottom: "1rem" }}>
        <h2 className="title is-4">
          {t('termsAndConditions')}
        </h2>
        <h3 className="subtitle is-3">
          {isClient ? t('providerPaymentCondition') : t('clientPaymentCondition')}
        </h3>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column" style={{ display: (editable ? 'none' : '') }}>
            <SelectSingle
                name={isClient ? 'provider' : 'client'}
                label={t(isClient ? 'provider' : 'customer')}
                placeholder={t('SelectOption')}
                onChange={handleSelectChange}
                options={handleOnInputChange === null || inputValue.length > 1 ? [
                  ...persons.map((person, index) => ({
                    label: `${person.idPerson.name} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], person)} ${person.idPerson.document_number}`,
                    value: index,
                  })),
                ] : [{}]}
                value={ isClient ? provider + 1 : client + 1 }
                selectClasses="is-fullwidth"
                controlClasses="has-icons-left"
                icon="far fa-user"
                iconPosition="is-left"
                isDisabled={editable}
                isSearchable
                onInputChange={handleOnInputChange}
                inputValue={inputValue}
              />
            </div>
            <div className="column" style={{ display: (editable ? '' : 'none') }}>
              <TextInput
                name="clientEdit"
                label={t(isClient ? 'provider' : 'customer')}
                value={isBank ? providerEdit : clientEdit}
                onChange={handleInputChange}
                placeholder="client"
                type="text"
                controlClasses="is-expanded"
                //min="0"
                disabled={editable}
              />
            </div>
          <div className="column" style={{ display: ((editable && isBank) ? '' : 'none') }}>
            <TextInput
              name="providerEdit"
              label={t(isClient ? 'customer' : 'provider')}
              value={clientEdit}
              onChange={handleInputChange}
              placeholder="client"
              type="text"
              controlClasses="is-expanded"
              //min="0"
              disabled={editable}
            />
          </div>
          <div className="column" style={{ display: ((editable && isBank) ? 'none' : '') }}>
            <TextInput
              name="baseDays"
              label={t('arrangedDays')}
              value={baseDays}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              //min="0"
              step="1"
              disabled={band}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal"
           style={{ display: ((editable && isBank) ? 'none' : 'none') }}>
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="baseDays"
              label={t('arrangedDays')}
              value={baseDays}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              //min="0"
              step="1"
              disabled={band}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column" style={{ display: ((editable && isBank) ? '' : 'none') }}>
            <TextInput
              name="baseDays"
              label={t('arrangedDays')}
              value={baseDays}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              //min="0"
              step="1"
              disabled={band}
            />
          </div>
          <div className="column">
            <SelectSingle
              name="startCountingFrom"
              label={t('startCountingFrom')}
              placeholder={t('SelectOption')}
              onChange={handleSelectChange}
              options={
                [{
                  label: t('receptionDate'),
                  value: 0,
                }, {
                  label: t('approvalDate'),
                  value: 1,
                }]}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="far fa-user"
              iconPosition="is-left"
              value={startCountingFrom}
              isClearable={false}
              isDisabled={band}
            />
          </div>
          <div className="column">
            <TextInput
              name="discountRate"
              label={t('financingRate')}
              value={discountRate}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              min="0"
              step="0.01"
              max="100"
              iconPosition="is-right"
              icon="fas fa-percent"
              disabled={band || isConditionClient || isProvider}
            />
          </div>
          {/*
              (editPercentage) == null && 
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <TextInput
                  name="discountRate"
                  label={t('chargeForService')}
                  value={discountRate}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>     
            */}
          {/*
              ((editPercentage > 0) && (editPercentage < 100)) &&
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <TextInput
                  name="discountRate"
                  label={t('chargeForService')}
                  value={discountRate}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
            */}
          {/*
              ((editPercentage == 0) && !isConditionClient) &&
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <TextInput
                  name="discountRate"
                  label={t('chargeForService')}
                  value={discountRate}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
            */}
          {/*
              ((editPercentage == 100) && !isConditionProvider) &&
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <TextInput
                  name="discountRate"
                  label={t('chargeForService')}
                  value={discountRate}
                  onChange={handleInputChange}
                  placeholder="0"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
            */}
        </div>
      </div>
        {/*
          (editPercentage == null) &&
          <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="receiverDiscountShare"
                  label={t('providerPercentageCharge')}
                  value={receiverDiscountShare}
                  onChange={handleInputChange/*handlePercentageChange}
                  placeholder="100"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                  {`${t('clientPercentageCharge')}:`}
                </div>
                {`${Number(clientPayment)
                  .toFixed(2)} %`}
              </div>
            </div>
          </div>
        */}
        {/*
          ((editPercentage > 0) && (editPercentage < 100)) &&
          <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="receiverDiscountShare"
                  label={t('providerPercentageCharge')}
                  value={receiverDiscountShare}
                  onChange={handleInputChange/*handlePercentageChange}
                  placeholder="100"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                  {`${t('clientPercentageCharge')}:`}
                </div>
                {`${Number(clientPayment)
                  .toFixed(2)} %`}
              </div>
            </div>
          </div>
        */}
        {/*
          ((editPercentage == 0) && !isConditionClient) &&
          <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="receiverDiscountShare"
                  label={t('providerPercentageCharge')}
                  value={receiverDiscountShare}
                  onChange={handleInputChange/*handlePercentageChange}
                  placeholder="100"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                  {`${t('clientPercentageCharge')}:`}
                </div>
                {`${Number(clientPayment)
                  .toFixed(2)} %`}
              </div>
            </div>
          </div>
        */}
        {/*
          ((editPercentage == 100) && !isConditionProvider) && 
          <div className="field is-horizontal" /*style={{ display: (hidden ? 'none' : '') }}>
            <div className="field-body columns">
              <div className="column">
                <TextInput
                  name="receiverDiscountShare"
                  label={t('providerPercentageCharge')}
                  value={receiverDiscountShare}
                  onChange={handleInputChange/*handlePercentageChange}
                  placeholder="100"
                  type="number"
                  controlClasses="is-expanded has-icons-right"
                  inputClasses="has-text-right"
                  min="0"
                  step="0.01"
                  max="100"
                  iconPosition="is-right"
                  icon="fas fa-percent"
                  disabled={band || isConditionClient || isProvider}
                />
              </div>
              <div className="column" /*style={{ display: (hidden ? 'none' : '') }}>
                <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
                  {`${t('clientPercentageCharge')}:`}
                </div>
                {`${Number(clientPayment)
                  .toFixed(2)} %`}
              </div>
            </div>
          </div>
        */}
        {/*<div className="field is-horizontal"
            style={{ display: (band ? '' : isProvider ? 'none' : '') }}>
          <div className="field-body columns">
            <div className="column">
              <TextInput
                name="limitAmount"
                label={t('limitAmount')}
                value={limitAmount}
                onChange={handleInputChange}
                placeholder="0.00"
                type="text"
                controlClasses="is-expanded has-icons-right"
                inputClasses="has-text-right"
                //min="0"
                //step="0.01"
                //max="999999999999999.99"
                iconPosition="is-right"
                icon="fas fa-money-bill-wave"
                disabled={band}
              />
            </div>
          </div>
        </div>*/}
      <div className="field is-horizontal" style={{ display: (hidden ? 'none' : '') }}>
        <div className="field-body columns">
          <div className="column">
            <TextInput
              name="receiverDiscountShare"
              label={t('receiverDiscountShare')}
              value={receiverDiscountShare}
              onChange={handlePercentageChange}
              placeholder={t('receiverDiscountShare')}
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              min="0"
              step="0.01"
              max="100"
              iconPosition="is-right"
              icon="fas fa-percent"
            />
          </div>
          <div className="column" style={{ display: (hidden ? '' : '') }}>
            <div className="field is-6 text-black" style={{ marginBottom: '12px' }}>
              {`${t('paymentClient')}:`}
            </div>
            {`${Number(clientPayment)
              .toFixed(2)} %`}
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name={'commerceCode'}
              label={t('commerceCode')}
              placeholder={t('SelectOption')}
              onChange={handleSelectChange}
              options={[
                ...codes.map((code, index) => ({
                  label: code.commerce_code,
                  value: index,
                })),
              ]}
              value={commerceCode}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="far fa-user"
              iconPosition="is-left"
              isDisabled={band}
            />
          </div>
          <div className="column">
            <div style={{ display: 'flex' }}>
              <p style={{ marginTop: '1.5em' }}>
                {codeDescription}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal subtitle is-4 text-black">
        {t('flexibleConditions')}
      </div>
      <div className="field is-horizontal">
        <div className="columns field-body">
          <div className="column">
            <TextInput
              name="percentage"
              label={t('interestRate')}
              value={percentage}
              onChange={handleInputChange}
              placeholder="0"
              type="number"
              controlClasses="is-expanded has-icons-right"
              inputClasses="has-text-right"
              min="0"
              step="0.01"
              iconPosition="is-right"
              icon="fas fa-percent"
            />
          </div>
          <div className="column">
            <SelectSingle
              name="period"
              label={t('period')}
              //placeholder={t('SelectOption')}
              onChange={handleSelectChange}
              options={
                [{
                  label: t('yearly'),
                  value: 0,
                }, {
                  label: t('monthly'),
                  value: 1,
                }, {
                  label: t('daily'),
                  value: 2,
                },]}
              value={period}
              selectClasses="is-fullwidth"
              controlClasses="has-icons-left"
              icon="far fa-user"
              iconPosition="is-left"
              isClearable={false}
            />
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <div className="field is-grouped is-grouped-right"
           style={{ display: hidden === false ? '' : 'none' }}>
        <button
          type="button"
          className={related ? 'button is-primary' : 'button is-primary is-danger'}
          onClick={closeModal.bind(this)}
        >
          {related ? t('accept') : t('cancel')}
        </button>
        &nbsp;&nbsp;
        <button
          style={{ display: related ? 'none' : '' }}
          type="button"
          className="button is-primary"
          onClick={onSubmit.bind(this)}
        >
          {t('save')}
        </button>
      </div>
    </Form>
    </Panel>
    </div>
  );

PaymentConditionForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleOnInputChange: PropTypes.func,
  handlePercentageChange: PropTypes.func,
  handleSelectChange: PropTypes.func.isRequired,
  isClient: PropTypes.bool,
  client: PropTypes.number,
  provider: PropTypes.number,
  persons: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  baseDays: PropTypes.number.isRequired,
  discountRate: PropTypes.number,
  receiverDiscountShare: PropTypes.number,
  //limitAmount: PropTypes.string.isRequired,
  clientPayment: PropTypes.number,
  editable: PropTypes.bool.isRequired,
  band: PropTypes.bool,
  hidden: PropTypes.bool,
  inputValue: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  usedInModals: PropTypes.bool,
};

PaymentConditionForm.defaultProps = {
  related: false,
  reject: true,
  isBank: false,
  isClient: false,
  client: null,
  provider: null,
  handleOnInputChange: null,
  inputValue: '',
  usedInModals: false,
};


export default PaymentConditionForm;
