import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Iframe } from '../../components';
import { webpayActions } from '../../state/ducks/webpay';
import './styles.scss';
import Panel from '../../components/Panel';

class RegisterCard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {
      accessToken,
    } = this.props;
    accessToken({});
  }

  //createTransaction = () => {
  // parent.document.location.href = '/dashboard';
    //window.parent.window.location.href = '/dashboard';
    //window.top.location.replace('/dashboard')
    //const { webpay, getWebpay, history } = this.props;
    //getWebpay({
    /*callback: () => {
      window.location.assign(webpay.list.url);
    }*/
    //});
//    const url = webpay.list.url;
  // }

  getUrl = () => {
    const arrays = window.location.href.split(window.location.pathname);
    return arrays[0];
  };

  render() {
    const {
      auth: { language },
      t,
      webpay: { accessToken },
      router: { location: { pathname } },
    } = this.props;
    let idCardReplace = -1;
    if (pathname === '/replace-card') {
      const {
        userInfo
      } = this.props.location.state;
      idCardReplace = userInfo;
    }
    const cvv = true,
      type = pathname === '/replace-card' ? 1 : 0,
      idCard = pathname === '/replace-card' ? idCardReplace : 0,
      //languageForm = language,
      languageForm = language === 'en' ? 'en' : 'ca',
      url = this.getUrl() + `/resp?cardToken={cardToken}&cardNumber={cardNumber}&expiration={expiration}&type=${type}&idCard=${idCard}`,
      success = encodeURIComponent(url);
    return (
      <Panel headingText={t('registerCardAmex')}>
        <div className="column is-full" style={{ height: '500px' }}>
          <Iframe
            width={'100%'}
            src={`https://service.pcibooking.net/api/payments/capturecard?accessToken=${accessToken}&brand=medularisdev_sandbox&language=${languageForm}&success=${success}&css=medullaris_styles&cvv=${cvv}&cardTypes=amex&same-origin&defaultCardType=amex&removeBaseCss=false&autoDetectCardType=false&showOwnerId=false&autoFocus=true&ThreeDS=false&UnavailThreeDSAuth=Accept`}>
          </Iframe>
        </div>
      </Panel>
    );
  }
}

RegisterCard.propTypes = {
  t: PropTypes.func.isRequired,
  getWebpay: PropTypes.func.isRequired,
  accessToken: PropTypes.func.isRequired,
};

const mapStateToProps = ({ webpay, router, }) => (
  {
    webpay,
    router,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...webpayActions,
  }),
  withNamespaces(),
)(RegisterCard);