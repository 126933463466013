import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Modal, Toggle } from '../../components';
import { CreditRequestForm, ConfirmCredit } from './components';

const CreditRequest = ({ credits: { usedFor }, t, }) => {
  
    const [credit, setCredit] = useState({
        accountNumber: 'AE120030000852114569874',
        // availableAmount,
        amount: null, 
        installments: 1,
        charge: 0,
        installmentAmount: 0,
    });
    const [step, setStep] = useState('CreditRequestForm')
    const handleForm = value => setCredit({ ...credit, ...value })
    
    return (
        <Toggle>
            {({toggled, onToggle}) => {
                return (
                    <>
                    {
                        toggled ? (
                            <Modal 
                              onToggle={onToggle}
                              title={t('approvedTransaction')}>
                                <p style={{ marginTop:'20px', fontSize: '1.1em', textAlign: 'center'}}> 
                                    {t('referenceNumber')}: TPS2005466YIJKL
                                </p>
                                <div className='modal-action-btns'>
                                    <Link 
                                      to={
                                        usedFor === 'creditLine' ? '/credits-list' : 
                                        (usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment') ? '/review-invoices' : '/approved-invoices'} 
                                      className='button custom-btn is-danger'>
                                        {t('return')}
                                    </Link>
                                    <button className='button custom-btn is-primary'>{t('print')}</button>
                                </div>
                            </Modal>
                        ) : step === 'ConfirmCredit' ? (
                            <>
                            <div className='Panel__extra-title-main'>
                                {usedFor === 'creditLine' && <p>{t('creditRequest')}</p>}
                                {(usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment') && <p>{t('creditRequestForInvoice')}</p>}
                                {(usedFor === 'advanceWithCreditLine') && <p>{t('creditRequestForAdvance')}</p>}
                                {(usedFor === 'advanceWithLoan') && <p>{t('loanRequest')}</p>}
                            </div>
                            <ConfirmCredit handleStep={setStep} credit={credit} onToggle={onToggle}/>
                            </>
                        ) : (
                            <>
                            <div className='Panel__extra-title-main'>
                                {usedFor === 'creditLine' && <p>{t('creditRequest')}</p>}
                                {(usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment') && <p>{t('creditRequestForInvoice')}</p>}
                                {(usedFor === 'advanceWithCreditLine') && <p>{t('creditRequestForAdvance')}</p>}
                                {(usedFor === 'advanceWithLoan') && <p>{t('loanRequest')}</p>}

                            </div>
                            <CreditRequestForm onToggle={onToggle} handleStep={setStep} handleForm={handleForm} credit={credit}/>
                            </>
                        )
                    }
                    </>
                )
            }}
        </Toggle>
    );
};

const mapStateToProps = ({ credits }) => (
    {
      credits,
    }
);

export default compose(
    connect(mapStateToProps),
    withNamespaces()
  )(CreditRequest);