import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, TableSimple, Toggle, } from '../../components';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';
import Modal from '../../components/Modal';
import { TOAST_CONFIG } from '../../config/constants';
import Form from '../../components/Form';
import TextArea from '../../components/TextArea';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { statsActions } from '../../state/ducks/stats';
import './styles.scss';
import { providersActions } from '../../state/ducks/providers';
import PaymentConditionForm from '../../components/PaymentConditionForm';

class Request extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    id: '',
    name: '',
    roleType: '',
    conditionList: [],
    reject: false,
    is_edit: false,
    startCountingFrom: -1,
    baseDays: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    commerceCode: -1,
    codeDescription: '',
    codes: [],
  });

  componentDidMount() {
    const {
      requestBank,
      providerCommerceCode,
      auth: {
        user: {
          b2b: { id, type_user, roles },
          profile: { role },
        },
      },
    } = this.props;

    if(role === 'seller_assist_card'){
      requestBank({
        params: {
          idBank: id,
          is_bank: false,
        },
        callback: (response) => {
          this.setState({
            conditionList: response,
          });
        }
      });
    } else {
      providerCommerceCode({
        params: {
          user: id,
          role: roles[0].id,
        },
        callback: (response) => {
          this.setState({
            codes: response.commerce_codes,
          });
          requestBank({
            params: {
              idBank: id,
            },
            callback: (response) => {
              this.setState({
                conditionList: response,
              });
            }
          });
        },
      });
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns;
    let data;
    let title;
    const {
      codes,
      conditionList,
    } = this.state;
    const {
      t,
      auth: {
        user: {
          b2b: { type_user, id },
          profile: { role },
        },
      },
      providerCommerceCode,
      deletePaymentCondition,
      fetchStats,
      paymentRequest,
      requestBank,
      showMessage,
      approveProductPayment,
      unsuscribeProductPayment,
      paymentConditions: { list, listReceiver },
      router: { location: { pathname } },
    } = this.props;

    /*let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }*/

    switch (pathname) {
      case '/payment-request-received':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'requestingBank', // Required because our accessor is not a string
            Header: t('requestingBank'),
            show: (role === 'buyer' || role === 'seller' || role == 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller' || role == 'seller_assist_card') ? null : `${d.bankSender.idPerson.name} `,
          },
          {
            id: 'person_commerce_code', // Required because our accessor is not a string
            Header: t('affiliateNumber'),
            show: (role === 'seller'),
            headerClassName: 'wordwrap',
            accessor: d => ((role === 'seller') && d.person_commerce_code !== null) ?
              codes[codes.findIndex(function (code) {
                return code.id === d.person_commerce_code;
              })].commerce_code : '',
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('buyer'),
            show: (role === 'buyer') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer') ? null : `${d.sender.idPerson.name} `,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            show: (role === 'seller' || role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller' || role === 'seller_assist_card') ? null : `${d.receiver.idPerson.name} `,
          },
          {
            id: 'rec_reason', // Required because our accessor is not a string
            Header: t('rejectReason'),
            show: (role !== 'seller_assist_card'),
            headerClassName: 'wordwrap',
            accessor: d => d.rec_reason !== null ? `${d.rec_reason}` : '',
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.days} `,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => ((d.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          /*{
            id: 'limit', // Required because our accessor is not a string
            Header: t('limit'),
            show: (role == 'seller_assist_card') ? false : true,
            accessor: d => `${formatCurrencyCl(d.limit_amount)} `,
          },
          {
            id: 'discountRate', // Required because our accessor is not a string
            Header: ((type_user === 'acquiring_bank') || (type_user === 'issuing_bank')) ? t('mdr') : type_user === 'seller' ? t('chargeForService') : t('chargeForService'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.discount_rate} %`,
          },
          {
            id: 'customerPayment', // Required because our accessor is not a string
            Header: t('clientPercentageCharge'),
            show: (role === 'seller_assist_card') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => `${d.percentage.slice(0, -4)} %`,
          },*/
          {
            id: 'product', // Required because our accessor is not a string
            Header: t('product'),
            show: (role === 'seller_assist_card') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller_assist_card') ? `${d.product.name}` : '',
          },
          {
            id: 'productType', // Required because our accessor is not a string
            Header: t('productType'),
            show: (role === 'seller_assist_card') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (d.request_unsubscribe_product === 0) ? `${t('contractRequest')}` : `${t('unsuscribeRequest')}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      className="button is-danger is-inverted"
                      onClick={() => {
                        if (role !== 'seller_assist_card') {
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.receiver_id,
                              role: 4,//role_id,
                            },
                            callback: (response) => {
                              console.dir('response===>');
                              console.log(response);
                              this.setState({
                                reject: false,
                                is_edit: false,
                                rejectReason: '',
                              });
                              onToggle({
                                codes: response.commerce_codes,
                                commerceCode: response.commerce_codes.length > 0 ? response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                }) : '',
                                codeDescription: response.commerce_codes.length > 0 ? response.commerce_codes[response.commerce_codes.findIndex(function (code) {
                                  return code.id === cellInfo.original.person_commerce_code;
                                })].description : '',
                                transaction: cellInfo.original.id,
                                infoPayment: cellInfo.original,
                                onSubmit: this.rejectInvoice
                              });
                            }
                          });
                        } else {
                          if (cellInfo.original.request_unsubscribe_product == 0) {
                            approveProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                approved: false,
                              },
                              callback: (resp) => {
                                fetchStats({});
                                showMessage({
                                  message: t('applicationRejected'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                //this.resetState();
                                requestBank({
                                  params: {
                                    idBank: id,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                //window.location.reload(false);
                              }
                            });
                          }else{
                            onToggle({
                              product: cellInfo.original.id,
                              onSubmit: this.rejectInvoice,
                            });
                          }
                        }
                      }}
                    >
                      <span className="icon" title={t('reject')}>
                        <i className="fas fa-times"/>
                      </span>
                    </button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        if(role !== 'seller_assist_card'){
                          paymentRequest({
                            data: {
                              id: cellInfo.original.id,
                              approved: true,
                              reason: '',
                            },
                            callback: (resp) => {
                              requestBank({
                                params: {
                                  idBank: id,
                                },
                                callback: (response) => {
                                  this.setState({
                                    conditionList: response,
                                  });
                                }
                              });
                              if (resp) {
                                showMessage({
                                  message: t('applicationSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                fetchStats({});
                              } else {
                                showMessage({
                                  message: t('actionNotAllowed'),
                                  config: TOAST_CONFIG.ERROR,
                                });
                              }
                              //onToggle();
                            },
                          });
                          //this.rejectInvoice('approve', cellInfo.original.id, '', onToggle);
                        }else{
                          if(cellInfo.original.request_unsubscribe_product == 0){
                            approveProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                approved: true,
                              },
                              callback: (resp) => {
                                fetchStats({});
                                showMessage({
                                  message: t('applicationSuccess'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                requestBank({
                                  params: {
                                    idBank: id,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                /*this.resetState();
                                window.location.reload(false);*/
                              }
                            });
                          }else{
                            unsuscribeProductPayment({
                              data: {
                                id: cellInfo.original.id,
                                approved: true,
                                rec_reason: '',
                              },
                              callback: (resp) => {
                                fetchStats({});
                                showMessage({
                                  message: t('productUnsubscribedSuccessfully'),
                                  config: TOAST_CONFIG.SUCCESS,
                                });
                                requestBank({
                                  params: {
                                    idBank: id,
                                    is_bank: false,
                                  },
                                  callback: (response) => {
                                    this.setState({
                                      conditionList: response,
                                    });
                                  }
                                });
                                /*this.resetState();
                                window.location.reload(false);*/
                              }
                            })
                          }
                        }

                      }}
                    >
                      <span className="icon" title={t('approve')}>
                        <i className="fas fa-check"/>
                      </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = conditionList;
        title = t('requestReceived'); //type_user === 'seller' ? t('requestsReceived') : t('requestReceived');
        break;
      case '/payment-request-sent':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'requestingBank', // Required because our accessor is not a string
            Header: t('requestingBank'),
            show: (role === 'buyer' || role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer' || role === 'seller') ? null : `${d.bankReceiver.idPerson.name} `,
          },
          {
            id: 'person_commerce_code', // Required because our accessor is not a string
            Header: t('affiliateNumber'),
            show: (role === 'seller') ? true : false,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller' && d.person_commerce_code !== null) ?
              codes[codes.findIndex(function (code) {
                return code.id === d.person_commerce_code;
              })].commerce_code : '',
          },
          {
            id: 'customer', // Required because our accessor is not a string
            Header: t('buyer'),
            show: (role === 'buyer') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'buyer') ? null : `${d.sender.idPerson.name} `,
          },
          {
            id: 'provider', // Required because our accessor is not a string
            Header: t('provider'),
            show: (role === 'seller') ? false : true,
            headerClassName: 'wordwrap',
            accessor: d => (role === 'seller') ? null : `${d.receiver.idPerson.name} `,
          },
          {
            id: 'arrangedDays', // Required because our accessor is not a string
            Header: t('arrangedDays'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.days} `,
          },
          {
            id: 'startCountingFrom', // Required because our accessor is not a string
            Header: t('startCountingFrom'),
            headerClassName: 'wordwrap',
            accessor: d => ((d.due_from_approval === '1') ? t('approvalDate') : t('receptionDate')),
          },
          /*{
            id: 'limit', // Required because our accessor is not a string
            Header: t('limit'),
            accessor: d => `${formatCurrencyCl(d.limit_amount)} `,
          },*/
          /*{
            id: 'discountRate', // Required because our accessor is not a string
            Header: ((type_user === 'acquiring_bank') || (type_user === 'issuing_bank'))  ? t('mdr') : type_user === 'seller' ? t('chargeForService') : t('chargeForService'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.discount_rate} %`,
          },
          {
            id: 'percentage', // Required because our accessor is not a string
            Header: t('clientPercentageCharge'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.percentage.slice(0, -4)} %`,
          },*/
          {
            id: 'status', // Required because our accessor is not a string
            Header: t('status'),
            accessor: d => `${d.status.description} `,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-danger is-inverted"
                      onClick={() => {
                        deletePaymentCondition({
                          data: {
                            id: cellInfo.original.id,
                            is_request: true,
                          },
                          callback: (resp) => {
                            requestBank({
                              params: {
                                idBank: id,
                              },
                              callback: (response) => {
                                this.setState({
                                  conditionList: response,
                                });
                              }
                            });
                            if (resp === 'Deleted') {
                              showMessage({
                                message: t('applicationDeleted'),
                                config: TOAST_CONFIG.SUCCESS,
                              });
                            } else {
                              showMessage({
                                message: t('actionNotAllowed'),
                                config: TOAST_CONFIG.ERROR,
                              });
                            }
                          },
                        });
                      }}
                    >
                      <i className="fas fa-times mr-8px" aria-hidden="true"/>
                      {t('delete')}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        data = list;
        title = t('requestSent');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  rejectInvoice = (type, transaction, product, rejectReason, onToggle) => {
    const {
      t,
      fetchStats,
      showMessage,
      paymentRequest,
      requestBank,
      auth: {
        user: {
          b2b: { id },
          profile: { role },
        },
      },
      unsuscribeProductPayment,
      history,
    } = this.props;
    if (type === 'rejectData') {
      this.setState({ reject: true });
    } else {
      if (role === 'seller_assist_card') {
        const data = {
          id: product,
          approved: false,
          rec_reason: rejectReason,
        };
        unsuscribeProductPayment({
          data,
          callback: (resp) => {
            //this.resetState();
            onToggle();
            fetchStats({});
            showMessage({
              message: t('applicationRejected'),
              config: TOAST_CONFIG.SUCCESS,
            });
            requestBank({
              params: {
                idBank: id,
                is_bank: false,
              },
              callback: (response) => {
                this.setState({
                  conditionList: response,
                });
              }
            });
            //window.location.reload(false);
          }
        });
      } else {
        const data = {
          id: transaction,
          approved: false,
          reason: rejectReason,
        };

        if (type !== 'approve') {
          if (rejectReason !== undefined) {
            paymentRequest({
              data,
              callback: (resp) => {
                requestBank({
                  params: {
                    idBank: id,
                    received: true
                  },
                  callback: (response) => {
                    this.setState({
                      conditionList: response,
                    });
                  }
                });
                if (resp) {
                  fetchStats({});
                  onToggle();
                  showMessage({
                    message: t('applicationRejected'),
                    config: TOAST_CONFIG.SUCCESS,
                  });
                  history.push('/dashboard');
                  this.setState({
                    rejectReason: '',
                    reject: false,
                    is_edit: false,
                  });
                  //this.setState({ rejectReason: '' });
                } else {
                  showMessage({
                    message: t('actionNotAllowed'),
                    config: TOAST_CONFIG.ERROR,
                  });
                }
                onToggle();
              },
            });
          } else {
            showMessage({
              message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      }
    }
  };

  saveRequest = (onToggle, transaction) => {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user, id },
        },
      },
      t,
      showMessage,
      requestPaymentCondition,
      paymentRequest,
      fetchStats,
      requestBank,
    } = this.props;

    const {
      //id,
      baseDays,
      startCountingFrom,
      //limitAmount,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      rejectReason,
      commerceCode,
      codes,
    } = this.state;

    const data = {
      id: transaction.id,
      approved: false,
      reason: rejectReason,
    };

    const conditionData = {
      bank_sender_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? true : null,
      sender_request_id: null,
      sender_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? transaction.sender_id :
        type_user === 'seller' ? transaction.sender_id : user_id,
      receiver_id: (type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? transaction.receiver_id :
        type_user === 'seller' ? user_id : transaction.receiver_id,
      days: baseDays,
      due_from_approval: startCountingFrom,
      discount_rate: discountRate,
      percentage: clientPayment,//percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      rec_reason: rejectReason,
      person_commerce_code: codes[commerceCode].id,
      type: 'base',
    };

    paymentRequest({
      data,
      callback: () => {
        requestPaymentCondition({
          data: conditionData,
          callback: () => {
            onToggle();
            fetchStats({});
            showMessage({
              message: t('editionRequestSent'),
              config: TOAST_CONFIG.SUCCESS,
            });
            requestBank({
              params: {
                idBank: id,
                is_bank: (type_user === 'issuing_bank' || type_user === 'acquiring_bank'),
              },
              callback: (response) => {
                this.setState({
                  conditionList: response,
                });
              }
            });
          },
        });
      }
    });
  };

  handleSelectChange = (newVal, label) => {
    const { codes } = this.state;

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: /*type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].id
          :*/ codes[newVal.value].id,
        codeDescription: /*type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].description
          :*/ codes[newVal.value].description,
      });
    }
    this.setState({
      [label.name]: newVal.value,
    });
  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  render() {
    const {
      t,
      stats: { loading: statsLoading },
      paymentConditions: { loading: paymentLoading },
      providers: { receiver: providerList, loading: providerLoading },
      auth: { user: { b2b: { type_user }, } }
    } = this.props;
    const {
      rejectReason,
      conditionList,
      reject,
      is_edit,
      startCountingFrom,
      baseDays,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      codes,
      commerceCode,
      codeDescription,
    } = this.state;
    const loading = paymentLoading || statsLoading || providerLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div className="dashboard">
              {loading && <Loading/>}
              <div className="columns is-multiline">
                <Fragment>
                  {toggled && is_edit === true
                  && (
                    <Modal onToggle={onToggle}
                           className={(type_user === 'issuing_bank' || type_user === 'acquiring_bank') ? 'wider-modal' : ''}>{loading &&
                    <Loading/>}
                      <PaymentConditionForm
                        t={t}
                        onSubmit={() => this.saveRequest(onToggle, toggleProps.infoPayment)}
                        handleInputChange={this.handleInputChange}
                        isClient={type_user !== 'seller'}
                        isProvider={(type_user === 'buyer' || type_user === 'seller')}
                        isBank={(type_user === 'issuing_bank' || type_user === 'acquiring_bank')}
                        handleSelectChange={this.handleSelectChange}
                        persons={[]}
                        client={'client'}
                        provider={'provider'}
                        baseDays={baseDays}
                        discountRate={discountRate}
                        receiverDiscountShare={receiverDiscountShare}
                        clientPayment={clientPayment}
                        startCountingFrom={startCountingFrom}
                        clientEdit={(type_user === 'issuing_bank' || type_user === 'acquiring_bank')
                          ? toggleProps.infoPayment.sender.idPerson.name :
                          type_user === 'buyer' ? toggleProps.infoPayment.receiver.idPerson.name :
                            toggleProps.infoPayment.sender.idPerson.name}
                        providerEdit={(type_user === 'issuing_bank' || type_user === 'acquiring_bank') ?
                          toggleProps.infoPayment.receiver.idPerson.name : ''}
                        codes={codes}
                        commerceCode={commerceCode}
                        codeDescription={codeDescription}
                        editable={true}
                        hidden={false}
                        closeModal={() => this.closeModal(onToggle)}
                        rejectReason={rejectReason}
                        reject={false}
                      />
                    </Modal>
                  )}
                  {
                    (toggled && is_edit === false)
                    && (
                      <Modal onToggle={onToggle}>{loading && <Loading/>}
                        <Panel
                          headingText={reject === true ? t('rejectReason') : t('rejectTradeRequest')}>
                          <div className="FormLogin">
                            <div style={{ display: reject === true ? '' : 'none' }}>
                              <Form
                                className="column is-full"
                                submitText={t('send')}
                                onSubmit={(event) => {
                                  event.preventDefault();
                                  this.rejectInvoice('reject', toggleProps.transaction, toggleProps.product, rejectReason, onToggle);
                                }}
                                buttonClasses="mr-8px"
                              >
                                <TextArea
                                  name="rejectReason"
                                  value={rejectReason}
                                  onChange={this.handleInputChange}
                                  placeholder=""
                                  controlClasses="is-expanded"
                                  maxLength="120"
                                />
                                <br/>
                              </Form>
                            </div>
                            <div className="field is-horizontal"
                                 style={{ display: reject === false ? '' : 'none' }}>
                              <div className="field-body columns" style={{ textAlign: 'center' }}>
                                <div className="column">
                                  <label style={{
                                    color: 'black',
                                    fontFamily: 'Roboto, Arial, sans-serif'
                                  }}>
                                    {t('permanentlyRejectCommercialStatus')}</label>
                                  <div>
                                    <a className="button is-primary"
                                       onClick={() => this.rejectInvoice('rejectData', toggleProps.infoPayment, toggleProps.product, rejectReason, onToggle)}
                                    >
                                      <i className="fas fa-times" aria-hidden="true"/>
                                      &nbsp;
                                      {t('reject')}
                                    </a>
                                  </div>
                                </div>
                                <div className="column">
                                  <label style={{
                                    color: 'black',
                                    fontFamily: 'Roboto, Arial, sans-serif'
                                  }}>
                                    {t('suggestTradeConditionCorrection')}</label>
                                  <div>
                                    <a className="button is-primary" onClick={() => this.setState({
                                      is_edit: true,
                                      reject: true,
                                      startCountingFrom: toggleProps.infoPayment.due_from_approval,
                                      baseDays: toggleProps.infoPayment.days,
                                      discountRate: Number(toggleProps.infoPayment.discount_rate)
                                        .toFixed(2),
                                      receiverDiscountShare: (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2), /*(type_user === 'issuing_bank' || type_user === 'acquiring_bank') && toggleProps.infoPayment.rec_reason === null ?
                                       (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2) :
                                       toggleProps.infoPayment.receiver_discount_share,*/ // (Number(toggleProps.infoPayment.receiver_discount_share) * 100).toFixed(2),
                                      clientPayment: 100 - Number(toggleProps.infoPayment.receiver_discount_share * 100), /* (type_user === 'issuing_bank' || type_user === 'acquiring_bank') && toggleProps.infoPayment.rec_reason === null ?
                                       100 - Number(toggleProps.infoPayment.receiver_discount_share * 100) :
                                       100 - toggleProps.infoPayment.receiver_discount_share,*/// Number(toggleProps.infoPayment.receiver_discount_share * 100),
                                      codes: toggleProps.codes,
                                      commerceCode: toggleProps.commerceCode,
                                      codeDescription: toggleProps.codeDescription,
                                    })}
                                    >
                                      <i className="far fa-edit" aria-hidden="true"/>
                                      &nbsp;
                                      {t('edit')}
                                    </a>
                                  </div>
                                </div>
                                <br/>
                              </div>
                              <br/>
                            </div>
                          </div>
                        </Panel>
                      </Modal>
                    )}
                  {data
                  && (
                    <div className="column is-full">
                      <Panel headingText={title}>
                        <TableSimple data={conditionList} columns={columns}/>
                      </Panel>
                    </div>
                  )}
                </Fragment>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

Request.propTypes = {
  t: PropTypes.func.isRequired,
  requestBank: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchTypesRole: PropTypes.func.isRequired,
  paymentRequest: PropTypes.func.isRequired,
  editRole: PropTypes.func.isRequired,
  role: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ stats, role, auth, router, paymentConditions, providers, }) => (
  {
    stats,
    role,
    auth,
    router,
    paymentConditions,
    providers,
  }
);
export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions, ...paymentConditionsActions, ...statsActions, ...providersActions, }),
  withNamespaces(),
)(Request);