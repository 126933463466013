import React from 'react';
import PropTypes from 'prop-types';
// import './styles.scss';
import { compose } from "redux";
import { withNamespaces } from "react-i18next";

const Form = ({
                className,
                onSubmit,
                children,
                submitText,
                buttonClasses,
                disabled,
                band,
                hasCancelBTN,
                handleCancel,
                t,
                isBTNFull,
              }) => (
  <div className={ `Form ${className}` }>
    <form
      action=""
      onSubmit={ onSubmit }
    >
      { children }
      <div
        // className={`field is-grouped is-grouped${band === '#FFFFFF' ? '-right' : '-centered'} `}
        className='field is-grouped is-grouped-centered'
        style={{ width: !isBTNFull && '50%', margin: '30px auto' }}
      >
        {hasCancelBTN &&
        <button
            type="button"
            style={{ color: '#C4C4C4', flex: '1', maxWidth: '200px' }}
            className={`button custom-btn is-block is-danger ${buttonClasses}`}
            onClick={handleCancel}
        >
            {t("cancel")}
        </button>
        }
        <button
          type="submit"
          style={{ color: band, flex: '1', maxWidth: !isBTNFull && '200px' }}
          className={`button custom-btn is-block is-primary ${buttonClasses}`}
          disabled={ disabled }
        >
          { submitText }
        </button>
      </div>
    </form>
  </div>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  buttonClasses: '',
  disabled: false,
  band: '#FFFFFF',
};

// export default Form;
export default compose(
  // connect(mapStateToProps, {
  //     ...clientsActions,
  //     ...providersActions,
  // }),
  withNamespaces()
)(Form);

