import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const saveCredit = createAction(types.SAVE_CREDIT);
const updateAmounts = createAction(types.UPDATE_AMOUNTS);
const setUsedFor = createAction(types.SET_USED_FOR);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  saveCredit,
  updateAmounts,
  setUsedFor,
  showMessage,
  startFetch,
  endFetch,
  endError,
};
