import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

const DataBox = ({ icon, bg, text, value, size }) => {
	return (
		<>
			<div className={`column ${size}`}>
				<div className="box-data">
					<div className="box-content">
						<div className={`box-icon ${bg}`}>
							<i className={icon} />
						</div>
						<div className="box-text">
							{text}
						</div>
						<div className="box-value">
							{value}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

DataBox.propTypes = {
  icon: PropTypes.string.isRequired,
  bg: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
};

export default DataBox;