import React, { Component } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { BankForm, Loading } from '../../components';
import { TOAST_CONFIG } from '../../config/constants';
import { documentTypeActions } from '../../state/ducks/documentType';
import { providersActions } from '../../state/ducks/providers';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { roleActions } from '../../state/ducks/role';
import { clientsActions } from '../../state/ducks/clients';
import { gatewayActions } from '../../state/ducks/gateway';
import Resizer from 'react-image-file-resizer';

class CreateBank extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    bank_id: '',
    registerLoading: false,
    bankType: -1,
    name: '',
    documentType: -1,
    documentNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'flecha',
    agency_user_type: 'business_admin',
    b2b: {
      bank_id: null,
      admin: 0,
      issuing_bank: 0,
      acquiring_bank: 0,
      seller: 0,
      buyer: 0,
    },
    gateways: [],
    gateway: null,
    isIssuing: true,
    file: '',
    binaryFile: '',
    tdc_bin: [{
      bin: '',
    }],
    bankName: '',
  });

  componentDidMount() {
    const {
      fetchDocumentTypes, fetchRoles, getGateway,
      router: { location: { pathname } },
    } = this.props;
    if (pathname === '/edit-bank-admin') {
      const {
        userInfo
      } = this.props.location.state;
    }

    fetchRoles({
      params: {
        type: 'bank',
        name: 'general_admin',
      },
    });
    getGateway({
      callback: (response) => {
        if (pathname === '/edit-bank-admin') {
          const {
            userInfo
          } = this.props.location.state;
          let category = pathname === '/edit-bank-admin' &&
          (userInfo.roles[0].name === 'general_admin' || userInfo.roles[0].name === 'general_admin_acquiring') ?
            (response.map(
              (documentTypeMap, index) => ({
                ...documentTypeMap,
                index
              })
            )
              .filter(documentTypeFil => documentTypeFil.id
                === userInfo.gateway[0].gateway_id)[0].index) : null;
          this.setState({
            gateways: response,
            gateway: category,
          });
        } else {
          this.setState({ gateways: response });
        }
      }
    });

    if (pathname === '/edit-bank-admin') {
      const {
        userInfo
      } = this.props.location.state;
      this.setState(this.arrayBank(userInfo));
    }
  }

  arrayBank = (userInfo) => {
    const {
      role: { list: roleList },
    } = this.props;
    const {
      gateways,
    } = this.state;

    return {
      bank_id: userInfo.id,
      name: userInfo.idPerson.name,
      documentType: 'RUT',
      documentNumber: userInfo.idPerson.document_number,
      bankName: roleList.map(
        (roleMap, index) => ({
          ...roleMap,
          index
        }),
      )
        .filter(roleFil => roleFil.id
          === Number(userInfo.roles[0].id))[0].name,
      bankType: roleList.map(
        (roleMap, index) => ({
          ...roleMap,
          index
        }),
      )
        .filter(roleFil => roleFil.id
          === Number(userInfo.roles[0].id))[0].index,
      email: userInfo.idPerson.idEmailActive.email,
      originalEmail: userInfo.idPerson.idEmailActive.email,
      tdc_bin: userInfo.tdc_bin.length > 0 ? userInfo.tdc_bin
        :
        [{
          bin: '',
        }],
      gateway: userInfo.gateway.length > 0 ? userInfo.gateway[0].gateway.name : '',
      /*tdc_bin: [{
        bin: '',
      }],*/
    };
  };

  resetState = () => {
    this.setState(this.getInitialState());
  };

  addCardBin = () => {
    const {
      tdc_bin,
    } = this.state;
    const {
      showMessage,
      t,
    } = this.props;
    let count = 0,
      i = 1,
      variable;
    count = tdc_bin.length + i;

    if (tdc_bin[tdc_bin.length - 1].bin === '') {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyAll')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {
      variable = {
        bin: '',
      };
      this.state.tdc_bin.push(variable);
    }

    this.setState({
      countCycleCard: count
    });
  };

  deleteCard = (index) => {
    const {
      tdc_bin,
    } = this.state;
    this.setState(tdc_bin.splice(index, 1));
  };

  handleInputChangeBin = (event) => {
    const {
      router: { location: { pathname } },
    } = this.props;
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    const bin = name.split('-');
    let tdc_bin = [...this.state.tdc_bin];

    switch (pathname) {
      case '/register-bank':
        tdc_bin[bin[1]] = {
          //id: Number(bin[1]),
          bin: value,
        };
        break;
      case '/edit-bank-admin':
        tdc_bin[bin[1]] = {
          bank_id: tdc_bin[bin[1]].bank_id,
          id: tdc_bin[bin[1]].id,
          bin: value,
          status: '1',
        };
        break;
    }
    this.setState({ tdc_bin });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    if (name === 'documentNumber') {
      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';

      if (num.length >= 9) {
        prenum = num.slice(0, 8);
        postnum = num.slice(8, 9);
        num = prenum + '-' + postnum;
      }

      this.setState({
        [name]: (num),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  };

  formatRUT = (n) => {
    return n.replace(/[^0-9Kk]/, '');
    //return n.replace(/[^a-zA-Z0-9]/, '');
  };

  handleSelectChange = (newVal, label) => {
    const { tdc_bin, gateway } = this.state;
    const { role: { list: roleList } } = this.props;

    if (label.name === 'bankType') {
      this.setState({
        [label.name]: newVal.value,
        bankName: newVal.value !== '' ? roleList[newVal.value].name : '',
        gateway: roleList[newVal.value].name === 'general_admin_issuing' || roleList[newVal.value].name === 'general_admin_issuing_amex' ? -1 : gateway,
        tdc_bin: roleList[newVal.value].name === 'general_admin_acquiring' ? [{
          bin: '',
        }] : tdc_bin,
      });
    } else {
      this.setState({
        [label.name]: newVal.value,
      });
    }

    /*for(let i = 0; i < roleList.length; i++){
      if(newVal.value == i){
        rolename = roleList[i].name;
        if(rolename === "main_bank_issuing"){
          this.setState({isIssuing: true});
        }else{
          this.setState({isIssuing: false});
        }
      }
    }*/
  };

  validateEmptyFields = () => {
    const {
      name,
      bankType,
      documentNumber,
      email,
    } = this.state;

    if (name === '' || documentNumber === '' || bankType === -1
      || email === '') {
      return false;
    }
    return true;
  };

  createProvider = () => {
    const {
      createProvider,
      showMessage,
      t,
      history,
      role: { list: roleList },
    } = this.props;

    const {
      name,
      bankType,
      documentType,
      documentNumber,
      email,
      password,
      confirmPassword,
      gateways,
      gateway,
      binaryFile,
      tdc_bin,
    } = this.state;

    if (this.validateEmptyFields()) {
      //if (password === confirmPassword) {
      let data;
      const dataForm = new FormData();
      let object = [];
      tdc_bin.forEach((item) => {
        if (item.bin !== '') {
          object.push(item.bin);
        }
      });

      data = {
        name,
        document_type: 'RUT',
        document_number: documentNumber,
        email,
        password,
        user_type: 'flecha',
        agency_user_type: 'business_admin',
        items: [],
        role_name: roleList[bankType].id,
        b2b: {
          bank_id: null,
          admin: 0,
          issuing_bank: 0,
          acquiring_bank: 0,
          [roleList[bankType].name]: 1,
          seller: 0,
          buyer: 0,
        },
        resource: binaryFile,
        gateway_id: gateway === -1 ? null : gateways[gateway].id,
        tdc_bin: object,
      };

      if (binaryFile) {
        for (let key in data) {
          if (typeof (data[key]) === 'object' && key !== 'resource') {
            if (key === 'tdc_bin') {
              for (let i = 0; i < tdc_bin.length; i++) {
                dataForm.append('tdc_bin[]', object[i]);
              }
            } else {
              for (let subKey in data[key]) {
                dataForm.append(`${key}.${subKey}`, data[key][subKey]);
              }
            }
          } else {
            dataForm.append(key, data[key]);
          }
        }
      }

      const dataSend = binaryFile ? dataForm : data;

      //this.setState({ registerLoading: true });
      createProvider({
        data: dataSend,
        is_perfil: true,
        callback: () => {
          history.push('/bank-list');
          showMessage({
            message: t('createBankSuccess', { name }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
      /*} else {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('nonMatchingPasswords')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      }*/
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('emptyFields')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  editProvider = () => {
    const {
      t,
      history,
      role: { list: roleList },
      documentType: { list: documentTypeList },
      showMessage,
      editProvider,
      fetchBanks,
    } = this.props;
    const {
      bank_id,
      name,
      documentType,
      documentNumber,
      bankType,
      email,
      originalEmail,
      password,
      confirmPassword,
      binaryFile,
      tdc_bin,
    } = this.state;

    const dataForm = new FormData();
    let object = [];
    tdc_bin.forEach((item) => {
      if (item.bin !== '') {
        object.push(item.bin);
      }
    });
    const data = {
      id_user: bank_id,
      country_code: '',
      phone_number: '',
      email: email === originalEmail ? '' : email,
      password: '',
      lastname: '',
      name,
      documentType: documentTypeList.map(
        (documentTypeMap, index) => ({
          ...documentTypeMap,
          index
        }),
      )
        .filter(documentTypeFil => documentTypeFil.document_type === 'RUT')[0].id,
      documentNumber,
      bankType: roleList[bankType].id,
      resource: binaryFile,
      b2b: {
        issuing_bank: 0,
        acquiring_bank: 0,
        [roleList[bankType].name]: 1,
      },
      tdc_bin: {
        bin: object,
        bank_id: bank_id,
      },
    };

    let i = 0;
    if (binaryFile) {
      for (let key in data) {
        if (typeof (data[key]) === 'object' && key !== 'resource') {
          for (let subKey in data[key]) {
            if (key === 'tdc_bin' && i === 0) {
              if (i === 0) dataForm.append('tdc_bin[bank_id]', bank_id);

              for (let j = 0; j < data.tdc_bin.bin.length; j++) {
                if (data.tdc_bin.bin[i] !== '') {
                  dataForm.append('tdc_bin[bin][' + i + ']', data.tdc_bin.bin[i]);
                  i += 1;
                }
              }
            } else {
              if (key !== 'tdc_bin') {
                dataForm.append(`${key}.${subKey}`, data[key][subKey]);
              }
            }
          }
        } else {
          dataForm.append(key, data[key]);
        }
      }
    }

    const dataSend = binaryFile ? dataForm : data;

    editProvider({
      data: dataSend,
      callback: () => {
        showMessage({
          message: t('editUserSuccess', { name: name }),
          config: TOAST_CONFIG.SUCCESS,
        });
        history.push('/dashboard');
        /*fetchBanks({
          params: {
            id,
            main_bank: true,
          }
        })*/
        this.setState(this.getInitialState());
      },
    });
  };

  resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 300, 55, 'png', 100, 0,
      uri => {
        //resolve(uri);
        this.setState({
          binaryFile: uri,
        });
      },
      'file'
    );
  });

  handleFileChange = (event) => {
    const {
      t,
      showMessage,
    } = this.props;
    const files = Array.from(event.target.files);
    const arrays = files[0].name;
    let ext = arrays.split('.');

    if (ext[ext.length - 1].toUpperCase() === 'JPG' || ext[ext.length - 1].toUpperCase() === 'PNG') {
      if (parseInt(files[0].size / 1024) > 3072) {
        showMessage({
          message: <strong style={{ color: '#ffffff' }}>{t('fileSizeExceeded')}</strong>,
          config: TOAST_CONFIG.ERROR,
        });
      } else {
        //const image = this.resizeFile(files[0]);
        this.resizeFile(files[0]);
        /*let reader = new FileReader();
        reader.onload = (e) => {

        }*/
        this.setState({
          //binaryFile: files[0],
          file: event.target.value,
        });
      }
    } else {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('errorLoad', {
          type: 'JPG',
          type1: 'PNG'
        })}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    }
  };

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
      clients: { loading: clientLoading },
      documentType: { loading: documentTypesLoading },
      providers: { loading: providerLoading },
      role: { list: roleList, loading: roleLoading },
      router: { location: { pathname } },
    } = this.props;
    const {
      name,
      documentNumber,
      email,
      password,
      confirmPassword,
      registerLoading,
      bankType,
      documentType,
      gateways,
      gateway,
      isIssuing,
      file,
      binaryFile,
      tdc_bin,
      bankName,
    } = this.state;
    const loading = clientLoading || documentTypesLoading || providerLoading || roleLoading || registerLoading;
    return (
      <div>
        {loading && <Loading/>}
        <div className="columns">
          <div className="column is-full">
            <BankForm
              //documentTypesList={documentTypesList}
              roleList={roleList}
              name={name}
              documentNumber={documentNumber}
              email={email}
              password={password}
              confirmPassword={confirmPassword}
              handleInputChange={this.handleInputChange}
              handleSelectChange={this.handleSelectChange}
              handleFileChange={this.handleFileChange}
              t={t}
              onSubmit={pathname === '/edit-bank-admin' ? this.editProvider : this.createProvider}
              bankType={bankType}
              documentType={documentType}
              title={pathname === '/edit-bank-admin' ? t('edit') : t('createNewManaged')}
              typeUser={type_user}
              gatewayList={gateways}
              gateway={gateway}
              isIssuing={isIssuing}
              file={file}
              fileName={binaryFile && binaryFile.name}
              tdc_bin={tdc_bin}
              handleInputChangeBin={this.handleInputChangeBin}
              addCard={this.addCardBin}
              deleteCard={this.deleteCard}
              route={pathname === '/edit-bank-admin'}
              bankName={bankName}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateBank.propTypes = {
  clients: PropTypes.shape(Object).isRequired,
  role: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  documentType: PropTypes.shape(Object).isRequired,
  fetchDocumentTypes: PropTypes.func.isRequired,
  fetchRoles: PropTypes.func.isRequired,
  createProvider: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};


const mapStateToProps = ({
                           clients,
                           documentType,
                           auth,
                           router,
                           role,
                           gateway,
                           providers,
                         }) => ({
  clients,
  documentType,
  auth,
  router,
  role,
  gateway,
  providers,
});

export default compose(
  connect(mapStateToProps, {
    ...documentTypeActions,
    ...providersActions,
    ...paymentConditionsActions,
    ...roleActions,
    ...clientsActions,
    ...gatewayActions,
  }),
  withNamespaces(),
)(CreateBank);
