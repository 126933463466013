import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';
import Form from '../Form';
import TextInput from '../TextInput';
import SelectSingle from '../SelectSingle';

const MainBankForm = ({
                        t,
                        onSubmit,
                        handleInputChange,
                        name,
                        handleSelectChange,
                        documentTypesList,
                        documentNumber,
                        roleList,
                        email,
                        bankType,
                        title,
                        file,
                        fileName,
                        handleFileChange,
                        phone,
                        handleInputChangeTypeDocument,
                        mark,
                        documentTypeUser,
                      }) => {
  let setObj = new Set();

  const uniqueRoles = roleList.reduce((acc,item)=>{
    if(!setObj.has(item.name)){
      setObj.add(item.name,item)
      acc.push(item)
    }
    return acc;
  },[]);

  return (
  <Panel headingText={title}>
    <Form
      submitText={t('save')}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      buttonClasses="mr-8px"
    >
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifths">
            <TextInput
              name="name"
              label={t('administratorName')}
              value={name}
              onChange={handleInputChange}
              placeholder={t('administratorName')}
              type="text"
              controlClasses="is-expanded"
              isRequired={true}
            />
          </div>
          <div className="column is-tree-fifths">
            <TextInput
              name="phone"
              label={t('phone')}
              value={phone}
              onChange={handleInputChange}
              placeholder={t('phone')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={12}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-two-third">
            <label><strong>{` ${t('type')}`}</strong></label>
            <br/>

            <input
              name="typePerson"
              value={'person'}
              onChange={handleInputChangeTypeDocument}
              type="radio"
              checked={mark === 'person'}
            />
            {` ${t('physical')}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input
              name="typePerson"
              value={'legal'}
              onChange={handleInputChangeTypeDocument}
              type="radio"
              checked={mark === 'legal'}
            />
            {` ${t('legal')}`}
          </div>
          <div className="column">
            <SelectSingle
              name="documentTypeUser"
              label={t('documentType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                ...documentTypesList.map((item, index) => ({
                  label: item.document_type,
                  value: index,
                })),
              ]}
              value={documentTypeUser}
            />
          </div>
          <div className="column">
            <TextInput
              name="documentNumber"
              label={t('documentNumber')}
              value={documentNumber}
              onChange={handleInputChange}
              placeholder={t('documentNumber')}
              type="text"
              controlClasses="is-expanded"
              inputClasses="has-text-right"
              maxlength={13}
              //disabled={edit}
              //isRequired={true}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column">
            <SelectSingle
              name="bankType"
              label={t('bankType')}
              selectClasses="is-fullwidth"
              placeholder={t('Select')}
              onChange={handleSelectChange}
              options={[
                // ...roleList.map((item, index) => ({
                ...uniqueRoles.map((item, index) => ({ //remove duplicates
                  value: index,
                  label: t(item.name),
                })),
              ]}
              controlClasses="is-expanded"
              value={bankType}
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-body columns">
          <div className="column is-tree-fifth">
            <TextInput
              name="email"
              label={t('email')}
              value={email}
              onChange={handleInputChange}
              placeholder={t('email')}
              type="text"
              controlClasses="is-expanded"
            />
          </div>
        </div>
      </div>
      <div className="field is-horizontal" style={{ display: 'none' }}>
        <div className="field-body columns">
          <div className="column is-full">
            <TextInput
              name="file"
              label={t('bankLogo')}
              value={file}
              fileName={fileName}
              onChange={handleFileChange}
              type="file"
            />
            <strong> <span className="texto-file">{t('allowedFormats')}</span></strong>
          </div>
        </div>
      </div>
    </Form>
  </Panel>
  )
};

MainBankForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  documentTypesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  documentNumber: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  documentType: PropTypes.number,
  bankType: PropTypes.number,
};

MainBankForm.defaultProps = {
  documentType: '',
  bankType: '',
};
export default MainBankForm;
