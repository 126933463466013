import { authActions, authTypes } from '../ducks/auth';
import { TOAST_CONFIG } from '../../config/constants';

const signIn = ({ dispatch }) => next => (action) => {

  next(action);
  if (action.type !== authTypes.SIGN_IN) {
    return;
  }

  const { data } = action.payload;
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/login-admin',
        data,
      },
      authorization: false,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        const color = "#E4002B";//chileAvatarColors[[Math.floor(Math.random()*chileAvatarColors.length)]];
        if (response.data.success) {
          response.data.data.colorAvatar = color;
          dispatch(authActions.updateUser(response.data.data));
          dispatch(authActions.updateAccount({ account_number: response.data.data.phone_number }));
          dispatch(authActions.logged());
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const logout = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.LOGOUT) {
    return;
  }

  dispatch(authActions.clear());
};

const getContacts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_CONTACTS) {
    return;
  }

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'api/contact-list',
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.updateContacts(response.data.data));
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const updateStatus = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.UPDATE_STATUS) {
    return;
  }

  const {
    data: {
      id_user = '',
      new_status = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'user/update-user-status',
        data: {
          id_user,
          new_status,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordReset = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET) {
    return;
  }
  const {
    email,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/request-password-reset',
        data: {
          email,
        },
      },
      authorization: false,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data);
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordResetToken = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET_TOKEN) {
    return;
  }
  const {
     params: {
        token,
        password,
     },
      callback,
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/reset-password',
        data: {
          token,
          password,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const passwordResetLogin = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.PASSWORD_RESET_LOGIN) {
    return;
  }
  const {
    email,
    password,
    callback,
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/reset-password-login',
        data: {
          email,
          password,
        },
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const changeRole = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.CHANGE_ROLE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `b2b/change-role`,
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.saveRole(response.data.data));
          callback();
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const updateB2B = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.UPDATE_B2B) {
    return;
  }

  const {
    params: {
      user_id,
    },
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `b2b/get-b2b-object?id=${user_id}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
          dispatch(authActions.saveB2B(response.data.data));
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const request2FA = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.REQUEST_2FA) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `api/send-second-factor-auth`,
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const change2FA = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.CHANGE_2FA) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `api/change-status-second-factor-auth`,
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if(response.data.success){
          callback(response);
        }else{
          dispatch(authActions.showMessage({
            message: response.data.data.message,
            config: TOAST_CONFIG.ERROR,
          }));
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const validateCode = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.VALIDATE_CODE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `api/validate-second-factor-auth`,
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        const color = '#A7A8AA';//chileAvatarColors[[Math.floor(Math.random()*chileAvatarColors.length)]];
        callback(response.data);
        if (response.data.success) {
          response.data.data.colorAvatar = color;
          dispatch(authActions.updateUser(response.data.data));
          dispatch(authActions.updateAccount({ account_number: response.data.data.phone_number }));
          dispatch(authActions.logged());
        } else {
          callback(response.data);
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getUserInfo = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_USER_INFO) {
    return;
  }

  const {
    params: {
      user,
    },
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `api/get-user-info?user=${user}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
          callback(response.data.data);
          dispatch(authActions.saveConfirmation(response.data.data));
      },
      onEnd: authActions.endFetch,
    },
  });
};

export default [signIn, logout, getContacts, updateStatus, passwordReset, passwordResetToken,
  passwordResetLogin, changeRole, updateB2B, request2FA, change2FA, validateCode, getUserInfo,];
