import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import './styles.scss';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { productActions } from '../../state/ducks/product/';
import { Loading, Modal, Panel, Toggle } from '../../components';
import ProductForm from '../../components/ProductForm';
import { TOAST_CONFIG } from '../../config/constants';
import Form from '../../components/Form';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { formatCurrencyCl } from '../../util';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    list: [],
    id: '',
    name: '',
    description: '',
    amount: null,
    countDetails: 0,
    details: [{
      description: ''
    }],
    detail: '',
    band: false,
    accept: '',
  });

    componentDidMount() {
      const {
        getProduct
      } = this.props;
      getProduct({
        callback: (response) => {
          this.setState({ list: response });
        }
      });
    }

    resetState = () => {
        this.setState(this.getInitialState());
    };

    handleInputChange = (event) => {
      const {
        target: {
          checked, value: targetValue, type, name,
        },
      } = event;
      const value = type === 'checkbox' ? checked : targetValue;
  
      const detail = name.split('-');
      const inputName = detail[0];
      const index = detail[1];
  
      if ('detail' === inputName) {
        let details = [...this.state.details];
        //let min = value;
  
        details[index] = {
          description: value,
        };
  
        this.setState({ details });
      }
        
      this.setState({
        [name]: value,
      });
      
    };

    addBenefit = () => {
      const {
        countDetails,
      } = this.state;
      let count = 0,
        i = 1;
      count = countDetails + i;
  
      let variable = {
        description: '',
      };
      this.state.details.push(variable);
  
      this.setState({
        countDetails: count
      });
    };

    createProduct = (edit) => {
      const { 
        t,
        showMessage,
        saveProduct,
        updateProduct, 
      } = this.props;
      
      const {
        id,
        name,
        description,
        amount,
        details,
      } = this.state;

      let finalDetails = [];

      if(details.length > 0){
        for(let i=0; i<details.length; i++){
          finalDetails[i] = details[i].description;
        }
      }

      const data = {
        id,
        name,
        description,
        amount,
        details: finalDetails,
      };

      if(edit){
        updateProduct({
          data,
          callback: (response) => {
            showMessage({
              message: t('createClientSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
            window.location.reload(false);
          }
        });
      }else{
        saveProduct({
          data,
          callback: (response) => {
            showMessage({
              message: t('createClientSuccess', { name }),
              config: TOAST_CONFIG.SUCCESS,
            });
            this.resetState();
            window.location.reload(false);
          }
        });
      } 
    }

    openModal = (response, edit, onToggle) => {
      this.setState({
        id: response.id,
        name: response.name,
        description: response.description,
        amount: response.amount,
        details: response.details,
        band: edit,
      });
      onToggle();
    }

    disableProduct = (id) => {
      const {
        t,
        showMessage,
        disableProduct} = this.props;

      const data = {
        id,
      }

      disableProduct({
        data,
        callback: (response) => {
          showMessage({
            message: t('createClientSuccess'),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
          window.location.reload(false);
        }
      });

    }

  acceptTerm = (onToggle, product, product_id, provider_id) => {
    const {
      accept,
    } = this.state;
    const {
      auth: { user: { b2b: { id } } },
      requestPaymentCondition,
      showMessage,
      t,
      history,
    } = this.props;

    if (accept) {
      const conditionData = {
        bank_sender_id: null,
        sender_request_id: null,
        sender_id: id,
        receiver_id: provider_id,
        days: '0',
        due_from_approval: 1,
        limit_amount: '1000000000.00',
        discount_rate: 0,
        percentage: '0.000',
        receiver_discount_share: 0,
        type: 'base',
        product_id: product_id,
      };

      requestPaymentCondition({
        data: conditionData,
        callback: () => {
          onToggle();
          history.push('/dashboard');
          showMessage({
            message: t('productContracted', { name: product }),
            config: TOAST_CONFIG.SUCCESS,
          });
          this.resetState();
        },
      });
    }
    if (!accept) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('rememberToAcceptTheTerms')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      //onToggle();
    }
  };

  render() {
    const {
      t,
      auth: { loading: authLoading, user: { profile: { role } } },
      paymentConditions: { loading: paymentConditionLoading },
      showMessage,
      getProduct,
      disableProduct,
    } = this.props;

    const {
      list,
      name,
      description,
      amount,
      details,
      band,
      accept,
    } = this.state;
    const loading = authLoading || paymentConditionLoading;
    return(
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          return (
            <div>
              {loading && <Loading/>}
              <div className="dashboard">
                <div className="columns is-multiline">
                  <Fragment>
                    {toggled && toggleProps.isEdit && role === 'buyer' &&
                    (
                      <Modal onToggle={onToggle}> {loading && <Loading/>}
                        <Panel headingText={t('termsAndConditions')}>
                          <Form
                            submitText={t('accept')}
                            onSubmit={(event) => {
                              event.preventDefault();
                              this.acceptTerm(onToggle, toggleProps.name_product, toggleProps.product_id, toggleProps.provider_id);
                            }}
                            buttonClasses={`mr-8px`}
                          >
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column is-full" style={{ textAlign: 'center' }}>
                                  <h1 style={{ fontSize: '18px' }}>
                                    <strong>{toggleProps.name_product}</strong>
                                  </h1>
                                  <br/>
                                  <label>{t('termsAndConditions')}</label>
                                </div>
                              </div>
                            </div>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column" style={{ textAlign: 'center' }}>
                                  <label htmlFor="assistCard" className="checkbox"
                                         style={{ width: '100%' }}/>
                                  <input
                                    name="accept"
                                    value={accept}
                                    onChange={this.handleInputChange}
                                    type="checkbox"
                                  />
                                  {` ${t('acceptTermsAndConditions')}`}
                                </div>
                              </div>
                            </div>
                          </Form>
                        </Panel>
                      </Modal>
                    )
                    }
                    {
                      toggled && !toggleProps.isEdit && (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <ProductForm
                            t={t}
                            name={name}
                            description={description}
                            amount={amount}
                            details={details}
                            handleInputChange={this.handleInputChange}
                            addBenefit={this.addBenefit}
                            onSubmit={this.createProduct}
                            edit={band}
                          />
                        </Modal>
                      )
                    }
                    {list
                    && (
                      <div className="column is-full">
                        <Panel headingText={t('products')}>
                          <div className="panel-top-content">
                            { role === 'seller_assist_card' &&
                            <button
                              className="button is-primary"
                              onClick={() => onToggle()}
                            >
                              {t('registerProduct')}
                            </button>
                            }
                          </div>
                          {list.map(product => (
                            <div className="product-holder">
                                { role === 'seller_assist_card' &&
                                  <div className='buttons is-right'>
                                    <button
                                      className="button is-primary"
                                      onClick={() => {
                                        getProduct({
                                          params: {
                                            id: product.id,
                                           },
                                          callback: (response) => {
                                            this.openModal(response, true, onToggle);
                                          },
                                        });
                                      }}
                                    >
                                      {t('edit')}
                                    </button>
                                    <button
                                      className={`button is-inverted is-${product.status === '1' ? 'danger' : 'success'}`}
                                      onClick={() => {
                                        disableProduct({
                                          data: {
                                            id: product.id
                                          },
                                          callback: (response) => {
                                            showMessage({
                                              message: t('createClientSuccess'),
                                              config: TOAST_CONFIG.SUCCESS,
                                            });
                                            this.resetState();
                                            window.location.reload(false);
                                        }})
                                      }}
                                    >
                                      {product.status === '1' ? t('disable') : t('enable')}
                                    </button>
                                  </div>}
                                <p className="product-title">{product.name}</p>
                                <p className="product-text">{product.description}</p>
                                <p className="product-subtitle">{t('benefits')}</p>
                                {product.details && 
                                  product.details.map(benefit => (
                                    <p className="product-text">{benefit.description}</p>
                                  ))
                                }
                              <p className="product-subtitle">{t('cost')}</p>
                              <p
                                className="product-text">{formatCurrencyCl(product.amount) + ' ' + product.currency_id}</p>
                                { role === 'buyer' &&
                                <div className='buttons is-right'>
                                  <button
                                    className="button is-primary"
                                    onClick={() => {
                                      onToggle({
                                        isEdit: true,
                                        name_product: product.name,
                                        provider_id: product.provider_id,
                                        product_id: product.id,
                                      });
                                    }}
                                  >
                                    {t('contract') + ' ' + product.name}
                                  </button>
                                </div>}
                            </div>
                          ))}
                        </Panel>
                      </div>
                    )
                    }
                  </Fragment>
                </div>
              </div>
            </div>
          );    
        }}
      </Toggle>
    );
  }
}

Product.propTypes = {
    t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ paymentConditions, product }) => (
  {
    paymentConditions,
    product,
  }
);

export default compose(
    connect(mapStateToProps, {
      ...productActions,
      ...paymentConditionsActions,
    }),
    withNamespaces(),
)(Product);