import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './styles.scss';
import { formatDate } from '../../util';
import { transactionsActions } from '../../state/ducks/transactions';

const Filters = ({ transactions, saveSearchValue }) => {
    
    const [searchOption, setSearchOption] = useState('invoicesNumber');
    const { sender, searchOptions } = transactions;

    useEffect(() => {
      saveSearchValue(sender)
    }, [sender])

    const handleSearchOption = e => {
        e.persist()
        const {value} = e.target 
        // e.target.parentNode.parentNode.classList.toggle('menu-hide')
        setSearchOption(value);
    }
    const setFilteredData = (e, data, searchOption) => {
        e.persist()
        let {value} = e.target
        value = value.toLowerCase()
        let filteredData = [];
        switch (searchOption) {
          case "invoicesNumber":
              filteredData = data.filter(item => item.invoice_number && item.invoice_number.includes(value))
            break;
          case "due_date":
              filteredData = data.filter(item => formatDate(item.due_date) === value && item)
            break;
          case "adjusted_amount":  
              filteredData = data.filter(item => item.adjusted_amount && item.adjusted_amount.includes(value))
            break;
          case "currency":  
              filteredData = data.filter(item => item.pay_currency_id && item.pay_currency_id.includes(value))
            break;
          case "last_four":  
              filteredData = data.filter(item => item.last_four && item.last_four.includes(value))
            break;
          case "name":  
              filteredData = data.filter(item => {
                return item.receiver.idPerson.name && item.receiver.idPerson.name.toLowerCase().includes(value)
              })
            break;
          case "description":  
              filteredData = data.filter(item => item.description && item.description.toLowerCase().includes(value))
            break;
          case "transferState":  
              filteredData = data.filter(item => {
                return item.senderStatus.description && item.senderStatus.description.toLowerCase().includes(value)
              })
            break;
          default:
              // filteredData = []
              filteredData = data
            break;
        }
        value === '' ? saveSearchValue(data) : saveSearchValue(filteredData)
      }
    return (
        <div className="Filters__main-container">
          {/* <button className="custom-menu menu-hide">
            {searchOption.header}
            <span onClick={e => {
                e.persist()
                e.target.parentNode.classList.toggle("menu-hide")
              }}>V
            </span>
            <ul onClick={e => handleSearchOption(e)}>
                {searchOptions && searchOptions.map((column, index) => {
                if (index > 0 && index < searchOptions.length-1) {
                    return(
                    <li key={column.id} data-option={column.id}>
                        {column.Header}
                    </li>
                    )
                }
                })}
            </ul>
          </button> */}
          <div className='select is-primary is-rounded is-medium'>
            <select 
              onChange={e => handleSearchOption(e)} 
              className='filters-options'>
                {searchOptions && searchOptions.map((column, index) => {
                    if (index > 0 && index < searchOptions.length-1) {
                        return(
                        <option key={column.id} value={column.id}>
                            {column.Header}
                        </option>
                        )
                    }
                })}
            </select>
          </div>
          <input 
            className='input is-primary is-rounded is-medium filters-options'
            placeholder="search"
            onChange={e => setFilteredData(e, sender, searchOption )}/>
        </div>
    );
};

const mapStateToProps = ({ transactions }) => ({ transactions });

export default connect(mapStateToProps, {...transactionsActions})(Filters);
