import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

/**
 * Created by Feedback Software on 25/10/18.
 * @param headingText is the title of the panel
 * @param children contain the elements inside the panel body
 * @returns {*}
 * @constructor
 */
const Panel = ({ headingText, children, alert, usedInModals }) => (
  <>
  {headingText && <h2 className="Panel__heading-text">{headingText}</h2>}
  <div className="Panel">
    <div
      className="columns is-centered"
      style={{ animationDelay: '1s' }}
    >
      <div className="column is-full wow animated fadeInUp">
        <div 
          className={`panel panel-default panel-table ${!usedInModals ? 'shadas' : ''}`}
          style={usedInModals ? { marginBottom: '0px' } : null }>          
          {/* {headingText !== ''
          && (
            <div
              className={alert === true ? 'title is-3 panel-header' : 'title is-3 panel-header'}>
              {headingText}
            </div>
          )} */}
          <div className="panel-body">
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
  </>
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  headingText: PropTypes.string,
};

Panel.defaultProps = {
  headingText: '',
};

export default Panel;
