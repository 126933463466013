import React from 'react';
import PropTypes from 'prop-types';

import { BarChart, Bar, Legend, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import { formatCurrencyCl } from '../../../../util';
import './styles.scss';

const CustomizedLabel = (props) => {
	const { x, y, value } = props;
	return (
		<text x={x} y={y} dy={-4} fontSize={8} textAnchor='middle'>
			{value}
		</text>
	);
}

const CustomizedAxisTick = (props) => {
	const { x, y, payload } = props;
	return (
		<g transform={`translate(${x},${y})`}>
			<text x={0} y={0} dy={16} textAnchor='end' fontSize={10} transform='rotate(-35)'>
				{payload.value}
			</text>
		</g>
	);
}


const BarChartBox = ({ title, data, keys, names, colors, legend, isFinantialAmmount }) => {
	return (
		<>
			<div className={`column`} style={{ display: 'flex', justifyContent: 'center' }}>
				<div className='bar-chart-box'>
					<h3 className='title'>{title}</h3>
					<ResponsiveContainer width='100%' height='100%'>
						<BarChart
							width={500}
							height={300}
							data={data}
							margin={{
								top: 15,
								right: 15,
								left: 10,
								bottom: 15,
							}}
						>
							{/* <CartesianGrid /> */}
							<XAxis dataKey='name' height={30} tick={<CustomizedAxisTick />} />
							<YAxis
								fontSize='9'
								tickFormatter={ tick => {
									return isFinantialAmmount ? formatCurrencyCl(tick) : tick
								}}
								></YAxis>
							<Tooltip />
							{ legend && <Legend wrapperStyle={{ fontSize: '10px', bottom: '10px' }} /> }
							{ keys.map((k, i) => (
								<Bar key={i} dataKey={k} fill={colors[i]} name={names[i]} label={<CustomizedLabel />} />
							))}
						</BarChart>
					</ResponsiveContainer>
				</div>
			</div>
		</>
	)
}

BarChartBox.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  keys: PropTypes.array.isRequired,
  names: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  legend: PropTypes.bool,
};

BarChartBox.defaultProps = {
	legend: false
};


export default BarChartBox;