import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { formatFriendlylongDate, formatCurrencyCl } from '../../util';
import { TableSimple, Panel } from '../../components';

const CreditsList = ({ 
    credits: { list },
    t,
 }) => {

    const getGridSettings = () => {
        let columns = [
            {
              expander: true,
              show: false,
            },
            {
              id: 'requestDate', // Required because our accessor is not a string
              Header: t('applicationDate'),
              accessor: d => formatFriendlylongDate(d.requestDate),
            },
            {
              id: 'amount', // Required because our accessor is not a string
              Header: t('amountToRequest'),
              accessor: d => `$${formatCurrencyCl(d.amount)}`,
            },
            {
              id: 'installments', // Required because our accessor is not a string
              Header: t('installmentsQty'),
              accessor: d => d.installments,
            },
            {
              id: 'charge', // Required because our accessor is not a string
              Header: t('chargeRate'),
              accessor: d => `${d.charge}%`,
            },
            {
              id: 'installmentAmount', // Required because our accessor is not a string
              Header: t('amountInstallments'),
              accessor: d => `$${formatCurrencyCl(d.installmentAmount)}`,
            },
            {
              id: 'detail', // Required because our accessor is not a string
              Header: t('detail'),
              Cell: cellInfo => (<i className="far fa-file-alt"></i>)
            }
        ]

        return columns
    }

    return (
        <>
          {
          list.length === 0 ? (
            <Panel>
              <div className='not-credit-box'>
                {/* <h2>No posee líneas de crédito activas, <br/> ¿Desea solicitar una línea de crédito?</h2> */}
                <h2>{t('emptyCreditList')}</h2>
                <Link 
                  to='/credit-request' 
                  className='button custom-btn is-primary'>{t('continue')}</Link>
              </div>
            </Panel>
          ) : (
            <Panel>
              <TableSimple data={list} columns={getGridSettings()} filterable={false} />
            </Panel>
          )
          }
        </>
    );
};

const mapStateToProps = ({ credits }) => ({ credits });

export default compose(
  connect(mapStateToProps),
  withNamespaces()
)(CreditsList);