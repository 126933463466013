const es = {
  translations: {
    // Despues de enviar a traduccion a portugues
    accept: 'Aceptar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    acceptTermsAndConditions: 'He leído y acepto los términos y condiciones del Servicio',
    acceptTermsGateway: 'Aceptamos los cargos o tarifas por servicio que en su caso estén indicados en la sección Clientes y aceptamos que éstas sean cargadas por GETNET mediante deducción directa a las liquidaciones mensuales practicadas al ESTABLECIMIENTO, como se indica en el Contrato de Afiliación y en su Anexo que regula el acceso y uso de la plataforma “B2B Enterprise”',
    account: 'Cuenta',
    accountBalance: 'Saldo de la cuenta',
    accountNumber: 'Número de cuenta',
    accountPlural: 'Cuentas',
    accumulatedAmount: 'Monto acumulado diario',
    acquiring_bank: 'Banco Adquiriente',
    acquiring_query: 'Banco Adquirente de Consulta',
    acquiringAdvance: 'Adelanto adquiriente',
    acquiringBank: 'Adquiriente',
    acquiringQuery: 'Adquirente de Consulta',
    action: 'Acción',
    actionNotAllowed: 'Acción no permitida',
    activate: 'Activar',
    active: 'Activo',
    activeCompanies: 'Empresas activas',
    addComment: 'Agregar comentario ...',
    addCondition: ' Agregar condición',
    addFinanceProductSuccess: 'Producto de financiamiento creado de manera exitosa',
    addFinancingProducts: 'Agregar productos de financiamiento',
    additionalInformation: 'Información Adicional',
    addNew: 'Agregar nuevo',
    address: 'Dirección',
    ADE: 'Adelantado',
    adjusted_amount: 'Monto ajustado',
    admin: 'Administrador',
    adminBank: 'Banco Administrador',
    administer: 'Administrar',
    administratorBank: 'Banco Administrador',
    administratorName: 'Nombre del administrador',
    administrators: 'Administradores',
    administratorsList: 'Lista de administradores',
    adminLastname: 'Apellido',
    adminName: 'Nombre',
    adminPosition: 'Cargo',
    adminRoutes: 'Administrar rutas',
    adminUser: 'Usuario Administrador',
    advance: 'Adelantar',
    advanceCommercialConditions: 'Condiciones comerciales de anticipo',
    advanceMount: 'Monto adelantado',
    advanceSuccessful: 'Adelanto realizado exitosamente',
    affiliateClients: 'Cliente afiliados',
    affiliateCode: 'Código de afiliación',
    affiliateN: 'Nro. de afiliado (SE#)',
    affiliateNumber: 'Número de afiliado (SE#)',
    affiliationCode: 'Código de afiliación de comercio',
    alias: 'Alias',
    allowedFormats: 'Formatos permitidos: JPG y PNG. Tamaño máximo 3MB.',
    allowedFormatsPDF: 'Formatos permitidos: PDF o XML. Tamaño máximo 3MB.',
    allowedFormatsXLS: 'Formatos permitidos: XLSX o XLS. Tamaño máximo 3MB.',
    amexCard: 'Tarjeta Santander Tesorero Amex',
    amount: 'Monto',
    amountApprovedInvoices: 'Monto de Facturas Aprobadas',
    amountInstallments: 'Monto de las cuotas',
    amountInt: 'El monto debe ser un número entero',
    amountMoney: 'Monto de dinero',
    amountOfTransactionsToProcess: 'Monto de transacciones por procesar',
    amountPaidInvoices: 'Monto de Facturas Pagadas',
    amountToCollect: 'Total diario por cobrar',
    amountToGet: 'Facturas por cobrar',
    amountToPay: 'Monto por pagar',
    amountToRequest: 'Monto a solicitar',
    amountValidate: 'El valor debe ser mayor o igual a 0.1',
    annualQuota: 'Cuota anual',
    answer: 'Respuesta',
    anticipatedAmount: 'Monto anticipado',
    anualRate: 'Tasa anual',
    applicant: 'Solicitante',
    applicantName: 'Nombre del Solicitante',
    applicationDate: 'Fecha de solicitud',
    applicationDeleted: 'Solicitud eliminada',
    applicationNotification: 'Tienes {{payment_request_pending}} solicitudes',
    applicationOneNotification: 'Tiene {{payment_request_pending}} solicitud',
    applicationRejected: 'Solicitud rechazada exitosamente',
    applicationSuccess: 'Solicitud aprobada exitosamente',
    approvalDate: 'Fecha de aprobación de facturas',
    approvalStatus: 'Estado de aprobación',
    approve: 'Aprobar',
    approved: 'Aprobadas',
    approvedChecks: 'Cheques aprobados',
    approvedInvoices: 'Fac. Aprobadas',
    approvedInvoicesTitle: 'Facturas aprobadas',
    approvedMount: 'Monto aprobado',
    approvedStatus: 'Aprobado',
    approvedTransaction: 'Transacción aprobada',
    approveInvoice: 'Aprobar factura',
    april: 'Abril',
    arrangedDays: 'Días de financiación acordados',
    arrangedDays: 'Días pactados',
    assign: 'Asignar',
    assignedCardNumber: 'N° tarjeta asignada',
    assignedRoutes: 'Rutas asignadas',
    assignedUsers: 'Usuarios Asignados',
    assignPermissions: 'Asignar permisos',
    assignRoleRoute: 'El rol y la ruta se asignaron exitosamente',
    assignRoute: 'Asignar rutas',
    assignRouteSuccess: 'La ruta fue asignada exitosamente',
    assistCard: 'Assist card',
    asterisk: '*** {{number_card}}',
    august: 'Agosto',
    authorization: 'Autorización',
    authUser: 'Usuario autorizado',
    available: 'Disponible',
    availableBalance: 'Saldo disponible',
    avance: 'Anticipo',
    b2bInformation: 'Información sobre B2B Enterprise',
    b2bMDR: 'Tasa B2B Enterprise (MDR)',
    backImage: 'Cargar imagen del dorso',
    balance: 'Saldo',
    balanceToPay: 'Saldo a cancelar',
    bankInformation: 'Información del Banco seleccionado',
    bankingExecutives: 'Back office',
    bankList: 'Lista de bancos',
    bankLogo: 'Logo del Banco',
    bankName: 'Nombre del banco',
    bankProvider: 'Banco Santander',
    banks: 'Bancos',
    bankType: 'Tipo de banco',
    baseConditions: 'Condiciones Base (Pago de Facturas)',
    benefits: 'Beneficios',
    billingCycle: 'Ciclo de facturación',
    billingCycles: 'Ciclos de facturación',
    billingPerMonth: 'Facturación {{month}}',
    billingVolume: 'Volumen de facturación',
    billPayment: 'Pago de facturas',
    bills: 'Facturas \nenviadas',
    bin: 'Bin',
    businessExecutive: 'Back office',
    businessExecutives: 'Back office',
    businessName: 'Razón social',
    buyer: 'Cliente',
    buyerManualUp: 'Cliente (Carga Manual)',
    buyersBatchUp: 'Clientes (Carga Masiva)',
    buyerCode: 'Código del cliente',
    buyerCreateLead: 'Requerir alta de cliente',
    buyerName: 'Nombre del cliente',
    buyersGroup: 'Grupo de clientes',
    by_amount: 'Por monto',
    by_hierarchy: 'Por jerarquía de puestos',
    cancel: 'Cancelar',
    cannotBeUploaded: 'Por lo tanto la factura no se puede cargar en B2B Enterprise',
    cantCreateTransaction: 'Ya existe el número de factura',
    cantCustomers: 'clientes seleccionados para cargar',
    cantDeleteRol: 'El rol no se puede eliminar porque tiene asignado una o mas rutas',
    cantDeleteRoute: 'La ruta no se puede eliminar porque esta asignada a uno o mas roles',
    canton: 'Cantón',
    cantProviders: 'empresas seleccionadas para cargar',
    cardBind: 'Bin de tarjetas',
    cardChargeDate: 'Fecha de Cargo en la Tarjeta',
    cardholderPaymentPercentage: 'Porcentaje de Pago del Tarjetahabiente',
    cardList: 'Lista de tarjetas',
    cardNumber: 'Numero de tarjeta',
    cardPayment: 'TTA seleccionada para pago',
    cardPaymentDate: 'Fecha de pago de tarjeta de crédito',
    cards: 'Tarjetas',
    categories: 'Categorías',
    ccChargeDate: 'CC charge date',
    cedulaJuridicaDesc: '10 dígitos, sin cero al inicio ni guiones',
    changeCode: 'Debe modificar el código de comercio que corresponda al nuevo tipo de moneda seleccionada',
    changeLog: 'Registro de cambio',
    changeOfCommercialConditions: 'Registro de cambio de condiciones comerciales',
    changePassword: 'Cambiar contraseña',
    changePasswordSuccess: 'Contraseña cambiada de manera exitosa',
    charge: 'Cargo',
    chargeDate: 'Fecha de cargo',
    chargeForService: 'Cargo por servicio',
    chargeRate: '% Interés',
    checkDate: 'Fecha del cheque',
    checkDeposit: 'Depositar cheque',
    checkNumber: 'Número de cheque',
    checks: 'Cheques',
    checksToBeApproved: 'Cheques por aprobar',
    CIF: 'CIF',
    cifPattern: 'Debe contener letras y números',
    city: 'Ciudad',
    client: 'Cliente',
    clientB2BRate: 'Tasa B2B a cargo del cliente es',
    clientName: 'Nombre del cliente',
    clientPay: 'Pagos de los clientes',
    clientPaymentCondition: 'Determina las condiciones de pago entre el proveedor y el cliente',
    clientPercentageCharge: 'Porcentaje cargo por servicio a cargo del cliente',
    clientRUT: 'RUT Cliente',
    clients: 'Clientes',
    clientsPay: 'Clientes por pagar',
    clientsQuantity: 'Cantidad de Clientes',
    code: 'Código',
    codeArea: 'cód. área + n˚ teléfono',
    collaboratorNumber: 'Número de colaborador',
    collaboratorNumberCharacter: 'Número de colaborador, debe tener 8 caracteres',
    collectionDate: 'Fecha cobro',
    commerceCode: 'Nro. de afiliado (SE#)',
    commerceCodeDesc: 'Ingrese descripción para código de comercio',
    commercialBillingUser: 'Usuario de facturación comercial',
    commercialConditions: 'Condiciones comerciales',
    commune: 'Comuna',
    companies: 'Empresas',
    company: 'Compañia',
    completeComercialConditionOrEmptyAll: 'Complete los datos de las condiciones comerciales o dejelos vacios si no desea agregar condicion comercial con algun proveedor',
    concept: 'Concepto',
    condition_amount: 'Monto',
    condition: 'Condición',
    conditions: 'Condiciones',
    configuration: 'Configuración',
    confirm: 'Confirmar',
    confirmData: 'Confirmar datos',
    confirmPassword: 'Confirmar contraseña',
    confirmPassTooltip: 'La contraseña y su confirmación deben ser idénticas',
    confirmTaxCode: 'Confirmar Clave Tributaria',
    confirmTransfer: 'Confirme su transacción',
    confirmUnsubscribe: '¿Está seguro que desea dar de baja la condición comercial con {{client}} y el {{product}} que involucra?',
    confirmUnsubscribeFrom: 'Confirmar baja de: {{product}}',
    consult: 'Consultar',
    contactPhone: 'Teléfono',
    continue: 'Continuar',
    continueLoadingCustomers: '¿Continuar con la carga de clientes?',
    continueLoadingProviders: '¿Continuar con la carga de empresas?',
    contract: 'Contratar',
    contractedProducts: 'Seguros contratados',
    contractRequest: 'Solicitud de contrato',
    cort: 'Corte',
    cortDate: 'Fecha de corte',
    cost: 'Costo',
    country: 'País',
    createBank: 'Crear nuevo banco',
    createBankSuccess: 'Banco {{name}} creado de manera exitosa',
    createBuyer: 'Crear cliente',
    createClientSuccess: 'Cliente {{name}} creado de manera exitosa',
    createCondition: 'Crear condición comercial para: {{name}}',
    createExecutive: 'Crear back office',
    createFlowApproval: 'Crear flujo de aprobación de factura',
    createFlowApprovalSuccess: 'Flujo de Aprobación de factura, creado de manera exitosa',
    createInvoicesSuccess: 'Las facturas se han registrado exitosamente',
    createInvoiceSuccess: 'La factura N° {{invoice_number}} se ha registrado exitosamente',
    createLead: 'Requerir \n alta',
    createNewExecutive: 'Crear nuevo ejecutivo "{{inputValueExecutive}}"',
    createNewItem: 'Crear nueva industria "{{inputValue}}"',
    createNewManaged: 'Crear nuevo administrador',
    createPaymentCondition: 'Crear condición \nde pago',
    createPaymentConditionSuccess: 'Condición de pago creada exitosamente',
    createProfile: 'Crear perfil',
    createProfileSuccess: 'Perfil {{name}} creado de manera exitosa',
    createProvider: 'Crear proveedor',
    createProviderSuccess: 'Proveedor {{name}} creado de manera exitosa',
    createQuestionSuccess: 'Pregunta creada de manera exitosa',
    createRol: 'Crear rol',
    createRoleSuccess: 'Role {{rolName}} creado exitosamente',
    createRolSuccess: 'Rol {{rolName}} creado de manera exitosa',
    createUser: 'Crear usuario',
    createUserSuccess: 'Usuario {{name}} creado de manera exitosa',
    creditAvailableBalance: 'Saldo disponible L.C.I.',
    creditCard: 'Tarjeta de crédito',
    creditCardSubstitution: 'Sustitución de tarjeta de crédito',
    creditConditionsMsg: '* Al elegir usar Línea de Crédito, debe definir las condiciones del crédito.',
    creditPeriod: 'Período del crédito',
    creditRequest: 'Solicitar línea de crédito',
    creditRequestForAdvance: 'Solicitar L.C.I para el adelanto de la factura',
    creditRequestForInvoice: 'Solicitar L.C.I para el pago de la factura',
    creditsList: 'Registro de línea de créditos',
    currency: 'Moneda',
    currentCycle: 'Ciclo Vigente',
    customer: 'Cliente',
    customerInformation: 'Información del cliente seleccionado',
    customerName: 'Nombre del cliente',
    customerPayment: 'Pago del cliente',
    customerPaymentPercentage: '% Pago cliente',
    customers: 'Clientes',
    customersSaved: 'Clientes cargados exitosamente',
    cuttingCycle: 'Ciclo de corte',
    cuttingCycleAmex: 'Ciclo de corte tarjeta tesorero AMEX',
    cuttingCycleCard: 'Ciclo de corte de la tarjeta ingresada es: {{message}}',
    cycle_loader: 'Operador de Ciclos de Facturación',
    cycleGroup: 'Grupo de ciclo',
    cyclesList: 'Lista de Ciclos de Facturación',
    daily: 'Diario',
    dashboard: 'Resumen',
    dataSavedSuccessfully: 'Datos guardados con exito',
    date: 'Fecha',
    dateFf: 'Fecha FF',
    dateInvoice: 'Fecha factura',
    dateNotAvailable: 'Fecha no disponible',
    dateOfAdmission: 'Fecha de ingreso en SII',
    dateOfEntry: 'Fecha de ingreso a B2B',
    datePayment: 'Fecha de pago',
    dateRange: 'Rango de fechas',
    dateV: 'Fecha de Vencimiento',
    day: 'Día',
    daysAdvance: 'Dias a adelantar',
    daysDefer: 'Dias a diferir',
    daysNumber: 'Número de días',
    daysSince: ' días desde la ',
    deactivate: 'Desactivar',
    december: 'Diciembre',
    defaultFlow: 'Flujo por defecto',
    defer: 'Diferido',
    deferCommercialConditions: 'Condiciones comerciales de diferimiento',
    deferMount: 'Monto diferido',
    deferred: 'Diferidas',
    deferredAmount: 'Monto Diferido',
    delete: 'Eliminar',
    deleteExecutive: 'Back office eliminado',
    deleteInvoice: 'La factura fue eliminada',
    deletePaymentCondition: 'La condición de pago fue eliminada',
    deletePaymentCondConf: '¿Está seguro que desea eliminar esta relación comercial?',
    deleteRol: 'El rol fue elminado',
    deleteRoute: 'La ruta fue eliminada',
    deleteTta: 'Tarjeta AMEX eliminada con éxito',
    demographicInformation: 'Datos de identificación',
    deny: 'Denegar',
    deposit: 'Depositar',
    depositReason: 'Motivo del depósito',
    description: 'Descripción',
    destinationAccount: 'Cuenta destino',
    detail: 'Detalle',
    DIF: 'Diferido',
    differ: 'Diferir',
    digitalCertificate: 'Certificado digital',
    disable: 'Deshabilitar',
    disable2FA: 'Desactivar verificación en dos pasos',
    disabled: 'Deshabilitado',
    discountAmount: 'Monto de descuento',
    discountRate: 'Tasa de descuento',
    discountRateAccept: 'Condiciones de cargos por servicio a Banco Santander',
    discountRateCharges: 'Cargos por tasa de descuento',
    discountRateInvoiceSavedSuccessfully: 'Factura por tasa de descuento guardada con éxito',
    discountRateMDR: 'Tasa dcto. (MDR)',
    discountRateOutOfLimits: 'Tasa de descuento fuera de los limites',
    discountRateTerms: 'Al aceptar el cargo a mi Tarjeta de Crédito Santander Tesorero American Express, el cliente se compromete a pagar la factura más los cargos por Servicio a Banco Santander en todas las facturas en las que el cliente le pague al proveedor indicado.',
    district: 'Distrito',
    document: 'Documento',
    documentDate: 'Fecha del documento',
    documentNumber: 'Nro. de documento',
    documentType: 'Tipo de documento',
    doNotIncludeTheUsername: '* No incluir como parte de la contraseña el nombre del usuario.',
    dontPose: 'No Posee',
    download: 'Descargar',
    eCommerceTerminal: 'Terminal E-commerce',
    edit_user: 'Editar Usuario',
    edit: 'Editar',
    editBank: 'Editar manual: {{name}}',
    editClientSuccess: 'Cliente {{name}} editado exitosamente',
    editCycle: 'Editar ciclo',
    editFlowApprovalSuccess: 'Flujo de aprobación editado con exito',
    editionRequestSent: 'Solicitud de edicion enviada',
    editPaymentConditionSuccess: 'Condición de pago Editada exitosamente.',
    editProfileSuccess: 'Perfil {{name}} editado exitosamente',
    editProviderSuccess: 'Proveedor {{name}} editado exitosamente',
    editUserSuccess: 'Usuario {{name}} editado exitosamente',
    email: 'Correo electrónico',
    email2FA: 'Se ha enviado un código de verificación a su correo electrónico, por favor ingréselo para continuar la operación',
    emailLabel: 'nombre@empresa.cl',
    emissionFlow: 'Flujo de emisión',
    emitInvoices: 'Tiene(s) {{emit_invoices}} factura(s) sin emitir',
    emptyAll: 'Asegúrate de llenar toda la información',
    emptyAllRequest: 'Asegúrate de llenar toda la información, de los Campos obligatorios',
    emptyCreditList: 'No posee líneas de crédito activas, ¿Desea solicitar una línea de crédito?',
    emptyFields: 'Debes completar todos los campos.',
    enable: 'Habilitar',
    enable2FA: 'Activar verificación en dos pasos',
    enabled: 'Habilitado',
    endingDate: 'Fecha de fin',
    eng: 'ENG',
    enter: 'Ingresar',
    enterCodeSms: 'Verifica e ingresa el código\n que te enviamos vía SMS',
    enterCuttingCycle: 'Ingresar ciclo de corte',
    enterDataInternalTaxService: 'Conecte su empresa al SII - Servicio de Impuestos Internos',
    enterTaxCode: 'Ingresar Clave Tributaria',
    enterTheCardBillingCycle: 'Es necesario que ingrese el ciclo de facturación de la tarjeta para continuar operando con está tarjeta',
    enterYourNewPassword: 'Introduzca su nueva contraseña',
    errorCustomers: 'Clientes con errores',
    errorData: 'Error al cargar los datos.',
    errorDate: 'La fecha \'Desde\' tiene que ser menor',
    errorDateCycles: 'La fecha FF tiene que ser menor',
    errorLoad: 'Debe cargar un archivo de extensión {{type}}',
    errorProviders: 'Proveedores con errores',
    errors: 'Errores',
    es: 'Español',
    esp: 'ESP',
    event: 'Evento',
    executiveData: 'Datos del back office',
    expirationDate: 'Fecha de expiración',
    expiredInvoices: 'Aprobadas / Pagadas',
    extract: 'Extraer',
    extractAccount: 'Extracción en red B2B',
    extractDetail1: 'Codigo de aprobación generado exitosamente',
    extractDetail2: 'Monto a retirar',
    extractDetail3: 'En',
    extractDetail4: 'Completa la extracción en un punto de nuestra Red',
    extractDetail5: 'Con tu número de telefono',
    extractDetail6: 'Proporciona tu numero de telefono al operador del punto para completar la extraccion iniciada',
    extractDetail7: 'Codigo QR',
    extractDetail8: 'Preséntate con el siguiente codigo en el punto de la red',
    factoring: 'Factoring',
    factoringDiscountAmount: 'Monto descuento por factoring',
    factoringRate: 'Tasa de factoring',
    failedCustomers: 'clientes con errores',
    failedCustomersAlert: '(Clientes con errores no serán tomados en cuenta para registrar)',
    failedProviders: 'empresas con errores',
    failedProvidersAlert: '(Empresas con errores no serán tomadas en cuenta para registrar)',
    fantasyName: 'Nombre de fantasía',
    february: 'Febrero',
    FF: 'FF',
    fileSentSuccessfully: 'El archivo fue enviado con éxito',
    fileSize: 'El tamaño máximo permitido, es 3MB',
    fileSizeExceeded: 'Tu archivo supera el peso máximo de 3MB',
    financingProducts: 'Productos de \nfinanciamiento',
    financingRate: 'Tasa de financiación',
    finish: 'Finalizar',
    fiscaldocument: 'Documento fiscal',
    flexibleConditions: 'Condiciones Flexibles (Financiamiento)',
    flowApproval: 'Flujo de aprobación',
    flowApprovalCheck: 'Flujo de aprobación por defecto',
    flowsApproval: 'Flujos de aprobación',
    FMA: 'Verificación en dos pasos',
    forgotPassword: '¿Olvidó su contraseña?',
    friday: 'Viernes',
    fromReceptionDate: 'Fecha de recepción',
    frontImage: 'Cargar imagen del frente',
    fullName: 'Nombre y apellido',
    FV: 'FV',
    fr: "Francés",
    en: "Ingles",
    pt: "Portugués",
    es: "Español",
    zh: "Chino",
    gateway: 'Gateway',
    general_admin_acquiring: 'Usuario administrador general del banco Adquiriente',
    general_admin_issuing_amex: 'Usuario administrador general del banco Emisor AMEX',
    general_admin_issuing: 'Usuario administrador general del banco Emisor',
    general_admin: 'Usuario administrador general del banco',
    getnet: 'Getnet',
    goBack: 'Regresar',
    grant: 'Conceder',
    greaterThan: 'Mayor',
    greaterThanOrEqualTo: 'Mayor Igual',
    group: 'Grupo ',
    handbook: 'Manual',
    identificationNumber: 'No. de Identificación',
    import: 'Importe',
    inactive: 'Inactivo',
    inactiveBusinesses: 'Comercios inactivos',
    inactiveClients: 'Clientes Inactivos',
    inCharge: 'Responsable',
    incompleteData: 'Datos incompletos',
    incorrectDate: 'No puedes ingresar una fecha anterior a, {{dateNow}}',
    incorrectDays: 'Debes ingresar un valor mayor o igual a 0',
    incorrectEmail: '{{email}} no parece un correo válido. Revisa que tenga el formato correcto: ejemplo@mail.com',
    info: 'Info',
    infoLog: 'Información del evento',
    informationOfTheSelectedBank: 'Información del banco seleccionado',
    insertYourEmailAddress: 'Introduce tu dirección de correo electrónico.',
    installmentNumber: 'Número de cuotas',
    installments: 'N° de cuotas',
    installmentsQty: 'Número de cuotas',
    insurances: 'Seguros',
    interest_rate: 'Monto de interés',
    interestRate: 'Tasa de interés',
    internalRevenueService: 'Servicio de Impuestos Internos',
    intNumber: 'Debe ser un número entero',
    invalidAmount: 'Monto invalido',
    invalidCard: 'Por verificar',
    invalidRoleOrRoute: 'El rol y la ruta no pueden estar vacios',
    invoice: 'Factura',
    invoiceAmount: 'Monto de la factura',
    invoiceApprovedSuccess: 'Factura aprobada exitosamente',
    invoiceCount: 'Número de Facturas',
    invoiceDate: 'Fecha de factura',
    invoiceDetail: 'Detalle de Factura',
    invoiceGroup: 'Grupo de facturas',
    invoiceIssueDate: 'Fecha de emisión factura',
    invoiceManualResolution: 'Facturas que requieren resolución manual',
    invoiceMarkPaid: 'Factura N° {{invoiceNumber}} fue marcada como pagada',
    invoiceN: 'Factura N° {{invoiceNumber}}',
    invoiceNumber: 'N° factura',
    invoiceNumberValidate: 'El formato válido para el ingreso del número de factura es xxx-xxx-xxxxxxxxx',
    invoicePending: 'Por emitir',
    invoiceProvidersReceiver: 'Factura de proveedores por cobrar',
    invoiceReject: 'Rechazar factura',
    invoiceRejectedSuccess: 'Factura rechazada exitosamente',
    invoices: 'Facturas',
    invoicesApproved: 'Facturas Aprobadas',
    invoicesDeferredApproved: 'Facturas Aprobadas y Diferidas',
    invoicesNumber: 'Número de factura',
    invoicesPendingApproved: 'Facturas Pendientes y Diferidas',
    invoicesSent: 'Facturas Enviadas',
    invoiceStatus: 'Estado de la factura en el SII es: {{status}}',
    invoiceStatusInTheSii: 'Estado de la factura en el SII',
    invoicesToBeApproved: 'Facturas por Aprobar',
    invoiceTerms: 'Al aprobar facturas dentro de la plataforma B2B Enterprise, acepto el cargo que se realizará en mi Tarjeta Santander Tesorero American Express, por el monto que se individualiza en cada factura, y del monto del cargo por servicio, si correspondiese de acuerdo a las condiciones comerciales pactadas con su proveedor. Instruye irrevocablemente a Banco Santander, para que tales cargos se efectúen en la referida Tarjeta.',
    invoiceTotalAmount: 'Monto en Facturas',
    invoiceUploaded: 'Factura N° {{invoiceNumber}} cargada en B2B Enterprise exitosamente',
    issuedInvoices: 'Facturas emitidas',
    issuing_bank: 'Banco Emisor',
    issuing_query: 'Banco Emisor de Consulta',
    items: 'Industrias',
    january: 'Enero',
    july: 'Julio',
    june: 'Junio',
    kindOfProduct: 'Tipo de producto',
    language: 'Lenguaje',
    last4Digits: 'Últimos 4 dígitos N° Tarjeta',
    lastDays: 'Últimos días',
    lastMovements: 'Últimos Movimientos',
    lead: 'Alta',
    leadList: 'Listado de \nrequeridos',
    legal: 'Jurídica',
    lengthToCharacters: '* Longitud 8 a 12 caracteres que contengan al menos tres de las siguientes características: letras mayúsculas (A-Z), letras minúsculas (a-z), números (0-9) o caracteres especiales.',
    lessThan: 'Menor',
    lessThanOrEqualTo: 'Menor Igual',
    limit: 'Límite',
    limitAmount: 'Monto límite',
    limitAmountValidate: 'Debes ingresar un valor mayor o igual a 0',
    line: 'Linea',
    listCards: 'Lista de tarjetas',
    listCuttingCycle: 'Lista de ciclos de corte',
    listFaqs: 'Listar FAQ\'s',
    listInvoices: 'En revisión',
    listManuals: 'Listar Manuales',
    listPayments: 'Listado de pagos',
    load: 'Cargar',
    loadBillingCycle: 'Cargar Ciclos de Facturación',
    loadBuyersGroup: 'Cargar grupo de clientes',
    loadCsv: 'Cargar CSV',
    loadCustomers: 'Cargar clientes',
    loadCustomersGroup: 'Cargar grupo de clientes',
    loadFile: 'Cargar archivo',
    loadFromFile: 'Cargar desde archivo',
    loading: 'Cargando...',
    loadingContent: 'Cargando contenido ...',
    loadInvoice: 'Cargar factura',
    loadInvoiceData: 'Cargar datos de factura',
    loadInvoicesGroup: 'Cargar grupo de facturas',
    loadProviders: 'Cargar proveedores',
    loadProvidersGroup: 'Cargar grupo de proveedores',
    loan: 'Préstamo',
    loanPeriod: 'Período del préstamo',
    loanRequest: 'Solicitar préstamo para el adelanto de la factura',
    logout: 'Salir',
    logs: 'Logs',
    logSource: 'Fuente del log',
    logTransaction: 'Log transacciones',
    logView: 'Vista log',
    main_bank_acquiring: 'Usuario Administrador del Banco Adquiriente',
    main_bank_issuing_amex: 'Usuario Administrador del Banco Emisor AMEX',
    main_bank_issuing: 'Usuario Administrador del Banco Emisor',
    main_bank: 'Usuario Administrador del Banco',
    mainInvoiceNumber: 'Número de factura principal',
    mainUser: 'Usuario Principal',
    MAN: 'Resolución Manual',
    mandatoryFieldsRequired: '(*) Debe rellenar todos los campos marcados con un asterisco',
    manualLoaded: 'Manual cargado exitosamente',
    manualResolution: 'Resolución Manual',
    manualsAndFaq: 'Manuales y FAQ\'s',
    march: 'Marzo',
    markAsPaid: 'Marcar como pagado',
    maxRank: '(hasta)',
    may: 'Mayo',
    mccCode: 'Código MCC',
    mdr: 'MDR',
    merchantAffiliateCode: 'Número de afiliado',
    message: 'Mensaje',
    messageUserProcess: 'En este momento no posee un tipo de flujo de aprobación configurado. Por favor comuníquese con su representante para activar esta funcionalidad.',
    minimumCharacter: 'Código de Afilación de Comercio, debe tener entre 8 y 15 caracteres',
    mixedBalance: 'Saldo mixto',
    mixedPayment: 'Pago mixto',
    monday: 'Lunes',
    month: 'Mes',
    monthly: 'Mensual',
    monthlyReport: 'Reporte mensual',
    name: 'Nombre',
    nameFantasy: 'Nombre fantasía',
    naoReter: 'No retener IR (propaganda, publicidad, comisiones y corretaje)',
    NEG: 'Negada',
    new: 'Nueva',
    newBank: 'Nuevo banco',
    newBankingExecutive: 'Nuevo back office',
    newPassword: 'Nueva contraseña',
    newPaymentCondition: 'Nuevas Condiciones de Pago',
    newProfile: 'Nuevo usuario',
    newRecharge: 'Nueva Recarga',
    newRole: 'Nuevo Rol',
    newTransfer: 'Nueva Transferencia',
    next: 'Siguiente',
    nextCollection: 'Próximo cobro',
    nextDays: 'Próximos días',
    nextPage: 'Siguiente',
    nextPayment: 'Próximo pago',
    nInvoices: 'Nro. de Facturas',
    no: 'No',
    noCard: 'No posee tarjeta prepagada',
    noCycles: 'No hay ciclos de corte cargados para: {{month}}',
    noData: 'Sin información',
    noExecutivesAvailable: 'No hay back office',
    noInformation: 'Sin Información',
    noItemsAvailable: 'No hay industrias disponibles',
    nonMatchingPasswords: 'Las contraseñas ingresadas no coinciden',
    noRowsFound: 'Sin información',
    notifyCardPaymentAuthorization: 'Notificar autorización de pago con tarjeta',
    notifyPaymentAuthorization: 'Notificar autorización de pago',
    noTransactions: 'Esta tarjeta no posee movimientos',
    notVerified: 'No verificada',
    notYetFound: 'La factura aún no se encuentra disponible en el SII, si lo desea puede cargarla y B2B Enterprise se encargará de reintentar la búsqueda de la misma y cargarla en nuestra plataforma, de lo contrario cancele la operación e intente cargarla nuevamente más tarde',
    november: 'Noviembre',
    number: 'Número',
    numberInstallments: 'Número de cuotas',
    numberTta: 'Número de TTA',
    october: 'Octubre',
    of: 'de',
    onlyCardPaymentDate: 'Fecha de pago de tarjeta',
    operationLog: 'Log de Eventos',
    operationRank: 'Rango de operaciones (desde)',
    operationVolume: 'Vol. operaciones',
    operator: 'Condición',
    originAccount: 'Cuenta origen',
    otherSign: 'Otras señas',
    PAG: 'Pagado',
    page: 'Páginas',
    paid: 'Pagadas',
    paidAmount: 'Monto pagado',
    paidInvoices: 'Facturas pagadas',
    paidOut: 'Pagado',
    PAP: 'Por aprobar',
    params: 'Parametros',
    password: 'Contraseña',
    passport: 'Pasaporte',
    passwordDigitalCertificate: 'Contraseña del certificado digital',
    passwordHasExpired: 'Su contraseña ha expirado, por favor ingrese su nueva contraseña',
    payedAmounts: 'Total diario facturado',
    payInvoiceWithBalance: '¿Desea cancelar la factura Nu. ${toggleProps.transaction.invoice_number} con el saldo de su cuenta corriente?',
    payment: 'Pago',
    paymentAmount: 'Monto a pagar',
    paymentClient: 'Porcentaje a cargo del cliente',
    paymentCondition: 'Condición',
    paymentConditionClients: 'Condiciones comerciales para clientes de',
    paymentConditionProvider: 'Condiciones comerciales para proveedores de',
    paymentCurrency: 'Moneda de pago',
    paymentDate: 'Fecha de cobro',
    paymentDateInvoice: 'Fecha de cobro de facturas:',
    paymentDateRange: 'Rango de fecha de cobro de facturas',
    paymentDateRangeClient: 'Rango de fecha de pago de facturas',
    paymentDeadline: 'Plazo de pago',
    paymentMethod: 'Método de pago',
    paymentOrders: 'Ordenes de Pago',
    paymentsTransfers: 'Pagos y Transferencias',
    paymentWay: 'Cómo desea cancelar la factura No.',
    paymentWayMsg: 'Al aceptar la opción de pagos mixtos podrá combinar las opciones de pago.',
    PEJ: 'Por Ejecutar',
    PEM: 'Por Emitir',
    penddingInvoice: 'Tiene {{pending_invoices}} factura pendiente sin aprobar',
    penddingInvoices: 'Tienes {{pending_invoices}} facturas pendientes sin aprobar',
    pending: 'Pendiente',
    pendingInvoices: 'Fac. Pendientes',
    pendingInvoicesQTY: 'Tienes {{pending_invoices}} facturas(s) pendiente(s) sin aprobar',
    pendingToApprove: 'Pendiente por aprobar',
    percentage: 'Porcentaje de interes anual',
    period: 'Periodo',
    permanentlyRejectCommercialStatus: 'Rechazar condición comercial permanentemente',
    permissions: 'Permisos',
    person: 'Persona',
    personType: 'Tipo de persona',
    phone: 'Teléfono',
    physical: 'Física',
    platformAdministrator: 'Administrador plataforma',
    platformFaqList: 'Listar FAQ\'s de la Plataforma',
    platformManuals: 'Manuales de la plataforma',
    por: 'POR',
    PRC: 'Pre Cargado',
    prepaidCard: 'Tarjeta Prepagada',
    present: 'Actual',
    previous_page: 'Anterior',
    previous: 'Anterior',
    PRF: 'Por reembolsar',
    print: 'Imprimir',
    printQR: 'Imprimir QR',
    product: 'Producto',
    productContracted: 'Solicitud de contrato del producto: {{name}}, enviada exitosamente',
    productName: 'Nombre del Producto',
    products: 'Seguros / Asistencias',
    productsContracted: 'Productos contratados',
    productType: 'Tipo de producto',
    productUnsubscribedSuccessfully: 'Producto dado de baja exitosamente',
    profile: 'Perfil',
    profileList: 'Lista de usuarios',
    profileName: 'Nombre del perfil',
    provider: 'Proveedor',
    providerB2BRate: 'Tasa B2B que paga el proveedor',
    providerGroup: 'Grupo de proveedores',
    providerName: 'Nombre del proveedor',
    providerPaymentCondition: 'Determina las condiciones de pago entre el cliente y el proveedor',
    providerPercentageCharge: 'Porcentaje cargo por servicio a cargo del proveedor',
    providerRUT: 'RUT Proveedor',
    providers: 'Proveedores',
    providersReceiver: 'Factura de proveedores por cobrar',
    providersSaved: 'Proveedores cargados exitosamente',
    province: 'Provincia',
    PRV: 'Por Reversar',
    PSL: 'Por venta',
    PVD: 'Por Vaciar',
    qualify: 'Habilitar',
    quantity: 'Cantidad',
    question: 'Pregunta',
    QuoteAndRequest: 'Cotizar y Solicitar',
    rate_operator: 'Operador de tarifas financieras',
    rate: 'Porcentaje',
    REC: 'Rechazado',
    receivedInvoices: 'Facturas \nRecibidas',
    receiverDiscountShare: 'Porcentaje de pago del proveedor',
    receptionDate: 'Fecha de emisión de factura',
    recharge: 'Recargar',
    recipient: 'Destinatario',
    recoverPassword: 'Recuperar contraseña',
    referenceNumber: 'Tu número de referencia es',
    referrer: 'Referido',
    region: 'Región',
    registerAdministrator: 'Registrar administrador',
    registerBank: 'Registrar banco',
    registerCard: 'Registrar Tarjeta',
    registerCardAmex: 'Ingrese Tarjeta American Express',
    registeredCards: 'Tarjetas registradas del cliente: {{name}}',
    registerProduct: 'Registrar Producto',
    registerRole: 'Registrar rol',
    registerRoute: 'Registrar ruta',
    registrationStatus: 'Estado de registro',
    reject: 'Rechazar',
    rejectedChecks: 'Cheques rechazados',
    rejectedCycle: 'Ciclo de facturación rechazado exitosamente.',
    rejectedInvoices: 'Facturas Rechazadas',
    rejectInvoice: 'Rechazadas',
    rejectInvoiceAll: 'Facturas rechazadas',
    rejectInvoices: 'Tienes {{reject_invoices}} factura(s) rechazadas',
    rejectReason: 'Motivo de Rechazo',
    rejectTradeRequest: 'Rechazar solicitud comercial',
    relatedUsers: 'Usuarios Relacionados',
    rememberToAcceptTheTerms: 'Recuerde aceptar los términos y condiciones para contratar el producto',
    removeRouteSuccess: 'La ruta fue removida exitosamente',
    replaceTheSelectedCard: 'Está seguro de sustituir la tarjeta seleccionada.',
    reports: 'Reportes',
    request: 'Solicitudes',
    requestEdition: 'Solicitar edición',
    requestingBank: 'Banco solicitante',
    requestProducts: 'Solicite su seguro o asistencia',
    requestReceived: 'Recibidas',
    requestSent: 'Enviadas',
    requestSentProduct: 'Solicitud enviada',
    requestsReceived: 'Solicitudes recibidas',
    requestsSent: 'Solicitudes enviadas',
    requestUnsubscribe: 'Solicitar baja',
    requiredFields: '(*) Campos obligatorios',
    requiredInstallments: 'El numero de cuotas es invalido',
    requiredName: 'El nombre es invalido',
    requiredPeriod: 'El periodo es invalido',
    requiredRate: 'La tasa es invalida',
    requiredTypeFinance: 'El tipo de financiamiento es invalido',
    requireSuccess: '{{name}} requerido con exito',
    resource: 'Recurso',
    respJson: 'Respuesta JSON',
    response: 'Respuesta',
    respXml: 'Respuesta XML',
    return: 'Volver',
    REV: 'Reversado',
    RFD: 'Reembolsado',
    rol: 'Rol',
    roleCantBeEmpty: 'El rol no puede estar vacio',
    roleList: 'Lista de roles',
    roles: 'Roles',
    roleType: 'Tipo del rol',
    rolName: 'Nombre del rol ',
    rolType: 'Tipo del rol',
    routeList: 'Lista de rutas',
    routes: 'Rutas',
    rows: 'Filas',
    RUT: 'RUT',
    safetyRules: 'Necesitamos conectar su empresa al SII para poder obtener las facturas emitidas por sus proveedores a su rázon social, para ello le agradecemos ingresar los siguientes datos:',
    saturday: 'Sábado',
    save: 'Guardar',
    savedCards: 'Tarjetas de crédito guardadas',
    saveFaq: 'Manuales y FAQ',
    savePlatformFaq: 'Cargar FAQ\'s de la Plataforma',
    saveQuestion: 'Cargar FAQ\'s',
    search: 'Buscar',
    searchBuyers: 'Buscar Clientes',
    searchSuppliers: 'Buscar Proveedores',
    searchUsers: 'Buscar Usuarios',
    secureCards: 'La información de tus tarjetas de crédito serán almacenadas bajo estrictas normas de seguridad',
    seeDetail: 'Ver detalle',
    seeHow: 'Ver como',
    seeQuestion: 'Ver pregunta',
    seeUsers: 'Ver usuarios',
    Select: 'Seleccione ...',
    selectANewCommercialExecutive: 'Debe seleccionar un nuevo back office',
    selectBank: 'Debe seleccionar un Banco',
    SelectBank: 'Seleccione un banco',
    selectCard: 'TTA Seleccionada',
    selectCycle: 'Debe seleccionar todos los ciclos',
    SelectDocumentType: 'Elige el tipo de documento para continuar',
    selectedBank: 'Banco',
    selectFile: 'Seleccionar archivo',
    selectInvoice: 'Selecciona la factura',
    selectMonth: 'Debe seleccionar un mes',
    SelectOption: 'Seleccione una opción',
    selectOrTypeItems: 'Seleccione o escriba las industrias...',
    selectOrTypePermissions: 'Seleccione o escriba el permiso',
    selectOrTypeRol: 'Seleccione o escriba los roles',
    selectOrTypeRoutes: 'Seleccione o escriba la ruta',
    selectRecipient: 'Seleccionar destinatario',
    seller: 'Proveedor',
    sellerCode: 'Código del proveedor',
    sellerCreateLead: 'Requerir alta de proveedor',
    sellerName: 'Nombre del proveedor',
    sellersQuantity: 'Cantidad de Proveedores',
    send: 'Enviar',
    send2FASuccess: 'Código enviado correctamente',
    sendDate: 'Fecha envío',
    senderDiscountShare: 'Porcentaje de pago del cliente',
    sendErp: 'Enviar ERP',
    sendInformation: 'Enviar información',
    sendInvoice: 'Fecha de envío de factura',
    sentInvoices: 'Facturas \nenviadas',
    september: 'Septiembre',
    settings: 'Ajustes',
    shippingDate: 'Fecha de envío',
    shouldNotSelect: 'No debe seleccionar el mismo Ejecutivo Comercial, que quiere eliminar',
    showPassword: 'Mostrar contraseña',
    showQR: 'Mostrar código QR',
    since: 'Desde',
    startCountingFrom: 'Días pactados desde',
    startDate: 'Fecha de inicio',
    status: 'Estado',
    statusOfInvoice: 'El estado de la factura N° {{invoiceNumber}} en el SII es: {{status}}.',
    street: 'Calle',
    successCard: 'Su tarjeta ya fue registrada.',
    successCycle: 'Ciclo de facturación confirmado exitosamente.',
    successCycles: 'Ciclos de facturación creado exitosamente.',
    successEmail: 'Se ha enviado un correo a tu dirección de correo electrónico.',
    successExecutive: 'Back office creado exitosamente',
    successfulFactoring: 'Factoring realizado exitosamente',
    successfullyAssignedCuttingCycle: 'Ciclo de corte asignado exitosamente',
    successfullyUpdatedManual: 'Manual actualizado con exito',
    suggestTradeConditionCorrection: 'Sugerir correción de condición comercial',
    summary: 'Resumen',
    sunday: 'Domingo',
    support: 'Asistencia',
    supportLink: 'Link de soporte',
    target: 'Tarjeta',
    termsAndConditions: 'Términos y Condiciones',
    theCodeExpiresIn: 'El código vence a las: {{time}}',
    theInvoiceWillBeCharged: 'La factura se cargará en B2B Enterprise en estado, {{status}}',
    thereIsNoInformationOnFlexibleConditions: 'No se posee información de condiciones flexibles',
    theyMustNotHave: '* No deberán tener caracteres idénticos consecutivos, ni totalmente numéricos, ni totalmente alfabéticos.',
    thursday: 'Jueves',
    time: 'Hora',
    toBeApproved: 'Por Aprobar',
    toDisable: 'Deshabilitar',
    total: 'Total',
    totalAmount: 'Importe total',
    totalAmountToPayOnTheInvoice: 'Monto total a pagar en la factura',
    totalAmountToReceive: 'Monto total a recibir',
    totalBuyers: 'Total Empresas Pagadoras',
    totalBuyersDeferred: 'Empresas con Facturas Diferidas',
    totalBuyersInvoiced: 'Empresas con Facturas Aprobadas',
    totalBuyersTh: 'Total Empresas TH',
    totalCharged: 'Total cobrado {{month}}',
    totalCommissionAmount: 'Comision total',
    totalDebtAmount: 'Monto total a pagar',
    totalInvoices: 'Total facturas',
    totalOwedAmount: 'Monto total a cobrar',
    totalPay: 'Total a pagar',
    totalReceiver: 'Total a cobrar',
    totalSellers: 'Proveedores Afiliados',
    totalSellersCharged: 'Proveedores con Facturación Aprobada',
    totalSellersInvoiced: 'Proveedores con Facturación Pendiente',
    totalToApprove: 'Total por aprobar',
    totalToPayCardholder: 'Total a Pagar Tarjetahabiente Cargo por Servicio',
    totalToPayInvoice: 'Total a Pagar Factura',
    totalToReceive: 'Total por cobrar',
    totalVolumePendingPayment: 'Total de volumen pendiente por pagar',
    tradeInformation: 'Información de comercio',
    transactionReport: 'Reporte de transacciones',
    transactions: 'Transacciones',
    transactionsToProcess: 'Transacciones por procesar',
    transactionType: 'Tipo de transacción',
    transfer: 'Transferir',
    transferCharge: 'Cargo de la operación',
    transferDetail: 'Transferencia Realizada',
    transferDetail2: 'Comprobante',
    transferDetail3: 'Enviado a',
    transferDetail4: 'Monto enviado',
    transferDetail5: 'Monto total descontado',
    transferring: 'Transfiriendo',
    transferState: 'Estado',
    transferType: 'Tipo de transacción',
    TtaValidation: 'Validación de TTA',
    tuesday: 'Martes',
    type: 'Tipo',
    typeAndDocumentNumber: 'Tipo y Nro. de documento',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Tipo de flujo de aprobación',
    typeOfEmitFlow: 'Tipo de flujo de emisión',
    underReview: 'En revisión',
    underReviewInvoices: 'Facturas en revisión',
    Unsubscribe: 'Dar de baja',
    unsuscribeRequest: 'Solicitud de baja',
    until: 'Hasta',
    updateBank: 'Actualizar banco',
    updatedBillingCycle: 'Ciclo de facturación actualizado',
    updatedBillingCycles: 'Ciclos de facturación actualizado',
    updateInfo: 'Actualizar información',
    updateInformation: 'Actualizar información',
    updatePlatformFaq: 'Editar FAQ\'s de la Plataforma',
    uploadInvoice: 'Cargar factura',
    uploadManuals: 'Cargar Manuales',
    user_list: 'Lista de Usuarios',
    user: 'Usuario',
    userBillingCycles: 'Usuario ciclos de facturación',
    userManual: 'Manual de usuario',
    username: 'Nombre de usuario',
    userQuery: 'Usuario consulta',
    users: 'Usuarios',
    validCard: 'Verificada',
    validEmail: 'Debe ingresar correos que pertenezcan a @baccredomatic.com',
    validity: 'Vigencia',
    valorNeto: 'Valor neto',
    vendorMerchantAffiliateCodes: 'Número de afiliado del proveedor: {{provider}}',
    verificationCode: 'Código de verificación',
    verificationCodeSent: 'Ingrese el código  de verificación enviado a su correo',
    verifyHuman: 'Por favor verifica que eres humano',
    view: 'Ver',
    viewTta: 'Ver TTA',
    virtualAccount: 'Cuenta Virtual',
    VOD: 'Vaciado',
    volumeProcessed: 'Volumen procesado en los últimos tres meses',
    waitingBillingCycle: 'Esperando asignación de ciclo de facturación',
    waitingForApproval: 'Esperando aprobación de solicitud de baja del servicio',
    webpay: 'Webpay',
    wednesday: 'Miércoles',
    whatPaymentCondition: '¿Que condición de pago desea utilizar?',
    yearly: 'Anual',
    yes: 'Sí',
    yourDataWillBeStoredEncrypted: '*Sus datos serán almacenados encriptados bajo estrictas normas de seguridad.',
    '/create-provider': 'Crear Proveedores',
    '/edit-provider': 'Editar Proveedores',
    '/create-customer': 'Crear Clientes',
    '/edit-customer': 'Editar Clientes',
    '/create-profile': 'Crear Perfil',
    '/dashboard': 'Resumen',
    '/account-details': 'Detalles de Cuenta',
    '/extract': 'Extraer',
    '/prepaid-Card': 'Tarjeta Prepaga',
    '/transfer': 'Transferir',
    '/deposit': 'Depositar',
    '/load-invoice': 'Cargar Facturas',
    '/customers': 'Listar, Editar o Deshabilitar Clientes',
    // '/invoices': 'Listar facturas',
    '/invoices': 'Facturas',
    '/expired-invoices': 'Listar Facturas Expiradas',
    '/related-users': 'Cliente o Proveedores Relacionados', //REVISAR
    '/clients': 'Clientes',
    '/providers': 'Proveedores',
    '/register-bank': 'Crear Bancos',
    '/bank-list': 'Listar, Editar o Deshabilitar Bancos',
    '/role-list': 'Listar, Editar y Eliminar Roles',
    '/invoice-detail': 'Detalle de Factura',
    '/register-financing-products': 'Crear Financiamiento de Productos',
    '/financing-products': 'Financiar Productos',
    '/register-role': 'Crear Roles',
    '/route-list': 'Listar, Editar y Eliminar Rutas',
    '/register-route': 'Crear Rutas',
    '/admin-routes': 'Asignar o Quitar Rutas a Roles',
    '/review-invoices': 'Facturas en Revisión',
    '/reject-invoices': 'Facturas Rechazadas',
    '/approved-invoices': 'Facturas Aprobadas',
    '/paid-invoices': 'Facturas Pagadas',
    '/pending-invoices': 'Facturas Pendientes',
    '/create-lead': 'Requerir Clientes o Proveedores',
    '/lead-list': 'Listar, Aprobar o Rechazar Requeridos',
    '/reports': 'Reportes',
    '/payment-conditions-log': 'Registro de Condiciones de Pago',
    '/transactions': 'Facturas Generadas por Banco',
    '/profile-list': 'Listar, Editar o Eliminar Perfiles',
    '/create-approval-flow': 'Crear Flujo de Aprobación de Factura',
    '/edit-approval-flow': 'Editar Flujo de Aprobación de Factura',
    '/approval-flow-list': 'Lista de Flujo de Aprobación', 
    '/account-profile': 'Perfil de la Cuenta',
    '/payment-conditions': 'Condiciones de Pago',
    '/create-bank-user': 'Crear Usuario del Banco',
    '/edit-bank-user': 'Editar Usuario del Banco',
    '/bank-user-list': 'Lista de Usuarios del Banco',
    '/register-card': 'Registrar Tarjeta',
    '/replace-card': 'Sustitución de Tarjeta de Crédito',
    '/list-cards': 'Lista de Tarjetas',
    '/payment-request-sent': 'Solicitudes',
    '/payment-request-received': 'Solicitudes',
    '/save-credentials': 'Guardar Credenciales',
    '/edit-credentials': 'Editar Credenciales',
    '/load-billing-cycle': 'Cargar Ciclo de Facturación',
    '/edit-billing-cycle': 'Editar Ciclo de Facturación',
    '/billing-cycles': 'Ciclos de Facturación',
    '/banking-executives': 'Back Office',
    '/create-banking-executive': 'Crear Back Office',
    '/load-executives': 'Cargar Ejecutivos',
    '/products': 'Productos',
    '/contracted-products': 'Productos Contratados',
    '/product-detail': 'Detalle de Productos',
    '/accept-discount-rate': 'Aceptar Tasa de Descuento',
    '/edit-bank-admin': 'Editar Administrador del Banco',
    '/save-manual': 'Guardar Manual',
    '/list-manual': 'Manuales',
    '/save-faq': 'Cargar FAQ\'s',
    '/list-faqs': 'Listar FAQ\'s',
    '/support': 'Asistencia',
    '/monthly-report': 'Reporte Mensual',
    '/commerce-code': 'Código de Comercio',
    '/confirm-provider': 'Confirmar Proveedor',
    '/confirm-customer': 'Confirmar Cliente',
    '/user-commerce-code': 'Número de Afiliado',
    '/create-payment-condition': 'Crear Nueva Condición de Pago',
    '/edit-payment-condition': 'Editar Condición de Pago',
    '/provider-group-load': 'Grupo de Proveedores',
    '/customer-group-load': 'Grupo de Clientes',
    '/list-provider': 'Lista de Proveedores',
    '/list-customer': 'Lista de Clientes',
    '/create-admin-bank': 'Crear Banco Administrador',
    '/edit-admin-bank': 'Editar Banco Administrador',
    '/admin-bank-list': 'Bancos Administradores',
    '/resp': 'Confirmación de Almacenamiento de Tarjeta',
    '/log-view': 'Logs',
    '/operation-log': 'Logs',
    '/mixed-payments': 'Programar Pago Mixto',
    '/credit-request': 'Créditos y Préstamos',
    '/credits-list': 'Registro de Créditos',
    '/check-deposit': 'Cheque a Depositar',
    '/approved-checks': 'Cheques Aprobados',
    '/not-approved-checks': 'Cheques por Aprobar',
    '/rejected-checks': 'Cheques Rechazados',
    '/send-information': 'Enviar ERP',
    '/load-invoice-group': 'Grupo de Facturas',
  },
};

export default es;
