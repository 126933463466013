// eslint-disable-next-line import/no-cycle
import { providersActions, providersTypes } from '../ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';

const addProviders = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.TRANSFER) {
    return;
  }
  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/user-transaction',
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const sendInvoice = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.LOAD_INVOICE) {
    return;
  }

  const {
    data,
    data: {
      buyer,
      seller,
      amount,
      detail_amount,
      date,
      invoice_number,
      description,
      file,
      type_dte,
      commerce_code,
    },
    callback = () => {},
  } = action.payload;

  // eslint-disable-next-line no-undef
  const dataForm = new FormData();
  if (file) {
    const dateFormat = new Date(date);
    dataForm.append('buyer', buyer);
    dataForm.append('seller', seller);
    dataForm.append('amount', amount);
    dataForm.append('detail_amount', JSON.stringify(detail_amount));
    dataForm.append('date', `${dateFormat.getFullYear()}/${dateFormat.getUTCMonth() + 1}/${dateFormat.getUTCDate()}`);
    dataForm.append('invoice_number', invoice_number);
    dataForm.append('description', description);
    dataForm.append('file', file);
    dataForm.append('type_dte', type_dte);
    dataForm.append('commerce_code', commerce_code);
  }
  const dataSend = file ? dataForm : data;
  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/load',
        data: dataSend,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const advance = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.ADVANCE_INVOICE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/advance',
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const factoringInvoice = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.FACTORING_INVOICE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b/factoring',
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const newProvider = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.CREATE_PROVIDER) {
    return;
  }

  const {
    data,
    is_perfil = false,
    callback = () => {
    },
  } = action.payload;
  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/register?is_perfil=${is_perfil}`,
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          callback(response.data.data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const fetchProvider = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_PROVIDER) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/provider?provider=${id}`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          dispatch(providersActions.setProvider(data));
          callback(data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const fetchProviders = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_PROVIDERS) {
    return;
  }

  const {
    params: {
      id = '',
      related = false,
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/providers?client=${id}&related=${related}`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(providersActions.saveProviders(data));
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const fetchProvidersConditions = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_PROVIDERS_CONDITIONS) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/providers-with-conditions?client=${id}`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(providersActions.setProvidersConditions(data));
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const editProvider = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.EDIT_PROVIDER) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/user/update-user-information',
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { data: { email }, data: rspData, success } }) => {
        if (success) {
          callback(rspData);
          if (email !== null && email.error) {
            dispatch(providersActions.showMessage({
              message: email.message,
              config: TOAST_CONFIG.ERROR,
            }));
          }
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const altaRequire = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.ALTA_REQUIRE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/b2b-leads',
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { data: rspData, success } }) => {
        if (success) {
          callback(rspData);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const readInvoiceQr = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.QR_READER) {
    return;
  }

  const {
    params: {
      provider_id = '',
    } = {},
    callback = () => { },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b/qr-invoice?provider_id=${provider_id}`,
      },
      authorization: false,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const loadSiiInvoice = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.LOAD_SII_INVOICE) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;


  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/sii/load-invoice`,
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const infoSiiInvoice = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.INFO_SII_INVOICE) {
    return;
  }

  const {
    params: {
      invoice_number = '',
      date_sii = '',
      buyer_id = '',
      dummy = '',
      type_dte = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/sii/invoice-info-sii?invoice_number=${invoice_number}&date_sii=${date_sii}&buyer_id=${buyer_id}&dummy=${dummy}&type_dte=${type_dte}`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(providersActions.setProvider(data));
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const getTypeDte = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_TYPE_DTE) {
    return;
  }

  const {
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/sii/type-dte`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(providersActions.getType(data));
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const providerCommerceCode = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.PROVIDER_COMMERCE_CODE) {
    return;
  }

  const {
    params: {
      user = '',
      role = ''
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-commerce-code/provider-commerce-code?user=${user}&role=${role}`,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const getProviderCommerceCode = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_PROVIDER_COMMERCE_CODE) {
    return;
  }

  const {
    params: {
      id = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-commerce-code/provider-commerce-code?user=${id}`,
      },
      authorization: false,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        //if (success) {
        callback(data);
        dispatch(providersActions.getCommerceCode(data));
        /* } else {
            callback([]);
        }*/
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const getPaymentCommerceCode = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.GET_PAYMENT_COMMERCE_CODE) {
    return;
  }

  const {
    params: {
      sender = '',
      receiver = '',
    } = {},
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/b2b-commerce-code/payment-commerce-code?sender=${sender}&receiver=${receiver}`,
      },
      authorization: false,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
          dispatch(providersActions.setProvider(data));
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const userFileLoad = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.USER_FILE_LOAD) {
    return;
  }

  const {
    data,
    data: {
      bank_id,
      role,
      users_file,
    },
    callback = () => {
    },
    t = () => {
    },
  } = action.payload;

  const dataForm = new FormData();
  if(users_file){
    dataForm.append('bank_id', bank_id);
    dataForm.append('role', role);
    dataForm.append('users_file', users_file);
  }
  const dataSend = users_file ? dataForm : data;

  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/users-file-load',
        data: dataSend,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: (response) => {
        dispatch(providersActions.setProvidersConditions(response.data.data));
        callback(response.data.data);
      },
      onEnd: providersActions.endFetch,
    },
  });
};

const loadProvidersGroup = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== providersTypes.LOAD_PROVIDERS_GROUP) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;


  dispatch({
    type: providersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/user-group-register`,
        data,
      },
      authorization: true,
      onStart: providersActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        if (success) {
          callback(data);
        }
      },
      onEnd: providersActions.endFetch,
    },
  });
};

export default [
  addProviders,
  sendInvoice,
  advance,
  newProvider,
  fetchProviders,
  fetchProvidersConditions,
  editProvider,
  altaRequire,
  readInvoiceQr,
  fetchProvider,
  loadSiiInvoice,
  infoSiiInvoice,
  getTypeDte,
  providerCommerceCode,
  getProviderCommerceCode,
  getPaymentCommerceCode,
  userFileLoad,
  loadProvidersGroup,
  factoringInvoice,
];
