import React from 'react';
import './styles.scss';
import AmexLogo from '../../../../resources/images/assets/amex.png';

const Footer = () => {
  const d = new Date();
  const n = d.getFullYear();
  return (
    <footer>
      <div>
        <figure className="image mr-16px">
          <img src={AmexLogo} alt="logo"/>
        </figure>
        <p>
          <span><strong>Copyright ©{` ${n} `}</strong> Business Link.</span>
          <span>Powered by American Express®. All rights reserved.</span>
        </p>
      </div>

    </footer>
  );
};

export default Footer;