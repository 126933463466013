import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { Loading, PaymentConditionForm, } from '../../components';
import { cardActions } from '../../state/ducks/card';
import 'moment/locale/es';
import { TOAST_CONFIG } from '../../config/constants';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import { providersActions } from '../../state/ducks/providers';

class CreatePaymentCondition extends Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
    } = props;
    this.state = { ...this.getInitialState(), ...state };
  }

  getInitialState = () => ({
    cycle_date: new Date(),
    id: '',
    baseDays: 0,
    percentage: 0,
    discountRate: 0,
    receiverDiscountShare: 0,
    client: null,
    provider: null,
    clientPayment: 0,
    days: 365,
    editable: false,
    codes: [],
    codeDescription: '',
    commerceCode: '',
    hidden: false,
  });

  resetState = () => {
    this.setState(this.getInitialState());
  };

  componentDidMount() {
    /*const {
      userInfo,
      codesProvider,
    } = this.props.location.state;*/
  };

  getGridSettings = () => {
    const {
      t,
      card: { list },
      deleteCard,
      showMessage,
      history,
    } = this.props;

    const columns = [
      {
        expander: true,
        show: false,
      },
      {
        id: 'numberTta', // Required because our accessor is not a string
        Header: t('numberTta'),
        accessor: d => d.number,
      },
      {
        id: 'expirationDate', // Required because our accessor is not a string
        Header: t('expirationDate'),
        accessor: d => d.due_pay,
      },
      {
        id: 'billingCycle', // Required because our accessor is not a string
        Header: t('billingCycle'),
        accessor: d => d.billing_cycle !== null ? d.billing_cycle.cycle : '',
      },
      {
        id: 'TtaValidation', // Required because our accessor is not a string
        Header: t('TtaValidation'),
        accessor: d => d.is_validate === '1' ? t('validCard') : t('notVerified'),
      },
      {
        id: 'action', // Required because our accessor is not a string
        Header: t('action'),
        show: false,
        accessor: d => d.number,
        Cell: cellInfo => (
          <table>
            <tbody>
            <tr>
              <td>
                <button className="button is-primary is-inverted"
                        onClick={() => {
                          deleteCard({
                            params: {
                              cardId: cellInfo.original.id,
                            },
                            callback: () => {
                              /*getCard({
                                param: {
                                  id: clientList.buyer[0].id,
                                }
                              });*/
                              showMessage({
                                message: t('deleteTta'),
                                config: TOAST_CONFIG.SUCCESS,
                              });
                              history.push('/dashboard');
                            }
                          });
                        }}
                >
                  <i className="fas fa-minus-circle mr-8px" aria-hidden="true"
                     style={{ color: 'red' }}/>
                  {t('delete')}
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        )
      },
    ];
    const data = list;
    const title = t('profileList');
    return {
      columns,
      data,
      title,
    };
  };

  getDaysInv = (period) => {
    switch (period) {
      case 0:
        return 365;
      case 1:
        return 30;
      case 2:
        return 1;
      default:
        return '';
    }
  };

  newPaymentCondition = (onToggle) => {
    const {
      auth: { user: { b2b: { type_user } } },
      clients: { list: clientList },
      providers: { list: providerList },
      requestPaymentCondition,
      t,
      showMessage,
      history,
      createPaymentCondition,
    } = this.props;
    const {
      userInfo,
      client,
      provider,
      discountRate,
      receiverDiscountShare,
      baseDays,
      startCountingFrom,
      //limitAmount,
      clientPayment,
      commerceCode,
      period,
      percentage,
    } = this.state;
    const days = baseDays;
    const persons = type_user === 'acquiring_bank' ? clientList : providerList;
    let response = '',
      response1 = '',
      response2 = '';

    if (period !== -1 && percentage !== 0) {
      // createPaymentCondition({
      //   data: {
      //     sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
      //     receiver_id: type_user === 'acquiring_bank' ? userInfo.id : persons[provider].id,
      //     /*sender_id: auth.user.b2b.id,
      //     receiver_id: id,*/
      //     percentage,
      //     days: this.getDaysInv(period),
      //     is_request: 0,
      //     person_commerce_code: commerceCode,
      //     type: 'advance',
      //   },
      //   callback: (response) => {
          requestPaymentCondition({
            data: {
              bank_sender_id: true, // TEST FOR PAYMENT REQUEST
              sender_request_id: null, // TEST FOR PAYMENT REQUEST
              sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
              receiver_id: type_user === 'acquiring_bank' ? userInfo.id : persons[provider].id,
              /*sender_id: clients.list[client].id,
              receiver_id: id,*/
              discount_rate: discountRate,
              due_from_approval: startCountingFrom,
              days: baseDays,
              receiver_discount_share: receiverDiscountShare / 100,
              person_commerce_code: commerceCode,
              // is_request: 0,
              type: 'base',
            },
            callback: (response) => {
              // response2 = response;
              showMessage({
                message: t('createPaymentConditionSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
              this.resetState();
              history.push('/dashboard');
            },
          });
      //   },
      // });
    }

    /*const conditionData = {
      bank_sender_id: true,
      sender_request_id: null,
      sender_id: type_user === 'acquiring_bank' ? persons[client].id : userInfo.id,
      receiver_id: type_user === 'acquiring_bank' ? userInfo.id : persons[provider].id,
      days,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      type: 'base',
      person_commerce_code: commerceCode,
    };
    if (days < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
    /*requestPaymentCondition({
      data: conditionData,
      callback: () => {
        showMessage({
          message: t('createPaymentConditionSuccess'),
          config: TOAST_CONFIG.SUCCESS,
        });
        this.resetState();
        history.push('/dashboard');
      },
    });*/
    //}
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else
    if(name === "document_number"){

      let num = this.formatRUT(event.target.value);
      let prenum = '';
      let postnum = '';
      if(num.length >= 9){
        prenum = num.slice(0,8);
        postnum = num.slice(8,9);
        num = prenum+"-"+postnum;
      }

      this.setState({
        [name]: (num),
      })
    } else*/
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: (value),
      });
    }
  };

  handlePercentageChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
      clientPayment: 100 - Number(value),
    });
  };

  handleSelectChange = (newVal, label) => {
    const {
      auth: { user: { b2b: { type_user } } },
      clients,
      providers, providerCommerceCode, role: { list }
    } = this.props;
    const { editable, codes, codesProvider } = this.state;
    this.setState({
      [label.name]: newVal.value,
    });
    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    if (label.name === 'provider') {
      providerCommerceCode({
        params: {
          user: providers.list[newVal.value].id,
          role: role_id,
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }

    if (label.name === 'commerceCode') {
      this.setState({
        commerceCode: type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].id
          : codes[newVal.value].id,
        codeDescription: type_user === 'acquiring_bank' ? codesProvider.commerce_codes[newVal.value].description
          : codes[newVal.value].description,
      });
    }

    if (editable) {
      const index = newVal.value;
      const conditions = label.name === 'client' ? clients.list[index].conditions : providers.list[index].conditions;

      const percentageValue = conditions.receiver_discount_share * 100;

      this.setState({
        baseDays: conditions.days,
        percentage: conditions.percentage,
        discountRate: conditions.discount_rate,
        receiverDiscountShare: percentageValue,
        clientPayment: 100 - percentageValue,
        //limitAmount: conditions.limit_amount,
      });
    }
  };

  handleOnInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, '');
    this.setState({ inputValue: newValue });
    return newValue;
  };

  cancelSave = () => {
    const { history } = this.props;
    history.push('/dashboard');
  };

  render() {
    const {
      t,
      auth: { user: { b2b: { type_user } } },
      clients: { list: clientList, loading: clientLoading },
      providers: { list: providerList, loading: providerLoading },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const {
      client,
      provider,
      baseDays,
      discountRate,
      receiverDiscountShare,
      editable,
      clientPayment,
      hidden,
      inputValue,
      codes,
      commerceCode,
      codeDescription,
      codesProvider,
      userInfo,
      percentage,
      period,
    } = this.state;
    const loading = paymentConditionLoading || clientLoading || providerLoading;
    //const persons = type_user === 'acquiring_bank' ? clientList : providerList;

    return (
      <div>
        {loading && <Loading/>}
        <PaymentConditionForm
          t={t}
          title={t('createCondition')}
          onSubmit={() => this.newPaymentCondition()}
          handleInputChange={this.handleInputChange}
          handlePercentageChange={this.handlePercentageChange}
          isClient={type_user !== 'acquiring_bank'}
          isConditionClient={type_user === 'buyer'}
          isConditionProvider={type_user === 'seller'}
          handleSelectChange={this.handleSelectChange}
          persons={type_user === 'acquiring_bank' ? clientList : providerList}
          client={client}
          provider={provider}
          baseDays={baseDays}
          discountRate={discountRate}
          receiverDiscountShare={receiverDiscountShare}
          clientPayment={clientPayment}
          editable={editable}
          //limitAmount={limitAmount}
          hidden={hidden}
          handleOnInputChange={this.handleOnInputChange}
          inputValue={inputValue}
          closeModal={this.cancelSave}
          codes={type_user === 'acquiring_bank' ? codesProvider.commerce_codes : codes}
          commerceCode={commerceCode}
          codeDescription={codeDescription}
          percentage={percentage}
          period={period}
        />
        <br/>
      </div>
    );
  }
}

CreatePaymentCondition.propTypes = {
  t: PropTypes.func.isRequired,
  getCycleCard: PropTypes.func.isRequired,
};

const mapStateToProps = ({ card, clients, auth, providers, role, paymentConditions, }) => (
  {
    card,
    clients,
    auth,
    providers,
    role,
    paymentConditions,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...cardActions,
    ...paymentConditionsActions,
    ...providersActions,
  }),
  withNamespaces(),
)(CreatePaymentCondition);