import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, Panel, TextInput } from '../../../../../components';

const FormRecover = ({
  t,
  onToggle,
  handleReset,
  handleInputChange,
  data,
}) => {
  const { username } = data;
  return (
    <>
    <Panel headingText={t('recoverPassword')}>
      <div className="FormLogin">
        <div className="column is-full" style={{ color: 'black', fontFamily: 'Roboto, Arial, sans-serif' }}>
          <span>{t('insertYourEmailAddress')}</span>
        </div>
        <Form
          className="column is-full"
          submitText={t('send')}
          onSubmit={(event) => {
            event.preventDefault();
            handleReset();
          }}
          buttonClasses="is-fullwidth"
        >
          <TextInput
            name="username"
            placeholder={t('username')}
            type="text"
            onChange={handleInputChange}
            value={username}
            isRequired
          />
          <div style={{fontFamily: 'sans-serif', marginBottom: '1em'}}>
            <label>{t('requiredFields')}</label>
          </div>
        </Form>
      </div>
    </Panel>
    </>
  );
};

FormRecover.propTypes = {
  t: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
  }).isRequired,
};

export default withNamespaces()(FormRecover);
