import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Loading, Panel, PaymentConditionForm, TableSimple, Toggle } from '../../components';
import { getPath } from '../../util';
import { statsActions } from '../../state/ducks/stats';
import { authActions } from '../../state/ducks/auth';
import './styles.scss';
import { paymentConditionsActions } from '../../state/ducks/paymentConditions';
import Modal from '../../components/Modal';
import { clientsActions } from '../../state/ducks/clients';
import { providersActions } from '../../state/ducks/providers';
import { TOAST_CONFIG } from '../../config/constants';
import { Link } from 'react-router-dom';
import { roleActions } from '../../state/ducks/role';

class RelatedUsers extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    persons: '',
    client: null,
    provider: null,
    baseDays: 0,
    editable: false,
    startCountingFrom: 0,
    //limitAmount: 0,
    band: false,
    clientEdit: '',
    discountRate: 0,
    receiverDiscountShare: 0,
    clientPayment: 0,
    editPercentage: 0,
    hidden: true,
    codes: [],
    codeDescription: '',
    commerceCode: '',
  });

  componentDidMount() {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user, roles },
        },
      },
      fetchRelatedUsers,
      getRelatedClientAssist,
      getClients,
      getProviders,
      providerCommerceCode,
    } = this.props;
    if (type_user === 'buyer' || type_user === 'seller') {
      fetchRelatedUsers({});
      providerCommerceCode({
        params: {
          user: user_id,
          role: roles[0].id
        },
        callback: (response) => {
          this.setState({ codes: response.commerce_codes });
        },
      });
    }
    if (type_user === 'admin_amex') {
      getClients({});
      getProviders({});
    }
    if (type_user === 'seller_assist_card') {
      getRelatedClientAssist({});
    }
  }

  resetState = () => {
    this.setState(this.getInitialState());
  };

  getGridSettings = (onToggle) => {
    let columns = null;
    let data = null;
    let title = null;
    const {
      t,
      history,
      stats: { assist, relatedUsers },
      auth: {
        user: {
          user_id, b2b: { type_user },
        },
      },
      getPaymentCondition,
      clients: { list: listClient },
      providers: { list: listProvider },
      router: { location: { pathname } },
      role: { list },
      providerCommerceCode,
    } = this.props;
    const {
      codes,
    } = this.state;

    let role_id = '';
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === 'seller') {
        role_id = list[i].id;
      }
    }
    let codes1 = [];

    switch (type_user) {
      case 'buyer':
      case 'seller':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('RUT'),
            accessor: d => `${getPath(['idPerson', 'document_number'], d.user)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d.user)}`,
          },
          /*{
            id: 'uniqueCode',
            Header: t('uniqueCode'),
            accessor: d => d.user.cod !== null ? `${d.user.cod}` : '',
          },*/
          /*{
            id: 'buyerCode',
            Header: t('buyerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.sender !== null ? `${d.commerce_codes.sender}` : '',
          },*/
          {
            id: 'sellerCode',
            Header: t('sellerCode'),
            headerClassName: 'wordwrap',
            accessor: d => d.commerce_codes.receiver !== null ? `${d.commerce_codes.receiver}` : '',
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            headerClassName: 'wordwrap',
            accessor: d => d.user.address_full !== null ? `${d.user.address_full}` : '',
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.user.username}`,
          },
          {
            id: 'approvedInvoices', // Required because our accessor is not a string
            Header: t('approvedInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.apr_count,
          },
          {
            id: 'pendingInvoices', // Required because our accessor is not a string
            Header: t('pendingInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.pap_count,
          },
          {
            id: 'rejectedInvoices', // Required because our accessor is not a string
            Header: t('rejectedInvoices'),
            headerClassName: 'wordwrap',
            accessor: d => d.invoices.rec_count,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('conditions'),
            headerClassName: 'wordwrap',
            accessor: d => `${d.user.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <button
                      className="button is-primary is-inverted ml-8px"
                      to="/payment-conditions"
                      onClick={() => {
                        type_user === 'seller' ?
                          getPaymentCondition({
                            params: {
                              idCondition: cellInfo.original.condition.id,
                              person: cellInfo.original.user.id,
                              isClient: false,
                            },
                            callback: (response) => {
                              this.openModal(response, cellInfo.original.user.id, true, onToggle);
                            },
                          })
                          :
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.user.id,
                              role: role_id,
                            },
                            callback: (response) => {
                              //this.openModal(response, cellInfo.original.id, true, onToggle);
                              this.setState({
                                codes: response.commerce_codes,
                              });
                              getPaymentCondition({
                                params: {
                                  person: cellInfo.original.user.id,
                                  isClient: true,
                                  idCondition: cellInfo.original.condition.id,
                                },
                                callback: (response) => {
                                  this.openModal(response, cellInfo.original.user.id, true, onToggle);
                                },
                              });
                            },
                          });
                      }}
                    >
                      <span className="icon" title={t('detail')}>
                        <i className="far fa-eye" aria-hidden="true"/>
                      </span>
                    </button>
                  </td>
                  <td>
                    <button
                      className="button is-primary is-inverted"
                      onClick={() => {
                        type_user === 'seller' ?
                          getPaymentCondition({
                            params: {
                              person: cellInfo.original.user.id,//cellInfo.original.id,
                              isClient: false,
                              idCondition: cellInfo.original.condition.id,
                            },
                            callback: (response) => {
                              history.push({
                                pathname: '/edit-payment-condition',
                                state: {
                                  response: response,
                                  id: cellInfo.original.user.id,
                                  band: false,
                                  codes,
                                }
                              });
                            },
                          })
                          :
                          providerCommerceCode({
                            params: {
                              user: cellInfo.original.user.id,
                              role: role_id,
                            },
                            callback: (response) => {
                              codes1 = response.commerce_codes;
                              //this.openModal(response, cellInfo.original.id, true, onToggle);
                              this.setState({
                                codes: response.commerce_codes,
                              });
                              getPaymentCondition({
                                params: {
                                  person: cellInfo.original.user.id,
                                  isClient: true,
                                  idCondition: cellInfo.original.condition.id,
                                },
                                callback: (response) => {
                                  history.push({
                                    pathname: '/edit-payment-condition',
                                    state: {
                                      codes: codes1,
                                      response: response,
                                      id: cellInfo.original.user.id,
                                      band: false,
                                    }
                                  });
                                  //this.openModal(response, cellInfo.original.user.id, false, onToggle);
                                },
                              });
                            },
                          });
                      }}
                    >
                      <span className="icon" title={t('requestEdition')}>
                        <i className="far fa-edit" aria-hidden="true"/>
                      </span>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            ),
          },
        ];
        //data = relatedUsers; /*(Array.isArray(relatedUsers) && relatedUsers.length > 0)*/
        //data = relatedUsers.payment_conditions;
        //data = typeof (relatedUsers) !== 'undefined' ? relatedUsers.payment_conditions : [];
        data = relatedUsers.hasOwnProperty('payment_conditions') ? relatedUsers.payment_conditions : [];
        title = type_user === 'seller' ? t('customers') : t('providers');
        break;
      case'admin_amex':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'fiscalDocument', // Required because our accessor is not a string
            Header: t('RUT'),
            accessor: d => `${getPath(['idPerson', 'document_number'], d)} `,
          },
          {
            id: 'name', // Required because our accessor is not a string
            Header: t('businessName'),
            accessor: d => `${getPath(['idPerson', 'name'], d)}`,
          },
          {
            id: 'address', // Required because our accessor is not a string
            Header: t('address'),
            accessor: d => `${d.address_full}`,
          },
          {
            id: 'email', // Required because our accessor is not a string
            Header: t('email'),
            accessor: d => `${d.username}`,
          },
        ];
        data = pathname === '/clients' ? listClient : listProvider;
        title = pathname === '/clients' ? t('customers') : t('providers');
        break;
      case 'seller_assist_card':
        columns = [
          {
            expander: true,
            show: false,
          },
          {
            id: 'client', // Required because our accessor is not a string
            Header: t('client'),
            accessor: d => `${d.client.name}`,
          },
          {
            id: 'kindOfProduct', // Required because our accessor is not a string
            Header: t('kindOfProduct'),
            accessor: d => d.product !== null ? `${d.product.name}` : '',
          },
          {
            id: 'effective_date', // Required because our accessor is not a string
            Header: t('validity'),
            accessor: d => d.effective_date === null ? '' : `${d.effective_date}`,
          },
          {
            id: 'action', // Required because our accessor is not a string
            Header: t('action'),
            accessor: d => `${d.id} `,
            Cell: cellInfo => (
              <table>
                <tbody>
                <tr>
                  <td>
                    <Link
                      className="button is-primary is-inverted"
                      onClick={() => {
                        onToggle({
                          isEdit: true,
                          id: cellInfo.original.paymentCondition.id,
                          name_product: cellInfo.original.product.name,
                          client: cellInfo.original.client.name,
                        });
                      }}
                    >
                      {t('Unsubscribe')}
                    </Link>
                  </td>
                </tr>
                </tbody>
              </table>
            )
          },
        ];
        data = assist;
        title = t('customers');
        break;
      default:
        columns = [];
        data = [];
        title = '';
    }
    return {
      columns,
      data,
      title,
    };
  };

  openModal = (response, id, edit, onToggle) => {
    const {
      stats: { relatedUsers },
      auth: {
        user: { b2b: { type_user } },
      },
    } = this.props;
    const { codes } = this.state;

    let client = this.getUser(type_user, id);

    if (type_user === 'seller_assist_card') {
      //onToggle();
    } else if (type_user !== 'seller_assist_card') {

      if (response.person_commerce_code != null) {
        this.setState({
          commerceCode: codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          }),
          codeDescription: codes[codes.findIndex(function (code) {
            return code.id == response.person_commerce_code;
          })].description,
        });
      } else {
        this.setState({
          commerceCode: '',
          codeDescription: '',
        });
      }

      this.setState({
        id: id,
        clientEdit: relatedUsers.related_users[client].idPerson.name,
        //clientEdit: relatedUsers[client].user.idPerson.name,
        baseDays: response.days,
        //limitAmount: response.limit_amount !== null ? this.formatNumber(response.limit_amount) : 0,
        persons: relatedUsers.related_users,
        //persons: relatedUsers,
        startCountingFrom: response.due_from_approval,
        editable: true,
        band: edit,
        receiverDiscountShare: (Number(response.receiver_discount_share) * 100).toFixed(2),
        discountRate: Number(response.discount_rate)
          .toFixed(2),
        editPercentage: parseInt(response.percentage),
        clientPayment: 100 - Number(response.receiver_discount_share * 100),
        //clientPayment: 100 - (receiver_discount_share * 100).toFixed(2),
      });
      onToggle();
    }

  };

  getUser = (type_user, id) => {
    const {
      stats: { relatedUsers },
    } = this.props;

    let client;
    if (type_user === 'seller') {
      // this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //clientEdit: ,
      //});
    } else if (type_user === 'buyer') {
      //this.setState({
      client = relatedUsers.related_users.map(
        (listClientMap, index) => ({
          ...listClientMap,
          index,
        }),
      )
        .filter(listClientFil => listClientFil.id
          === id)[0].index;
      //});
    }

    return client;
  };

  formatNumber = (n) => {
    return n.replace(/\D/g, '')
      .replace(/([0-9])([0-9]{2})$/, '$1.$2')
      .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ',');
  };

  handleSelectChange = (newVal, label) => {
    this.setState({
      [label.name]: newVal.value,
    });
  };

  handleInputChange = (event) => {
    const {
      target: {
        checked, value: targetValue, type, name,
      },
    } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    const { clientPayment } = this.state;

    /*if (name === 'limitAmount') {
      let num = this.formatNumber(event.target.value);
      this.setState({
        [name]: (num),
      });
    } else*/
    if (name === 'receiverDiscountShare') {
      let percentage = 100 - value;
      this.setState({
        [name]: (value),
        clientPayment: percentage,
      });
    } else {
      this.setState({
        [name]: (value),
      });
    }
  };

  closeModal = (onToggle) => {
    onToggle();
  };

  saveRequest = (onToggle) => {
    const {
      auth: {
        user: {
          user_id, b2b: { type_user },
        },
      },
      t,
      showMessage,
      requestPaymentCondition,
    } = this.props;

    const {
      id,
      baseDays,
      startCountingFrom,
      //limitAmount,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      codes,
      code,
      commerceCode,
    } = this.state;

    let percentage = 0;

    const conditionData = {
      bank_sender_id: null,
      sender_request_id: null,
      sender_id: type_user === 'seller' ? id : user_id,
      receiver_id: type_user === 'seller' ? user_id : id,
      days: baseDays,
      due_from_approval: startCountingFrom,
      //limit_amount: limitAmount.toString().replaceAll(',', ''),
      discount_rate: discountRate,
      percentage: clientPayment, //percentage,
      receiver_discount_share: receiverDiscountShare / 100,
      person_commerce_code: codes[commerceCode].id,
      type: 'base',
    };

    if (baseDays < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('incorrectDays')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    /*if (conditionData.limit_amount < 0) {
      showMessage({
        message: <strong style={{ color: '#ffffff' }}>{t('limitAmountValidate')}</strong>,
        config: TOAST_CONFIG.ERROR,
      });
    } else {*/
      requestPaymentCondition({
        data: conditionData,
        callback: () => {
          onToggle();
          showMessage({
            message: t('editionRequestSent'),
            config: TOAST_CONFIG.SUCCESS,
          });
        },
      });
    //}
  };

  acceptTerm = (onToggle, resp, id) => {
    const {
      t,
      showMessage,
      deletePaymentCondition,
      getRelatedClientAssist,
    } = this.props;

    if (resp === 'yes') {
      deletePaymentCondition({
        data: {
          id: id,
        },
        t,
        callback: (resp) => {
          onToggle();
          getRelatedClientAssist({});
          if (resp === 'Deleted') {
            showMessage({
              message: t('deletePaymentCondition'),
              config: TOAST_CONFIG.SUCCESS,
            });
          } else {
            showMessage({
              message: t('actionNotAllowed'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
    if (resp === 'no') {
      onToggle();
    }
  };

  closeModal = (onToggle) => {
    this.getInitialState();
    onToggle();
  };

  render() {
    const {
      t,
      stats: { loading: statsLoading },
      auth: { loading: authLoading, user: { b2b: { type_user } } },
      paymentConditions: { loading: paymentConditionLoading },
    } = this.props;
    const {
      client,
      provider,
      baseDays,
      editable,
      startCountingFrom,
      //limitAmount,
      persons,
      band,
      clientEdit,
      discountRate,
      receiverDiscountShare,
      clientPayment,
      editPercentage,
      hidden,
      codes,
      commerceCode,
      codeDescription,
    } = this.state;
    const loading = statsLoading || authLoading || paymentConditionLoading;
    return (
      <Toggle>
        {({ toggled, onToggle, toggleProps }) => {
          const { title, columns, data } = this.getGridSettings(onToggle);
          return (
            <div>
              {loading && <Loading/>}
              <div className="dashboard">
                <div className="columns is-multiline">
                  <Fragment>
                    {
                      toggled && type_user === 'seller_assist_card' &&
                      (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <Panel
                            headingText={t('confirmUnsubscribeFrom', { product: toggleProps.name_product })}>
                            <div className="field is-horizontal">
                              <div className="field-body columns">
                                <div className="column is-full" style={{ textAlign: 'center' }}>

                                  <label>{t('confirmUnsubscribe', {
                                    client: toggleProps.client,
                                    product: toggleProps.name_product
                                  })}</label>
                                </div>
                              </div>
                            </div>
                            <div className="is-fullwidth has-text-centered">
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={this.acceptTerm.bind(this, onToggle, 'yes', toggleProps.id)}
                              >
                                {t('yes')}
                              </button>
                              &nbsp;&nbsp;
                              <button
                                type="button"
                                className="button is-primary"
                                onClick={() => this.acceptTerm(onToggle, 'no')}
                              >
                                {t('no')}
                              </button>
                            </div>
                          </Panel>
                        </Modal>
                      )}
                    {
                      toggled && type_user !== 'seller_assist_card'
                      && (
                        <Modal onToggle={onToggle}> {loading && <Loading/>}
                          <PaymentConditionForm
                            t={t}
                            onSubmit={band === true ? () => this.closeModal(onToggle) : () => this.saveRequest(onToggle)}
                            handleInputChange={this.handleInputChange}
                            handlePercentageChange={this.handlePercentageChange}
                            isClient={type_user !== 'seller'}
                            isProvider={(type_user === 'buyer' || type_user === 'seller')}
                            isConditionClient={type_user === 'buyer'}
                            isConditionProvider={type_user === 'seller'}
                            handleSelectChange={this.handleSelectChange}
                            persons={persons}
                            client={client}
                            provider={provider}
                            baseDays={baseDays}
                            discountRate={discountRate}
                            receiverDiscountShare={receiverDiscountShare}
                            clientPayment={clientPayment}
                            startCountingFrom={startCountingFrom}
                            //limitAmount={limitAmount}
                            clientEdit={clientEdit}
                            editable={editable}
                            //hidden={true}
                            hidden={false}
                            title={band ? false : t('requestEdition')}
                            band={band}
                            editPercentage={editPercentage}
                            // hidden={hidden}
                            related={true}
                            closeModal={() => this.closeModal(onToggle)}
                            codes={codes}
                            commerceCode={commerceCode}
                            codeDescription={codeDescription}
                            usedInModals
                          />
                        </Modal>
                      )
                    }
                    {data
                    && (
                      <div className="column is-full">
                        <Panel headingText={title}>
                          <TableSimple data={data} columns={columns}/>
                        </Panel>
                      </div>
                    )}
                  </Fragment>
                </div>
              </div>
            </div>
          );
        }}
      </Toggle>
    );
  }
}

RelatedUsers.propTypes = {
  t: PropTypes.func.isRequired,
  fetchRelatedUsers: PropTypes.func.isRequired,
  getPaymentCondition: PropTypes.func.isRequired,
  stats: PropTypes.shape(Object).isRequired,
  auth: PropTypes.shape(Object).isRequired,
  router: PropTypes.shape(Object).isRequired,
};


const mapStateToProps = ({ auth, clients, providers, stats, paymentConditions, router, role, }) => (
  {
    auth,
    clients,
    providers,
    stats,
    paymentConditions,
    router,
    role,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...statsActions, ...authActions, ...paymentConditionsActions, ...clientsActions,
    ...providersActions, ...roleActions,
  }),
  withNamespaces(),
)(RelatedUsers);