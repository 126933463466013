import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import { Form, TextInput } from '../../../../../components';

const FormLogin = ({
                      t,
                      handleSubmit,
                      options,
                      handleInputChange,
                      data,
                      handleClickIcon,
                    }) => {
  const { username, password } = data;
  const { icon, inputType } = options;
  return (
    <div className="FormLogin">
      <Form
        className="column is-full"
        submitText={t('enter')}
        onSubmit={handleSubmit}
        // buttonClasses="is-fullwidth is-rounded button-chile"
        buttonClasses="is-fullwidth custom-btn"
        isBTNFull
      >
        <TextInput
          name="username"
          placeholder={t('username')}
          type="text"
          onChange={handleInputChange}
          value={username}
          // inputClasses="input-asia"
        />
        <TextInput
          placeholder={t('password')}
          type={inputType || 'password'}
          // type="password"
          name="password"
          onChange={handleInputChange}
          value={password}
          login={true}
          icon={icon}
          iconPosition="is-right"
          controlClasses="has-icons-right"
          onClickIcon={handleClickIcon}
        />
      </Form>
    </div>
  );
};

FormLogin.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  options: PropTypes.shape(),
  handleInputChange: PropTypes.func.isRequired,
  data: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    device_info: PropTypes.string,
  }).isRequired,
};

FormLogin.defaultProps = {
  options: {},
};

export default withNamespaces()(FormLogin);
