import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const checks = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.checks,
      };
    }
    case types.SAVE_CHECK: {
      if(action.payload.state === 'approvedChecks') {
        return {
          ...state,
          approvedChecks: [...state.approvedChecks, action.payload],
        };
      }
      if(action.payload.state === 'notApprovedChecks') {
        return {
          ...state,
          notApprovedChecks: [...state.notApprovedChecks, action.payload],
        };
      }
      if(action.payload.state === 'rejectedChecks') {
        return {
          ...state,
          rejectedChecks: [...state.rejectedChecks, action.payload],
        };
      }
    }
    // case types.UPDATE_CHECKS: {
    //   return {
    //     ...state,
    //     availableAmounts: {...state.availableAmounts, ...action.payload}
    //   };
    // }
    default:
      return state;
  }
};

export default checks;
