const en = {
  translations: {
    accept: 'Accept',
    acceptTerms: 'You must agree to the terms terms and conditions', //
    acceptTermsGateway: 'We accept the charges or fees for service that are indicated in the Buyers section and we accept that these are charged by GETNET by direct deduction from the monthly settlements made to the ESTABLISHMENT, as indicated in the Affiliation Contract and in its Annex that regulates access and use of the "B2B Enterprise" platform',
    account: 'Account',
    accountBalance: 'Account balance',
    accountNumber: 'Account number',
    accountPlural: 'Accounts',
    accumulatedAmount: 'Daily accumulated amount',
    acquiring_bank: 'Acquiring Bank',
    acquiring_query: 'Acquiring Consultation Bank',
    acquiringAdvance: 'Acquiring advance',
    acquiringBank: 'Acquiring',
    acquiringQuery: 'Acquiring Query',
    action: 'Actions',
    actionNotAllowed: 'Action not allowed',
    activate: 'Activate',
    active: 'Active',
    activeCompanies: 'Active companies',
    addComment: 'Add a comment...',
    addCondition: 'Add condition',
    addFinanceProductSuccess: 'Financing product created successfully',
    addFinancingProducts: 'Add financing products',
    additionalInformation: 'Additional Information',
    addNew: 'Add new',
    address: 'Address',
    ADE: 'Advanced',
    adjusted_amount: 'Adjusted amount',
    admin: 'Administrator',
    adminBank: 'Administrator Bank',
    administer: 'Manage',
    administratorBank: 'Admin Bank',
    administratorName: 'Admin name',
    administrators: 'Administrators',
    administratorsList: 'Admin list',
    adminLastname: 'Last name',
    adminName: 'Name',
    adminPosition: 'Position',
    adminRoutes: 'Admin routes',
    adminUser: 'Administator',
    advance: 'Advance',
    advanceCommercialConditions: 'Commercial advance conditions',
    advanceMount: 'Advance Amount',
    advanceSuccessful: 'Advance successful',
    affiliateClients: 'Affiliate buyers',
    affiliateCode: 'Affiliate Code',
    affiliateN: 'Affiliation number (SE#)',
    affiliateNumber: 'Affiliation number (SE#)',
    affiliationCode: 'Merchant Affiliate Code ',
    alias: 'Alias',
    allowedFormats: 'Allowed formats: JPG and PNG. Maximum size 3MB.',
    allowedFormatsPDF: 'Allowed formats: PDF and XML. Maximum size 3MB.',
    allowedFormatsXLS: 'Allowed formats: XLSX or XLS. Maximum size 3MB.',
    amexCard: 'Amex Tesorero Santander Card',
    amount: 'Amount',
    amountApprovedInvoices: 'Amount Approved Invoices',
    amountInstallments: 'Amount of fees',
    amountInt: 'The amount must be a whole number',
    amountMoney: 'Amount of money',
    amountOfTransactionsToProcess: 'Amount of transactions to process',
    amountPaidInvoices: 'Amount Paid Invoices',
    amountToCollect: 'Daily total receivable', //
    amountToGet: 'Amount to Receive',
    amountToPay: 'Amount to Pay',
    amountToRequest: 'Amount to request',
    amountValidate: 'The amount must be positive',
    annualQuota: 'Annual quota',
    answer: 'Answer',
    anticipatedAmount: 'Advance Amount',
    anualRate: 'Anual rate',
    applicant: 'Applicant',
    applicantName: 'Applicant\'s name',
    applicationDate: 'Application date',
    applicationDeleted: 'Request removed',
    applicationNotification: 'You have {{payment_request_pending}} requests',
    applicationOneNotification: 'You have {{payment_request_pending}} request',
    applicationRejected: 'Application successfully rejected',
    applicationSuccess: 'Application successfully approved',
    approvalDate: 'Invoice approval date',
    approvalStatus: 'Approval status',
    approve: 'Approve',
    approved: 'Approved',
    approvedChecks: 'Approved checks',
    approvedInvoices: 'Approved Inv.',
    approvedInvoicesTitle: 'Approved Invoices',
    approvedMount: 'Approved amount',
    approvedStatus: 'Approved',
    approvedTransaction: 'Approved transaction',
    approveInvoice: 'Approve invoice',
    april: 'April',
    arrangedDays: 'Agreed financing days',
    assign: 'Assign',
    assignedCardNumber: 'Assigned card number',
    assignedRoutes: 'Assigned routes',
    assignedUsers: 'Assigned Users',
    assignPermissions: 'Assign permissions',
    assignRoleRoute: 'The role and route were assigned successfully', // ...
    assignRoute: 'Assign routes',
    assignRouteSuccess: 'The route was assigned successfully',
    assistCard: 'Assist card',
    asterisk: '*** {{number_card}}',
    august: 'August',
    authorization: 'Authorization',
    authUser: 'Administrator user',
    available: 'Available',
    availableBalance: 'Saldo disponible',
    avance: 'Advance',
    b2bMDR: 'B2B Enterprise Rate (MDR)',
    backImage: 'Back image',
    balance: 'Balance',
    balanceToPay: 'Balance to pay',
    bankInformation: 'Selected Bank Information',
    bankingExecutives: 'Back office',
    bankList: 'Bank list',
    bankLogo: 'Bank logo',
    bankName: 'Bank name',
    bankProvider: 'Banco Santander',
    banks: 'Banks',
    bankType: 'Bank type',
    baseConditions: 'Base Conditions (Invoice Payment)',
    benefits: 'Benefits',
    billedBusiness: 'Billed Business',
    billingCycle: 'Billing cycle',
    billingCycles: 'Billing cycles',
    billingVolume: 'Billing volume',
    billPayment: 'Bill payment',
    bills: 'Invoices \nsent',
    bin: 'Bin',
    businessExecutive: 'Back office',
    businessExecutives: 'Back office',
    businessName: 'Legal Entity Name',
    buyer: 'Buyer',
    buyerManualUp: 'Buyer (Manual Upload)',
    buyersBatchUp: 'Buyers (Batch Upload)',
    buyerCode: 'Buyer commerce code',
    buyerCreateLead: 'Require buyer registration',
    buyerName: 'Buyer name',
    buyersGroup: 'Buyers group',
    by_amount: 'By amount',
    by_hierarchy: 'By position hierarchy',
    cancel: 'Cancel',
    cannotBeUploaded: 'Therefore the invoice cannot be uploaded to B2B Enterprise',
    cantCreateTransaction: 'The invoice number already exists',
    cantDeleteRol: 'The role cannot be deleted because it has one or more routes assigned',
    cantDeleteRoute: 'The route cannot be deleted because it is assigned to one or more roles',
    canton: 'Canton',
    cardBind: 'Card bind',
    cardChargeDate: 'Charge Date on the Santander Treasurer Amex Card',
    cardholderPaymentPercentage: 'Cardholder Payment Percentage',
    cardList: 'Cards list',
    cardNumber: 'Card number',
    cardPayment: 'TTA selected for payment',
    cardPaymentDate: 'Car payment date',
    cards: 'Cards',
    categories: 'Categories',
    ccChargeDate: 'CC charge date',
    cedulaJuridicaDesc: '10 digits, no leading zero or hyphens',
    changeCode: 'You must modify the commercial code that corresponds to the new type of currency selected',
    changeLog: 'Change T&C',
    changeOfCommercialConditions: 'Commercial conditions changes record',
    changePassword: 'Change Password',
    changePasswordSuccess: 'Password change success',
    charge: 'Position',
    chargeDate: 'Charge date',
    chargeForService: 'Charge for service',
    chargeRate: 'Charge %',
    checkDate: 'Check date',
    checkDeposit: 'Check deposit',
    checkNumber: 'Check number',
    checks: 'Checks',
    checksToBeApproved: 'Checks to be approved',
    CIF: 'CIF',
    cifPattern: 'Must contain letters and numbers',
    city: 'City',
    client: 'Buyer',
    clientB2BRate: 'B2B rate payable by the buyer is',
    clientName: 'Buyer name',
    clientPay: 'Buyer payment',
    clientPaymentCondition: 'Determine the payment terms between supplier and buyer',
    clientPercentageCharge: 'Percentage of service charge paid by the supplier',
    clients: 'Buyers',
    clientsPay: 'Buyers payable',
    clientsQuantity: "Total number of buyers",
    code: 'Code',
    codeArea: 'area code + phone number',
    collaboratorNumber: 'Collaborator number',
    collaboratorNumberCharacter: 'Collaborator number, must be 8 characters',
    collectionDate: 'Payment date',
    commerceCode: 'Affiliation number (SE#)',
    commerceCodeDesc: 'Enter a description  for commerce code',
    commercialBillingUser: 'Commercial billing user',
    commercialConditions: 'Commercial terms',
    commune: 'Commune',
    companies: 'Companies',
    company: 'Company',
    concept: 'Concept',
    condition_amount: 'Amount',
    condition: 'Condition',
    conditions: 'Conditions',
    configuration: 'Settings',
    confirm: 'Confirm',
    confirmData: 'Confirm data',
    confirmPassword: 'Confirm password',
    confirmPassTooltip: 'The password and confirm password must be equals',
    confirmTaxCode: 'Confirm Tax Code',
    confirmTransfer: 'Confirm transaction',
    confirmUnsubscribe: 'Are you sure you want to cancel the commercial condition with {{client}} and the {{product}} it involves?',
    confirmUnsubscribeFrom: 'Confirm unsubscribe from: {{product}}',
    consult: 'Consult',
    contactPhone: 'Phone',
    continue: 'Continue',
    contract: 'Contract',
    contractedProducts: 'Contracted insurance',
    contractRequest: 'Contract Request',
    cort: 'Cort',
    cortDate: 'Cutoff date',
    cost: 'Cost',
    country: 'Country',
    createBank: 'Create a new bank',
    createBankSuccess: 'Bank {{name}} created successfully ',
    createBuyer: 'Create Buyers',
    createClientSuccess: 'Buyer {{name}} created successfully',
    createCondition: 'Create new commercial relationship',
    createExecutive: 'Create back office',
    createFlowApproval: 'Create approval flow',
    createFlowApprovalSuccess: 'Approval flow, created successfully',
    createInvoicesSuccess: 'The invoices has been successfully registered',
    createInvoiceSuccess: 'Invoice N° {{invoice_number}} has been successfully registered',
    createLead: 'Require Registration',
    createNewExecutive: 'Create a new back office "{{inputValueExecutive}}"',
    createNewItem: 'Create a new industry "{{inputValue}}"',
    createNewManaged: 'Create a new administrator',
    createPaymentCondition: 'Create payment \ncondition',
    createPaymentConditionSuccess: 'Payment condition created successfully',
    createProfile: 'Create Profile',
    createProfileSuccess: 'Profile {{name}} created successfully',
    createProvider: 'Create Supplier',
    createProviderSuccess: '{{name}} supplier created successfully',
    createRol: 'Create rol',
    createRoleSuccess: 'Role {{rolName}} created successfully ',
    createRolSuccess: '{{rolName}} role created successfully',
    createUser: 'Create user',
    createUserSuccess: 'User {{name}} has been successfully registered',
    creditAvailableBalance: 'Pre approved credit',
    creditCard: 'Credit card',
    creditCardSubstitution: 'Credit card substitution',
    creditConditionsMsg: '* When choosing to use a credit, you must define the credit conditions.',
    creditPeriod: 'Credit period',
    creditRequest: 'Credit request',
    creditRequestForAdvance: 'Credit request for advance invoice',
    creditRequestForInvoice: 'Credit request for invoice payment',
    creditsList: 'Credits list',
    currency: 'Currency',
    currentCycle: 'Current Cycle',
    customer: 'Buyer',
    customerInformation: 'Selected Buyer Information',
    customerName: 'Buyer name',
    customerPayment: 'Buyers payment',
    customerPaymentPercentage: '% Buyer payment',
    customers: 'Buyers',
    cuttingCycle: 'Cutting cycle',
    cuttingCycleAmex: 'AMEX treasurer card cutting cycle',
    cuttingCycleCard: 'Cut cycle of the card entered is: {{message}}',
    cycle_loader: 'Invoice Cycle Loader',
    cycleGroup: 'Cycle group',
    cyclesList: 'List of Billing Cycles',
    daily: 'Daily',
    dashboard: 'Main Menu',
    dataSavedSuccessfully: 'Data saved successfully', 
    date: 'Date',
    dateFf: 'Date FF',
    dateInvoice: 'Invoice date',
    dateNotAvailable: 'Date not available',
    dateOfAdmission: 'Date of entry in Sii',
    dateOfEntry: 'Date of entry to B2B',
    datePayment: 'Payment date',
    dateRange: 'Date range',
    dateV: 'Expiration date',
    day: 'Day',
    daysAdvance: 'Days to advance',
    daysDefer: 'Days to defer',
    daysNumber: 'Number of days',
    daysSince: 'Days since',
    deactivate: 'Deactivate',
    december: 'December',
    defaultFlow: 'Default flow',
    defer: 'Defer',
    deferCommercialConditions: 'Commercial Deferral Conditions',
    deferMount: 'Deferred Amount',
    deferred: 'Deferred',
    deferredAmount: 'Deferred Amount',
    delete: 'Remove',
    deleteExecutive: 'Back office removed',
    deleteInvoice: 'The invoice was removes',
    deletePaymentCondition: 'The payment condition was removed',
    deletePaymentCondConf: 'Are you sure you want to delete this payment condition?',
    deleteRol: 'Can\'t delete rol',
    deleteRoute: 'Can\'t delete route',
    deleteTta: 'TTA successfully eliminated',
    demographicInformation: 'Identification data',
    deny: 'Deny',
    deposit: 'Deposit',
    depositReason: 'Deposit reason',
    description: 'Description',
    destinationAccount: 'Destination account',
    detail: 'Detail',
    DIF: 'Deferred',
    differ: 'Differ',
    digitalCertificate: 'Digital certificate',
    disable: 'Disable',
    disable2FA: 'Deactivate Multi-factor authentication (2FA)',
    disabled: 'Disabled',
    discountAmount: 'Discount amount',
    discountRate: 'Discount rate',
    discountRateCharges: 'Discount rate charges',
    discountRateInvoiceSavedSuccessfully: 'Discount rate invoice saved successfully',
    discountRateMDR: 'Discount rate (MDR)',
    discountRateOutOfLimits: 'Discount rate out of bounds',
    district: 'District',
    document: 'Document',
    documentDate: 'Document date',
    documentNumber: 'Document number',
    documentType: 'Document type',
    doNotIncludeTheUsername: '* Do not include the username as part of the password.',
    dontPose: 'Does not have',
    download: 'Download',
    eCommerceTerminal: 'E-commerce terminal',
    edit_user: 'Edit User',
    edit: 'Edit',
    editBank: 'Edit manual: {{name}}',
    editClientSuccess: 'Buyer {{name}} edited successfully',
    editCycle: 'Edit cycle',
    editFlowApprovalSuccess: 'Approval flow edited successfully',
    editionRequestSent: 'Edition request sent',
    editPaymentConditionSuccess: 'Payment condition edited successfully',
    editProfileSuccess: 'Profile {{name}} edited successfully',
    editProviderSuccess: 'Supplier {{name}} edited successfully',
    editUserSuccess: 'User {{name}} edited successfully',
    email: 'Email',
    email2FA: 'A verification code has been sent to your email, please enter it to continue the operation',
    emailLabel: 'name@company.cl',
    emissionFlow: 'Emission Flow',
    emitInvoices: 'You have {{emit_invoices}} unissued invoice(s)',
    emptyAll: 'Make sure to fill in all the information',
    emptyAllRequest: 'Make sure to fill all the required fields',
    emptyCreditList: 'You do not have active credits, Do you want to apply for a new one?',
    emptyFields: 'Please complete all fields.',
    enable: 'Enable',
    enabled: 'Enabled',
    enable2FA: 'Activate Multi-factor authentication (2FA)',
    endingDate: 'Ending date',
    eng: 'ENG',
    enter: 'Enter',
    enterCodeSms: 'Check and type the code\n that we send you through SMS',
    enterCuttingCycle: 'Enter cutting cycle',
    enterDataInternalTaxService: 'Connect your company to the SII - Internal Revenue Service',
    enterTaxCode: 'Enter Tax Code',
    enterTheCardBillingCycle: 'You need to enter the card\'s billing cycle to continue operating with that card',
    enterYourNewPassword: 'Enter your new password',
    errorData: 'Failed to load data.',
    errorDate: 'The date \'since\' has to be less',
    errorDateCycles: 'The date FF has to be less',
    errorLoad: 'You must upload an extension file {{type}} o {{type1}}',
    esp: 'ESP',
    event: 'Event',
    executiveData: 'Commercial back office data',
    expirationDate: 'Expiration date',
    expiredInvoices: 'Approved / Paid',
    extract: 'Extract',
    extractAccount: 'Extract in B2B network',
    extractDetail1: 'Successfully generated approval code',
    extractDetail2: 'Amount to withdraw',
    extractDetail3: 'In',
    extractDetail4: 'Complete the extraction in a point of our Network',
    extractDetail5: 'With your phone number',
    extractDetail6: 'Provide your telephone number to the operator of the point to complete the extraction started',
    extractDetail7: 'QR Code',
    extractDetail8: 'Introduce yourself with the following code at the point of the network',
    factoring: 'Factoring',
    factoringDiscountAmount: 'Factoring discount amount',
    factoringRate: 'Factoring rate',
    fantasyName: 'Fantasy name',
    february: 'February',
    FF: 'FF',
    fileSentSuccessfully: 'File sent successfully',
    fileSize: 'The maximum size allowed is 3MB',
    fileSizeExceeded: 'File size exceeded',
    financingProducts: 'Financing \nproducts',
    financingRate: 'Financing rate (B2B rate / MDR)',
    finish: 'Finish',
    fiscaldocument: 'Fiscal Document',
    flexibleConditions: 'Flexible Conditions (Extended payment terms)',
    flowApproval: 'Flow approval',
    flowApprovalCheck: 'Default flow approval',
    flowsApproval: 'Flows approval',
    FMA: 'Multi-factor authentication',
    forgotPassword: 'Forgot your password?',
    friday: 'Friday',
    frontImage: 'Front image',
    fullName: 'Name and last name',
    FV: 'FV',
    gateway: 'Gateway',
    general_admin__issuing_amex: 'General administrator user of the Issuing bank AMEX',
    general_admin_acquiring: 'General administrator user of the Acquiring bank',
    general_admin_issuing: 'General administrator user of the Issuing bank',
    general_admin: 'General administrator user of the bank',
    getnet: 'Getnet',
    goBack: 'Back',
    grant: 'Grant',
    greaterThan: 'Greater Than',
    greaterThanOrEqualTo: 'Greater than or equal to',
    group: 'Group',
    handbook: 'Handbook',
    identificationNumber: 'Identification number',
    import: 'Import',
    inactive: 'Inactive',
    inactiveBusinesses: 'Inactive businesses',
    inactiveClients: 'Inactive buyers',
    inCharge: 'In Charge',
    incompleteData: 'Incomplete data',
    incorrectDate: 'You cannot enter a date before {{dateNow}}',
    incorrectDays: 'You must enter a value greater than or equal to 0',
    incorrectEmail: '{{email}} it doesn\'t look like a valid email. Check that you have the correct format: ejemplo@mail.com',
    info: 'Info',
    infoLog: 'Event information',
    informationOfTheSelectedBank: 'Information of the selected bank',
    insertYourEmailAddress: 'Insert your email address.',
    installmentNumber: 'Number of fees',
    installments: 'No. de cuotas',
    installmentsQty: 'Installments quantity',
    insurances: 'Insurances',
    interest_rate: 'Mount rate',
    interestRate: 'Interest rate',
    internalRevenueService: 'Internal Revenue Service',
    intNumber: 'Must be a whole number',
    invalidAmount: 'Invalid amount',
    invalidCard: 'To be checked',
    invalidRoleOrRoute: 'The role and route, can\'t be empty',
    invoice: 'Invoice',
    invoiceAmount: 'Invoice amount',
    invoiceApprovedSuccess: 'Invoice approved sucessfully',
    invoiceCount: 'Number of Invoices',
    invoiceDate: 'Invoice date',
    invoiceDetail: 'Invoice detail',
    invoiceGroup: 'Group of invoices',
    invoiceIssueDate: 'Invoice issue date',
    invoiceManualResolution: 'Invoices that require manual resolution',
    invoiceMarkPaid: 'Invoice # {{invoiceNumber}} was marked as paid',
    invoiceN: 'Invoice N° {{invoiceNumber}}',
    invoiceNumber: 'Invoice No.',
    invoiceNumberValidate: 'The format must be xxx-xxx-xxxxxxxxx',
    invoicePending: 'To emit',
    invoiceProvidersReceiver: 'Receipt invoice',
    invoiceReject: 'Invoice reject',
    invoiceRejectedSuccess: 'Invoice rejected sucessfully',
    invoices: 'Invoices',
    invoicesApproved: 'Approved Invoices',
    invoicesDeferredApproved: 'Approved and Deferred Invoices',
    invoicesNumber: 'Invoice number',
    invoicesPendingApproved: 'Pending and Approved Invoices',
    invoicesSent: 'Sent invoices',
    invoiceStatusInTheSii: 'Invoice status in the SII',
    invoicesToBeApproved: 'Invoices to be approved',
    invoiceTerms: 'By approving invoices within the B2B Enterprise platform, I accept the charge that will be made to my Santander Treasurer American Express Card, for the amount that is individualized in each invoice, and the amount of the service charge, if applicable according to the commercial conditions agreed with your supplier. Irrevocably instructs Banco Santander, so that such charges are made on the aforementioned Card.',
    invoiceTotalAmount: 'Invoice Amount',
    invoiceUploaded: 'Invoice N° {{invoiceNumber}} uploaded to B2B Enterprise successfully',
    issuedInvoices: 'Issued invoices',
    issuing_bank: 'Issuing bank',
    issuing_query: 'Issuing Consultation Bank',
    items: 'Industries',
    january: 'January',
    july: 'July',
    june: 'June',
    kindOfProduct: 'Kind of product',
    language: 'Language',
    last4Digits: 'Last 4 digits card number',
    lastDays: 'Last days',
    lastMovements: 'Last Movements',
    lead: 'Lead',
    leadList: 'Require list',
    legal: 'Legal',
    lengthToCharacters: '* Length 8 to 12 characters containing at least three of the following characteristics: uppercase letters (A-Z), lowercase letters (a-z), numbers (0-9), or special characters.',
    lessThan: 'Less Than',
    lessThanOrEqualTo: 'Less than or equal to',
    limit: 'Limit',
    limitAmount: 'Limit amount',
    line: 'Line',
    listCards: 'Cards list',
    listCuttingCycle: 'List of cutting cycles',
    listFaqs: 'List FAQ\'s',
    listInvoices: 'In review',
    listManuals: 'List manuals',
    listPayments: 'Payment List',
    load: 'Load',
    loadBillingCycle: 'Upload Billing Cycles',
    loadCsv: 'Load CSV',
    loadCustomers: 'Load Buyers',
    loadCustomersGroup: 'Buyers Batch Upload',
    loadFromFile: 'File upload',
    loading: 'Loading...',
    loadingContent: 'Loading content...',
    loadInvoice: 'Load \nInvoice',
    loadInvoiceData: 'Load invoice Data',
    loadInvoicesGroup: 'Load group of invoices',
    loadProviders: 'Load Suppliers',
    loadProvidersGroup: 'Load suppliers group',
    loan: 'Loan',
    loanPeriod: 'Loan period',
    loanRequest: 'Load request for advance invoice',
    logout: 'Logout',
    logs: 'Logs',
    logSource: 'Log source',
    logTransaction: 'Log transaction',
    logView: 'Log View',
    main_bank_acquiring: 'Main User of the Acquiring Bank',
    main_bank_issuing_amex: 'Administrator User of the AMEX Issuing Bank',
    main_bank_issuing: 'Main User of the Issuing Bank',
    main_bank: 'Bank Main User',
    mainInvoiceNumber: 'Main invoice number',
    mainUser: 'Main User',
    MAN: 'Manual Resolution',
    mandatoryFieldsRequired: '(*) You have to fill up all the marked fields.',
    manualLoaded: 'Manual loaded successfully',
    manualResolution: 'Manual Resolution',
    manualsAndFaq: 'Manuals and FAQ\'s',
    march: 'March',
    markAsPaid: 'Mark as paid',
    maxRank: '(to)',
    may: 'May',
    mdr: 'MDR',
    merchantAffiliateCode: 'Affiliation number',
    message: 'Message',
    messageUserProcess: 'You do not currently have a configured approval flow type. Please contact your representative to activate this functionality.',
    minimumCharacter: 'Merchant affiliate code, must be between 8 and 15 characters',
    mixedBalance: 'Mixed balance',
    mixedPayment: 'Mixed payment',
    monday: 'Monday',
    month: 'Month',
    monthly: 'Monthly',
    monthlyReport: 'Monthly report',
    name: 'Name',
    nameFantasy: 'Fantasy Name',
    naoReter: 'Not retain IR (advertising, publicity, commissions and correctje.)',
    NEG: 'Denied',
    new: 'New',
    newBank: 'New bank',
    newBankingExecutive: 'New Banking back office',
    newPassword: 'New password',
    newPaymentCondition: 'New Payment Conditions',
    newProfile: 'New user',
    newRecharge: 'New Recharge',
    newRole: 'New role',
    newTransfer: 'New Transfer',
    next: 'Next',
    nextCollection: 'Next collection',
    nextDays: 'Next days',
    nextPage: 'Next',
    nextPayment: 'Next payment',
    nInvoices: 'No. of Invoices',
    no: 'No',
    noCard: 'You don\'t have a prepaid Card', //
    noCycles: 'There are no loaded cutting cycles for: {{month}}',
    noData: 'No information',
    noExecutivesAvailable: 'No back office available',
    noInformation: 'No Information',
    noItemsAvailable: 'No industries available',
    noManuals: 'No hay manuales cargados',
    nonMatchingPasswords: 'The passwords don\'t match.',
    noRowsFound: 'No information',
    notifyCardPaymentAuthorization: 'Notify card payment authorization',
    noTransactions: 'This Card doesn\'t have movements',
    notVerified: 'Not verified',
    notYetFound: 'The invoice is not yet available in the SII, if you wish you can upload it and B2B Enterprise will take care of retrying the search for it and uploading it to our platform, otherwise cancel the operation and try to upload it again later',
    november: 'November',
    null: '',
    number: 'Number',
    numberInstallments: 'Number of fees',
    numberTta: 'TTA number',
    october: 'October',
    of: 'of',
    onlyCardPaymentDate: 'Card payment date',
    operationLog: 'Event Log',
    operationRank: 'Range of operations (from)',
    operationVolume: 'Total amount ',
    operator: 'Condition',
    originAccount: 'Origin account',
    otherSign: 'Other signs',
    PAG: 'Paid',
    page: 'pages',
    paid: 'Paid',
    paidAmount: 'Amount Paid',
    paidInvoices: 'Invoices paid',
    paidOut: 'Paid out',
    PAP: 'To be approved',
    params: 'Params',
    passport: 'Passport',
    password: 'Password',
    passwordDigitalCertificate: 'Digital certificate password',
    passwordHasExpired: 'Your password has expired, please enter your new password',
    payedAmounts: 'Daily total billed', //
    payInvoiceWithBalance: 'Do you want to pay the invoice No. {{toggleProps.transaction.invoice_number}} with your account balance?',
    payment: 'Payment',
    paymentAmount: 'Payment amount',
    paymentClient: 'Buyer payment percentage',
    paymentCondition: 'Condition',
    paymentConditionClients: 'Commercial conditions for buyers of',
    paymentConditionProvider: 'Commercial conditions for suppliers of',
    paymentCurrency: 'Payment currency',
    paymentDate: 'Payment date',
    paymentDateInvoice: 'Bill collection date:',
    paymentDateRange: 'Bills collection date range',
    paymentDateRangeClient: 'Bills payment date range',
    paymentDeadline: 'Payment deadline',
    paymentMethod: 'Payment method',
    paymentOrders: 'Payment orders',
    paymentsTransfers: 'Payments and Transfers',
    paymentWay: 'Which way do you choose to pay the invoice No.',
    paymentWayMsg: 'By accepting the mixed payment option you will be able to combine the payment options.',
    PEJ: 'To be executed',
    PEM: 'To be issued',
    pending: 'Pending',
    pendingInvoices: 'Pending Inv.',
    pendingInvoicesQTY: 'You have {{pending_invoices}} unapproved pending invoice(s)',
    pendingToApprove: 'Pending approval',
    percentage: 'Anual interest percentage',
    period: 'Period',
    permanentlyRejectCommercialStatus: 'Permanently reject commercial status',
    permissions: 'Permissions',
    person: 'Person',
    phone: 'Phone number',
    physical: 'Physical',
    platformAdministrator: 'Platform administrator',
    platformFaqList: 'Platform FAQ\'s list',
    platformManuals: 'Platform manuals',
    por: 'POR',
    PRC: 'Preloaded',
    prepaidCard: 'Prepaid Card',
    present: 'Present',
    previous_page: 'Previous',
    previous: 'Previous',
    PRF: 'To be reimbursed',
    print: 'Print',
    printQR: 'Print QR',
    product: 'Product',
    productContracted: 'Product contract request: {{name}}, sent successfully',
    productName: 'Product name',
    products: 'Insurance / Assistance',
    productsContracted: 'Contracted products',
    productType: 'Product Type',
    productUnsubscribedSuccessfully: 'Product unsubscribed successfully',
    profile: 'Profile',
    profileList: 'Users List',
    profileName: 'Profile name',
    provider: 'Supplier',
    providerB2BRate: 'B2B rate paid by supplier',
    providerGroup: 'Suppliers group',
    providerName: 'Supplier\'s name',
    providerPaymentCondition: 'Determine the payment terms between buyer and supplier',
    providers: 'Suppliers',
    providersReceiver: 'Suppliers to receive',
    province: 'State',
    PRV: 'By Reverse',
    PSL: 'For sale',
    pt: 'Português',
    PVD: 'To empty',
    qualify: 'Enable',
    quantity: 'Quantity',
    question: 'Question',
    QuoteAndRequest: 'Quote And Request',
    rate_operator: 'Financing Rate Operator',
    rate: 'Percentage',
    REC: 'Rejected',
    receivedInvoices: 'Received invoices',
    receiverDiscountShare: 'Supplier funding percentage',
    receptionDate: 'Invoice issue date',
    recharge: 'Recharge',
    recipient: 'Recipient',
    recoverPassword: 'Recover Password',
    referenceNumber: 'Your reference number is',
    referrer: 'Referrer',
    region: 'Region',
    registerAdministrator: 'Register admin',
    registerBank: 'Register bank',
    registerCard: 'Register Card',
    registerCardAmex: 'Enter American Express card',
    registeredCards: 'Registered cards from: {{name}}',
    registerProduct: 'Register Product',
    registerRole: 'Register role',
    registerRoute: 'Register route',
    registrationStatus: 'Registration status',
    reject: 'Reject',
    rejectedChecks: 'Rejected checks',
    rejectedCycle: 'Billing cycle successfully rejected.',
    rejectedInvoices: 'Rejected',
    rejectInvoices: 'You have {{reject_invoices}} rejected invoice(s)',
    rejectReason: 'Reject Reason',
    rejectTradeRequest: 'Reject trade request',
    relatedUsers: 'Related Users',
    rememberToAcceptTheTerms: 'Remember to accept the terms and conditions to contract the product',
    removeRouteSuccess: 'The route was removed succeffully',
    replaceTheSelectedCard: 'Are you sure you want to replace the selected card?',
    reports: 'Reports',
    request: 'Commercial Requests',
    requestEdition: 'Request edition',
    requestingBank: 'Requesting bank',
    requestProducts: 'Request your insurance or assistance',
    requestReceived: 'Received requests',
    requestSent: 'Sent requests',
    requestSentProduct: 'Request sent',
    requestsReceived: 'Requests received',
    requestsSent: 'Requests sent',
    requestUnsubscribe: 'Request unsubscribe',
    requiredFields: '(*) Required fields',
    requiredInstallments: 'The number of dues is invalid', //
    requiredName: 'The name is invalid', //
    requiredPeriod: 'The period is invalid', //
    requiredRate: 'The rate is invalid', //
    requiredTypeFinance: 'The financing type is invalid', //
    requireSuccess: '{{name}} required succeffully',
    resource: 'Resource',
    respJson: 'Response JSON',
    response: 'Response',
    respXml: 'Response XML',
    return: 'Return',
    REV: 'Reversed',
    RFD: 'Refunded',
    rol: 'Role',
    roleCantBeEmpty: 'The role can\'t be empty',
    roleList: 'Role List',
    roles: 'Roles',
    roleType: 'Rol type',
    rolName: 'Rol name',
    rolType: 'Rol type',
    routeList: 'Route list',
    routes: 'Routes',
    rows: 'Rows',
    RUT: 'RUT',
    FV: 'FV',
    fr: "French",
    en: "English",
    pt: "Portuguese",
    es: "Spanish",
    zh: "Chinese",
    safetyRules: 'We need to connect your company to the SII to be able to obtain the invoices issued by your suppliers to your social reason, for this we thank you for entering the following data:',
    saturday: 'Saturday',
    save: 'Save',
    savedCards: 'Saved credit cards',
    saveQuestion: 'Save FAQ\'s',
    search: 'Search',
    searchBuyers: 'Search Buyers',
    searchSuppliers: 'Search Suppliers',
    searchUsers: 'Search Users',
    secureCards: 'Your credit card information will be stored under strict security regulations',
    seeDetail: 'See detail',
    seeHow: 'See how',
    seeUsers: 'See users',
    Select: 'Select...',
    selectANewCommercialExecutive: 'You must select a new back office',
    SelectBank: 'Select a bank',
    selectBank: 'You must select a Bank',
    selectCard: 'TTA selected',
    selectCycle: 'You must select all cycles',
    SelectDocumentType: 'Select a document type',
    selectedBank: 'Bank',
    selectFile: 'Select file',
    selectInvoice: 'Select invoice',
    selectMonth: 'You must select a month',
    SelectOption: 'Select an option',
    selectOrTypeItems: 'Select or type industries...',
    selectOrTypePermissions: 'Select or type the permissions',
    selectOrTypeRol: 'Select or type the roles',
    selectOrTypeRoutes: 'Select or type the route',
    selectRecipient: 'SelectSingle recipient',
    seller: 'Supplier',
    sellerCode: 'Supplier commerce code',
    sellerCreateLead: 'Requires supplier registration',
    sellerName: 'Supplier name',
    sellersQuantity: 'Suppliers Quantity',
    send: 'Submit',
    sendDate: 'Send date',
    senderDiscountShare: 'Buyer funding percentage',
    sendErp: 'Send ERP',
    sendInformation: 'Send information',
    sendInvoice: 'Invoice shipping date',
    sentInvoices: 'Sent invoices',
    september: 'September',
    settings: 'Settings',
    shippingDate: 'Shipping date',
    shouldNotSelect: 'You should not select the same back office, who wants to eliminate',
    showPassword: 'Show password',
    showQR: 'Show QR code',
    since: 'Since',
    startCountingFrom: 'Invoice agreed start date',
    startDate: 'Start date',
    status: 'Status',
    statusOfInvoice: 'The status of invoice N ° {{invoiceNumber}} in the SII is: {{status}}.',
    street: 'Street',
    successCard: 'Your card has already been registered.',
    successCycle: 'Billing cycle confirmed successfully.',
    successCycles: 'Successfully confirmed billing cycles',
    successEmail: 'An email has been sent to your email address.',
    successExecutive: 'Back office created successfully',
    successfulFactoring: 'Successful factoring',
    successfullyAssignedCuttingCycle: 'Successfully assigned cutting cycle',
    successfullyUpdatedManual: 'Successfully updated manual',
    suggestTradeConditionCorrection: 'Suggest trade condition correction',
    summary: 'Summary',
    sunday: 'Sunday',
    support: 'Support',
    supportLink: 'Support link',
    target: 'Card',
    taxId: 'Tax ID',
    termsAndConditions: 'Terms and Conditions',
    theCodeExpiresIn: 'The code expires at: {{time}}',
    theInvoiceWillBeCharged: 'The invoice will be charged, {{status}}',
    thereIsNoInformationOnFlexibleConditions: 'There is no information on flexible conditions',
    theyMustNotHave: '* They must not have identical consecutive characters, neither totally numeric, nor totally alphabetic.',
    thursday: 'Thursday',
    time: 'Hour',
    toBeApproved: 'To be approved',
    toDisable: 'Disable',
    total: 'Total',
    totalAmount: 'Total import',
    totalAmountToPayOnTheInvoice: 'Total amount to pay on the invoice',
    totalAmountToReceive: 'Total amount to receive',
    totalBuyers: 'Total Buyers',
    totalBuyersDeferred: 'Companies with Deferred Invoices',
    totalBuyersInvoiced: 'Companies with Approved Invoices',
    totalBuyersTh: 'Total Buyers TH',
    totalCharged: 'Total charged {{month}}',
    totalCommissionAmount: 'Total commission',
    totalDebtAmount: 'Total amount to pay',
    totalOwedAmount: 'Total receivable amount',
    totalPay: 'Total to pay',
    totalReceiver: 'Total to receiver',
    totalSellers: 'Affiliated Suppliers',
    totalSellersCharged: 'Suppliers with Approved Invoices',
    totalSellersInvoiced: 'Suppliers with Pending Invoices',
    totalToApprove: 'Total to approve',
    totalToPayCardholder: 'Total to Pay Cardholder Service Charge',
    totalToPayInvoice: 'Total to Pay Invoice',
    totalToReceive: 'Total to receive',
    totalVolumePendingPayment: 'Total volume pending payment',
    tradeInformation: 'Supplier information',
    transactionReport: 'Transactions report',
    transactions: 'Transaction Report',
    transactionsToProcess: 'Transactions to process',
    transactionType: 'Transaction type',
    transfer: 'Transfer',
    transferCharge: 'Operation charge',
    transferDetail: 'Succesfull Transfer',
    transferDetail2: 'Voucher',
    transferDetail3: 'Send to',
    transferDetail4: 'Amount send',
    transferDetail5: 'Discoundted total amount',
    transferring: 'Transferring',
    transferState: 'Status',
    transferType: 'Transaction type',
    TtaValidation: 'TTA validation',
    tuesday: 'Tuesday',
    type: 'Type',
    typeAndDocumentNumber: 'Type and document number',
    typeFinance: 'Tipo de financiamiento',
    typeOfApprovalFlow: 'Type of approval flow',
    typeOfEmitFlow: 'Emission flow type',
    underReview: 'In Review',
    underReviewInvoices: 'Invoices Under Review',
    Unsubscribe: 'Unsubscribe',
    unsuscribeRequest: 'Unsuscribe Request',
    until: 'Until',
    updateBank: 'Update bank',
    updatedBillingCycle: 'Updated billing cycle',
    updatedBillingCycles: 'Updated billing cycles',
    updateInfo: 'Update information',
    updateInformation: 'Update information',
    uploadInvoice: 'Upload invoice',
    uploadManuals: 'Upload Manuals',
    user_list: 'User list',
    user: 'User',
    userBillingCycles: 'User billing cycles',
    userManual: 'User manual',
    username: 'Username',
    userQuery: 'User query',
    users: 'Users',
    validCard: 'Verified',
    validEmail: 'You must enter emails that belong to @ baccredomatic.com',
    validity: 'Validity',
    valorNeto: 'Total value',
    vendorMerchantAffiliateCodes: 'Vendor Affiliate Number: {{provider}}',
    verificationCode: 'Verification code',
    verificationCodeSent: 'Enter the verification code sent to your email',
    verifyHuman: 'Please verify that you are human',
    view: 'Watch',
    viewTta: 'See TTA',
    virtualAccount: 'Virtual account',
    VOD: 'Emptied',
    volumeProcessed: 'Volume processed in the last three months',
    waitingBillingCycle: 'Waiting for billing cycle assignment',
    waitingForApproval: 'Waiting for approval of request to cancel the service',
    webpay: 'Webpay',
    wednesday: 'Wednesday',
    whatPaymentCondition: 'What payment condition do you want to use?',
    yearly: 'Annual',
    yes: 'Yes',
    yourDataWillBeStoredEncrypted: '*Your data will be stored encrypted under strict security regulations.',
    '/create-provider': 'Create Suppliers',
    '/edit-provider': 'Edit Suppliers',
    '/create-customer': 'Create Buyers',
    '/edit-customer': 'Edit Buyers',
    '/create-profile': 'Crear Profile',
    '/dashboard': 'Dashboard',
    '/account-details': 'Account Details',
    '/extract': 'Extract',
    '/prepaid-Card': 'Prepaid Card',
    '/transfer': 'Transfer',
    '/deposit': 'Deposit',
    '/load-invoice': 'Load Invoices',
    '/customers': 'List, Ddit or Disable Buyers',
    // '/invoices': 'List, edit, approve or reject invoices without approving',
    '/invoices': 'Invoices',
    '/expired-invoices': 'List of Expired Invoices',
    '/related-users': 'Related Users', //REVISAR
    '/clients': 'Buyers',
    '/providers': 'Suppliers',
    '/register-bank': 'Create Banks',
    '/bank-list': 'List, Edit or Disable Banks',
    '/role-list': 'List, Edit and Delete Roles',
    '/invoice-detail': 'Invoice Detail',
    '/register-financing-products': 'Create a Financing Product',
    '/financing-products': 'Finance Products',
    '/register-role': 'Create Roles',
    '/route-list': 'List, Edit and Delete Routes',
    '/register-route': 'Create Routes',
    '/admin-routes': 'Assign or Remove Routes to Roles',
    '/review-invoices': 'List, Edit, Approve or Reject Invoices without Approving',
    '/reject-invoices': 'Rejected Invoices',
    '/approved-invoices': 'List, Advance or Defer Approved Invoices',
    '/paid-invoices': 'List Paid Invoices',
    '/pending-invoices': 'Pending Invoices',
    '/create-lead': 'Require Buyers or Suppliers',
    '/lead-list': 'List, Approve or Reject Required',
    '/reports': 'Reports',
    '/payment-conditions-log': 'Payments Conditions Log',
    '/transactions': 'Bank Generated Invoices',
    '/profile-list': 'List, Edit or Delete Profiles',
    '/create-approval-flow': 'Create Invoice Approval Flow',
    '/edit-approval-flow': 'Edit Invoice Approval Flow',
    '/approval-flow-list': 'Approval Flow List', 
    '/account-profile': 'Account Profile',
    '/payment-conditions': 'Payment Conditions',
    '/create-bank-user': 'Create Bank User',
    '/edit-bank-user': 'Edit Bank User',
    '/bank-user-list': 'Bank Users List',
    '/register-card': 'Register Card',
    '/replace-card': 'Credit Card Replacement',
    '/list-cards': 'List of Cards',
    '/payment-request-sent': 'Requests',
    '/payment-request-received': 'Requests',
    '/save-credentials': 'Save Credentials',
    '/edit-credentials': 'Edit Credentials',
    '/load-billing-cycle': 'Load Billing Cycle',
    '/edit-billing-cycle': 'Edit Billing Cycle',
    '/billing-cycles': 'Billing Cycles',
    '/banking-executives': 'Back Office',
    '/create-banking-executive': 'Create Back Office',
    '/load-executives': 'Load Executives',
    '/products': 'Products',
    '/contracted-products': 'Contracted Products',
    '/product-detail': 'Product Details',
    '/accept-discount-rate': 'Accept Discount Rate',
    '/edit-bank-admin': 'Edit Bank Admin',
    '/save-manual': 'Upload Manual',
    '/list-manual': 'Manuals List',
    '/save-faq': 'Upload FAQ\'s',
    '/list-faqs': 'FAQ\'s list',
    '/support': 'Support',
    '/monthly-report': 'Monthly Report',
    '/commerce-code': 'Affiliation Number',
    '/user-commerce-code': 'Affiliation Number',
    '/confirm-provider': 'Confirm Supplier',
    '/confirm-customer': 'Confirm Buyer',
    '/create-payment-condition': 'Create new Payment Condition',
    '/edit-payment-condition': 'Edit Payment Condition',
    '/provider-group-load': 'Load Suppliers Group',
    '/customer-group-load': 'Buyers Batch Upload',
    '/list-provider': 'Suppliers List',
    '/list-customer': 'Buyers List',
    '/create-admin-bank': 'Create Admin Bank',
    '/edit-admin-bank': 'Edit Admin Bank',
    '/admin-bank-list': 'Managing Banks',
    '/resp': 'Card Storage Confirmation',
    '/log-view': 'Logs',
    '/operation-log': 'Logs',
    '/mixed-payments': 'Schedule Mixed Payment',
    '/credit-request': 'Credits and Loans',
    '/credits-list': 'Credits List',
    '/check-deposit': 'Checks to Deposit',
    '/approved-checks': 'Approved Checks',
    '/not-approved-checks': 'Checks to be Approved',
    '/rejected-checks': 'Rejected Checks',
    '/send-information': 'Send ERP',
    '/send-information': 'Send ERP',
    '/load-invoice-group': 'Group of Invoices',
  },
};

export default en;
