import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Panel } from '../../../../components';
import { formatCurrencyCl } from '../../../../util';
import { Link } from 'react-router-dom';
import { creditsActions } from '../../../../state/ducks/credits';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';

const CreditRequestForm = ({ 
    onToggle, 
    handleForm, 
    handleStep,
    credit: { 
        accountNumber, 
        amount, 
        installments, 
        charge, 
        installmentAmount },
    credits: { usedFor = 'creditLine', availableAmounts: { accountCredit, preApprovedCredit } },
    setUsedFor,
    checks: { notApprovedChecks },
    transactions: { currentInvoice: { id, invoice_amount, invoice_number, paymentWay, requiredAmount } },
    auth: { user: { b2b: { type_user } } },
    t,
 }) => {

    const [blockedAmount, setBlockedAmount] = useState(0);

    useEffect(() => {
        setBlockedAmount(notApprovedChecks.reduce((acc, curr) => acc + curr.amount, 0))
    }, [])

    const handleChange = e => {
        let { name, valueAsNumber } = e.target;
        valueAsNumber = Number.isNaN(valueAsNumber) ? 0 : valueAsNumber;

        if(name === 'installments') {
            let charge = valueAsNumber > 1 ? (valueAsNumber * 3.2).toFixed(2) : 0;
            let installmentAmount = 
                (usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine') ?
                ((amount / valueAsNumber) + (amount / valueAsNumber * charge / 100)).toFixed(2) :
                ((invoice_amount / valueAsNumber) + (invoice_amount / valueAsNumber * charge / 100)).toFixed(2)
            
            handleForm({
                // [name]: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber,
                [name]: valueAsNumber,
                charge, 
                installmentAmount,
            })
        } else {
            let installmentAmount = 
                installments > 1 ? 
                ((valueAsNumber / installments) + (valueAsNumber / installments * charge / 100)).toFixed(2) :
                valueAsNumber;
            
            handleForm({ 
                // [name]: Number.isNaN(valueAsNumber) ? 0 : valueAsNumber,
                [name]: valueAsNumber,
                installmentAmount,
            })
        }
    }
    
    return (
        <>
        <div className='CreditRequestForm__main'>
            {/* {paymentWay === 'loan' ? ( */}
                <div className='Panel__extra-title'>
                    {(usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment' || usedFor === 'advanceWithLoan') && (
                    <>
                      <p>{t('balanceToPay')}</p>
                      <p>${formatCurrencyCl(invoice_amount)}</p>
                    </>
                    )}
                    {(usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine') && (
                    <>
                      <p>{t('accountBalance')}</p>
                      <p>${formatCurrencyCl(accountCredit)}</p>
                    </>
                    )}
                </div>
            {/* ) : (
                <>
                    <div className='Panel__extra-title'>
                    </div> */}
                    {/* <div className='Panel__extra-title'>
                        <p>Saldo bloqueado</p>
                        <p>${formatCurrencyCl(blockedAmount)}</p>
                    </div> */}
                {/* </> */}
            {/* )
            } */}
            <Panel>
                <form>
                    <div className='form-field'>
                        <label>{(usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine') ? t('accountNumber') : t('invoiceNumber')}</label>
                        <input 
                          className='custom-input'
                          type='text'
                          disbled
                          value={(usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine') ? accountNumber : invoice_number}/>
                    </div>
                    {usedFor !== 'advanceWithLoan' &&
                      <div className='form-field'>
                        <label>{t('creditAvailableBalance')}</label>
                        <input 
                          className='custom-input'
                          type='text'
                          disbled
                          value={`$${formatCurrencyCl(preApprovedCredit)}`}/>
                      </div>
                    }
                    <div className='form-field'>
                        <label>{t('amountToRequest')}</label>
                        <input 
                          className='custom-input'
                          type={usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment' || usedFor === 'advanceWithLoan' ? 'text' : 'number'}
                          name='amount'
                          value={usedFor === 'approveWithCredit' || usedFor === 'advanceWithLoan' ? `$${formatCurrencyCl(invoice_amount)}` : usedFor === 'approveWithMixedPayment' ? `$${formatCurrencyCl(requiredAmount)}` : null}
                          
                          required
                          onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-field'>
                        <label>{t('installmentsQty')}</label>
                        <input 
                            className='custom-input small-input'
                            type='number'
                            name='installments'
                            required
                            value={installments}
                            min={1}
                            onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-field'>
                        <label>{t('chargeRate')}</label>
                        <input 
                            className='custom-input small-input'
                            type='text'
                            name='charge'
                            disabled
                            value={charge}
                            onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-field'>
                        <label>{t('amountInstallments')}</label>
                        <input 
                            className='custom-input small-input'
                            type='text'
                            name='installmentAmount'
                            disabled
                            value={`$${formatCurrencyCl(installmentAmount)}`}
                            onChange={e => handleChange(e)}/>
                    </div>
                    <div className='form-BTNS'>
                        <Link 
                            to={
                                usedFor === 'creditLine' ? '/credits-list' : 
                                usedFor === 'approveWithCredit'  ? '/review-invoices' : '/approved-invoices'}
                            className='button custom-btn is-danger'
                            onClick={() => {
                                setUsedFor('creditLine')
                            }}>
                                {t('return')}
                        </Link>
                        <button
                        className='button custom-btn is-primary'
                        disabled={(usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine') ? !amount || amount > accountCredit : false}
                        onClick={e => {
                            e.preventDefault()
                            handleStep('ConfirmCredit')
                        }}>
                                {t('continue')}    
                        </button>
                    </div>
                </form>
            </Panel>
        </div>
        </>
    );
};

const mapStateToProps = ({ credits, checks, transactions, auth }) => ({ 
    credits, 
    checks, 
    transactions,
    auth 
});


export default compose(
    connect(mapStateToProps, {...creditsActions}),
    withNamespaces()
)(CreditRequestForm);