import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";
import PropTypes from "prop-types";
import "./styles.scss";

const months = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};

const CustomizedLabel = (props) => {
  const { x, y, value } = props;
  return (
    <text x={x} y={y} dy={-4} fontSize={11} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fontSize={10}
        transform="rotate(-35)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomizedTooltip = (value) => {
  const label = months[value];
  return label;
};

const LineChartBox = ({ title, data, name }) => {
  return (
    <>
      <div
        className={`column`}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="line-chart-box">
          <h3 className="title">{title}</h3>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 15,
                right: 30,
                left: 10,
                bottom: 35,
              }}
            >
              {/* <CartesianGrid /> */}
              <XAxis
                dataKey="name"
                height={30}
                tick={<CustomizedAxisTick />}
              ></XAxis>
              <YAxis fontSize="9">
                <Label
                  value={name}
                  position="insideLeft"
                  angle={-90}
                  style={{ textAnchor: "middle", fontSize: "14px" }}
                  offset={20}
                />
              </YAxis>
              <Tooltip labelFormatter={CustomizedTooltip} />
              <Line
                type="linear"
                dataKey="value"
                name={name}
                stroke="#0278f3"
                strokeWidth="3"
                label={<CustomizedLabel />}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  );
};

LineChartBox.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

export default LineChartBox;
