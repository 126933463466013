import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const LoaderCounter = ({ radius, stroke, progress, color }) => {
	const normalizedRadius = radius - stroke * 2;
	const circumference = normalizedRadius * 2 * Math.PI;
	const strokeDashoffset = circumference - progress / 100 * circumference;
	return (
		<>
			<div className='loader-counter'>
				<svg
					height={radius * 2}
					width={radius * 2}
				>
					<circle
						stroke={'#d0d0d0'}
						fill="transparent"
						strokeWidth={stroke}
						strokeDasharray={circumference + ' ' + circumference}
						style={{ strokeDashoffset: 0 }}
						strokeWidth={stroke}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
					/>
				</svg>
				<svg
					height={radius * 2}
					width={radius * 2}
				>
					<circle
						stroke={color}
						fill="transparent"
						strokeWidth={stroke}
						strokeDasharray={circumference + ' ' + circumference}
						style={{ strokeDashoffset }}
						strokeWidth={stroke}
						r={normalizedRadius}
						cx={radius}
						cy={radius}
					/>
				</svg>
			</div>
		</>
	)
}

LoaderCounter.propTypes = {
	radius: PropTypes.number.isRequired,
	stroke: PropTypes.number.isRequired,
	progress: PropTypes.number.isRequired,
	color: PropTypes.string.isRequired,
};

export default LoaderCounter;