import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { formatFriendlylongDate, formatCurrencyCl } from '../../../util';
import { TableSimple, Panel } from '../../../components';

const ApprovedChecks = ({ 
    checks: { approvedChecks },
    t,
 }) => {

    const getGridSettings = () => {
        let columns = [
            {
              expander: true,
              show: false,
            },
            {
              id: 'checkDate', // Required because our accessor is not a string
              Header: t('applicationDate'),
            //   accessor: d => formatFriendlylongDate(d.checkDate),
              accessor: d => d.checkDate,
            },
            {
              id: 'amount', // Required because our accessor is not a string
              Header: t('amountToRequest'),
              accessor: d => `$${formatCurrencyCl(d.amount)}`,
            },
            {
              id: 'currency', // Required because our accessor is not a string
              Header: t('currency'),
              accessor: d => d.currency === 'dolar' ? 'US Dolar' : 'Euro',
            },
            {
              id: 'checkNumber', // Required because our accessor is not a string
              Header: t('checkNumber'),
              accessor: d => d.checkNumber,
            },
            {
              id: 'mail', // Required because our accessor is not a string
              Header: t('email'),
              accessor: d => d.mail,
            },
            {
              id: 'detail', // Required because our accessor is not a string
              Header: t('detail'),
              Cell: cellInfo => (<i className="far fa-file-alt"></i>)
            }
            
        ]

        return columns
    }

    return (
        <>
            <Panel>
                <TableSimple data={approvedChecks} columns={getGridSettings()} filterable={false} />
            </Panel>
        </>
    );
};

const mapStateToProps = ({ checks }) => ({ checks });

export default compose(
  connect(mapStateToProps),
  withNamespaces()
)(ApprovedChecks);