const zh = {
  translations: {
    accept: '接受',
    acceptTerms: '必须接受条款和条件',
    acceptTermsAndConditions: '我已经阅读并接受了服务的条款和条件',
    acceptTermsGateway: '我们接受在客户部分中指示的指控或服务率, 并接受getNet通过直接扣除对机构实践的每月定居点的加载, 如隶属合同中所示, 并在其附件中进行了调节的附件。访问和使用“B2B企业”平台',
    account: '帐户',
    accountBalance: '账户余额',
    accountNumber: '帐号',
    accountPlural: '帐户',
    acquiring_bank: '收单机构银行',
    acquiring_query: '咨询收单机构银行',
    acquiringAdvance: '收单机构预支',
    acquiringBank: '收单机构',
    acquiringQuery: '咨询收单机构银行',
    action: '执行',
    actionNotAllowed: '无法执行',
    activate: '启用',
    active: '资产',
    activeCompanies: '活跃公司',
    addComment: '添加评论 ...',
    addCondition: '添加条件',
    addFinanceProductSuccess: '成功添加的融资服务',
    addFinancingProducts: '添加融资服务',
    additionalInformation: '附加信息',
    addNew: '添新',
    address: '地址',
    ADE: '先进的',
    adjusted_amount: '调整金额',
    admin: '管理员',
    adminBank: '管理员银行',
    administer: '管理',
    administratorBank: '管理员银行',
    administratorName: '管理员姓名',
    administrators: '管理员',
    administratorsList: '管理员列表',
    adminLastname: '姓',
    adminName: '姓名',
    adminPosition: '管理员职位',
    adminRoutes: '管理路线',
    adminUser: '管理员',
    advance: '预支',
    advanceCommercialConditions: '商业条件',
    advanceMount: '预支金额',
    advanceSuccessful: '预支成功',
    affiliateClients: '关联客户',
    affiliateCode: '客户代码',
    affiliateN: '客户编号 (SE#)',
    affiliateNumber: '客户编号 (SE#)',
    affiliationCode: '客户关联编号',
    alias: '别名',
    allowedFormats: '允许格式: JPG和PNG。最大尺寸3MB',
    allowedFormatsPDF: '允许格式: PDF或XML。最大尺寸3MB',
    allowedFormatsXLS: '允许格式: XLSX或XLS。最大尺寸3MB',
    amexCard: '美国运通桑坦德资金卡',
    amount: '数量',
    amountApprovedInvoices: '批准的发票数量',
    amountInstallments: '配额金额',
    amountInt: '数量必须是整数',
    amountMoney: '金额',
    amountOfTransactionsToProcess: '处理的数量',
    amountPaidInvoices: '支付的发票数量',
    amountToCollect: '每日总收据',
    amountToGet: '应收账款',
    amountToPay: '需支付金额',
    amountToRequest: '请求支付金额',
    amountValidate: '该值必须大于或等于0.1',
    annualQuota: '年度配额',
    answer: '回答',
    anticipatedAmount: '预期的金额',
    anualRate: '年度费率',
    applicant: '申请人',
    applicantName: '申请人姓名',
    applicationDate: '申请日期',
    applicationDeleted: '删除的应用程序',
    applicationNotification: '您有 {{payment_request_pending}} 请求',
    applicationOneNotification: '具有 {{payment_request_pending}} 请求',
    applicationRejected: '应用程序成功拒绝',
    applicationSuccess: '批准申请',
    approvalDate: '批准发票日期',
    approvalStatus: '批准状态',
    approve: '批准',
    approved: '已批准',
    approvedChecks: '批准的支票',
    approvedInvoices: '批准的发票',
    approvedInvoicesTitle: 'FAC。批准',
    approvedMount: '批准的金额',
    approvedStatus: '已批准',
    approvedTransaction: '批准的交易',
    approveInvoice: '批准发票',
    april: '四月',
    arrangedDays: '协议账期',
    assignedCardNumber: '分配的卡片',
    assignedRoutes: '分配的路线',
    assignedUsers: '分配的用户',
    assignPermissions: '分配许可证',
    assignRoleRoute: '角色和路线被成功分配',
    assignRoute: '分配路线',
    assignRouteSuccess: '该路线已成功分配',
    assistCard: '辅助卡',
    asterisk: '*** {{number_card}}',
    august: '八月',
    authorization: '授权',
    authUser: '授权用户',
    available: '可用的',
    availableBalance: '可用余额',
    avance: '预支',
    b2bInformation: '有关B2B企业的信息',
    b2bMDR: 'B2B企业费率 (MDR)',
    backImage: '背部的负载图像',
    balance: '余额',
    balanceToPay: '取消余额',
    bankInformation: '选定的银行信息',
    bankingExecutives: '后台',
    bankList: '银行列表',
    bankLogo: '银行徽标',
    bankName: '银行的名称',
    bankProvider: '桑坦德银行',
    banks: '银行',
    bankType: '银行类型',
    baseConditions: '基本条件（发票支付）',
    benefits: '好处',
    billingCycle: '结算周期',
    billingCycles: '计费周期',
    billingPerMonth: '计费 {{month}}',
    billingVolume: '计费量',
    billPayment: '缴费',
    bills: '发票',
    bin: '卡BIN',
    businessExecutive: '业务名称',
    businessExecutives: '顾客',
    businessName: '商业名称',
    buyer: '顾客',
    buyerManualUp: '客户（手动负载）',
    buyersBatchUp: '客户（大规模负载）',
    buyerCode: '客户代码',
    buyerCreateLead: '需要客户排放',
    buyerName: '顾客姓名',
    buyersGroup: '客户群',
    by_amount: '金额',
    by_hierarchy: '由职位层次结构',
    cancel: '取消',
    cannotBeUploaded: '因此, 发票不能在B2B Enterprise中加载',
    cantCreateTransaction: '发票号已经存在',
    cantCustomers: '选定的客户加载',
    cantDeleteRol: '由于分配了一个或多个路线，因此无法删除该角色',
    cantDeleteRoute: '该路由无法删除，因为它被分配给一个或多个角色',
    canton: '广州',
    cantProviders: '选定的公司加载',
    cardBind: '卡BIN',
    cardChargeDate: '卡费用日期',
    cardholderPaymentPercentage: '持卡人支付百分比',
    cardList: '卡列表',
    cardNumber: '卡号',
    cardPayment: '选择付款的TTA',
    cardPaymentDate: '信用卡付款日期',
    cards: '牌',
    categories: '类别',
    ccChargeDate: 'CC费用日期',
    cedulaJuridicaDesc: '10位数字, 开头或脚本没有零',
    changeCode: '您必须修改与新选择的货币类型相对应的贸易代码',
    changeLog: '更改记录',
    changeOfCommercialConditions: '商业条件变更的注册',
    changePassword: '更改密码',
    changePasswordSuccess: '成功更改密码',
    charge: '邮政',
    chargeDate: '货物日期',
    chargeForService: '服务费',
    chargeRate: '利率',
    checkDate: '检查日期',
    checkDeposit: '存放支票',
    checkNumber: '检查号码',
    checks: '检查',
    checksToBeApproved: '支票要批准',
    CIF: 'CIF',
    cifPattern: '它必须包含字母和数字',
    city: '城市',
    client: '顾客',
    clientB2BRate: '客户的B2B利率是',
    clientName: '顾客姓名',
    clientPay: '客户付款',
    clientPaymentCondition: '确定供应商与客户之间的付款条件',
    clientPercentageCharge: '客户服务百分比',
    clientRUT: 'RUT客户',
    clients: '顾客',
    clientsPay: '客户付款',
    clientsQuantity: '客户数量',
    code: '代码',
    codeArea: '代码。区域 +n˚电话',
    collaboratorNumber: '合作者号码',
    collaboratorNumberCharacter: '合作者编号必须有8个字符',
    collectionDate: '采集日期',
    commerceCode: '会员编号 (SE#)',
    commerceCodeDesc: '输入商业代码的描述',
    commercialBillingUser: '商业计费用户',
    commercialConditions: '商业条款',
    commune: '公社',
    companies: '公司',
    company: '公司',
    completeComercialConditionOrEmptyAll: '完成商业条件的数据，如果您不想与任何供应商添加商业条件，请将其空白',
    concept: '概念',
    condition_amount: '数量',
    condition: '健康）状况',
    conditions: '状况',
    configuration: '环境',
    confirm: '确认',
    confirmData: '确认数据',
    confirmPassword: '确认密码',
    confirmPassTooltip: '密码和确认必须相同',
    confirmTaxCode: '确认税密钥',
    confirmTransfer: '确认您的交易',
    confirmUnsubscribe: '您确定要使用{{client}}和涉及的{{product}}取消订阅商业条件？',
    confirmUnsubscribeFrom: '确认低：{{product}}',
    consult: '咨询',
    contactPhone: '电话',
    continue: '继续',
    continueLoadingCustomers: '继续客户负载？',
    continueLoadingProviders: '继续承担公司的负担？',
    contract: '聘请',
    contractedProducts: '雇用保险',
    contractRequest: '合同申请',
    cort: '法庭',
    cortDate: '截止日期',
    cost: '成本',
    country: '国家',
    createBank: '创建新银行',
    createBankSuccess: '银行{{name}}成功创建了',
    createBuyer: '创建客户',
    createClientSuccess: '客户端{{name}}成功创建了',
    createCondition: '创建：{{name}}的商业条件',
    createExecutive: '创建后台',
    createFlowApproval: '创建发票批准流',
    createFlowApprovalSuccess: '发票批准流，成功创建',
    createInvoicesSuccess: '发票已成功记录',
    createInvoiceSuccess: '发票号{{Invoice_number}}已成功记录',
    createLead: '需要\ n高',
    createNewExecutive: '创建新的执行官“ {{inputValueExectee}}”',
    createNewItem: '创建新的行业“ {{inputValue}}”',
    createNewManaged: '创建新的管理员',
    createPaymentCondition: '创建条件\ nde付款',
    createPaymentConditionSuccess: '付款条件成功创造了',
    createProfile: '创建个人资料',
    createProfileSuccess: 'profile {{name}}成功创建了',
    createProvider: '创建供应商',
    createProviderSuccess: '供应商{{name}}成功创建了',
    createQuestionSuccess: '成功创建了问题',
    createRol: '创建角色',
    createRoleSuccess: '角色{{rolname}}成功创建了',
    createRolSuccess: '角色{{rolname}}成功创建了',
    createUser: '创建用户',
    createUserSuccess: '用户{{name}}成功创建了',
    creditAvailableBalance: '可用余额L.C.I.',
    creditCard: '信用卡',
    creditCardSubstitution: '信用卡更换',
    creditConditionsMsg: '*选择使用信用额度时，您必须定义信用条件。',
    creditPeriod: '信用周期',
    creditRequest: '请求信用额度',
    creditRequestForAdvance: '请求L.C.I进行发票的发展',
    creditRequestForInvoice: '请求L.C.I支付发票',
    creditsList: '信用管线注册',
    currency: '货币',
    currentCycle: '当前周期',
    customer: '顾客',
    customerInformation: '选定的客户信息',
    customerName: '顾客姓名',
    customerPayment: '客户付款',
    customerPaymentPercentage: '客户付款的％',
    customers: '顾客',
    customersSaved: '成功加载客户',
    cuttingCycle: '循环支付周期',
    cuttingCycleAmex: '循环支付周期卡',
    cuttingCycleCard: '卡循环周期是：{{{messages}}',
    cycle_loader: '计费周期',
    cycleGroup: '周期组',
    cyclesList: '计费周期列表',
    daily: '日记',
    dashboard: '概括',
    dataSavedSuccessfully: '成功保存数据',
    date: '日期',
    dateFf: '日期ff',
    dateInvoice: '发票日期',
    dateNotAvailable: '日期不可用',
    dateOfAdmission: 'SII入学日期',
    dateOfEntry: '进入B2B的日期',
    datePayment: '付款日期',
    dateRange: '日期范围',
    dateV: '到期日',
    day: '天',
    daysAdvance: '预支天数',
    daysDefer: '几天有所不同',
    daysNumber: '天数',
    daysSince: '从',
    deactivate: '停用',
    december: '十二月',
    defaultFlow: '默认流',
    defer: '递延',
    deferCommercialConditions: '延期的商业条件',
    deferMount: '递延金额',
    deferred: '递延',
    deferredAmount: '递延金额',
    delete: '排除',
    deleteExecutive: '后台卸下',
    deleteInvoice: '发票已删除',
    deletePaymentCondition: '消除了付款条件',
    deletePaymentCondConf: '您确定要消除这种商业关系吗？',
    deleteRol: '角色被取消',
    deleteRoute: '路线被取消',
    deleteTta: 'Amex卡成功删除',
    demographicInformation: '识别数据',
    deny: '否定',
    deposit: '存款',
    depositReason: '存款的原因',
    description: '描述',
    destinationAccount: '目标帐户',
    detail: '细节',
    DIF: '递延',
    differ: '不同',
    digitalCertificate: '数字证书',
    disable: '禁用',
    disable2FA: '停用两个步骤验证',
    disabled: '禁用',
    discountAmount: '折扣金额',
    discountRate: '折扣率',
    discountRateAccept: '桑坦德银行的服务条件',
    discountRateCharges: '折现率费用',
    discountRateInvoiceSavedSuccessfully: '折现率发票成功节省了',
    discountRateMDR: 'DCTO率 (MDR)',
    discountRateOutOfLimits: '超出限制的折现率',
    discountRateTerms: '通过接受我的桑坦德信贷司库American Express的费用, 客户承诺支付账单, 以及向Banco Santander提供服务费用的所有发票中的所有发票, 其中客户支付了指示的供应商。',
    district: '区',
    document: '文档',
    documentDate: '文件日期',
    documentNumber: '文件号码。',
    documentType: '文档类型',
    doNotIncludeTheUsername: '*不要将用户的名称作为密码的一部分。',
    dontPose: '不具有',
    download: '释放',
    eCommerceTerminal: '电子商务终端',
    edit_user: '编辑用户',
    edit: '编辑',
    editBank: '编辑手册：{{name}}',
    editClientSuccess: '客户端{{name}}成功编辑',
    editCycle: '编辑周期',
    editFlowApprovalSuccess: '成功编辑的批准流',
    editionRequestSent: '要求发送的版本请求',
    editPaymentConditionSuccess: '付款条件成功。',
    editProfileSuccess: '个人资料{{name}}成功编辑',
    editProviderSuccess: '供应商{{name}}成功编辑',
    editUserSuccess: '用户{{name}}成功编辑',
    email: '电子邮件',
    email2FA: '验证代码已发送到您的电子邮件中，请输入以继续操作',
    emailLabel: 'name@empresa.cl',
    emissionFlow: '排放流',
    emitInvoices: '具有 {{emit_invoices}} 发票 而无需发出',
    emptyAll: '确保填写所有信息',
    emptyAllRequest: '确保填写强制性字段的所有信息',
    emptyCreditList: '您没有积极的信用额度，您想请求信用额度吗？',
    emptyFields: '您必须完成所有字段。',
    enable: '使能够',
    enable2FA: '激活两个步骤验证',
    enabled: '启用',
    endingDate: '结束日期',
    eng: '工程',
    enter: '进入',
    enterCodeSms: '验证并输入我们通过SMS发送给您的代码\ n',
    enterCuttingCycle: '输入切割周期',
    enterDataInternalTaxService: '将您的公司连接到SII-内部税务服务',
    enterTaxCode: '输入税密钥',
    enterTheCardBillingCycle: '有必要输入卡计费周期以继续使用此卡',
    enterYourNewPassword: '输入新密码',
    errorCustomers: '有错误的客户',
    errorData: '错误加载数据。',
    errorDate: '日期\'因为\'必须更少',
    errorDateCycles: 'FF日期必须少',
    errorLoad: '您必须加载一个扩展文件{{{type}}',
    errorProviders: '供应商有错误',
    errors: '错误',
    es: '西班牙语',
    pt: '葡萄牙语',
    en: '英语',
    fr: '法语',
    zh: "中文",
    event: '事件',
    executiveData: '后台数据',
    expirationDate: '截止日期',
    expiredInvoices: '批准 /付款',
    extract: '提炼',
    extractAccount: 'B2B网络提取',
    extractDetail1: '成功生成的批准代码',
    extractDetail2: '撤回',
    extractDetail3: '在',
    extractDetail4: '在我们网络上的某个点完成提取',
    extractDetail5: '带有您的电话号码',
    extractDetail6: '将您的电话号码提供给点运算符，以完成提取的提取',
    extractDetail7: '二维码',
    extractDetail8: '在网络点介绍以下代码',
    factoring: '考虑',
    factoringDiscountAmount: '保理折扣金额',
    factoringRate: '保理率',
    failedCustomers: '有错误的客户',
    failedCustomersAlert: '(没有错误的客户不会考虑注册)',
    failedProviders: '有错误的业务',
    failedProvidersAlert: '(没有错误的公司不会考虑注册)',
    fantasyName: '虚构名称',
    february: '二月',
    FF: 'ff',
    fileSentSuccessfully: '该文件已成功发送',
    fileSize: '最大允许尺寸为3MB',
    fileSizeExceeded: '您的文件超过3MB的最大权重',
    financingProducts: '\nfinancing产品',
    financingRate: '融资率',
    finish: '结束',
    fiscaldocument: '财政文件',
    flexibleConditions: '灵活条件（融资)',
    flowApproval: '批准流',
    flowApprovalCheck: '默认批准流',
    flowsApproval: '批准流',
    FMA: '两个步骤验证',
    forgotPassword: '忘记密码了吗？',
    friday: '星期五',
    fromReceptionDate: '接待日期',
    frontImage: '上传图像',
    fullName: '名和姓',
    FV: 'FV',
    gateway: '网关',
    general_admin_acquiring: '收购方用户总管理员',
    general_admin_issuing_amex: 'Amyx发行人银行的用户总管理员',
    general_admin_issuing: '发行人的用户总管理员',
    general_admin: '银行总管理员用户',
    getnet: 'getnet',
    goBack: '回去',
    grant: '授予',
    greaterThan: '老年',
    greaterThanOrEqualTo: '年龄较大',
    group: '簇',
    handbook: '手动的',
    identificationNumber: '身份证号码。',
    import: '数量',
    inactive: '闲置的',
    inactiveBusinesses: '不活动的商店',
    inactiveClients: '不活跃的客户',
    inCharge: '负责任的',
    incompleteData: '数据不完整',
    incorrectDate: '您不能在之前输入日期，{{datenow}}',
    incorrectDays: '您必须输入大于或等于0的值',
    incorrectEmail: '{{email}}似乎没有有效的电子邮件。检查您的格式正确：示例@mail.com',
    info: '信息',
    infoLog: '事件信息',
    informationOfTheSelectedBank: '选定的银行信息',
    insertYourEmailAddress: '输入你的电子邮箱地址。',
    installmentNumber: '配额的数量',
    installments: '配额编号',
    installmentsQty: '配额的数量',
    insurances: '保险',
    interest_rate: '利息金额',
    interestRate: '利率',
    internalRevenueService: '国税局',
    intNumber: '仅支持整数',
    invalidAmount: '数额错误',
    invalidCard: '被检查',
    invalidRoleOrRoute: '角色和路线不能是空的',
    invoice: '账单',
    invoiceAmount: '发票金额',
    invoiceApprovedSuccess: '发票成功批准',
    invoiceCount: '发票数量',
    invoiceDate: '发票日期',
    invoiceDetail: '发票细节',
    invoiceGroup: '发票组',
    invoiceIssueDate: '发票发行日期',
    invoiceManualResolution: '需要手动分辨率的发票',
    invoiceMarkPaid: '发票号{{invoiceNumber}}被标记为付费',
    invoiceN: '发票n° {{invoiceNumber}}}}',
    invoiceNumber: 'n°发票',
    invoiceNumberValidate: '发票编号入口的有效格式是xxx-xxx-xxxxxxxxxxxx',
    invoicePending: '发行',
    invoiceProvidersReceiver: '提供者账单收据',
    invoiceReject: '拒绝发票',
    invoiceRejectedSuccess: '发票成功拒绝了',
    invoices: '账单',
    invoicesApproved: '批准的发票',
    invoicesDeferredApproved: '批准和递延发票',
    invoicesNumber: '发票号码',
    invoicesPendingApproved: '待定和递延发票',
    invoicesSent: '发送发票',
    invoiceStatus: 'SII中发票的状态为: {{status}}',
    invoiceStatusInTheSii: 'SII中发票的状态',
    invoicesToBeApproved: '批准的发票',
    invoiceTerms: '在批准B2B企业平台内的发票时，我接受将在我的桑坦德美国运通司库卡上持有的职位，每张发票中个性化的金额以及服务的金额，如果适用的话，商业条件与您的供应商一致。不可撤销地指示Banco Santander，以便在上述卡中进行此类费用。',
    invoiceTotalAmount: '发票中的金额',
    invoiceUploaded: '发票号 {{invoiceNumber}} 在B2B企业中加载成功',
    issuedInvoices: '发行发票',
    issuing_bank: '发卡行',
    issuing_query: '咨询发卡行',
    items: '行业',
    january: '一月',
    july: '七月',
    june: '六月',
    kindOfProduct: '一种产品',
    language: '语言',
    last4Digits: '最后4位数字',
    lastDays: '最后一天',
    lastMovements: '最后动作',
    lead: '高的',
    leadList: '\nrequeridos的列表',
    legal: '合法的',
    lengthToCharacters: '*长度为8至12个字符，包含以下三个特征：大写字母（A-Z），小写字母（A-Z），数字（0-9）或特殊字符。',
    lessThan: '次要的',
    lessThanOrEqualTo: '不太相同',
    limit: '限制',
    limitAmount: '限额',
    limitAmountValidate: '您必须输入大于或等于0的值',
    line: '线',
    listCards: '卡列表',
    listCuttingCycle: '切割周期',
    listFaqs: '列表常见问题',
    listInvoices: '在评论中',
    listManuals: '列出手册',
    listPayments: '付款清单',
    load: '携带',
    loadBillingCycle: '收费计费周期',
    loadBuyersGroup: '加载客户端组',
    loadCsv: '负载CSV',
    loadCustomers: '加载客户',
    loadCustomersGroup: '加载客户端组',
    loadFile: '上传文件',
    loadFromFile: '从文件加载',
    loading: '收费...',
    loadingContent: '加载内容...',
    loadInvoice: '加载发票',
    loadInvoiceData: '加载发票数据',
    loadInvoicesGroup: '加载发票组',
    loadProviders: '负载供应商',
    loadProvidersGroup: '负载供应商',
    loan: '贷款',
    loanPeriod: '贷款期',
    loanRequest: '请求贷款以促进发票',
    logout: '出去',
    logs: '日志',
    logSource: '日志源',
    logTransaction: '日志交易',
    logView: '日志视图',
    main_bank_acquiring: '收单银行管理员',
    main_bank_issuing_amex: 'Amyx发行人的用户管理员',
    main_bank_issuing: '发行人的用户管理员',
    main_bank: '银行管理员用户',
    mainInvoiceNumber: '主要发票号',
    mainUser: '主要用户',
    MAN: '手动分辨率',
    mandatoryFieldsRequired: '(*) 您必须填写所有标记有星号的字段',
    manualLoaded: '成功加载手册',
    manualResolution: '手动分辨率',
    manualsAndFaq: '手册和常见问题',
    march: '行进',
    markAsPaid: '标记为付费',
    maxRank: '(直到)',
    may: '可能',
    mccCode: 'MCC代码',
    mdr: 'MDR',
    merchantAffiliateCode: '会员编号',
    message: '信息',
    messageUserProcess: '目前，它没有一种已配置的批准流。请联系您的代表以激活此功能。',
    minimumCharacter: '贸易外科代码必须具有8到15个字符',
    mixedBalance: '混合平衡',
    mixedPayment: '混合付款',
    monday: '周一',
    month: '月',
    monthly: '每月',
    monthlyReport: '月度报告',
    name: '姓名',
    nameFantasy: '虚构名称',
    naoReter: '不要保留GO（宣传，广告，委员会和经纪)',
    NEG: '否认',
    new: '新的',
    newBank: '新银行',
    newBankingExecutive: '新的后台',
    newPassword: '新密码',
    newPaymentCondition: '新的付款条件',
    newProfile: '新用户',
    newRecharge: '新的充电',
    newRole: '新角色',
    newTransfer: '新转移',
    next: '下列的',
    nextCollection: '下一个系列',
    nextDays: '接下来的日子',
    nextPage: '下列的',
    nextPayment: '下一个付款',
    nInvoices: '发票的NRO',
    no: '不',
    noCard: '没有预付费卡',
    noCycles: '没有收费的切割周期为: {{month}}',
    noData: '无信息',
    noExecutivesAvailable: '没有后台',
    noInformation: '无信息',
    noItemsAvailable: '没有可用的行业',
    nonMatchingPasswords: '添加的密码不匹配',
    noRowsFound: '无信息',
    notifyCardPaymentAuthorization: '通知卡付款授权',
    notifyPaymentAuthorization: '通知付款授权',
    noTransactions: '这张卡没有动作',
    notVerified: '未经审核的',
    notYetFound: 'SII中尚不可用该发票，如果您希望可以加载它，并且B2B Enterprise将负责重新布置搜索并将其加载到我们的平台上，否则取消操作并再次尝试再次加载它',
    november: '十一月',
    number: '数字',
    numberInstallments: '配额的数量',
    numberTta: 'TTA编号',
    october: '十月',
    of: '的',
    onlyCardPaymentDate: '卡付款日期',
    operationLog: '事件日志',
    operationRank: '运营范围（来自)',
    operationVolume: '卷操作',
    operator: '健康）状况',
    originAccount: '根帐户',
    otherSign: '其他标志',
    PAG: '有薪酬的',
    page: '页面',
    paid: '有薪酬的',
    paidAmount: '支付的金额',
    paidInvoices: '支付的账单',
    paidOut: '有薪酬的',
    PAP: '被允许',
    params: '参数',
    password: '密码',
    passport: '护照',
    passwordDigitalCertificate: '数字证书密码',
    passwordHasExpired: '您的密码已经过期，请输入您的新密码',
    payedAmounts: '每日总发票',
    payInvoiceWithBalance: '您想取消NU账单吗？ ${{toggleProps.transaction.invoice_number}} 带有支票帐户的余额？',
    payment: '支付',
    paymentAmount: '应付金额',
    paymentClient: '负责客户的百分比',
    paymentCondition: '健康）状况',
    paymentConditionClients: '客户的商业条件',
    paymentConditionProvider: '供应商的商业条件',
    paymentCurrency: '付款货币',
    paymentDate: '付款日期',
    paymentDateInvoice: '发票收集日期：',
    paymentDateRange: '发票收集日期日期',
    paymentDateRangeClient: '发票付款日期范围',
    paymentDeadline: '付款截止日期',
    paymentMethod: '付款方式',
    paymentOrders: '付款订单',
    paymentsTransfers: '付款和转让',
    paymentWay: '您想如何取消发票号',
    paymentWayMsg: '通过接受混合付款的选项，您可以结合付款选项。',
    period: '时期',
    profileList: '用户列表',
    PEJ: '执行',
    PEM: '发行',
    pendingInvoices: '待处理的发票',
    pendingInvoicesQTY: '您有 {{pending_invoices}} 个未经批准的未决发票',
    physical: '身体的',
    profile: '轮廓',
    provider: '供应商',
    providerGroup: '提供商组',
    providers: '供应商',
    receivedInvoices: '收到发票',
    receiverDiscountShare: '供应商付款百分比',
    receptionDate: '发票签发日期',
    recoverPassword: '恢复密码',
    registerCard: '注册卡',
    registeredCards: '客户注册卡: {{name}}',
    registerProduct: '注册产品',
    registerRole: '注册角色',
    registerRoute: '注册路线',
    registrationStatus: '注册状态',
    reject: '拒绝',
    rejectedChecks: '失败支票',
    rejectedCycle: '成功拒绝了计费周期。',
    rejectedInvoices: '拒绝发票',
    rejectInvoice: '拒绝',
    rejectInvoiceAll: '拒绝发票',
    rejectInvoices: '您有{{reculd_invoices}}拒绝发票（s)',
    rejectReason: '拒绝的原因',
    rejectTradeRequest: '拒绝商业应用',
    relatedUsers: '相关用户',
    rememberToAcceptTheTerms: '记住接受租用产品的条款和条件',
    removeRouteSuccess: '这条路线已成功删除',
    replaceTheSelectedCard: '您一定要替换所选的卡。',
    reports: '报告',
    request: '申请',
    requestEdition: '请求编辑',
    requestingBank: '申请人银行',
    requestProducts: '请求您的保险或协助',
    requestReceived: '已收到',
    requestSent: '提交',
    requestSentProduct: '发送的请求',
    requestsReceived: '收到的请求',
    requestsSent: '发送的申请',
    requestUnsubscribe: '请求提款',
    requiredFields: '(*）必需字段',
    requiredInstallments: '配额的数量无效',
    requiredName: '名称无效',
    requiredPeriod: '这个时期无效',
    requiredRate: '费率无效',
    requiredTypeFinance: '融资类型无效',
    requireSuccess: '{{name}} 成功需要',
    resource: '资源',
    respJson: 'JSON答案',
    response: '回答',
    respXml: 'XML答案',
    return: '返回',
    REV: '逆转',
    RFD: '退款',
    rol: '角色',
    roleCantBeEmpty: '角色不能是空的',
    roleList: '角色列表',
    roles: '角色',
    roleType: '角色类型',
    rolName: '角色名称',
    rolType: '角色类型',
    routeList: '路线列表',
    routes: '路线',
    rows: '排名',
    RUT: '发情',
    safetyRules: '我们需要将您的公司连接到SII，以获取您的供应商向您的Social Razon发行的发票，为此，我们感谢您输入以下数据：',
    saturday: '周六',
    save: '保存',
    savedCards: '保存信用卡',
    saveFaq: '手册和常见问题',
    savePlatformFaq: '负载平台的常见问题解答',
    saveQuestion: '加载常见问题',
    search: '寻找',
    searchBuyers: '查找客户',
    searchSuppliers: '查找供应商',
    searchUsers: '查找用户',
    secureCards: '信用卡的信息将根据严格的安全标准存储',
    seeDetail: '请参阅细节',
    seeHow: '看看如何',
    seeQuestion: '请参阅问题',
    seeUsers: '查看用户',
    Select: '选择 ...',
    selectANewCommercialExecutive: '您必须选择一个新的后台办公室',
    selectBank: '您必须选择一家银行',
    SelectBank: '选择一家银行',
    selectCard: '选定的TTA',
    selectCycle: '您必须选择所有周期',
    SelectDocumentType: '选择要继续的文档的类型',
    selectedBank: '银行',
    selectFile: '选择文件',
    selectInvoice: '选择发票',
    selectMonth: '您必须选择一个月',
    SelectOption: '选择一个选项',
    selectOrTypeItems: '选择或填写行业...',
    selectOrTypePermissions: '选择或填写许可',
    selectOrTypeRol: '选择或填写角色',
    selectOrTypeRoutes: '选择或编写路线',
    selectRecipient: '选择收件人',
    seller: '供应商',
    sellerCode: '供应商代码',
    sellerCreateLead: '需要供应商排放',
    sellerName: '提供者的名字',
    sellersQuantity: '供应商数量',
    send: '发送',
    send2FASuccess: '正确发送的代码',
    sendDate: '发送日期',
    senderDiscountShare: '客户支付百分比',
    sendErp: '发送ERP',
    sendInformation: '发送信息',
    sendInvoice: '发票运输日期',
    sentInvoices: '发票',
    september: '九月',
    settings: '设置',
    shippingDate: '发货日期',
    shouldNotSelect: '您不应该选择要删除的同一商业主管',
    showPassword: '显示密码',
    showQR: '显示QR码',
    since: '从',
    startCountingFrom: '同意的日子',
    startDate: '开始日期',
    status: '状态',
    statusOfInvoice: 'SII中发票号 {{InvoIceNumber}} 的状态是: {{status}}',
    street: '街道',
    successCard: '您的卡已经注册。',
    successCycle: '成功确认了计费周期。',
    successCycles: '成功设立计费周期。',
    successEmail: '一封电子邮件已发送到您的电子邮件地址。',
    successExecutive: '后台成功创建了',
    successfulFactoring: '保理成功',
    successfullyAssignedCuttingCycle: '成功分配的切割周期',
    successfullyUpdatedManual: '更新手册成功',
    suggestTradeConditionCorrection: '建议校正商业条件',
    summary: '概括',
    sunday: '星期日',
    support: '出勤',
    supportLink: '支持链接',
    target: '卡片',
    termsAndConditions: '条款和条件',
    theCodeExpiresIn: '代码到期：{{time}}',
    theInvoiceWillBeCharged: '发票将上载至B2B Enterprise，{{status}}',
    thereIsNoInformationOnFlexibleConditions: '没有灵活的条件信息',
    theyMustNotHave: '*他们不应具有连续的相同字符，也不应完全数值，也不完全字母。',
    thursday: '周四',
    time: '小时',
    toBeApproved: '被允许',
    toDisable: '禁用',
    total: '全部的',
    totalAmount: '全部的',
    totalAmountToPayOnTheInvoice: '账单上要支付的总金额',
    totalAmountToReceive: '总金额',
    totalBuyers: '总付费公司',
    totalBuyersDeferred: '具有延期发票的公司',
    totalBuyersInvoiced: '具有批准发票的公司',
    totalBuyersTh: '总公司',
    totalCharged: '总收费 {{month}}',
    totalCommissionAmount: '总委员会',
    totalDebtAmount: '总金额',
    totalInvoices: '总发票',
    totalOwedAmount: '要收集的总金额',
    totalPay: '总付款',
    totalReceiver: '附属供应商',
    totalSellers: '供应商拥有批准的账单',
    totalSellersCharged: '供应待定的账单',
    totalSellersInvoiced: '总计批准',
    totalToApprove: '总计批准',
    totalToPayCardholder: '总支付服务的持卡费',
    totalToPayInvoice: '总支付账单',
    totalToReceive: '总应收',
    totalVolumePendingPayment: '总销量待定付款',
    tradeInformation: '商业信息',
    transactionReport: '交易报告',
    transactions: '交易',
    transactionsToProcess: '交易进行处理',
    transactionType: '交易类型',
    transfer: '转移',
    transferCharge: '操作位置',
    transferDetail: '成功转移',
    transferDetail2: '代金券',
    transferDetail3: '发给',
    transferDetail4: '发送的金额',
    transferDetail5: '总金额打折',
    transferring: '转移',
    transferState: '状态',
    transferType: '交易类型',
    TtaValidation: 'TTA验证',
    tuesday: '周二',
    type: '伙计',
    typeAndDocumentNumber: '类型和文档',
    typeFinance: '融资类型',
    typeOfApprovalFlow: '批准流',
    typeOfEmitFlow: '发射流',
    underReview: '审查中',
    underReviewInvoices: '审查中的发票',
    Unsubscribe: '退订',
    unsuscribeRequest: '退订请求',
    until: '直到',
    updateBank: '刷新银行',
    updatedBillingCycle: '更新的计费周期',
    updatedBillingCycles: '更新的计费周期',
    updateInfo: '更新信息',
    updateInformation: '更新信息',
    updatePlatformFaq: '编辑平台的常见问题解答',
    uploadInvoice: '加载发票',
    uploadManuals: '加载手册',
    user_list: '用户列表',
    user: '用户',
    userBillingCycles: '用户计费周期',
    userManual: '用户手册',
    username: '用户名',
    userQuery: '咨询用户',
    users: '用户',
    validCard: '经过验证',
    validEmail: '您必须输入属于 @baccredomatic.com的邮件',
    validity: '有效性',
    valorNeto: '净值',
    vendorMerchantAffiliateCodes: '供应商会员编号: {{provider}}',
    verificationCode: '验证码',
    verificationCodeSent: '输入发送到电子邮件的验证代码',
    verifyHuman: '请验证您是人类',
    view: '看',
    viewTta: '参见TTA',
    virtualAccount: '虚拟帐户',
    VOD: '空的',
    volumeProcessed: '过去三个月的处理数量',
    waitingBillingCycle: '等待计费周期分配',
    waitingForApproval: '等待服务批准离开服务',
    webpay: '网页',
    wednesday: '周三',
    whatPaymentCondition: '您想使用什么付款条件？',
    yearly: '年度的',
    yes: '是的',
    yourDataWillBeStoredEncrypted: '*您的数据将根据严格的安全标准进行加密。',
    '/create-provider': '创建供应商',
    '/edit-provider': '编辑供应商',
    '/create-customer': '创建客户',
    '/edit-customer': '编辑客户',
    '/create-profile': '创建个人资料',
    '/dashboard': '概括',
    '/account-details': '帐户详细资料',
    '/extract': '提取',
    '/prepaid-Card': '预付卡',
    '/transfer': '转移',
    '/deposit': '存款',
    '/load-invoice': '负载账单',
    '/customers': '列表，编辑或禁用客户',
    '/invoices': '列出发票',
    '/invoices': '账单',
    '/expired-invoices': '列表已过期的发票',
    '/related-users': '客户或相关供应商，//检查',
    '/clients': '顾客',
    '/providers': '供应商',
    '/register-bank': '创建银行',
    '/bank-list': '列表，编辑或禁用银行',
    '/role-list': '列表，编辑和消除角色',
    '/invoice-detail': '发票细节',
    '/register-financing-products': '创建产品融资',
    '/financing-products': '金融产品',
    '/register-role': '创建角色',
    '/route-list': '列表，编辑和删除路线',
    '/register-route': '创建路线',
    '/admin-routes': '分配或删除到角色的路由',
    '/review-invoices': '审查中的发票',
    '/reject-invoices': '拒绝发票',
    '/approved-invoices': '批准的发票',
    '/paid-invoices': '支付的账单',
    '/pending-invoices': '等待发票',
    '/create-lead': '需要客户或供应商',
    '/lead-list': '列表，批准或拒绝所需的',
    '/reports': '报告',
    '/payment-conditions-log': '付款条件的注册',
    '/transactions': '银行生成的发票',
    '/profile-list': '列表，编辑或删除配置文件',
    '/create-approval-flow': '创建发票批准流',
    '/edit-approval-flow': '编辑发票批准流',
    '/approval-flow-list': '批准流列表',
    '/account-profile': '帐户资料',
    '/payment-conditions': '付款条件',
    '/create-bank-user': '创建银行用户',
    '/edit-bank-user': '编辑银行用户',
    '/bank-user-list': '银行用户列表',
    '/register-card': '注册卡',
    '/replace-card': '信用卡更换',
    '/list-cards': '卡列表',
    '/payment-request-sent': '申请',
    '/payment-request-received': '申请',
    '/save-credentials': '保存凭据',
    '/edit-credentials': '编辑凭据',
    '/load-billing-cycle': '负载计费周期',
    '/edit-billing-cycle': '编辑计费周期',
    '/billing-cycles': '计费周期',
    '/banking-executives': '后台',
    '/create-banking-executive': '创建后台',
    '/load-executives': '加载高管',
    '/products': '产品',
    '/contracted-products': '收费产品',
    '/product-detail': '产品信息',
    '/accept-discount-rate': '接受折现率',
    '/edit-bank-admin': '编辑银行管理员',
    '/save-manual': '保存手册',
    '/list-manual': '手册',
    '/save-faq': '加载常见问题',
    '/list-faqs': '列表常见问题',
    '/support': '出勤',
    '/monthly-report': '月度报告',
    '/commerce-code': '商业法规',
    '/confirm-provider': '确认供应商',
    '/confirm-customer': '确认客户',
    '/user-commerce-code': '会员编号',
    '/create-payment-condition': '创建新的付款条件',
    '/edit-payment-condition': '编辑付款条件',
    '/provider-group-load': '一组供应商',
    '/customer-group-load': '客户群',
    '/list-provider': '供应商清单',
    '/list-customer': '客户端列表',
    '/create-admin-bank': '创建管理员银行',
    '/edit-admin-bank': '编辑管理员银行',
    '/admin-bank-list': '行政银行',
    '/resp': '卡存储确认',
    '/log-view': '日志',
    '/operation-log': '日志',
    '/mixed-payments': '计划混合付款',
    '/credit-request': '积分和贷款',
    '/credits-list': '信用注册',
    '/check-deposit': '支票存入',
    '/approved-checks': '批准的支票',
    '/not-approved-checks': '支票要批准',
    '/rejected-checks': '支票支付失败',
    '/send-information': '发送ERP',
    '/load-invoice-group': '发票组'
  },
};

export default zh;