import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { Panel, Toggle, Modal } from '../../../components';
import { CheckForm } from './components';

const CheckDeposit = ({ t, }) => {
    // const [step, setStep] = useState('CreditRequestForm')
    const [check, setCheck] = useState({
        accountNumber: 'AE120030000853214569874',
        currency: 'dolar',
        amount: null,
        reason: '',
        checkNumber: '',
        checkDate: '',
        mail: ''
    })
    const handleForm = value => setCheck({ ...check, ...value })

    return (
        <Toggle>
            {({toggled, onToggle}) => {
                return (
                    <>
                    {
                        toggled ? (
                            <Modal 
                              onToggle={onToggle}
                              title={t('approvedTransaction')}>
                                <p style={{ marginTop:'20px', fontSize: '1.1em', textAlign: 'center'}}> 
                                    {t('referenceNumber')}: TPS2005466YIJKL
                                </p>
                                <div className='modal-action-btns'>
                                    <Link to='/approved-checks' className='button custom-btn is-danger'>
                                        {t('return')}
                                    </Link>
                                    <button className='button custom-btn is-primary'>{t('print')}</button>
                                </div>
                            </Modal>
                        ) : (
                            <CheckForm onToggle={onToggle} handleForm={handleForm} check={check}/>
                        )
                    }
                    </>
                )
            }}
        </Toggle>
    );
};

export default compose(
    connect(null),
    withNamespaces()
  )(CheckDeposit);