import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import './styles.scss';
import LoaderCounter from './components/LoaderCounter';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { roleActions } from '../../state/ducks/role';
import { authActions } from '../../state/ducks/auth';

const SendInformation = (props) => {
	const { t } = props;
	
	const [isProcessing, setIsProcessing] = useState(false)
	const [title, setTitle] = useState('')
	const [counter, setCounter] = useState(0)

	let increment = 0
	const timer = () => {
		setCounter(increment * 10);
		increment++;
	};

	const procesingFile = () => {
		setIsProcessing(true)
		setTitle(`${t('transferring')}...`);
		const interval = setInterval(timer, 1000);
		setTimeout(() => {
			setIsProcessing(false);
			setTitle(t('fileSentSuccessfully'));
			clearInterval(interval);
		}, 11000);
	}

	return (
		<>
			<div className='send-information animated fadeInUp'>
				<div className='columns is-multiline is-three-quarters shadow'>
					<div className='column is-full header'>
							<h2>{t('sendInformation')}</h2>
					</div>
					<div className='column is-full'>
						<p className='has-text-centered title has-text-primary'>{title}</p>
						<div className='counter-container'>
							<p className='counter-value'> {counter} <small> %</small></p>
							<LoaderCounter
								radius={100}
								stroke={12}
								progress={counter}
								color={'#16aa85'}
							/>
						</div>
						<div className="column">
							<div className="columns">
								<div className="column is-half">
									<button className='button is-fullwidth is-primary'>
										{t('cancel')}
									</button>
								</div>
								<div className="column is-half">
									<button className='button is-fullwidth is-primary' disabled={isProcessing} onClick={procesingFile}>
										{t('accept')}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = ({ role, auth }) => ({ role, auth });

export default compose(
  connect(mapStateToProps, { ...roleActions, ...authActions }),
  withNamespaces(),
)(SendInformation);