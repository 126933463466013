const fr = {
    translations : {
        accept : 'Accepter',
        invoiceStatus : '{{status}}',
        acceptTerms : 'Veuillez accepter les modalités',
        acceptTermsAndConditions : 'J’ai lu et j’accepte les modalités du service.',
        acceptTermsGateway : 'Nous acceptons les opérations et les frais de service qui figurent dans la section des clients et acceptons qu’ils soient chargés par Getnet, par application d’une déduction directe aux règlements mensuels de l’établissement, comme l’indiquent le contrat d’affiliation ainsi que son annexe régissant l’accès à la plateforme B2B Enterprise et l’utilisation de celle-ci.',
        account : 'Compte',
        accountBalance : 'Solde du compte',
        accountNumber : 'Numéro de compte',
        accountPlural : 'Comptes',
        acquiring_bank : 'Banque administratrice',
        acquiring_query : 'Banque administratrice (rapports et rapprochement)',
        acquiringAdvance : 'Accélération du paiement par banque administratrice',
        acquiringBank : 'Banque administratrice',
        acquiringQuery : 'Banque administratrice (rapports et rapprochement)',
        action : 'Mesure à prendre',
        actionNotAllowed : 'Mesure non permise',
        activate : 'Activer',
        active : 'Actif',
        activeCompanies : 'Entreprises actives',
        addComment : 'Ajouter un commentaire...',
        addCondition : 'Ajouter une condition',
        addFinanceProductSuccess : 'Produit de financement créé avec succès',
        addFinancingProducts : 'Ajouter des produits de financement',
        additionalInformation : 'Renseignements supplémentaires',
        addNew : 'Ajouter nouveau',
        address : 'Adresse',
        ADE : 'Paiement accéléré',
        adjusted_amount : 'Montant redressé',
        admin : 'Administrateur',
        adminBank : 'Banque d’administration',
        administer : 'Gérer',
        administratorBank : 'Banque d’administration',
        administratorName : 'Nom de l’administrateur',
        administrators : 'Administrateurs',
        administratorsList : 'Liste des administrateurs',
        adminLastname : 'Nom de famille',
        adminName : 'Nom',
        adminPosition : 'Inscription',
        adminRoutes : 'Gérer les identifiants',
        adminUser : 'Administrateur',
        advance : 'Accélération',
        advanceCommercialConditions : 'Conditions commerciales de l’accélération du paiement',
        advanceMount : 'Montant du paiement accéléré',
        advanceSuccessful : 'Accélération de paiement effectuée avec succès',
        affiliateClients : 'Client affilié',
        affiliateCode : 'Code d’affilié',
        affiliateN : 'No d’affilié (no d’établissement)',
        affiliateNumber : 'Numéro d’affilié (no d’établissement)',
        affiliationCode : 'Code d’affiliation commerciale',
        alias : 'Alias',
        allowedFormats : 'Formats permis : JPG et PNG. La taille maximale est de 3 Mo.',
        allowedFormatsPDF : 'Formats permis : PDF ou XML. La taille maximale est de 3 Mo.',
        allowedFormatsXLS : 'Formats permis : XLSX ou XLS. La taille maximale est de 3 Mo.',
        amexCard : "Trésorier d'AMEX Santander Card",
        amount : 'Montant',
        amountApprovedInvoices : 'Montant des factures approuvées',
        amountInstallments : 'Montant du versement',
        amountInt : 'Le montant doit être un entier',
        amountMoney : 'Montant d’argent',
        amountOfTransactionsToProcess : 'Montant des opérations à traiter',
        amountPaidInvoices : 'Montant des factures payées',
        amountToCollect : 'Total des recettes quotidiennes',
        amountToGet : 'Factures à recevoir',
        amountToPay : 'Montant à payer',
        amountToRequest : 'Montant à demander',
        amountValidate : 'La valeur doit être supérieure ou égale 0,1',
        annualQuota : 'Versement pour l’année',
        answer : 'Réponse',
        anticipatedAmount : 'Montant prévu',
        anualRate : 'Taux annuel',
        applicant : 'Demandeur',
        applicantName : 'Nom du demandeur',
        applicationDate : 'Date de la demande',
        applicationDeleted : 'Demande supprimée',
        applicationNotification : 'Vous avez {{payment_request_pending}} demandes',
        applicationOneNotification : 'A {{payment_request_pending}} demande',
        applicationRejected : 'Demande rejetée avec succès',
        applicationSuccess : 'Approbation effectuée avec succès',
        approvalDate : 'Date de l’approbation des factures',
        approvalStatus : 'Statut d’approbation',
        approve : 'Approbation',
        approved : 'Approuvée',
        approvedChecks : 'Vérifications approuvées',
        approvedInvoices : 'Factures approuvées',
        approvedInvoicesTitle : 'Factures approuvées',
        approvedMount : 'Montant approuvé',
        approvedStatus : 'Approuvée',
        approvedTransaction : 'Opération approuvée',
        approveInvoice : 'Approuver la facture',
        april : 'Avril',
        arrangedDays : 'Jours convenus',
        arrangedDays : 'Jours de financement convenus',
        assign : 'Attribuer',
        assignedCardNumber : 'N° de la Carte',
        assignedRoutes : 'Identifiants attribués',
        assignedUsers : 'Utilisateurs attribués',
        assignPermissions : 'Permis attribués',
        assignRoleRoute : 'Le rôle et l’identifiant ont été attribués avec succès.',
        assignRoute : 'Attribuer des identifiants',
        assignRouteSuccess : 'L’identifiant a été attribué avec succès.',
        assistCard : 'Assist Card',
        asterisk : '*** {{number_card}}}',
        august : 'Août',
        authorization : 'Autorisation',
        authUser : 'Utilisateur autorisé',
        available : 'Disponible',
        availableBalance : 'Solde disponible',
        avance : 'Accélération du paiement',
        b2bInformation : 'Renseignements sur B2B Enterprise',
        b2bMDR : 'Taux de B2B Enterprise *(taux d’escompte de marchand)',
        backImage : 'Charger l’image du dos',
        balance : 'Solde',
        balanceToPay : 'Solde à annuler',
        bankInformation : 'Renseignements sur la banque choisie',
        bankingExecutives : 'Services administratifs',
        bankList : 'Liste de banques',
        bankLogo : 'Logo de la banque',
        bankName : 'Nom de la banque',
        bankProvider : 'banque Santander',
        banks : 'Banques',
        bankType : 'Type de banque',
        baseConditions : 'Conditions de base (paiement de factures)',
        benefits : 'Avantages',
        billingCycle : 'Cycle de facturation',
        billingCycles : 'Cycles de facturation',
        billingPerMonth : '{{months}} de facturation',
        billingVolume : 'Volume de facturation',
        billPayment : 'Paiement de factures',
        bills : 'Factures',
        bin : 'NIB',
        businessExecutive : 'Services administratifs',
        businessExecutives : 'Services administratifs',
        businessName : 'Nom de l’entreprise',
        buyer : 'Client',
        buyerCode : 'CODE CLIENT',
        buyerCreateLead : 'Exige l’intégration de l’acheteur (client)',
        buyerManualUp : 'Client (chargement manuel)',
        buyerName : 'Nom du client',
        buyersBatchUp : 'Clients (chargement massif)',
        buyersGroup : 'Groupe de clients',
        by_amount : 'Pour le montant',
        by_hierarchy : 'Par hiérarchie de frais',
        cancel : 'Annuler',
        cannotBeUploaded : 'Par conséquent, la facture ne peut pas être chargée dans B2B Enterprise',
        cantCreateTransaction : 'Le numéro de facture existe déjà',
        cantCustomers : 'clients sélectionnés à charger',
        cantDeleteRol : 'Le rôle ne peut pas être supprimé, car il est attribué à un ou plusieurs identifiants',
        cantDeleteRoute : 'L’identifiant ne peut pas être supprimé, car il est attribué à un ou plusieurs rôles',
        canton : 'Canton',
        cantProviders : 'entreprises sélectionnées à charger',
        cardBind : 'NIB de la Carte',
        cardChargeDate : 'Date d’opération par Carte',
        cardholderPaymentPercentage : 'Pourcentage de paiement du titulaire',
        cardList : 'Liste de Cartes',
        cardNumber : 'Numéro de la Carte',
        cardPayment : 'Carte sélectionnée pour paiement',
        cardPaymentDate : 'Date de paiement par Carte de crédit',
        cards : 'Cartes',
        categories : 'Catégories',
        ccChargeDate : 'Date d’opération',
        cedulaJuridicaDesc : '10 chiffres, sans zéro au début ni scripts',
        changeCode : 'Vous devez modifier le numéro d’établissement qui correspond au nouveau type de devise sélectionné',
        changeLog : 'Enregistrement de modification',
        changeOfCommercialConditions : 'INSCRIPTION DE LA MODIFICATION DES CONDITIONS COMMERCIALES',
        changePassword : 'Modifier le mot de passe',
        changePasswordSuccess : 'Mot de passe modifié avec succès',
        charge : 'Inscription',
        chargeDate : 'Date des frais',
        chargeForService : 'Frais de service',
        chargeRate : '% d’intérêt',
        checkDate : 'Date du chèque',
        checkDeposit : 'Chèque d’acompte',
        checkNumber : 'Numéro de chèque',
        checks : 'Chèques',
        checksToBeApproved : 'Chèques à approuver',
        CIF : 'Numéro de dossier client',
        cifPattern : 'Doit contenir des lettres et des chiffres',
        city : 'Ville',
        client : 'Client',
        clientB2BRate : 'Le taux B2B pour le client est de',
        clientName : 'Nom du client',
        clientPay : 'Paiements du client',
        clientPaymentCondition : 'Déterminer les conditions de paiement entre le fournisseur et le client',
        clientPercentageCharge : 'Pourcentage pour service par le client',
        clientRUT : 'RUT DU CLIENT',
        clients : 'Clients',
        clientsPay : 'Les clients paient',
        clientsQuantity : 'Nombre de clients',
        code : 'Code',
        codeArea : 'Code. Indicatif régional + No de téléphone',
        collaboratorNumber : 'Numéro du collaborateur',
        collaboratorNumberCharacter : 'Le numéro du collaborateur doit compter 8 caractères',
        collectionDate : 'Date de recouvrement',
        commerceCode : 'No d’affilié (no d’établissement)',
        commerceCodeDesc : 'Entrer la description du code commercial',
        commercialBillingUser : 'Utilisateur de la facturation commerciale',
        commercialConditions : 'Modalités commerciales',
        commune : 'Commune',
        companies : 'Entreprises',
        company : 'Entreprise',
        completeComercialConditionOrEmptyAll : 'Entrez les données des conditions commerciales ou laissez cet espace vide si vous ne voulez pas ajouter de conditions commerciales pour les fournisseurs',
        concept : 'Concept',
        condition : 'Condition',
        condition_amount : 'Montant',
        conditions : 'Conditions',
        configuration : 'Paramètre',
        confirm : 'Confirmer',
        confirmData : 'Confirmer les données',
        confirmPassTooltip : 'Le mot de passe et celui entré pour le confirmer doivent être identiques',
        confirmPassword : 'Confirmer le mot de passe',
        confirmTaxCode : 'Confirmer l’indicatif de taxe (tax key)',
        confirmTransfer : 'Confirmer votre opération',
        confirmUnsubscribe : 'Voulez-vous vraiment abandonner la condition commerciale avec {{client}} pour le produit {{product}}?',
        confirmUnsubscribeFrom : 'Confirmer le retrait de : {{Product}}',
        consult : 'Consulter',
        contactPhone : 'Téléphone',
        continue : 'Continuer',
        continueLoadingCustomers : 'Continuer et charger les clients?',
        continueLoadingProviders : 'Continuer et charger les entreprises?',
        contract : 'Embaucher',
        contractedProducts : 'Assurance embauchée',
        contractRequest : 'Demande de contrat',
        cort : 'Tribunal',
        cortDate : 'Date limite',
        cost : 'Coût',
        country : 'Pays',
        createBank : 'Créer une nouvelle banque',
        createBankSuccess : 'Banque {{name}} créée avec succès',
        createBuyer : 'Créer le client',
        createClientSuccess : 'Client {{name}} créé avec succès',
        createCondition : 'Créer une condition commerciale pour : {{name}}',
        createExecutive : 'Créer des services administratifs',
        createFlowApproval : 'Créer un flux d’approbation de factures',
        createFlowApprovalSuccess : 'Flux d’approbation de factures créé avec succès',
        createInvoicesSuccess : 'Factures enregistrées avec succès',
        createInvoiceSuccess : 'No de facture {{Invoice_number}} créé avec succès',
        createLead : 'Exige un nouvel utilisateur, un nouvel acheteur ou un nouveau fournisseur',
        createNewExecutive : 'Créer un nouvel utilisateur ou un nouveau rôle {{inputvalueexecutive}}',
        createNewItem : 'Créer nouveau secteur d’activité {{inputvalue}}',
        createNewManaged : 'Créer un nouvel administrateur',
        createPaymentCondition : 'Créer une condition de paiement',
        createPaymentConditionSuccess : 'Mode de paiement créé avec succès',
        createProfile : 'Créer le profil',
        createProfileSuccess : 'Profil {{name}} créé avec succès',
        createProvider : 'Créer un fournisseur',
        createProviderSuccess : 'Fournisseur {{name}} créé avec succès',
        createQuestionSuccess : 'Question créée avec succès',
        createRol : 'Créer le rôle',
        createRoleSuccess : 'Rôle {{rolname}} créé avec succès',
        createRolSuccess : 'Rôle {{rolname}} créé avec succès',
        createUser : 'Créer un utilisateur',
        createUserSuccess : 'Utilisateur {{name}} créé avec succès',
        creditAvailableBalance : 'Solde disponible de la LCI',
        creditCard : 'Carte de crédit',
        creditCardSubstitution : 'Remplacement de Carte de crédit',
        creditConditionsMsg : '* Lorsque vous choisissez d’utiliser une marge de crédit, vous devez définir les conditions de crédit.',
        creditPeriod : 'Période de crédit',
        creditRequest : 'Demander une marge de crédit',
        creditRequestForAdvance : 'Demander une LCI pour accélérer le paiement de la facture',
        creditRequestForInvoice : 'Demander une LCI pour le paiement de la facture',
        creditsList : 'Inscription de la marge de crédit',
        currency : 'Devise',
        currentCycle : 'Cycle actuel',
        customer : 'Client',
        customerInformation : 'Renseignements sur le client sélectionné',
        customerName : 'Nom du client',
        customerPayment : 'Paiement du client',
        customerPaymentPercentage : '% de paiement du client',
        customers : 'Clients',
        customersSaved : 'Clients chargés avec succès',
        cuttingCycle : 'Cycle de la Carte',
        cuttingCycleAmex : 'CYCLE DE LA Carte Treasurer',
        cuttingCycleCard : 'Le cycle de la Carte est de : {{message}}',
        cycle_loader : 'Cycles de facturation',
        cycleGroup : 'Groupe de cycles',
        cyclesList : 'Liste des cycles de facturation',
        daily : 'Journal',
        dashboard : 'Résumé',
        dataSavedSuccessfully : 'Données enregistrées avec succès',
        date : 'Date',
        dateFf : 'Date de fin de cycle',
        dateInvoice : 'Date de facturation',
        dateNotAvailable : 'Date non disponible',
        dateOfAdmission : "Date d'admission SII",
        dateOfEntry : 'Date de saisie dans B2B',
        datePayment : 'Date du paiement',
        dateRange : 'Période',
        dateV : 'Date d’échéance',
        day : 'Jour',
        daysAdvance : 'Accélération de paiement en jours',
        daysDefer : 'Report en jours',
        daysNumber : 'Nombre de jours',
        daysSince : 'jours à partir de',
        deactivate : 'Désactiver',
        december : 'Décembre',
        defaultFlow : 'Flux par défaut',
        defer : 'Reporté',
        deferCommercialConditions : 'Conditions commerciales du report',
        deferMount : 'Montant reporté',
        deferred : 'Reporté',
        deferredAmount : 'Montant reporté',
        delete : 'Éliminer',
        deleteExecutive : 'Services administratifs supprimés',
        deleteInvoice : 'La facture a été supprimée',
        deletePaymentCondConf : 'Voulez-vous vraiment éliminer cette relation commerciale?',
        deletePaymentCondition : 'La condition de paiement a été éliminée',
        deleteRol : 'Le rôle a été résilié',
        deleteRoute : 'L’identifiant a été éliminé',
        deleteTta : 'AMEX éliminée avec succès',
        demographicInformation : 'Données d’identification',
        deny : 'Refuser',
        deposit : 'Pour déposer',
        depositReason : 'Raison du dépôt',
        description : 'Description',
        destinationAccount : 'Compte de destination',
        detail : 'Renseignements',
        DIF : 'Reporté',
        differ : 'À reporter',
        digitalCertificate : 'Certificat numérique',
        disable : 'Désactiver',
        disable2FA : 'Désactiver la vérification en deux étapes',
        disabled : 'Désactivé',
        discountAmount : 'Montant de la réduction',
        discountRate : 'Taux d’escompte',
        discountRateAccept : 'Conditions de service à Banco Santander',
        discountRateCharges : 'Taux d’escompte',
        discountRateInvoiceSavedSuccessfully : 'Facture avec taux d’escompte enregistrée avec succès',
        discountRateMDR : 'Taux d’escompte de marchand (taux d’escompte de marchand)',
        discountRateOutOfLimits : 'Taux d’escompte hors limites',
        discountRateTerms : "En acceptant l'accusation à mon trésorier de crédit de crédit Santander American Express, le client s'engage à payer la facture plus les frais de service à Banco Santander dans toutes les factures dans lesquelles le client paie le fournisseur indiqué.",
        district : 'District',
        document : 'Document',
        documentDate : 'Date du document',
        documentNumber : 'No du document',
        documentType : 'Type de document',
        doNotIncludeTheUsername : '* Ne pas inclure le nom de l’utilisateur dans le mot de passe.',
        dontPose : 'N’a pas',
        download : 'Demande d’intégration d’acheteur',
        eCommerceTerminal : 'Terminal de commerce électronique',
        edit : 'Modifier',
        edit_user : 'Modifier l’utilisateur',
        editBank : 'Modifier manuellement : {{name}}',
        editClientSuccess : 'Client {{name}} modifié avec succès',
        editCycle : 'Cycle de modification',
        editFlowApprovalSuccess : 'Flux d’approbation modifié avec succès',
        editionRequestSent : 'Demande de modification envoyée',
        editPaymentConditionSuccess : 'Condition de paiement réussie.',
        editProfileSuccess : 'Profil {{name}} modifié avec succès',
        editProviderSuccess : 'Fournisseur {{name}} modifié avec succès',
        editUserSuccess : 'Utilisateur {{name}} modifié avec succès',
        email : 'Courriel',
        email2FA : 'Un code de vérification a été envoyé à votre adresse électronique. Veuillez entrer celui-ci pour continuer.',
        emailLabel : 'name@empresa.cl',
        emissionFlow : 'Flux d’émission',
        emitInvoices : '{{emit_invoices}} facture(s) non émise(s)',
        emptyAll : 'Assurez-vous d’entrer toute l’information',
        emptyAllRequest : 'Assurez-vous d’entrer toute l’information dans tous les champs obligatoires',
        emptyCreditList : 'Vous n’avez aucune marge de crédit active. Voulez-vous en demander une?',
        emptyFields : 'Vous devez remplir tous les champs.',
        fr: "Français",
        en: "Anglais",
        pt: "Portugais",
        es: "Espagnol",
        zh: "Chinois",
        enable : 'Activer',
        enable2FA : 'Activer la vérification en deux étapes',
        enabled : 'Activée',
        endingDate : 'Date de fin',
        eng : 'Eng',
        enter : 'Obtenir les renseignements',
        enterCodeSms : 'Vérifiez et entrez le code que nous vous avons envoyé par message texte',
        enterCuttingCycle : 'Entrer le cycle de la Carte',
        enterDataInternalTaxService : 'Connectez votre entreprise à SII - Service fiscal interne',
        enterTaxCode : 'Entrer l’indicatif de taxe (tax key)',
        enterTheCardBillingCycle : 'Pour continuer d’utiliser cette Carte, vous devez entrer son cycle de facturation',
        enterYourNewPassword : 'Entrez votre nouveau mot de passe',
        errorCustomers : 'Clients avec erreurs',
        errorData : 'Erreur de chargement des données.',
        errorDate : "La date 'de début' doit être antérieure à",
        errorDateCycles : 'La date de fin de cycle doit être antérieure à',
        errorLoad : 'Vous devez charger un fichier d’extension {{Type}}',
        errorProviders : 'Fournisseurs avec erreurs',
        errors : 'Erreurs',
        es : 'Espagnol',
        esp : 'Esp',
        event : 'Événement',
        executiveData : 'Données de services administratifs',
        expirationDate : 'Date d’expiration',
        expiredInvoices : 'Approuvée/payée',
        extract : 'Extraire',
        extractAccount : 'Extraction réseau B2B',
        extractDetail1 : 'Code d’autorisation généré avec succès',
        extractDetail2 : 'Montant à retirer',
        extractDetail3 : 'Dans le cadre de',
        extractDetail4 : 'l’extraction effectuée à un point de contact',
        extractDetail5 : 'Avec votre numéro de téléphone',
        extractDetail6 : 'Fournissez votre numéro de téléphone au point de contact pour compléter l’extraction amorcée',
        extractDetail7 : 'Code QR',
        extractDetail8 : 'Présentez-vous avec le code suivant au point de contact',
        factoring : 'Affacturage',
        factoringDiscountAmount : 'Montant d’escompte pour affacturage',
        factoringRate : 'Tarif d’affacturage',
        failedCustomers : 'Clients avec erreurs',
        failedCustomersAlert : '(Les clients comportant des erreurs ne seront pas pris en compte lors de l’inscription)',
        failedProviders : 'Entreprise avec erreurs',
        failedProvidersAlert : '(Les entreprises comportant des erreurs ne seront pas prises en compte lors de l’inscription)',
        fantasyName : 'Nom non officiel',
        february : 'Février',
        FF : 'Fin de cycle',
        fileSentSuccessfully : 'Le fichier a été envoyé avec succès',
        fileSize : 'La taille maximale autorisée est de 3 Mo',
        fileSizeExceeded : 'Votre fichier dépasse la taille maximale de 3 Mo',
        financingProducts : '\ produits de financement',
        financingRate : 'Taux de financement',
        finish : 'Terminer',
        fiscaldocument : 'Document fiscal',
        flexibleConditions : 'Conditions souples (financement)',
        flowApproval : 'Flux d’approbation',
        flowApprovalCheck : 'Flux d’approbation par défaut',
        flowsApproval : 'Flux d’approbation',
        FMA : 'Vérification en deux étapes',
        forgotPassword : 'Vous avez oublié votre mot de passe?',
        friday : 'Vendredi',
        fromReceptionDate : 'Date de réception',
        frontImage : 'Charger l’image du recto',
        fullName : 'Nom et nom de famille',
        FV : 'Fv',
        gateway : 'Passerelle',
        general_admin : 'Administrateur général des utilisateurs pour la banque',
        general_admin_acquiring : 'Administrateur général des utilisateurs de la banque administratrice',
        general_admin_issuing : 'Administrateur général des utilisateurs de l’émettrice',
        general_admin_issuing_amex : "Administrateur général de l'utilisateur de la banque émetteur AMEX",
        getnet : 'Getnet',
        goBack : 'Retour',
        grant : 'Accorder',
        greaterThan : 'Plus élevée',
        greaterThanOrEqualTo : 'Identique à précédente',
        group : 'Groupe',
        handbook : 'Manuel',
        identificationNumber : 'No d’identification',
        import : 'Montant',
        inactive : 'Inactif',
        inactiveBusinesses : 'Commerces inactifs',
        inactiveClients : 'Clients inactifs',
        inCharge : 'Responsable',
        incompleteData : 'Données incomplètes',
        incorrectDate : 'Vous ne pouvez entrer une date antérieure à {{datenow}}',
        incorrectDays : 'Vous devez entrer une valeur supérieure ou égale à 0',
        incorrectEmail : '{{email}} ne semble pas être une adresse électronique valide. Vérifiez si le format d’adresse est le bon : Example@mail.com',
        info : 'Info',
        infoLog : 'Renseignements sur l’événement',
        informationOfTheSelectedBank : 'Renseignements sur la banque choisie',
        insertYourEmailAddress : 'Entrez votre adresse électronique.',
        installmentNumber : 'Nombre de versements',
        installments : 'Numéro du versement',
        installmentsQty : 'Nombre de versements',
        insurances : 'Assurances',
        interest_rate : 'Montant des intérêts',
        interestRate : 'Taux d’intérêt',
        internalRevenueService : 'Internal Revenue Service',
        intNumber : 'Il faut entrer un entier',
        invalidAmount : 'MONTANT INVALIDE',
        invalidCard : 'à vérifier',
        invalidRoleOrRoute : 'Les champs de rôle et d’identifiant ne peuvent pas être vides',
        invoice : 'Facture',
        invoiceAmount : 'Montant de la facture',
        invoiceApprovedSuccess : 'Facture approuvée avec succès',
        invoiceCount : 'Nombre de factures',
        invoiceDate : 'Date de facturation',
        invoiceDetail : 'Détails de la facture',
        invoiceGroup : 'Groupe de factures',
        invoiceIssueDate : 'Date d’émission de la facture',
        invoiceManualResolution : 'Factures à approuver manuellement',
        invoiceMarkPaid : 'Facture no {{invoicenumber}} marquée comme étant payée',
        invoiceN : 'No de facture {{invoicenumber}}}',
        invoiceNumber : 'No de facture',
        invoiceNumberValidate : 'Le format valide pour la saisie d’un numéro de facture est XXX-XXX-XXXXXXXXXXX',
        invoicePending : 'À émettre',
        invoiceProvidersReceiver : 'Reçu de factures de fournisseurs',
        invoiceReject : 'Rejeter facture',
        invoiceRejectedSuccess : 'Facture rejetée avec succès',
        invoices : 'Factures',
        invoicesApproved : 'Factures approuvées',
        invoicesDeferredApproved : 'Factures approuvées et reportées',
        invoicesNumber : 'Numéro de facture',
        invoicesPendingApproved : 'Factures en attente et reportées',
        invoicesSent : 'Factures envoyées',
        invoiceStatusInTheSii : 'Statut de la facture dans le SII',
        invoicesToBeApproved : 'Factures à approuver',
        invoiceTerms : "Lors de l'approbation des factures au sein de la plate-forme B2B Enterprise, j'accepte le poste qui se tiendra sur ma carte de trésorier Santander American Express, pour le montant individualisé dans chaque facture, et du montant du service, le cas échéant, le cas échéant selon le Les conditions commerciales étaient d'accord avec votre fournisseur. Instruire irrévocablement Banco Santander, afin que de telles charges soient portées dans la carte susmentionnée.",
        invoiceTotalAmount : 'Montants indiqués dans les factures',
        invoiceUploaded : 'No de facture {{invoicenumber}} chargé dans B2B Enterprise avec succès',
        issuedInvoices : 'Factures émises',
        issuing_bank : 'Banque émettrice',
        issuing_query : 'Utilisateur émetteur (rapports et rapprochement)',
        items : 'Secteurs d’activité',
        january : 'Janvier',
        july : 'Juillet',
        june : 'Juin',
        kindOfProduct : 'type de produit',
        language : 'Langue',
        last4Digits : '<4 derniers chiffres>',
        lastDays : 'Derniers jours',
        lastMovements : 'Derniers mouvements',
        lead : 'nouvel acheteur ou un nouveau fournisseur',
        leadList : 'Liste de champs obligatoires',
        legal : 'Affaires juridiques',
        lengthToCharacters : '* Entre 8 et 12 caractères, dont au moins trois des suivants : majuscules (A à Z), minuscules (a à z), chiffres (0 à 9) ou caractères spéciaux.',
        lessThan : 'Inférieur à',
        lessThanOrEqualTo : 'Identique à précédent',
        limit : 'Limite',
        limitAmount : 'Montant de la limite',
        limitAmountValidate : 'Vous devez entrer une valeur supérieure ou égale à 0',
        line : 'Ligne',
        listCards : 'Liste de Cartes',
        listCuttingCycle : 'Cycles des Cartes',
        listFaqs : 'Afficher la FAQ',
        listInvoices : 'En examen',
        listManuals : 'Afficher les manuels',
        listPayments : 'Liste de paiements',
        load : 'Reporter',
        loadBillingCycle : 'Charger les cycles de facturation',
        loadBuyersGroup : 'Charger le groupe de clients',
        loadCsv : 'Charger CSV',
        loadCustomers : 'Charger les clients',
        loadCustomersGroup : 'Charger le groupe de clients',
        loadFile : 'Téléverser le fichier',
        loadFromFile : 'Charger à partir du fichier',
        loading : 'Chargement en cours...',
        loadingContent : 'Chargement du contenu en cours...',
        loadInvoice : 'Charger la facture',
        loadInvoiceData : 'Charger les données de facture',
        loadProviders : 'Charger les fournisseurs',
        loadProvidersGroup : 'Charger les fournisseurs',
        loan : 'Prêt',
        loanPeriod : 'Durée du prêt',
        loanRequest : 'Demander prêt pour accélérer le paiement de la facture',
        logout : 'Déconnexion',
        logs : 'Journaux',
        logSource : 'Source de journal',
        logTransaction : 'Journaliser les opérations',
        logView : 'Afficher le journal',
        main_bank : 'Utilisateur d’administrateur de banque',
        main_bank_acquiring : 'Administrateur de la banque administratrice',
        main_bank_issuing : 'Administrateur d’utilisateurs de l’émetteur',
        main_bank_issuing_amex : "Administrateur utilisateur de l'émetteur AMEX",
        mainInvoiceNumber : 'Numéro de la facture principale',
        mainUser : 'Utilisateur principal',
        MAN : 'Approbation manuelle',
        mandatoryFieldsRequired : '(*) Vous devez remplir tous les champs marqués d’un astérisque',
        manualLoaded : 'Manuel chargé avec succès',
        manualResolution : 'Approbation manuelle',
        manualsAndFaq : 'Manuels et foire aux questions',
        march : 'Mars',
        markAsPaid : 'Marquer comme étant payée(s)',
        maxRank : '(jusqu’à)',
        may : 'Mai',
        mccCode : 'Code MCC',
        mdr : 'Taux d’escompte de marchand',
        merchantAffiliateCode : 'Numéro d’affilié',
        message : 'Message',
        messageUserProcess : 'Aucun type de flux d’approbation n’est configuré actuellement. Pour activer cette fonction, veuillez communiquer avec votre représentant.',
        minimumCharacter : 'Le code d’affiliation d’établissement doit contenir entre 8 et 15 caractères',
        mixedBalance : 'Solde mixte',
        mixedPayment : 'Paiement mixte',
        monday : 'Lundi',
        month : 'Mois',
        monthly : 'Chaque mois',
        monthlyReport : 'Rapport mensuel',
        name : 'Nom',
        nameFantasy : 'Nom non officiel',
        naoReter : 'Ne pas afficher ni utiliser de matériel publicitaire (promotion, publicité, commissions et courtage)',
        NEG : 'Refusé',
        new : 'Nouveau',
        newBank : 'Nouvelle banque',
        newBankingExecutive : 'Nouveaux services administratifs',
        newPassword : 'Nouveau mot de passe',
        newPaymentCondition : 'Nouvelles conditions de paiement',
        newProfile : 'Nouvel utilisateur',
        newRecharge : 'Nouvelle recharge',
        newRole : 'Nouveaux rôles',
        newTransfer : 'Nouveau transfert',
        next : 'Suivant',
        nextCollection : 'Prochain recouvrement',
        nextDays : 'Jours suivants',
        nextPage : 'Suivant',
        nextPayment : 'Paiement suivant',
        nInvoices : 'Nombre de factures',
        no : 'Non',
        noCard : 'N’a pas de Carte prépayée',
        noCycles : 'Aucun cycle de Carte chargé jusqu’à : {{month}}',
        noData : 'Aucune information',
        noExecutivesAvailable : 'Il n’y a pas de services administratifs',
        noInformation : 'Aucune information',
        noItemsAvailable : 'Aucun secteur d’activité disponible',
        nonMatchingPasswords : 'Les mots de passe ajoutés ne concordent pas',
        noRowsFound : 'Aucune information',
        notifyCardPaymentAuthorization : 'Signaler l’autorisation du paiement par Carte',
        notifyPaymentAuthorization : 'Signaler l’autorisation du paiement',
        noTransactions : 'Cette Carte n’affiche aucun mouvement',
        notVerified : 'Non vérifié',
        notYetFound : "La facture n'est pas encore disponible dans le SII, si vous souhaitez que vous puissiez le charger et B2B Enterprise sera chargé de rembourser la recherche et de le charger sur notre plate-forme, sinon annuler l'opération et réessayer de le charger à nouveau",
        november : 'Novembre',
        number : 'Numéro',
        numberInstallments : 'Nombre de versements',
        numberTta : 'Numéro de Carte',
        october : 'Octobre',
        of : 'de',
        onlyCardPaymentDate : 'Date de paiement de la Carte',
        operationLog : 'Journal des événements',
        operationRank : 'PLAGE D’OPÉRATIONS (DÉBUT)',
        operationVolume : 'Vol. d’opérations',
        operator : 'Condition',
        originAccount : 'Compte racine',
        otherSign : 'Autres signes',
        PAG : 'Payé',
        page : 'pages',
        paid : 'Payé',
        paidAmount : 'Montant payé',
        paidInvoices : 'Factures payées',
        paidOut : 'Payé',
        PAP : 'À approuver',
        params : 'Paramètres',
        passport : 'Passeport',
        password : 'Mot de passe',
        passwordDigitalCertificate : 'Mot de passe du certificat numérique',
        passwordHasExpired : 'Votre mot de passe est expiré, veuillez en entrer un nouveau',
        payedAmounts : 'Total facturé quotidiennement',
        payInvoiceWithBalance : 'Voulez-vous annuler le numéro de facture? {toggyprops.transaction.invoice_number} $ avec le solde de votre compte chèques?',
        payment : 'Payer',
        paymentAmount : 'Montant à payer',
        paymentClient : 'Pourcentage facturé au client',
        paymentCondition : 'Condition',
        paymentConditionClients : 'Conditions commerciales pour clients de',
        paymentConditionProvider : 'Conditions commerciales pour fournisseurs de',
        paymentCurrency : 'Monnaie de paiement',
        paymentDate : 'Date du paiement',
        paymentDateInvoice : 'Date de recouvrement des factures : ',
        paymentDateRange : 'Date d’émission des factures et date de leur recouvrement',
        paymentDateRangeClient : 'Plage de dates de paiement des factures',
        paymentDeadline : 'Date limite de paiement',
        paymentMethod : 'Mode de paiement',
        paymentOrders : 'Ordres de paiement',
        paymentsTransfers : 'Paiements et transferts',
        paymentWay : 'Comment désirez-vous annuler la facture numéro',
        paymentWayMsg : 'Si vous acceptez l’option de paiements mixtes, vous pourrez combiner les options de paiement.',
        PEJ : 'À exécuter',
        PEM : 'À émettre',
        penddingInvoice : 'A {{pending_invoices}} facture(s) en attente non approuvée(s)',
        penddingInvoices : 'Vous avez {{pending_invoices}} facture(s) en attente non approuvée(s)',
        pending : 'En attente',
        pendingInvoices : 'Facture En attente',
        pendingToApprove : 'En attente d’approbation',
        percentage : 'Pourcentage d’intérêt annuel',
        period : 'Période',
        permanentlyRejectCommercialStatus : 'Rejeter la condition commerciale permanente',
        permissions : 'Permis',
        person : 'Personne',
        personType : 'Type de personne',
        phone : 'Téléphone',
        physical : 'Physique',
        platformAdministrator : 'Administrateur de la plateforme',
        platformFaqList : 'Afficher la FAQ de la plateforme',
        platformManuals : 'Manuels de la plateforme',
        por : 'PAR',
        PRC : 'Préchargé',
        prepaidCard : 'Carte prépayée',
        present : 'Actuel',
        previous : 'Ancien',
        previous_page : 'Ancien',
        PRF : 'Pour remboursement',
        print : 'Imprimer',
        printQR : 'Imprimer le code QR',
        product : 'Produit',
        productContracted : 'Demande de contrat de produit : {{name}} envoyé avec succès',
        productName : 'Nom du produit',
        products : 'Assurance/Assistance',
        productsContracted : 'Produits visés par un contrat',
        productType : 'type de produit',
        productUnsubscribedSuccessfully : 'Produit donné avec succès',
        profile : 'Profil',
        profileList : 'Liste d’utilisateurs',
        profileName : 'Nom du profil',
        provider : 'Fournisseur',
        providerB2BRate : 'Tarif B2B payé par le fournisseur',
        providerGroup : 'Groupe de fournisseurs',
        providerName : 'Nom du fournisseur',
        providerPaymentCondition : 'Déterminer les conditions de paiement entre le client et le fournisseur',
        providerPercentageCharge : 'Pourcentage pour service fourni par le fournisseur',
        providerRUT : 'RUT DU FOURNISSEUR',
        providers : 'Fournisseurs',
        providersReceiver : 'Reçu de factures de fournisseurs',
        providersSaved : 'Fournisseurs chargés avec succès',
        province : 'Province',
        PRV : 'Aux fins d’annulation',
        PSL : 'Aux fins de vente',
        pt : 'Portugais',
        PVD : 'Retirer',
        qualify : 'Activer',
        quantity : 'Montant',
        question : 'Demander',
        QuoteAndRequest : 'Devis et demande',
        rate : 'Pourcentage',
        rate_operator : 'Banque fournissant les taux',
        REC : 'Refusé',
        receivedInvoices : 'Factures reçues',
        receiverDiscountShare : 'Pourcentage de paiement du fournisseur',
        receptionDate : 'Date d’émission de la facture',
        recharge : 'Recharger',
        recipient : 'Destinataire',
        recoverPassword : 'Récupérer le mot de passe',
        referenceNumber : 'Votre numéro de référence est',
        referrer : 'Avec référence',
        region : 'Région',
        registerAdministrator : 'Inscrire l’administrateur',
        registerBank : 'Inscrire la banque',
        registerCard : 'Inscrire la Carte',
        registerCardAmex : 'Entrer la Carte American Express',
        registeredCards : 'Cartes inscrites du client : {{name}}',
        registerProduct : 'Inscrire le produit',
        registerRole : 'Inscrire le rôle',
        registerRoute : 'Inscrire l’identifiant',
        registrationStatus : 'État de l’inscription',
        reject : 'Refuser',
        rejectedChecks : 'Chèques sans provision',
        rejectedCycle : 'Cycle de facturation rejeté avec succès.',
        rejectedInvoices : 'Factures rejetées',
        rejectInvoice : 'Rejetée(s)',
        rejectInvoiceAll : 'Factures rejetées',
        rejectInvoices : 'Vous avez {{Reject_invoices}} facture(s) rejetée(s)',
        rejectReason : 'Motif du rejet',
        rejectTradeRequest : 'Rejeter la demande commerciale',
        relatedUsers : 'Utilisateurs liés',
        rememberToAcceptTheTerms : "N'oubliez pas d'accepter les termes et conditions pour embaucher le produit",
        removeRouteSuccess : 'L’identifiant a été supprimé avec succès',
        replaceTheSelectedCard : 'Vous voulez vraiment remplacer la Carte sélectionnée.',
        reports : 'Rapports',
        request : 'Demandes',
        requestEdition : 'Demander une modification',
        requestingBank : 'Banque du demandeur',
        requestProducts : 'Demander votre assurance ou de l’aide',
        requestReceived : 'Reçue',
        requestSent : 'Présentée',
        requestSentProduct : 'Demande envoyée',
        requestsReceived : 'Demandes reçues',
        requestsSent : 'Demande envoyée',
        requestUnsubscribe : 'Demander un retrait',
        requiredFields : '(*) Champs obligatoires',
        requiredInstallments : 'Le nombre de versements est invalide',
        requiredName : 'Le nom est invalide',
        requiredPeriod : 'La période est invalide',
        requiredRate : 'Le taux est invalide',
        requiredTypeFinance : 'Le type de financement est invalide',
        requireSuccess : '{{name}} exigé avec succès',
        resource : 'Ressource',
        respJson : 'Réponse Json',
        response : 'Réponse',
        respXml : 'Réponse XML',
        return : 'Retour',
        REV : 'Annulation en cours',
        RFD : 'Remboursement effectué',
        rol : 'Rôle',
        roleCantBeEmpty : 'Le champ de rôle ne peut pas être vide',
        roleList : 'Liste des rôles',
        roles : 'Rôles',
        roleType : 'Type de rôle',
        rolName : 'Nom du rôle',
        rolType : 'Type de rôle',
        routeList : 'Liste d’identifiants',
        routes : 'Identifiants',
        rows : 'Rangs',
        RUT : 'RUT',
        safetyRules : "Nous devons connecter votre entreprise au SII afin d'obtenir les factures émises par vos fournisseurs à votre Razon social, pour cela, nous remercions de saisir les données suivantes: ",
        saturday : 'Samedi',
        save : 'Conserver',
        savedCards : 'Enregistrer les cartes de crédit',
        saveFaq : 'Manuels et FAQ',
        savePlatformFaq : 'Charger la FAQ de la plateforme',
        saveQuestion : 'Charger la FAQ',
        search : 'Rechercher',
        searchBuyers : 'Trouver des clients',
        searchSuppliers : 'Trouver des fournisseurs',
        searchUsers : 'Trouver des utilisateurs',
        secureCards : 'Les renseignements de vos Cartes de crédit seront conservés selon des normes de sécurité rigoureuses.',
        seeDetail : 'Voir les détails',
        seeHow : 'Pour voir comment',
        seeQuestion : 'Voir la question',
        seeUsers : 'Voir les utilisateurs',
        Select : 'Sélectionner ...',
        selectANewCommercialExecutive : 'Vous devez sélectionner de nouveaux services administratifs',
        SelectBank : 'Sélectionner une banque',
        selectBank : 'Vous devez sélectionner une banque',
        selectCard : 'Carte sélectionnée',
        selectCycle : 'Vous devez sélectionner tous les cycles',
        SelectDocumentType : 'Pour continuer, choisissez le type de document',
        selectedBank : 'Banque',
        selectFile : 'Sélectionner un fichier',
        selectInvoice : 'Sélectionner la facture',
        selectMonth : 'Vous devez sélectionner un mois.',
        SelectOption : 'Sélectionner une option',
        selectOrTypeItems : 'Sélectionner ou entrer les secteurs d’activité ...',
        selectOrTypePermissions : 'Sélectionner ou entrer l’autorisation',
        selectOrTypeRol : 'Sélectionner ou entrer les rôles',
        selectOrTypeRoutes : 'Sélectionner ou entrer l’identifiant',
        selectRecipient : 'Sélectionner le destinataire',
        seller : 'Fournisseur',
        sellerCode : 'Code de fournisseur',
        sellerCreateLead : 'Exige l’intégration de l’acheteur (fournisseur)',
        sellerName : 'Nom du fournisseur',
        sellersQuantity : 'Nombre de fournisseurs',
        send : 'Envoyer',
        send2FASuccess : 'Code envoyé correctement',
        sendDate : 'Date d’envoi',
        senderDiscountShare : 'Pourcentage de paiement du client',
        sendErp : 'Envoyer PRE',
        sendInformation : 'Envoyer l’information',
        sendInvoice : 'Date d’expédition selon facture',
        sentInvoices : 'Factures',
        september : 'Septembre',
        settings : 'Paramètres',
        shippingDate : 'Date d’expédition',
        shouldNotSelect : 'Vous devriez sélectionner un utilisateur/rôle commercial autre que celui que vous désirez supprimer',
        showPassword : 'Afficher le mot de passe',
        showQR : 'Afficher le code QR',
        since : 'De',
        startCountingFrom : 'Nombre de jours convenus à partir de',
        startDate : 'Date de début',
        status : 'État',
        statusOfInvoice : 'Le statut de la facture n ° {{invoiceNumber}} dans le SII est: {{status}}.',
        street : 'Rue',
        successCard : 'Votre Carte est déjà inscrite.',
        successCycle : 'Cycle de facturation confirmé avec succès.',
        successCycles : 'Cycles de facturation créés avec succès',
        successEmail : 'Un courriel a été envoyé à votre adresse électronique.',
        successExecutive : 'Services administratifs créés avec succès',
        successfulFactoring : 'Affacturage effectué avec succès',
        successfullyAssignedCuttingCycle : 'Cycle de la Carte attribué avec succès',
        successfullyUpdatedManual : 'Manuel mis à jour avec succès',
        suggestTradeConditionCorrection : 'Suggérer une correction de condition commerciale',
        summary : 'Résumé',
        sunday : 'Dimanche',
        support : 'Assiduité',
        supportLink : 'Lien vers le soutien',
        target : 'Carte',
        termsAndConditions : 'Modalités',
        theCodeExpiresIn : 'Le code expire à : {{time}}',
        theInvoiceWillBeCharged : 'La facture sera chargée dans B2B Enterprise à l’état {Status}}}',
        thereIsNoInformationOnFlexibleConditions : 'Il n’y a aucun renseignement sur les conditions souples',
        theyMustNotHave : '* Ils ne doivent pas contenir des caractères identiques consécutifs ni être entièrement numériques ou alphabétiques.',
        thursday : 'Jeudi',
        time : 'Heure',
        toBeApproved : 'À approuver',
        toDisable : 'Désactiver',
        total : 'Total',
        totalAmount : 'Total',
        totalAmountToPayOnTheInvoice : 'Montant total à payer sur la facture',
        totalAmountToReceive : 'Montant total à recevoir',
        totalBuyers : 'Nombre d’entreprises facturées',
        totalBuyersDeferred : 'Entreprises avec factures reportées',
        totalBuyersInvoiced : 'Entreprises avec factures approuvées',
        totalBuyersTh : 'Nombre d’acheteurs utilisant la plateforme',
        totalCharged : 'Total facturé {{months}}',
        totalCommissionAmount : 'Total des commissions',
        totalDebtAmount : 'Montant total à payer',
        totalInvoices : 'Total des factures',
        totalOwedAmount : 'Montant total à recouvrer',
        totalPay : 'Total à payer',
        totalReceiver : 'Total à recouvrer',
        totalSellers : 'Fournisseurs affiliés',
        totalSellersCharged : 'Fournisseurs avec facturation approuvée',
        totalSellersInvoiced : 'FOURNITURES EN ATTENTE DE FACTURATION',
        totalToApprove : 'Total à approuver',
        totalToPayCardholder : 'Total à payer pour frais de service liés à l’adhésion',
        totalToPayInvoice : 'Total à payer pour facture',
        totalToReceive : 'Total à recevoir',
        totalVolumePendingPayment : 'Volume total avec paiement en attente',
        tradeInformation : 'Information commerciale',
        transactionReport : 'Rapport d’opérations',
        transactions : 'Opérations',
        transactionsToProcess : 'Opérations à traiter',
        transactionType : 'Type d’opération',
        transfer : 'Transférer',
        transferCharge : 'Frais de transaction',
        transferDetail : 'Transfert effectué avec succès',
        transferDetail2 : 'Reçu',
        transferDetail3 : 'Envoyer à',
        transferDetail4 : 'Somme envoyée',
        transferDetail5 : 'Montant total de l’escompte',
        transferring : 'Transfert en cours',
        transferState : 'État',
        transferType : 'Type d’opération',
        TtaValidation : 'Validation de la Carte',
        tuesday : 'Mardi',
        type : 'Type',
        typeAndDocumentNumber : 'Type et document',
        typeFinance : 'Type de financement',
        typeOfApprovalFlow : 'Type de flux d’approbation',
        typeOfEmitFlow : 'Type de flux d’émission',
        underReview : 'En examen',
        underReviewInvoices : 'Factures en cours d’examen',
        Unsubscribe : 'Se désabonner',
        unsuscribeRequest : 'Demande de désabonnement',
        until : 'Jusqu’à',
        updateBank : 'Actualiser la banque',
        updatedBillingCycle : 'Cycle de facturation mis à jour',
        updatedBillingCycles : 'Cycles de facturation mis à jour',
        updateInfo : 'Mettre à jour l’information',
        updateInformation : 'Mettre à jour l’information',
        updatePlatformFaq : 'Modifier la FAQ de la plateforme',
        uploadInvoice : 'Charger la facture',
        uploadManuals : 'Charger les manuels',
        user : 'Utilisateur',
        user_list : 'Liste d’utilisateurs',
        userBillingCycles : 'Cycles de facturation d’utilisateurs',
        userManual : 'Manuel d’utilisation',
        username : 'Nom d’utilisateur',
        userQuery : 'Consulter l’utilisateur',
        users : 'Utilisateurs',
        validCard : 'Vérifié',
        validEmail : 'Vous devez entrer des adresses faisant partie de @baccredomatic.com',
        validity : 'Validité',
        valorNeto : 'Valeur nette',
        vendorMerchantAffiliateCodes : 'Numéro d’affilié du fournisseur : {{provider}}',
        verificationCode : 'Code de vérification',
        verificationCodeSent : 'Entrer le code de vérification que vous avez reçu par courriel',
        verifyHuman : 'Veuillez confirmer que vous êtes un utilisateur humain',
        view : 'Voir',
        viewTta : 'Voir la Carte',
        virtualAccount : 'Compte virtuel',
        VOD : 'Vide',
        volumeProcessed : 'Volume traité au cours des trois derniers mois',
        waitingBillingCycle : 'En attente de l’attribution du cycle de facturation',
        waitingForApproval : 'En attente de l’approbation du service pour quitter le service',
        webpay : 'Webpay',
        wednesday : 'Mercredi',
        whatPaymentCondition : 'Quelle condition de paiement voulez-vous utiliser?',
        yearly : 'Annuel',
        yes : 'Oui',
        yourDataWillBeStoredEncrypted : '*Vos données seront chiffrées et conservées selon des normes de sécurité rigoureuses.',
        '/accept-discount-rate' : 'Accepter le taux d’escompte',
        '/account-details' : 'Détails du compte',
        '/account-profile' : 'Profil de compte',
        '/admin-bank-list' : 'Banques d’administration',
        '/admin-routes' : 'Attribuer des identifiants à des rôles ou en retirer',
        '/approval-flow-list' : 'Afficher le flux d’approbation',
        '/approved-checks' : 'Vérifications approuvées',
        '/approved-invoices' : 'Factures approuvées',
        '/bank-list' : 'Afficher, modifier ou désactiver des banques',
        '/bank-user-list' : 'Liste de clients de banque',
        '/banking-executives' : 'Services administratifs',
        '/billing-cycles' : 'Cycles de facturation',
        '/check-deposit' : 'Chèque à déposer',
        '/clients' : 'Clients',
        '/commerce-code' : 'Code commercial',
        '/confirm-customer' : 'Confirmer le client',
        '/confirm-provider' : 'Confirmer le fournisseur',
        '/contracted-products' : 'Produits visés par un contrat',
        '/create-admin-bank' : 'Créer une banque d’administration',
        '/create-approval-flow' : 'Créer un flux d’approbation de factures',
        '/create-bank-user' : 'Créer un client de banque',
        '/create-banking-executive' : 'Créer des services administratifs',
        '/create-customer' : 'Créer des clients',
        '/create-lead' : 'Clients ou fournisseurs requis',
        '/create-payment-condition' : 'Créer une nouvelle condition de paiement',
        '/create-profile' : 'Créer le profil',
        '/create-provider' : 'Créer des fournisseurs',
        '/credit-request' : 'Crédits et prêts',
        '/credits-list' : 'Inscription du crédit',
        '/customer-group-load' : 'Groupe de clients',
        '/customers' : 'Afficher, modifier ou désactiver les clients',
        '/dashboard' : 'Résumé',
        '/deposit' : 'Pour déposer',
        '/edit-admin-bank' : 'Modifier une banque d’administration',
        '/edit-approval-flow' : 'Modifier le flux d’approbation de factures',
        '/edit-bank-admin' : 'Modifier l’administrateur de banque',
        '/edit-bank-user' : 'Modifier un client de banque',
        '/edit-billing-cycle' : 'Modifier le cycle de facturation',
        '/edit-credentials' : 'Modifier les identifiants',
        '/edit-customer' : 'Modifier des clients',
        '/edit-payment-condition' : 'Modifier la condition de paiement',
        '/edit-provider' : 'Modifier des fournisseurs',
        '/expired-invoices' : 'Afficher les factures expirées',
        '/extract' : 'Extraire',
        '/financing-products' : 'Produits de financement',
        '/invoice-detail' : 'Détails de la facture',
        '/invoices' : 'Factures',
        '/lead-list' : 'Affichage, approbation ou rejet requis',
        '/list-cards' : 'Liste de Cartes',
        '/list-customer' : 'Liste de clients',
        '/list-faqs' : 'Afficher la FAQ',
        '/list-manual' : 'Manuels',
        '/list-provider' : 'Liste de fournisseurs',
        '/load-billing-cycle' : 'Charger le cycle de facturation',
        '/load-executives' : 'Charger les utilisateurs/rôles',
        '/load-invoice-group' : 'Groupe de factures',
        '/load-invoice' : 'Charger les factures',
        '/log-view' : 'Journaux',
        '/mixed-payments' : 'Programme Paiement mixte',
        '/monthly-report' : 'Rapport mensuel',
        '/not-approved-checks' : 'Chèques à approuver',
        '/operation-log' : 'Journaux',
        '/paid-invoices' : 'Factures payées',
        '/payment-conditions-log' : 'Inscription des conditions de paiement',
        '/payment-conditions' : 'Conditions de paiement',
        '/payment-request-received' : 'Demandes',
        '/payment-request-sent' : 'Demandes',
        '/pending-invoices' : 'Factures en attente',
        '/prepaid-Card' : 'Carte prépayée',
        '/product-detail' : 'Renseignements sur le produit',
        '/products' : 'Produits',
        '/profile-list' : 'Afficher, modifier ou supprimer des profils',
        '/provider-group-load' : 'Groupe de fournisseurs',
        '/providers' : 'Fournisseurs',
        '/register-bank' : 'Créer des banques',
        '/register-card' : 'Inscrire la Carte',
        '/register-financing-products' : 'Créer le produit de financement',
        '/register-role' : 'Créer des rôles',
        '/register-route' : 'Créer des identifiants',
        '/reject-invoices' : 'Factures rejetées',
        '/rejected-checks' : 'Chèques sans provision',
        '/related-users' : 'Client ou fournisseurs liés, // Vérifier',
        '/replace-card' : 'Remplacement de Carte de crédit',
        '/reports' : 'Rapports',
        '/resp' : 'Confirmation de stockage de Carte',
        '/review-invoices' : 'Factures en cours d’examen',
        '/role-list' : 'Afficher, modifier et éliminer des rôles',
        '/route-list' : 'Énumérer, modifier et supprimer les identifiants',
        '/save-credentials' : 'Enregistrer les identifiants',
        '/save-faq' : 'Charger la FAQ',
        '/save-manual' : 'Enregistrer le manuel',
        '/send-information' : 'Envoyer PRE',
        '/support' : 'Assiduité',
        '/transactions' : 'Factures générées par la banque',
        '/transfer' : 'Transférer',
        '/user-commerce-code' : 'Numéro d’affilié',
    }
}

export default fr;