import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Panel, TextInput, } from '../index';
import './styles.scss';
import Form from '../Form';

const InvoiceDetailForm = (props) => {
  const {
    t,
    onSubmit,
    handleInputChange,
    invoiceAmount,
    invoice_date,
    due_date,
    description,
    invoiceNumber,
    client,
    wf_status,
    sender,
    receiver,
    cardPayment,
    isEdit,
    message,
    cardMask,
    auth,
    days,
    cardCollectionDate,
    payProviderDate,
    receiverStatusId,
    issuing_bank,
    provider,
    status,
    updatePaid,
    wf_status_emit,
    discountRateCharges,
    discountRateChargesDescription,
    discountRateChargesDate,
    discountRateChargesDueDate,
    discountRateChargesInvoiceNumber,
    discountRateChargesDiscountRate,
    discountRateChargesPercentage,
    discountRateChargesTotal,
    currency,
    rate,
    isRate,
    approvalDate,
    commerceCode,
    descriptionCommerceCode,
  } = props;
  return (
    <div className="columns">
      <div className="column is-full">
        {rate !== true && (
          <Panel headingText={t('invoiceDetail')}>
            {issuing_bank && (
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column"></div>
                  <div className="column" align="right">
                    <button
                      className="button is-success"
                      onClick={updatePaid}
                    >
                      {t('markAsPaid')}
                    </button>
                  </div>
                </div>
              </div>
            )
            }
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <TextInput
                      name="invoice_date"
                      label={t('invoiceIssueDate')}
                      value={invoice_date}
                      onChange={handleInputChange}
                      type="text"
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      icon=""
                      iconPosition=""
                      disabled={isEdit}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="approvalDate"
                    type="text"
                    label={t('approvalDate')}
                    value={approvalDate}
                    onChange={handleInputChange}
                    inputClasses="has-text-left"
                    iconPosition=""
                    icon=""
                    disabled={isEdit}
                  />
                </div>
                <div className="column is-two-third">
                  <TextInput
                    name="invoice_number"
                    type="text"
                    label={t('invoicesNumber')}
                    value={invoiceNumber}
                    onChange={handleInputChange}
                    inputClasses="has-text-right"
                    iconPosition=""
                    icon=""
                    disabled={isEdit}
                  />
                </div>
                {/* <div className="column is-two-third">
                <TextInput
                  name="due_date"
                  type="text"
                  label={t('cardPaymentDate')}
                  value={due_date}
                  onChange={handleInputChange}
                  inputClasses="has-text-left"
                  iconPosition=""
                  icon=""
                  disabled={isEdit}
                />
              </div>*/}
              </div>
            </div>
            {issuing_bank &&
            (
              <div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column is-one-third select-lead-require">
                      <div className="width-select-lead-require">
                        <TextInput
                          name="client"
                          label={t('customer')}
                          onChange={handleInputChange}
                          selectClasses="is-fullwidth"
                          controlClasses="has-icons-left"
                          icon="far fa-user"
                          iconPosition="is-left"
                          value={client}
                          disabled={isEdit}
                        />
                      </div>
                    </div>
                    <div className="column is-two-third">
                      <TextInput
                        name="invoice_description"
                        label={t('description')}
                        value={description}
                        onChange={handleInputChange}
                        placeholder=""
                        fieldClasses="is-expanded"
                        controlClasses="is-expanded"
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-body columns">
                    <div className="column is-one-third select-lead-require">
                      <div className="width-select-lead-require">
                        <TextInput
                          name="provider"
                          label={t('provider')}
                          onChange={handleInputChange}
                          selectClasses="is-fullwidth"
                          controlClasses="has-icons-left"
                          icon="far fa-user"
                          iconPosition="is-left"
                          value={provider}
                          disabled={isEdit}
                        />
                      </div>
                    </div>
                    <div className="column is-two-third">
                      <TextInput
                        name="status"
                        label={t('status')}
                        value={status}
                        onChange={handleInputChange}
                        placeholder=""
                        fieldClasses="is-expanded"
                        controlClasses="is-expanded"
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
            }
            {!issuing_bank && (
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-one-third select-lead-require">
                    <div className="width-select-lead-require">
                      <TextInput
                        name="client"
                        label={sender ? t('provider') : t('customer')}
                        onChange={handleInputChange}
                        selectClasses="is-fullwidth"
                        controlClasses="has-icons-left"
                        icon="far fa-user"
                        iconPosition="is-left"
                        value={client}
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                  <div className="column is-two-third">
                    <TextInput
                      name="invoice_description"
                      label={t('description')}
                      value={description}
                      onChange={handleInputChange}
                      placeholder=""
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      disabled={isEdit}
                    />
                  </div>
                </div>
              </div>
            )
            }
            {receiver && (
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-one-third select-lead-require">
                    <div className="width-select-lead-require">
                      <TextInput
                        name="commerceCode"
                        label={t('merchantAffiliateCode')}
                        onChange={handleInputChange}
                        selectClasses="is-fullwidth"
                        controlClasses="has-icons-left"
                        icon="fas fa-store"
                        iconPosition="is-left"
                        value={commerceCode}
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                  <div className="column is-two-third">
                    <TextInput
                      name="descriptionCommerceCode"
                      label={t('description')}
                      value={descriptionCommerceCode}
                      onChange={handleInputChange}
                      placeholder=""
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      disabled={isEdit}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="field is-horizontal">
              <div className="field-body columns">
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    <TextInput
                      name="due_date"
                      label={sender ? t('cardChargeDate') : t('paymentDate')}
                      value={due_date}
                      onChange={handleInputChange}
                      type="text"
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      icon=""
                      iconPosition=""
                      disabled={isEdit}
                    />
                  </div>
                </div>
                <div className="column is-one-third select-lead-require">
                  <div className="width-select-lead-require">
                    {sender ? <br/> : ''}
                    <TextInput
                      name="days"
                      label={t('arrangedDays')}
                      value={days}
                      onChange={handleInputChange}
                      type="text"
                      inputClasses="has-text-right"
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      icon=""
                      iconPosition=""
                      disabled={isEdit}
                    />
                  </div>
                </div>
                <div className="column is-two-third">
                  {sender ? <br/> : ''}
                  <TextInput
                    name="invoiceAmount"
                    label={t('totalToPayInvoice')}
                    onChange={handleInputChange}
                    value={invoiceAmount}
                    inputClasses="has-text-right"
                    /*controlClasses="has-icons-right"
                    icon="fas fa-dollar-sign"
                    iconPosition="is-right"*/
                    disabled={isEdit}
                    currency={currency}
                    width="89%"
                  />
                </div>
              </div>
            </div>
            {/*  {
            payProviderDate != null && (receiver || issuing_bank)
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('datePayment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {payProviderDate}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          }*/}
            {/*
            cardCollectionDate != null && sender
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('cardPaymentDate')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {cardCollectionDate}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          */}
            {/*
            message != null
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('message')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {message}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            )
          */}
            {/*
            wf_status_emit.length > 0 && receiver && !issuing_bank
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('emissionFlow')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    {Array.isArray(wf_status_emit) &&
                    (
                      wf_status_emit.map((flow, index) => (
                        <li key={index} style={{ marginLeft: '35px' }}>
                          <p>
                            <label>{flow.actual ? t('present') + ':' : t('transferState') + ':'}</label>
                            &nbsp;
                            {flow.actual || (flow.state === 'complete' ? 'Completado' : flow.state)}
                          </p>
                          <p>
                            <label>{t('inCharge') + ':'}</label>
                              &nbsp;
                              {flow.role || flow.responsable}
                          </p>
                          <p>
                            <label>Status:</label>
                            &nbsp;
                            {flow.status}
                          </p>
                          <br/>
                        </li>
                      ))
                    )
                    } {Array.isArray(wf_status_emit) === false && (
                    <li style={{ marginLeft: '35px' }}>
                      <p>
                        {wf_status_emit}
                      </p>
                    </li>
                  )}{/*{
                    receiver && (
                      <li style={{ marginLeft: '35px' }}>
                        <p>
                          {wf_status === 'complete' ? 'Completado' : wf_status}
                        </p>
                      </li>
                    )
                  }*/}
            {/*     </ul>
                </div>
              </div>
            )
          */}

            {/* sender && Array.isArray(wf_status) === false &&
          <div>
            <div className="field is-horizontal title is-3 text-black">
              {t('flowApproval')}
            </div>
            <div className="field is-horizontal">
              <p>
                {wf_status === 'complete' ? 'Completado' : wf_status}
              </p>
            </div>
          </div>
          */}

            {/*
            Array.isArray(wf_status) === true && !issuing_bank
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black"
                     style={{ display: (wf_status.length === 0 ? 'none' : '') }}>
                  {t('flowApproval')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    {
                      sender &&
                      (
                        wf_status.map((flow, index) => (
                          <li key={index} style={{ marginLeft: '35px' }}>
                            <p>
                              <label>{flow.actual ? t('present') + ':' : t('transferState') + ':'}</label>
                              &nbsp;
                              {flow.actual || (flow.state === 'complete' ? 'Completado' : flow.state)}
                            </p>
                            <p>
                              <label>{t('inCharge') + ':'}</label>
                              &nbsp;
                              {flow.role || flow.responsable}
                            </p>
                            <p>
                              <label>Status:</label>
                              &nbsp;
                              {flow.status}
                            </p>
                            <br/>
                          </li>
                        ))
                      )
                    } {
                    receiver && wf_status_emit.length === 0 && (
                      <li style={{ marginLeft: '35px' }}>
                        <p>
                          {wf_status === 'complete' ? 'Completado' : wf_status}
                        </p>
                      </li>
                    )
                  }
                  </ul>
                </div>
              </div>
            )
          */} {/*Array.isArray(wf_status) === false && !issuing_bank && !receiver
        && (
          <div>
            <div className="field is-horizontal title is-3 text-black"
                 style={{ display: (wf_status.length > 0 ? '' : 'none') }}>
              {t('flowApproval')}
            </div>
            <li style={{ marginLeft: '35px' }} style={{ display: (receiver ? '' : 'none') }}>
              <p>
                {wf_status}
              </p>
            </li>
          </div>
        )
        */}
            {/*
            cardPayment.length > 0 && sender
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('payment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    {
                      cardPayment.map((card, index) => (
                        <li key={card.id} style={{ marginLeft: '35px' }}>
                          {
                            sender && (
                              <p>
                                <label>{t('selectCard') + ':'}</label>
                                &nbsp;
                                {card.number}
                              </p>
                            )
                          }
                          {
                            auth !== '' &&
                            (
                              <p>
                                <label>{t('authorization') + ':'}</label>
                                &nbsp;
                                {auth}
                              </p>
                            )
                          }
                          {
                            sender &&
                            (
                              <p>
                                <label>{t('billingCycle') + ':'}</label>
                                &nbsp;
                                {card.collection_date}
                              </p>
                            )
                          }
                        </li>
                      ))

                    }
                  </ul>
                </div>
              </div>
            )
          */}
            {
              cardPayment.collection_date !== null && sender && (receiverStatusId === 'PEJ' || receiverStatusId === 'PAG')
              && (
                <div>
                  <div className="field is-horizontal title is-3 text-black">
                    {t('cardPayment')}
                  </div>
                  <div className="field is-horizontal">
                    <ul style={{ marginBottom: '3px' }}>
                      {
                        <li style={{ marginLeft: '35px' }}>
                          {
                            sender && (
                              <p>
                                <label>{t('selectCard') + ':'}</label>
                                &nbsp;
                                {cardPayment.number}
                              </p>
                            )
                          }
                          {/*
                          auth !== '' &&
                          (
                            <p>
                              <label>{t('authorization') + ':'}</label>
                              &nbsp;
                              {auth}
                            </p>
                          )
                        */}
                          {
                            sender &&
                            (
                              <p>
                                <label>{t('billingCycle') + ':'}</label>
                                &nbsp;
                                {cardPayment.collection_date}
                              </p>
                            )
                          }
                        </li>
                      }
                    </ul>
                  </div>
                  <br/>
                </div>
              )
            }
            {sender && discountRateCharges !== null && (
              <div className="Panel1">
                <div
                  className="columns is-centered"
                  style={{ animationDelay: '1s' }}
                >
                  <div className="column is-full wow animated fadeInUp">
                    <div
                      className={'title is-4 panel-header bg-panel text-white'}
                      style={{ background: '#016fd0' }}>
                      {t('financingRate')}
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-third select-lead-require">
                          <div className="width-select-lead-require">
                            <TextInput
                              name="discountRateChargesDate"
                              label={t('invoiceIssueDate')}
                              value={discountRateChargesDate}
                              onChange={handleInputChange}
                              type="text"
                              fieldClasses="is-expanded"
                              controlClasses="is-expanded"
                              icon=""
                              iconPosition=""
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                        <div className="column is-two-third">
                          <TextInput
                            name="discountRateChargesInvoiceNumber"
                            type="text"
                            label={t('invoicesNumber')}
                            value={discountRateChargesInvoiceNumber}
                            onChange={handleInputChange}
                            inputClasses="has-text-right"
                            iconPosition=""
                            icon=""
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-third select-lead-require">
                          <div className="width-select-lead-require">
                            <TextInput
                              name="client"
                              label={sender ? t('provider') : t('customer')}
                              onChange={handleInputChange}
                              selectClasses="is-fullwidth"
                              controlClasses="has-icons-left"
                              icon="far fa-user"
                              iconPosition="is-left"
                              value={t('bankProvider')}
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                        <div className="column is-two-third">
                          <TextInput
                            name="discountRateChargesDescription"
                            label={t('description')}
                            value={discountRateChargesDescription}
                            onChange={handleInputChange}
                            placeholder=""
                            fieldClasses="is-expanded"
                            controlClasses="is-expanded"
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-third select-lead-require">
                          <div className="width-select-lead-require">
                            <TextInput
                              name="discountRateChargesDueDate"
                              label={t('cardChargeDate')}
                              value={discountRateChargesDueDate}
                              onChange={handleInputChange}
                              type="text"
                              fieldClasses="is-expanded"
                              controlClasses="is-expanded"
                              icon=""
                              iconPosition=""
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                        <div className="column is-two-third">
                          <div className="width-select-lead-require">
                            {sender ? <br/> : ''}
                            <TextInput
                              name="discountRateChargesDiscountRate"
                              label={t('financingRate')}
                              value={discountRateChargesDiscountRate}
                              onChange={handleInputChange}
                              type="text"
                              fieldClasses="is-expanded"
                              inputClasses="has-text-right"
                              controlClasses="has-icons-right"
                              icon="fas fa-percentage"
                              iconPosition="is-right"
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field is-horizontal">
                      <div className="field-body columns">
                        <div className="column is-one-third select-lead-require">
                          <div className="width-select-lead-require">
                            <TextInput
                              name="discountRateChargesPercentage"
                              label={t('cardholderPaymentPercentage')}
                              onChange={handleInputChange}
                              value={discountRateChargesPercentage}
                              inputClasses="has-text-right"
                              controlClasses="has-icons-right"
                              icon="fas fa-percentage"
                              iconPosition="is-right"
                              disabled={isEdit}
                            />
                          </div>
                        </div>
                        <div className="column is-two-third">
                          <TextInput
                            name="discountRateChargesTotal"
                            label={t('totalToPayCardholder')}
                            onChange={handleInputChange}
                            value={discountRateChargesTotal}
                            inputClasses="has-text-right"
                            //controlClasses="has-icons-right"
                            //icon="fas fa-dollar-sign"
                            iconPosition="is-right"
                            disabled={isEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/*
            auth !== '' && receiver && receiverStatusId !== 'PAP' && receiverStatusId !== 'REC' && receiverStatusId !== 'PEM'
            && (
              <div>
                <div className="field is-horizontal title is-3 text-black">
                  {t('payment')}
                </div>
                <div className="field is-horizontal">
                  <ul style={{ marginBottom: '3px' }}>
                    <li style={{ marginLeft: '35px' }}>
                      {
                        auth !== '' &&
                        (
                          <p>
                            <label>{t('authorization') + ':'}</label>
                            &nbsp;
                            {auth}
                          </p>
                        )
                      }
                    </li>
                  </ul>
                </div>
              </div>
            )
          */}
            <br/>
          </Panel>
        )}
        {rate && (
          <Panel headingText={t('discountRateCharges')}>
            <Form
              submitText={t('save')}
              onSubmit={(event) => {
                event.preventDefault();
                onSubmit();
              }}
              buttonClasses={`mr-8px`}
            >
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-one-third select-lead-require">
                    <div className="width-select-lead-require">
                      <TextInput
                        name="discountRateChargesDate"
                        label={t('invoiceDate')}
                        value={discountRateChargesDate}
                        onChange={handleInputChange}
                        type="text"
                        fieldClasses="is-expanded"
                        controlClasses="is-expanded"
                        icon=""
                        iconPosition=""
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                  <div className="column is-two-third">
                    <TextInput
                      name="discountRateChargesInvoiceNumber"
                      type="text"
                      label={t('invoicesNumber')}
                      value={discountRateChargesInvoiceNumber}
                      onChange={handleInputChange}
                      inputClasses="has-text-right"
                      iconPosition=""
                      icon=""
                    />
                  </div>
                  <div className="column is-two-third">
                    <TextInput
                      name="client"
                      label={sender ? t('provider') : t('customer')}
                      onChange={handleInputChange}
                      selectClasses="is-fullwidth"
                      controlClasses="has-icons-left"
                      icon="far fa-user"
                      iconPosition="is-left"
                      value={t('bankProvider')}
                      disabled={isEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column is-two-third">
                    <TextInput
                      name="discountRateChargesDescription"
                      label={t('description')}
                      value={discountRateChargesDescription}
                      onChange={handleInputChange}
                      placeholder=""
                      fieldClasses="is-expanded"
                      controlClasses="is-expanded"
                      disabled={isEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <div className="width-select-lead-require">
                      <TextInput
                        name="discountRateChargesDueDate"
                        label={t('chargeDate')}
                        value={discountRateChargesDueDate}
                        onChange={handleInputChange}
                        type="text"
                        fieldClasses="is-expanded"
                        controlClasses="is-expanded"
                        icon=""
                        iconPosition=""
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                  <div className="column">
                    <div className="width-select-lead-require">
                      <TextInput
                        name="discountRateChargesDiscountRate"
                        label={t('financingRate')}
                        value={discountRateChargesDiscountRate}
                        onChange={handleInputChange}
                        type="text"
                        fieldClasses="is-expanded"
                        inputClasses="has-text-right"
                        controlClasses="has-icons-right"
                        icon="fas fa-percentage"
                        iconPosition="is-right"
                        disabled={isEdit}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-body columns">
                  <div className="column">
                    <TextInput
                      name="discountRateChargesPercentage"
                      label={t('senderDiscountShare')}
                      onChange={handleInputChange}
                      value={discountRateChargesPercentage}
                      inputClasses="has-text-right"
                      controlClasses="has-icons-right"
                      icon="fas fa-percentage"
                      iconPosition="is-right"
                      disabled={isEdit}
                    />
                  </div>
                  <div className="column">
                    <TextInput
                      name="discountRateChargesTotal"
                      label={t('totalPay')}
                      onChange={handleInputChange}
                      value={discountRateChargesTotal}
                      placeholder="00"
                      inputClasses="has-text-right"
                      currency={currency}
                      width="85%"
                      type="number"
                      controlClasses="is-expanded has-icons-left"
                      min="1"
                      max="999999999999999"
                      // step="0.01"
                      iconPosition="is-left"
                      icon="far fa-usd"
                      disabled={isRate}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Panel>
        )}
      </div>
    </div>
  );
};

InvoiceDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  invoiceAmount: PropTypes.string,
  isEdit: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  wf_status: PropTypes.arrayOf(PropTypes.object),
  wf_status_emit: PropTypes.arrayOf(PropTypes.object),
  detailAmount: PropTypes.shape({}),
  invoiceDate: PropTypes.string,
  due_date: PropTypes.instanceOf(Date).isRequired,
  description: PropTypes.string,
  invoiceNumber: PropTypes.string,
  selectedClient: PropTypes.number,
  file: PropTypes.string,
  client: PropTypes.string,
  sender: PropTypes.bool,
  receiver: PropTypes.bool,
  issuing_bank: PropTypes.bool,
  cardPayment: PropTypes.array,
  message: PropTypes.string,
  cardMask: PropTypes.string,
  auth: PropTypes.string,
  cardCollectionDate: PropTypes.string,
};

InvoiceDetailForm.defaultProps = {
  detailAmount: {},
  invoiceAmount: '',
  description: '',
  invoiceNumber: '',
  invoiceDate: '',
  band: false,
};

export default withNamespaces()(InvoiceDetailForm);
