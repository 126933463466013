import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import InformativeBox from './InformativeBox';
import { formatAmount } from '../../util';

const InformativeBoxes = (props) => {
  const {
    t, items, size, className,
  } = props;
  return (
    <div className={`columns is-multiline informative-boxes is-centered ${className}`}>
      {!!items && items.map(item => (
        <InformativeBox
          key={item.title}
          title={t(item.title)}
          bigValue={item.value}
          bigValue1={item.bigValue1}
          typeCurrency={item.typeCurrency}
          typeCurrency1={item.typeCurrency1}
          icon={item.icon}
          size={size}
        />
      ))}
    </div>
  );
};

InformativeBoxes.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(Object),
  size: PropTypes.string,
  className: PropTypes.string,
};

InformativeBoxes.defaultProps = {
  items: [],
  size: '',
  className: '',
};

export default withNamespaces()(InformativeBoxes);
