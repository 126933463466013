import React, { useState, useEffect } from 'react';
import './styles.scss';
import { Panel } from '../../../../components';
import { formatCurrencyCl, formatFriendlylongDate } from '../../../../util';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withNamespaces } from 'react-i18next';
import { creditsActions } from '../../../../state/ducks/credits';
import { transactionsActions } from '../../../../state/ducks/transactions';
import { providersActions } from '../../../../state/ducks/providers';
import { clientsActions } from '../../../../state/ducks/clients';
import { statsActions } from '../../../../state/ducks/stats';
import { TOAST_CONFIG } from '../../../../config/constants';

const ConfirmCredit = ({ 
    handleStep,
    onToggle,
    saveCredit,
    updateAmounts,
    setUsedFor,
    advanceInvoice,
    approveInvoice,
    listTransactionsSender,
    listTransactionsReceiver,
    fetchStats,
    showMessage,
    credit,
    credit: { 
        accountNumber, 
        availableAmount, 
        amount, 
        installments, 
        charge, 
        installmentAmount },
    t,
    credits: { usedFor, availableAmounts: { accountCredit, preApprovedCredit } },
    checks: { notApprovedChecks },
    transactions: { currentInvoice: { payment_condition_id, advanceCondition, invoice_amount, invoice_number, paymentWay, requiredAmount } },
    transactions,
    auth: { user: { user_id, profile, b2b: { type_user } } },
    auth,
}) => {

    const [acceptTerms, setAcceptTerms] = useState(true)
    const [blockedAmount, setBlockedAmount] = useState(0);

    useEffect(() => {
        setBlockedAmount(notApprovedChecks.reduce((acc, curr) => acc + curr.amount, 0))
    }, [])

    const advancePayment = (transaction_id, condition_id, onToggle) => {
        const { user: { b2b: { id } } } = auth;
        // const {
        //   advanceInvoice,
        //   listTransactionsReceiver,
        //   fetchStats,
        //   auth: {
        //     user: {
        //       b2b: { id },
        //     },
        //   },
        // } = this.props;
        // const { days } = this.state;
        const data = {
          transaction_id,
          condition_id,
          days: 1,
        };
        advanceInvoice({
          data,
          callback: () => {
            showMessage({
              message: t('advanceSuccessful'),
              config: TOAST_CONFIG.SUCCESS,
            });
            listTransactionsReceiver({ params: { user_id: id } });
            fetchStats();
            // onToggle();
          },
        });
    };

    const approveInvoices = (transaction_id/*, onToggle*/) => {
        const data = {
          transaction_id,
          approved: true,
          user_id: profile.user_id,
          role_id: profile.role_id,
          provider: type_user === 'seller' ? true : false,
          file_sii: '',
          file_pass: '',
        //   file_sii: binaryFile,
        //   file_pass: password,
        };
    
        approveInvoice({
          data,
          callback: (resp) => {
            type_user === 'buyer' ? listTransactionsSender({ params: { user_id } })
              : listTransactionsReceiver({ params: { user_id } });
            if (resp.process) {
              showMessage({
                message: t('invoiceApprovedSuccess'),
                config: TOAST_CONFIG.SUCCESS,
              });
            } else {
              showMessage({
                message: t('actionNotAllowed'),
                config: TOAST_CONFIG.ERROR,
              });
            }
            //  onToggle();
          },
        });
        //onToggle();
    };
    

    return (
      <>
        <div className='ConfirmCredit__main'>
            {usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine' ? (
                    <>
                        <div className='Panel__extra-title'>
                            <p>{t('accountBalance')}</p>
                            <p>${formatCurrencyCl(accountCredit)}</p>
                        </div>
                        {/* <div className='Panel__extra-title'>
                            <p>Saldo bloqueado</p>
                            <p>${formatCurrencyCl(blockedAmount)}</p>
                        </div> */}
                    </>
                ) : (
                    <div className='Panel__extra-title'>
                        <p>{t('balanceToPay')}</p>
                        <p>${formatCurrencyCl(invoice_amount)}</p>
                    </div>
                )
            }
            <Panel>
              <div className='ConfirmCredit__content'>  
                <div className='confirm-field'>
                    <p>{usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine' ? t('accountNumber') : t('invoicesNumber')}</p>
                    <p>{usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine' ? accountNumber : invoice_number}</p>
                </div>
                {usedFor !== 'advanceWithLoan' &&
                   <div className='confirm-field'>
                     <p>{t('creditAvailableBalance')}</p>
                     <p>${formatCurrencyCl(preApprovedCredit-amount)}</p>
                  </div>
                }
                <div className='confirm-field'>
                    <p>{t('amountToRequest')}</p>
                    {usedFor === 'creditLine' || usedFor === 'advanceWithCreditLine' ?
                     <p>${formatCurrencyCl(amount)}</p> : 
                     usedFor === 'approveWithMixedPayment' ? 
                     <p>${formatCurrencyCl(requiredAmount)}</p> :
                     <p>${formatCurrencyCl(invoice_amount)}</p>
                    }
                </div>
                <div className='confirm-field'>
                    <p>{t('applicationDate')}</p>
                    <p>{formatFriendlylongDate(new Date().getTime())}</p>
                </div>
                <div className='confirm-field'>
                    <p>{t('installmentsQty')}</p>
                    <p>{installments}</p>
                </div>
                <div className='confirm-field'>
                    <p>{t('chargeRate')}</p>
                    <p>{charge}%</p>
                </div>
                <div className='confirm-field'>
                    <p>{t('amountInstallments')}</p>
                    <p>${formatCurrencyCl(installmentAmount)}</p>
                </div>
                <div className='accept-terms'>
                    <input 
                      type='checkbox'
                      onChange={() => setAcceptTerms(!acceptTerms)}/>
                    <p>{t('acceptTerms')}</p>
                </div>
                <div className='confirm-BTNS'>
                    <button 
                      className='button custom-btn is-danger'
                      onClick={() => handleStep('CreditRequestForm')}>
                          {t('return')}
                    </button>
                    <button 
                      className='button custom-btn is-primary'
                      disabled={acceptTerms}
                      onClick={() => {
                        if (usedFor === 'creditLine') {
                            saveCredit({...credit, requestDate: new Date().getTime()})
                            updateAmounts({
                                preApprovedCredit: preApprovedCredit - amount, 
                                accountCredit: accountCredit + amount,
                            })
                        } else {
                            saveCredit({...credit, amount: invoice_amount, requestDate: new Date().getTime()})
                            updateAmounts({
                                preApprovedCredit: preApprovedCredit - Number(invoice_amount), 
                                // accountCredit: accountCredit + Number(invoice_amount),
                            })
                          }
                          
                        (usedFor === 'approveWithCredit' || usedFor === 'approveWithMixedPayment') && approveInvoices(transactions.currentInvoice.id)
                        type_user === 'seller' && advancePayment(transactions.currentInvoice.id, payment_condition_id)
                        setUsedFor('creditLine')
                        onToggle()
                      }}>
                              {t('confirm')}
                    </button>
                </div>
              </div>
            </Panel>
        </div>
      </>
    );
};

const mapStateToProps = ({ transactions, credits, checks, auth, }) => ({ 
    transactions,
    credits,
    checks,
    auth,
 })

export default compose(
    connect(mapStateToProps, {
      ...creditsActions, 
      ...providersActions,
      ...clientsActions,
      ...transactionsActions,
      ...statsActions,
    }),
    withNamespaces(),
)(ConfirmCredit);