import React, { useEffect, useState } from 'react';
import { Panel, Modal, TextInput, Toggle, Loading, SelectSingle } from '../../components';
import { providersActions } from '../../state/ducks/providers';
import { clientsActions } from '../../state/ducks/clients';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { API_URL, TOAST_CONFIG } from '../../config/constants';
import { csvToArray, getPath } from '../../util';

const LoadInvoiceGroup = ({ 
    showMessage, 
    t, 
    history, 
    loadInvoice,
    transactions: { balance, charge, loading: transactionLoading },
    getPaymentCommerceCode,
    providers: { loading: providerLoading, type },
    clients: { list: clientsList },
    auth: { user: { b2b: { id } } },
    getClients,
 }) => {
    const [binaryFile, setBinaryFile] = useState(''),
    [invoices, setInvoices] = useState([]),
    [buyerId, setBuyerId] = useState('');
    // [invoices, setInvoices] = useState({
    //     file: '',
    //     binaryFile: '',
    //     fileZip: '',
    //     binaryFileZip: '',
    // });

    useEffect(() => {
      getClients({
        params: {
          id,
          related: true,
        },
      });
    }, [])
    const handleFileChange = (event) => {
        const {
          target: {
            checked, value: targetValue, type, name,
          },
        } = event;
        // const {
        //   t,
        //   showMessage,
        // } = this.props;
        const files = Array.from(event.target.files);
        const arrays = files[0].name;
        let ext = arrays.split('.');
    
        if (name === 'fileCsv') {
          if (ext[1] === 'csv') {
            setBinaryFile({
              binaryFile: files[0],
              file: event.target.value,
            });
          } else {
            showMessage({
              message: t('errorLoad', { type: 'csv' }),
              config: TOAST_CONFIG.WARNING,
            });
          }
        }
        if (name === 'fileZip') {
          if (ext[1] === 'zip') {
            if (parseInt(files[0].size / 1024) > 51200) {
              showMessage({
                message: t('fileSizeExceeded'),
                config: TOAST_CONFIG.WARNING,
              });
            } else {
              setBinaryFile({
                binaryFileZip: files[0],
                fileZip: event.target.value,
              });
            }
          } else {
            showMessage({
              message: t('errorLoad', { type: 'zip' }),
              config: TOAST_CONFIG.WARNING,
            });
          }
        }
      };

    const handleSubmit = e => {
        e.preventDefault()
        // const input = csvFile.files[0];
        const input = binaryFile.binaryFile;
        const reader = new FileReader();

        reader.onload = function (e) {
            const text = e.target.result;
            const data = csvToArray(text);
            // document.write(JSON.stringify(data));
            // setInvoices(JSON.stringify(data));
            const formatedData = data.map(inv => Object.values(inv))
            setInvoices(formatedData);
            
        };
        
        reader.readAsText(input);
    }

    const sendInvoices = () => {
        // const {
        //   client,
        //   invoice_amount,
        //   invoice_date,
        //   invoice_number,
        //   description,
        //   binaryFile,
        //   detail_amount,
        //   commerceCode,
        // } = this.state;
        // const {
        //   t,
        //   auth: {
        //     user: {
        //       currency,
        //       b2b: {
        //         id,
        //       },
        //     },
        //   },
        //   clients,
        //   loadInvoice,
        //   history,
        //   showMessage,
        //   providers: { list },
        //   //loadSiiInvoice,
        // } = this.props;
    
        /*const year = invoice_date.getFullYear();
        const month = ("0" + (invoice_date.getMonth() + 1)).slice(-2);
        const sii_date = year+month;*/
    
        invoices.map((invoice, index) => {
            const data = {
              invoice_number: invoice[0],
              buyer_id: buyerId.id, //"610877d77de5116d195afe22",
              //date_sii: sii_date,
              buyer: buyerId.id, //"610877d77de5116d195afe22",
              seller: id, //"610010937de511040c37a6a2",
              amount: invoice[2].replaceAll('.', '')
                .replaceAll(',', '.'),
              detail_amount: '',
              date: new Date,
              description: invoice[4],
              file: '',
              commerce_code: buyerId.commerceCodes[0].id//13,
            };

            setTimeout(() => {
                loadInvoice({
                  data,
                  callback: () => {
                      if(index === invoices.length - 1) {
                          history.push('/review-invoices');
                          showMessage({
                            message: t('createInvoicesSuccess'),
                            config: TOAST_CONFIG.SUCCESS,
                          });
                      }
                    // this.resetState();
                  },
                  t,
                }, 300);
            })
        })
    
    }

    const handleClientChange = (newDate) => {
      // const {
      //   auth: { user: { b2b: { id } } },
      //   getPaymentCommerceCode,
      //   clients: { list },
      // } = this.props;
  
      getPaymentCommerceCode({
        params: {
          sender: clientsList[newDate.value].id,
          receiver: id,
        },
        callback: (response) => {
          setBuyerId({
            commerceCodes: response,
            commerceCode: -1,
            descriptionCommerceCode: '',
            id: newDate.id,
          });
        }
      });
  
      // this.setState({
      //   client: newDate.value,
      // });
    };

    return (
        <Toggle>
            {({ toggled, onToggle, toggleProps }) => {
                const loading = transactionLoading || providerLoading;
                return (
                    <>
                    {
                        toggled ? (
                            <Modal title="Do you want to upload these invoices?" onToggle={onToggle}>
                                {loading && <Loading />}
                                <table className='table' style={{ margin: '25px' }}>
                                    <thead>
                                        <tr>
                                            <th><abbr title={t('invoiceNumber')}>{t('invoiceNumber')}</abbr></th>
                                            <th><abbr title={t('commerceCode')}>{t('commerceCode')}</abbr></th>
                                            <th><abbr title={t('amount')}>{t('amount')}</abbr></th>
                                            <th><abbr title={t('buyer')}>{t('buyer')}</abbr></th>
                                            <th><abbr title={t('description')}>{t('description')}</abbr></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.map(invoice => {
                                            return (
                                                <tr key={invoice[0]}>
                                                    <td>{invoice[0]}</td>
                                                    <td>{invoice[1]}</td>
                                                    <td>{invoice[2]}</td>
                                                    <td>{invoice[3]}</td>
                                                    <td>{invoice[4]}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '75%', margin: '15px'}}>
                                    <button 
                                        style={{ width: '200px', margin: '0 10px' }}
                                        className='button custom-btn is-danger' 
                                        type="button"
                                        onClick={() => {
                                            onToggle()
                                        }}>
                                            {t('return')}
                                    </button>
                                    <button 
                                        style={{ width: '200px', margin: '0 10px' }}
                                        className='button custom-btn is-primary' 
                                        onClick={() => 
                                            sendInvoices()
                                        }
                                        >
                                            {t('approve')}
                                    </button>
                                </div>
                            </Modal>
                        ) : (
                            <Panel headingText={t('loadInvoicesGroup')}>
                                <form 
                                    style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
                                    onSubmit={e => {
                                        handleSubmit(e)
                                        onToggle()
                                    }}>
                                    <div style={{width: '250px', margin: '10px 0'}}>
                                      <SelectSingle 
                                        label={`${t('buyer')}`}
                                        options={[
                                          ...clientsList.map((person, index) => ({
                                            label: `${person.idPerson.name} - ${getPath(['idPerson', 'idDocumentType', 'document_type'], person)} ${person.idPerson.document_number}`,
                                            value: index,
                                            id: person.id,
                                          })),
                                        ]}
                                        onChange={handleClientChange}
                                        />
                                      </div>  
                                    <TextInput
                                        name="fileCsv"
                                        value={invoices.file}
                                        fileName={binaryFile !== '' ? binaryFile.binaryFile.name : binaryFile}
                                        onChange={handleFileChange}
                                        placeholder={t('loadCsv')}
                                        type="file"
                                        fieldClasses="file has-name"
                                        labelClasses="file-label"
                                        controlClasses="is-expanded has-icons-left file-label"
                                        inputClasses="has-text-right file-input"
                                        iconPosition="is-left"
                                        icon="fas fa-file-csv fa-2x"
                                        band={true}
                                        />
                                    {/* <TextInput
                                        name="fileZip"
                                        value={invoices.fileZip}
                                        // fileName={invoices.binaryFileZip !== '' ? invoices.binaryFileZip.name : invoices.binaryFileZip}
                                        onChange={handleFileChange}
                                        placeholder={t('loadZip')}
                                        type="file"
                                        fieldClasses="file has-name"
                                        labelClasses="file-label"
                                        controlClasses="is-expanded has-icons-left file-label"
                                        inputClasses="has-text-right file-input"
                                        iconPosition="is-left"
                                        icon="fas fa-file-archive fa-2x"
                                        band={true}
                                        /> */}
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '75%', margin: '15px'}}>
                                        <button 
                                            style={{ width: '200px', margin: '0 10px' }}
                                            className='button custom-btn is-danger' 
                                            type="button"
                                            onClick={() => {
                                                history.push('/dashboard')
                                            }}>
                                                {t('cancel')}
                                        </button>
                                        <button 
                                            style={{ width: '200px', margin: '0 10px' }}
                                            className='button custom-btn is-primary' 
                                            type='submit'
                                            disabled={binaryFile === ''}
                                            >
                                                {t('accept')}
                                        </button>
                                    </div>
                                </form>
                            </Panel> 
                        )
                        
                    }
                    </>
                )
            
            }}
        </Toggle>
    
        // step === 1 ?
        //     <Panel headingText="Load group of invoices">
        //         <form 
        //             style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}
        //             onSubmit={e => {
        //                 e.preventDefault()
        //                 setStep(2)
        //               }}>
        //             <TextInput
        //                 name="fileCsv"
        //                 value={invoices.file}
        //                 fileName={invoices.binaryFile !== '' ? invoices.binaryFile.name : invoices.binaryFile}
        //                 onChange={handleFileChange}
        //                 placeholder={t('loadCsv')}
        //                 type="file"
        //                 fieldClasses="file has-name"
        //                 labelClasses="file-label"
        //                 controlClasses="is-expanded has-icons-left file-label"
        //                 inputClasses="has-text-right file-input"
        //                 iconPosition="is-left"
        //                 icon="fas fa-file-csv fa-2x"
        //                 band={true}
        //                 />
        //             <TextInput
        //                 name="fileZip"
        //                 value={invoices.fileZip}
        //                 fileName={invoices.binaryFileZip !== '' ? invoices.binaryFileZip.name : invoices.binaryFileZip}
        //                 onChange={handleFileChange}
        //                 placeholder={t('loadZip')}
        //                 type="file"
        //                 fieldClasses="file has-name"
        //                 labelClasses="file-label"
        //                 controlClasses="is-expanded has-icons-left file-label"
        //                 inputClasses="has-text-right file-input"
        //                 iconPosition="is-left"
        //                 icon="fas fa-file-archive fa-2x"
        //                 band={true}
        //                   />
        //             <div style={{ display: 'flex', justifyContent: 'center', width: '75%', margin: '15px'}}>
        //                 <button 
        //                     style={{ width: '200px', margin: '0 10px' }}
        //                     className='button custom-btn is-danger' 
        //                     type="button"
        //                     onClick={() => {
        //                         history.push('/dashboard')
        //                     }}>
        //                         {t('cancel')}
        //                 </button>
        //                 <button 
        //                     style={{ width: '200px', margin: '0 10px' }}
        //                     className='button custom-btn is-primary' 
        //                     type='submit'
        //                     >
        //                         {t('accept')}
        //                 </button>
        //             </div>
        //         </form>
        //     </Panel> 
        // : step === 2 ? 
        //     <Modal title="Do you want to upload these invoices?">
        //         Paso 2 
        //     </Modal> 
        // : <div>Paso 3</div>
    )
};

// export default LoadInvoiceGroup;
const mapStateToProps = ({ documentType, role, providers, clients, router, transactions, auth }) => ({
        documentType,
        role,
        providers,
        clients,
        router, 
        transactions,
        auth,
    });

export default compose(
        connect(mapStateToProps, {
        // ...documentTypeActions,
        // ...roleActions,
        ...clientsActions,
        // ...authActions,
        ...providersActions
        }),
        withNamespaces(),
    )(LoadInvoiceGroup);
