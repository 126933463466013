import types from './types';
import { authTypes } from '../auth';
import { initialState } from '../../../App';

const credits = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.credits,
      };
    }
    case types.SAVE_CREDIT: {
      return {
        ...state,
        list: [...state.list, action.payload],
      };
    }
    case types.UPDATE_AMOUNTS: {
      return {
        ...state,
        availableAmounts: {...state.availableAmounts, ...action.payload}
      };
    }
    case types.SET_USED_FOR: {
      return {
        ...state,
        usedFor: action.payload
      };
    }
    default:
      return state;
  }
};

export default credits;
